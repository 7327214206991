import React from "react";

import ExtendedRecipeTile from '../../../components/ExtendedRecipeTile/ExtendedRecipeTile';
import ExtendedButton from "@src/stories/elements/ExtendedButton/ExtendedButton";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import PromotionTile from "@stories/ecommerce/PromotionalTile/PromotionTile";

export default function ExtendedRecipeListingList ({ translations, recipes, yotpo_snippet, setItemCount, itemCount, maxCount, loadCount, recipesCount, wishlist }) {
  const isMobile = useMobileBreakpoint('1024px');

  return (
    <div className="recipe-listing-list">
    { recipesCount == 0 && (
        <div className="recipe-listing-list__empty">
            {`${translations.empty_message}`}
        </div>
    )}
    { recipesCount > 0 && ( 
        <>
            <div className="recipe-listing-list__items">
                {recipes.map((recipe, index) => {  
                    if (recipe.type == "promo") {
                        if (!isMobile) {
                        return (
                            <PromotionTile
                            key={`${recipe.id}_${index}`}
                            promotion={recipe}
                            />
                        )
                        }
                        return null
                    }   
                
                return ( <ExtendedRecipeTile recipe={recipe} yotpo_snippet={yotpo_snippet} dummy_id={recipe.dummy_id} wishlist={wishlist} /> )
                })}
            </div>
        
        { itemCount <= maxCount &&  (
        <div className="recipe-listing-list__button"> 
            <ExtendedButton
            label={'Load More'}
            style="secondary"
            onClick={()=>{setItemCount(itemCount + loadCount)}}
            />
        </div>
        )}
      
      </>
    )}
      
    </div>
  );
};
