import React,  { useRef, useEffect, useState } from "react";

import {Headings} from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Headings/Headings";
import {Paragraph} from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Paragraph/Paragraph";
import ExtendedProductTile from "@src/stories/components/ExtendedProductTile/ExtendedProductTile";
import ExtendedButton from '@src/stories/elements/ExtendedButton/ExtendedButton';

import '.././ExtendedListProducts.scss'

export default function ListProductsDefault({ products, settings, translations, section_id, type, add_to_cart, localization, wishlist }) {

  const { autoPlay = 0 } = settings; 
  const { currency_symbol } = localization.country;
  const componentRef = useRef(null);
  const [ sliderVisible, setSliderVisible ] = useState(true)

  function goToSlide(action) {
    const event = new CustomEvent(`carousel-navigate`, {
      detail: { section_id, action }
    });

    window.dispatchEvent(event);
  }

  const [carouselInitialized, setCarouselInitialized] = useState(false);

  useEffect(() => {
    setTimeout(() => {
     setCarouselInitialized(true); // This will trigger a re-render
    }, 250);
  }, []);
  useEffect(() => {
    const divWithClass = componentRef.current && componentRef.current.querySelector('.flickity-viewport');
    if (componentRef.current) {
      if (divWithClass) {
        setSliderVisible(true);
      } else {
        setSliderVisible(false);
      }
    }
  }, [componentRef.current, componentRef, carouselInitialized]);

    return (
        <div className="product-slider default-list  pb-12 lg:pb-0" ref={componentRef}>
            <div className={'section-top px-8'}>
                <div className="product-slider-header"> 
                    <div className={'h3 lg:h2'}> {settings.title}  </div>
                    {sliderVisible && 
                        <div className='product-slider-header__buttons'>
                           <ExtendedButton
                               icon="chevron-left"
                               style='dark-primary'
                               className="product-slider-header__buttons-arrow prev"
                               onClick={() => goToSlide('previous')}
                           />
   
                           <ExtendedButton
                               icon="chevron-right"
                               style='dark-primary'
                               className="product-slider-header__buttons-arrow next"
                               onClick={() => goToSlide('next')}
                           />
                       </div>
                    }
                 
                </div>
              
                <Paragraph text={settings.subtext}/>
              
            </div>

            <div className="product-slider-carousel"
                {
                ...{
                    'x-data': "extended_carousel",
                    'x-init': `$nextTick(() => { initialise(true, false, false, true, ${autoPlay}, "${section_id}") })`,
                    'x-ref': "carousel"
                }
                }
            >
            
                    {products.map((product, id) => (
                        <div key={id} className="carousel-item">
                            {type == 'products' ?   
                            
                                <ExtendedProductTile
                                    key={product.product.id}
                                    product={product.product}
                                    addToCartUrl={add_to_cart}
                                    currentVariant={product.product.selected_or_first_available_variant}
                                    translations={translations}
                                    chilled={product.product.chilled}
                                    delivery_date={product.product.deliveryDate}
                                    delivery_date_end={product.product.deliveryDateEnd}
                                    hideAddToCart={product.product.hamper_products.length > 0 ? false : !product.product.is_sellable}
                                    currencySymbol={currency_symbol}
                                    hideWishlist={!wishlist}
                                    
                                /> :  
                                <ExtendedProductTile
                                key={id}
                                product={product}
                                chilled={product.chilled}
                                delivery_date={product.deliveryDate}
                                delivery_date_end={product.deliveryDateEnd}
                                addToCartUrl={add_to_cart}
                                currentVariant={product.selected_or_first_available_variant}
                                translations={translations}
                                hideAddToCart={product.hamper_products && product.hamper_products.length > 0 ? false : !product.is_sellable}
                                currencySymbol={currency_symbol}
                                hideWishlist={!wishlist}
                                /> 
                        
                            }
                               
                        </div>
                    ))}
            </div>
        </div>
    )

}