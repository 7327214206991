import React from 'react';

import ExtendedImage from '@src/stories/components/ExtendedImage/ExtendedImage';
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { mobileBreakpoint } from 'frontend/entrypoints/theme';

import './ExtendedFeaturedCategories.scss';
import Headings from '@src/stories/elements/ExtendedHeadings/ExtendedHeadings';
import Paragraph from '@src/stories/elements/Paragraph';
import ExtendedButton from '@src/stories/elements/ExtendedButton/ExtendedButton';

export default function ExtendedFeaturedCategories ({ settings }) {
  const isMobile = useMobileBreakpoint(mobileBreakpoint);
  const { blocks = [], section_color_schema, section_id } = settings;

  if (isMobile) {
    const hasButton = blocks.some(block => block.settings && block.settings.primary_button_text);
    return (
      <div className={`featured-categories color-${section_color_schema} overflow-hidden ${hasButton? 'has-button': ''}`}>
        <div className={`featured-categories__wrap`} 
            {
              ...{
                  'x-data': "extended_carousel",
                  'x-init': `$nextTick(() => { initialise(true, false, false, false, false, "${section_id}") })`,
                  'x-ref': "carousel"
              }
          }
        
        >
          {blocks.map((block, index) => {
            const { title, description, image, mobile_image } = block.settings
  
            const desktopImage = image?.src ?? image;
            const imageUrl = isMobile
              ? mobile_image ? mobile_image : desktopImage
              : desktopImage
  
            const button = block.settings.primary_button_text && (
              <ExtendedButton
                style={block.settings.primary_button_style}
                label={block.settings.primary_button_text}
                link={block.settings.primary_button_url || '#'}
              />
            )
  
            return (
              <div
                className='featured-categories__category'
                key={`${title}_${index}`}
              >
                <div className='featured-categories__image-wrap'>
                  <ExtendedImage
                    imageUrl={imageUrl}
                    altText={title}
                    className='featured-categories__image'
                  />
                </div>
  
                <div className='featured-categories__details'>
                  <div className='featured-categories__title-button'>
                    <Headings text={title} type={'h3'} />
                    
                    {button}
                  </div>
  
                  <Paragraph text={description} type={'b2'} />
  
                  <div className='featured-categories__mobile-button'>
                    {button}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
  return (
    <div className={`featured-categories color-${section_color_schema} ${blocks.length > 3 && 'two-row'} ${blocks.length == 1 && 'single'}`}>
      <div className={`featured-categories__wrap grid-cols-${blocks.length > 3 ? 3 : blocks.length} ${blocks.length > 3 ? 'grid-rows-2' : ''}`}>
        {blocks.map((block, index) => {
          const { title, description, image, mobile_image } = block.settings

          const desktopImage = image?.src ?? image;
          const imageUrl = isMobile
            ? mobile_image ? mobile_image : desktopImage
            : desktopImage

          const button = block.settings.primary_button_text && (
            <ExtendedButton
              style={block.settings.primary_button_style}
              label={block.settings.primary_button_text}
              link={block.settings.primary_button_url || '#'}
            />
          )

          return (
            <div
              className='featured-categories__category'
              key={`${title}_${index}`}
            >
              <div className='featured-categories__image-wrap'>
                <ExtendedImage
                  imageUrl={imageUrl}
                  altText={title}
                  className='featured-categories__image'
                />
              </div>

              <div className='featured-categories__details'>
                <div className='featured-categories__title-button'>
                  <Headings text={title} type={'h3'} />
                  
                  {button}
                </div>

                <Paragraph text={description} type={'b2'} />

                <div className='featured-categories__mobile-button'>
                  {button}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
};