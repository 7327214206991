// @ts-nocheck
import React from "react";

import ProductTileAddToCart from "../../ExtendedProductTile/components/ProductTileAddToCart";
import ProductTileImage from "../../ExtendedProductTile/components/ProductTileImage";
import ExtendedButton from "@src/stories/elements/ExtendedButton/ExtendedButton";

import {
  formatPrice,
} from "frontend/js/helpers";

type TileProps = {
  product: undefined,
  currentVariant?: undefined,
  addToCartUrl?:  undefined,
  starRatingsApp?: '',
  translations?: object,
  hideAddToCart?: boolean,
  hideWishlist?: boolean,
  hideTags?: boolean,
  hideCompare?: boolean,
  upgradeTile?: undefined,
  currencySymbol?: string,
  handleProductClick?: Function,
  onClose?: undefined
};

export default function ExtendedRecipeProductTile (props: TileProps) {

  const {
    product,
    currentVariant = '',
    addToCartUrl = '',
    translations = {},
    hideAddToCart = false,
    currencySymbol = '',
    handleProductClick = () => {},
    onClose = () => {}
  } = props;

  const { price = 0  } = product
  const currentPrice = formatPrice(price,  currencySymbol);  

  return (
    <div className={`product-tile`}>
      <div className="product-tile__image-wrapper">
        <a href={`/products/${product.handle}`}>
          <ProductTileImage
            featuredImageUrl={product.featured_image}
            altText={product.title}
            images={product.images}
          />
        </a>

      </div>

      <div className="product-tile__details">

        <div className="product-tile__details__title-price">
          <a href={`/products/${product.handle}`}>
            <div className="product-tile__details__title">
              {product.title}
            </div>
          </a>

            <p className="product-tile__details__price">
                 <span className="product-tile__details__price-value">
                      {currentPrice}
                 </span>
            </p>
          
        </div>
        { product.is_sellable  &&
          <ProductTileAddToCart
            variant={currentVariant}
            addToCartUrl={addToCartUrl}
            translations={translations}
            onClose={onClose}
          />
        }
        { 
            <div className="w-full"> 
                <ExtendedButton
                style="secondary"
                label={`${translations.view_content}`}
                className={"w-full recipe-product-tile__button"}
                onClick={() => handleProductClick(product)}
                />
            </div>
        }
      </div>
    </div>
  );
};
