import * as React from "react";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import AlpineWidget from "@mindarc/arctheme-components/frontend/src/stories/components/AlpineWidget";
import ExtendedTextField from "@src/stories/elements/ExtendedTextField/ExtendedTextField";
import ExtendedLocalization from "./ExtendedLocalization";
import { useEffect, useState } from "react";
import { useCart } from "frontend/js/useShopifyCart";

const HeaderActions = ({ item_count, settings, localization }) => {
  const { cart } = useCart();
  const isMobile = useMobileBreakpoint('1024px');
  const isCustomerLoggedIn = settings.globals.is_customer_logged_in;
  const {
      enable_currency,
      help_link,
      enable_help_link,

      enable_searchbar,
      header_search_bar_position,

      enable_account,
      use_account_popup,
      account_popup_trigger,

      enable_minicart

   } = settings.section;

   const [menuState, setMenuState] = useState({
    isMenuVisible: false,
  });

  const excludedIds = [
    // ...settings.card_types.map(item => item.id),
    // ...settings.gift_wrap_types.map(item => item.id),
    Number(settings.shipping_cost_id)
  ];

  const excludedItems = cart && cart.items.filter(
    (cartItem) => excludedIds.includes(cartItem.id)
  );

  const updateMenuState = updates => setMenuState(prevState => ({ ...prevState, ...updates }));

  const dispatchCustomEvent = (eventName, detail = { message: "" }) => {
    window.dispatchEvent(new CustomEvent(eventName, { detail }));
  };

  const handleMenuOpen = () => {
    updateMenuState({
      isMenuVisible: true
    })
  };

  const onCloseClick = () => {
    updateMenuState({
      isMenuVisible: false
    })

    dispatchCustomEvent("toggle-display-close", { message: "header-account" });
    dispatchCustomEvent("mobile-menu-closed");
    dispatchCustomEvent("megamenu-close");
  };

  const handleMenuClose = () => {
    updateMenuState({
      isMenuVisible: false
    })
  };
  
  useEffect(() => {
    window.addEventListener('mobile-menu-open',  handleMenuOpen);
    window.addEventListener('megamenu-close',  handleMenuClose);

    return () => {
      window.removeEventListener('mobile-menu-open',  handleMenuOpen);
      window.addEventListener('megamenu-close',  handleMenuClose);
    };
  }, []);

  return (
    <div className="flex items-center lg:pl-6 lg:pr-0 lg:space-x-6 justify-end header-toolbar z-40 max-lg:gap-2">
      {(header_search_bar_position === 'horizontal' && enable_searchbar) &&
        <ExtendedTextField
          icon="search"
          className="header-search-bar hidden lg:block"
          placeholder="Search products, recipes, blogs"
          readonly={true}
          onFocus={( )=>{ window.dispatchEvent(new CustomEvent("toggle-search"));}}
        />
      }
      { enable_currency &&  <div className="hidden lg:block"> <ExtendedLocalization localization={localization} /> </div> }
      {(help_link && enable_help_link) &&
        <a
          href={help_link}
          className="hidden lg:block"
        >
          Help
        </a>
      }

      {settings.globals.enable_wishlist === 'true' &&
        <a
          href="/pages/wishlist"
          className="antialiased h-[24px] items-center hover:no-underline hidden lg:flex"
        >
          <i className="icon-heart text-[22px] text-mb-red"></i>
        </a>
      }

      {(enable_account && use_account_popup && account_popup_trigger == 'hover') &&
        <AlpineWidget
          template={`
            <div
              href="#"
              class="account-icon antialiased h-[24px] flex items-center"
              @mouseenter="$nextTick(() => { $dispatch('toggle-display-open',{ message: 'header-account' }) })"
              @mouseleave="$nextTick(() => { $dispatch('toggle-display-trigger-leave',{ message: 'header-account' }) })"
            >
              <i class="icon-user text-[22px]"></i>
            </div>`
          }
        />
      }

      {(enable_account && use_account_popup && account_popup_trigger == 'click') && 
        <AlpineWidget
          template={`
            <div
              href="#" class="account-icon antialiased h-[24px] flex items-center"
              @mousedown="$nextTick(() => { $dispatch('toggle-display',{ message: 'header-account' }) })"
            >
              <i class="icon-user text-[22px]"></i>
            </div>`
          }
        />
      }

      {(enable_account && !isCustomerLoggedIn && !use_account_popup) &&
        <AlpineWidget
          template={`
            <a href="/account" class="account-icon antialiased h-[24px] flex items-center">
              <i class="icon-user text-[22px]"></i>
            </a>`
          }
        />
      }

      {enable_minicart && !menuState.isMenuVisible 
        ? <div className="flex items-center relative">
            <AlpineWidget
              template={`
                <div
                  class="relative h-[24px] lg:flex items-center max-lg:px-2 hover:no-underline"
                  @click="$store.global.toggleMinicart()"
                >
                  <i class="icon-cart text-[24px]" style="line-height: 26px"></i>
                </div>`
              }
            />

          <div
                  className='extended-minicart__trigger'
                  x-cloak="true"
                  x-show="$store.global.isMinicartDialogVisible"
                >
                <div> Item Added To Cart </div> 
                <button className="button button-secondary" onClick={()=> { window.location.href = '/cart'}}> Checkout Now </button>
                </div>
                    <div className="b2 header-item-count">
                      {cart.item_count > 0 &&
                     
                          <span>
                            {cart.item_count - excludedItems.length}
                          </span>
                      
                      }
                    </div>
                  </div>

        : <span className="relative" aria-hidden="true" onClick={() => { onCloseClick() }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M16.192 6.34424L11.949 10.5862L7.70697 6.34424L6.29297 7.75824L10.535 12.0002L6.29297 16.2422L7.70697 17.6562L11.949 13.4142L16.192 17.6562L17.606 16.2422L13.364 12.0002L17.606 7.75824L16.192 6.34424Z" fill="black"/>
            </svg>
          </span> 
      }

    
    </div>
  )
}

export default HeaderActions;