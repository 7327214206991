import React from "react";

import ExtendedHeadings from "../../elements/ExtendedHeadings/ExtendedHeadings";
import ExtendedImage from "../../elements/ExtendedImage/ExtendedImage";
import {Paragraph} from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Paragraph/Paragraph";
import {Button} from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Button/Button";
import ExtendedSearchTileBadge from "./ExtendedSearchTileBadge";

export const ExtendedRecipeTile = ({ article }) => {
    const tags = article.meta.map(tag => tag.split('|'));
    return (
        <div className={'blog-article-tile flex flex-col pb-2 lg:pb-3 pt-3 lg:pt-0'}>
            <div className="blog-article-tile__image-wrapper">
                <ExtendedImage imageUrl={article.image} classes={'blog-article-tile__image'} />
                {tags && <ExtendedSearchTileBadge badges={tags} />}
            </div>
            <div className={"blog-article-tile-heading h6"}>  {article.title} </div>
         
        </div>
    )
}

export default ExtendedRecipeTile;