import React, { useState } from "react";

import ExtendedButton from "@src/stories/elements/ExtendedButton/ExtendedButton";
import ExtendedImage from "@src/stories/components/ExtendedImage/ExtendedImage";

import { addToCart } from "frontend/js/useShopifyCart";
import { formatPrice } from "frontend/js/helpers";

export default function ExtendedArticleFeaturedProduct({ product }) {
  const [isLoading, setIsLoading] = useState(false);

  async function handleAddToCart(id) {
    setIsLoading(true);
    await addToCart({ id, quantity: 1 });
    setIsLoading(false);
  }

  return (
    <div className="bg-white shadow-small rounded-lg p-3 lg:p-6">
      <div className="flex items-center gap-6 mb-6">
        <div className="w-1/2">
          <ExtendedImage className="extended-article__products" imageUrl={product.featured_image} />
        </div>

        <div className="font-medium w-1/2">
          <p>{product.title}</p>

          <p className="text-[20px] font-[500] mt-4">
            {formatPrice(product.price)}
          </p>
        </div>
      </div>

      <ExtendedButton
        label={isLoading ? 'Adding to cart...' : 'Add to cart'}
        disabled={isLoading}
        className="extended-article__add-to-cart-button"
        onClick={() => handleAddToCart(product.variants[0].id)}
      />
    </div>
  )
}