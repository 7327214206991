import { useState, useEffect } from "react";

let cart = {
  items: [],
  item_count: 0,
};

let listeners = [];

const notifyListeners = () => {
  listeners.forEach((listener) => listener(cart));
};

export async function fetchCart() {
  const response = await fetch(`/cart.js`);
  cart = await response.json();
  notifyListeners();
}

const requestCart = async (url, payload, action, fetchUpdatedCart = true) => {
  await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  })
    .then(() => {
      if (fetchUpdatedCart) fetchCart();
    })
    .catch((error) => console.error(`Failed to ${action}: `, error));
};

export const addToCart = async (payload, fetchUpdatedCart = true) => {
  await requestCart("/cart/add.js", payload, "add item", fetchUpdatedCart);
};

export const updateItem = async (payload, fetchUpdatedCart = true) => {
  await requestCart(
    "/cart/change.js",
    payload,
    "update item",
    fetchUpdatedCart,
  );
};

export const updateItems = async (payload, fetchUpdatedCart = true) => {
  await requestCart(
    "/cart/update.js",
    payload,
    "update items",
    fetchUpdatedCart,
  );
};

export const useCart = (isMainCart) => {
  const [currentCart, setCurrentCart] = useState(cart);

  useEffect(() => {
    listeners.push(setCurrentCart);
    if (isMainCart) fetchCart();

    return () => {
      listeners = listeners.filter((listener) => listener !== setCurrentCart);
    };
  }, []);

  return {
    cart: currentCart,
  };
};
