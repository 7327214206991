import React, { useState, useEffect } from "react";
import ExtendedTextField from "@src/stories/elements/ExtendedTextField/ExtendedTextField";
import { Headings } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Headings/Headings";
import Paragraph from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Paragraph/Paragraph";

export const NewsletterInfo = ({ settings }) => {
  const { section, blocks } = settings;

  const infoBlock = blocks.find((block) => block.type === "cta_info");

  return (
    <div className="calltoaction__content z-10 gap-y-[12px]">
      <Headings
        text={infoBlock.settings?.heading_text}
        type={infoBlock.settings?.heading_style}
      />
      <Paragraph
        text={infoBlock.settings?.subheading_text}
        type={infoBlock.settings?.subheading_style}
      />
    </div>
  );
};
