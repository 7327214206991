import React, { useRef } from "react";

import { Headings } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Headings/Headings";
import ExtendedProductTile from "./ExtendedUpgradeModalProductTile";

const AddToCartButton = ({
  quantity,
  addToCartUrl,
  variant,
  buttonLabel,
  redirect,
  onClose,
  buttonType,
  delivery_date,
  delivery_date_end,
  chilled
}) => {
  
  const dispatchCustomEvent = (eventName, detail) => {
    window.dispatchEvent(new CustomEvent(eventName, { detail }));
  };

  const addForm = useRef(null);
  return (
    <ajax-cart-product-form>
      <form
        action={addToCartUrl}
        noValidate
        ref={addForm}
        data-prodify-product-form
      >
        <input type="hidden" name="id" value={variant.id} />
        <input type="hidden" name="quantity" value={quantity} />

        <div>
          <div
            className={`button button-${buttonType}`}
            onClick={() => {
              dispatchCustomEvent("add-to-cart", {
                id: variant.id,
                quantity: quantity,
                form: addForm.current,
                delivery_date: delivery_date,
                delivery_date_end: delivery_date_end,
                chilled:  chilled
              });
              if (redirect == 'close') {
                onClose(); 
              }
              else {
                window.location.href = '/cart';
              }
            }}
          
          >
            <span
              className={`add-to-cart-button__label ${buttonType == "text" ? "underline" : ""}`}
            >
              {buttonLabel}
            </span>

            <span className="add-to-cart-button__loader">{buttonLabel}</span>
          </div>
        </div>

        <div
          data-ajax-cart-errors="form"
          className="absolute text-[10px] text-mb-red w-full whitespace-nowrap left-1/3"
        >
          {" "}
        </div>
      </form>
    </ajax-cart-product-form>
  );
};

export const ExtendedUpgradeModal = ({
  modal_id,
  isOpen,
  onClose,
  product,
  add_to_cart_url,
  translations,
  localization,
  variant,
  productQuantity,
  delivery_date,
  delivery_date_end,
  chilled
}) => {
  if (!isOpen) return null;
  const { currency_symbol } = localization.country;
  const { siblings } = product;
  const filteredSiblings = siblings.sibling_products.filter(
    (prod) =>
      Number(product.price) < Number(prod.price) && product.title != prod.title,
  );

  return (
    <div
      id={`${modal_id}`}
      className={`product-upgrade-modal  fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-90 overflow-y-auto w-full  h-full z-50`}
      style={{ backdropFilter: "blur(6px)" }}
    >
      <div
        className={`border shadow-lg rounded-md overflow-scroll lg:overflow-auto bg-white lg:w-[1000px] p-5 h-full lg:h-auto flex flex-col`}
      >
        <div className="flex justify-between items-center py-0 lg:py-4 flex-col-reverse lg:flex-row">
          <Headings text={translations?.enhance} type={"h5"} />
          <button
            className="p-0 h-6 text-[24px] border-none w-full flex justify-end lg:w-auto"
            onClick={onClose}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M16.192 6.34375L11.949 10.5858L7.70697 6.34375L6.29297 7.75775L10.535 11.9998L6.29297 16.2418L7.70697 17.6558L11.949 13.4137L16.192 17.6558L17.606 16.2418L13.364 11.9998L17.606 7.75775L16.192 6.34375Z"
                fill="black"
              />
            </svg>
          </button>
        </div>
        <div className={`product-upgrade-modal__main`}>
          <ExtendedProductTile
            key={product.id}
            product={product}
            addToCartUrl={add_to_cart_url}
            currentVariant={variant}
            translations={translations}
            currencySymbol={currency_symbol}
            upgradeTile={productQuantity}
          />
        </div>

        <div className={"product-upgrade-modal__upgrades"}>
          {filteredSiblings &&
            filteredSiblings.map((prod, index) => {
              const { variants: siblingVariants } = prod;
              const productLength = filteredSiblings.length;
              const currentVariant = siblingVariants.find(
                (obj) => obj.title === variant.title,
              );
              return (
                <div className="relative">
                  <div className={`product-upgrade-modal__line`}> </div>
                  {productLength - 1 == index && (
                    <div
                      className={`product-upgrade-modal__overlay h-[86px] lg:h-[156px]`}
                    >
                      {" "}
                    </div>
                  )}

                  <ExtendedProductTile
                    key={prod.id}
                    product={prod}
                    addToCartUrl={add_to_cart_url}
                    currentVariant={currentVariant}
                    translations={translations}
                    currencySymbol={currency_symbol}
                    upgradeTile={productQuantity}
                    onClose={onClose}
                  />
                </div>
              );
            })}
        </div>

        <div className="flex flex-col justify-between items-center gap-2 mt-auto lg:flex-row lg:mt-0">
          <div className="flex flex-row gap-2 z-20 relative w-full justify-between lg:w-auto lg:justify-normal">
            <AddToCartButton
              quantity={productQuantity}
              buttonType={"text"}
              addToCartUrl={add_to_cart_url}
              buttonLabel={translations?.continue_shopping}
              variant={variant}
              redirect={"close"}
              onClose={onClose}
              delivery_date={delivery_date}
              delivery_date_end={delivery_date_end}
              chilled={chilled}
            />
            <AddToCartButton
              quantity={productQuantity}
              buttonType={"text"}
              addToCartUrl={add_to_cart_url}
              buttonLabel={translations?.view_cart}
              variant={variant}
              redirect={"cart"}
              delivery_date={delivery_date}
              delivery_date_end={delivery_date_end}
              chilled={chilled}
              onClose={onClose}
            />
          </div>
          <div className="w-full lg:w-auto">
            <AddToCartButton
              quantity={productQuantity}
              buttonType={"primary"}
              addToCartUrl={add_to_cart_url}
              buttonLabel={translations?.checkout}
              delivery_date={delivery_date}
              delivery_date_end={delivery_date_end}
              variant={variant}
              chilled={chilled}
              redirect={"cart"}
              onClose={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ExtendedUpgradeModal.propTypes = {};
