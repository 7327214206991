import React, { useState, useEffect, useRef } from 'react';
import ExtendedImage from "@src/stories/components/ExtendedImage/ExtendedImage";
import ExtendedVideo from "@src/stories/elements/ExtendedVideo/ExtendedVideo";
import ExtendedBreadcrumbs from "../../components/Breadcrumbs/ExtendedBreadcrumbs";
import './recipe.scss';
import ActionBar from "@stories/sections/ExtendedCollectionBanner/ActionBar";
import ExtendedRecipeDetailsInstructions from './components/ExtendedRecipeDetailsInstructions';
import ExtendedRecipeDetailsIngredients from './components/ExtendedRecipeDetailsIngredients';
import recipeAlpine from "../../../../js/alpine/components/recipe";
import ExtendedButton from "@src/stories/elements/ExtendedButton/ExtendedButton";
import { isVideoUrl } from 'frontend/js/helpers';

export default function ExtendedRecipeDetails  ({recipe}) {
    const { title, subtitle, description, prep_time, image, video, meal, published_date, servings, instructions, ingredients, autoplay, loop, muted, prereq_media_1, prereq_media_2, prereq_title_1, prereq_title_2, prereq_qty_1, prereq_qty_2, yotpo_snippet , dummy_id , tags , shop , translations, tip_background, tip_color, wishlist, ingredients_toggle } = recipe;
    const baseQuantity = Number(servings);
    const [ quantity, setQuantity ] = useState(baseQuantity);
    const [ unitState, setUnitState ] = useState('metric');
    const [ productAddList, setProductAddList ] = useState({});
    const [ productsFetch , setProductsFetch  ] = useState(false);
    const reqMedia = { media1: prereq_media_1, media2: prereq_media_2, title1: prereq_title_1, title2: prereq_title_2 , qty1: prereq_qty_1, qty2: prereq_qty_2};
    const controls = { autoplay: JSON.parse(autoplay), loop: JSON.parse(loop), muted: JSON.parse(muted) };

    const isVideo = isVideoUrl(video);
    let result = {};

    const jumpToSection = (section) => {

        if ( document.querySelector(section)){
            document.querySelector(section)!.scrollIntoView({
                behavior: 'smooth', 
                block: 'start'      
            });
        }
    }

    const print = () => {
        window.print();
    }

    async function getResult(productObject) {
        try {
            result = await recipeAlpine.component().updateProducts(Object.keys(productObject));
            setProductsFetch(true);
            setProductAddList(result); 
            return result  
        } catch (error) {
            console.error(error);  
            setProductsFetch(true);
        }
    }

    const ingredientArray = ingredients.reduce((acc, item) => {
        const matches = item.match(/\[PRODUCT\](.*?)\[\/PRODUCT\]/);
        if (matches) {
            acc.push(matches[1]);
        }
        return acc;
    }, []);

    const productObject = ingredientArray.reduce((acc, item) => {
        const [title, idWithBraces] = item.split('|');
        const id = idWithBraces.match(/\{(\d+)\}/) && idWithBraces.match(/\{(\d+)\}/)[1];
        
        acc[id] = { title };
        
        return acc;
    }, {});

   
    useEffect(() => {
        if (!productsFetch) {
            getResult(productObject);
        }
    }, [productObject]); 


    const hasLoggedBraze = useRef(false);

    useEffect(() => {
        if(!hasLoggedBraze.current) {
        hasLoggedBraze.current = true;
        setTimeout(() => {
            window.braze?.logCustomEvent("Blog or Recipe Page Views", { "recipe_category": meal });
        
          }, 2000); 
        }
    }, []); 
   
    const badges =  tags && tags.map(tag => tag.split('|'));

    return (
        <div className="recipe-details printable">
            <div className="block lg:hidden"> 
                <ExtendedBreadcrumbs
                    templateName={"recipes"}
                    pageTitle={`${title}`}
                    shop={shop}
                />
            </div>
            <div className="recipe-details__banner"> 
                <div className="recipe-details__banner-information"> 
                    <div className="hidden lg:block"> 
                    <ExtendedBreadcrumbs
                        templateName={"recipes"}
                        pageTitle={`${title}`}
                        shop={shop}
                    />
                    </div>
                    <div className="flex flex-row items-center justify-between">   
                        <div className={`recipe-details__badges narrow`}>
                            {badges.map((badge, index) => (
                                <div key={`badge_${index}`}>
                                    <div
                                        className="recipe-details__badge"
                                        style={{ backgroundColor: `${badge[2] || '#FFF'}`, color: `${badge[3] || '#000'}` }}
                                    >
                                        <span>{badge[0]}</span>
                                        <span className={`icon-${badge[1] || 'no-icon'}`}></span>
                            
                                    </div>
                                </div>
                            ))}
                        </div>
                         { wishlist  && <span className="icon-heart icon text-[24px] text-[#445B4B] flex items-center h-full mb-6"></span> }
                    </div>
                 
                    <div className="flex flex-col lg:flex-row "> 
                   
                        <div className={`recipe-details__reviews ${prep_time ? 'bordered' : ''}`}> 
                            <div className="yotpo-widget-instance" data-yotpo-instance-id={`${yotpo_snippet}`} data-yotpo-product-id={`${dummy_id}`} data-yotpo-cart-product-id={`${dummy_id}`} data-yotpo-section-id="product"></div>
                        </div>
                        
                       {prep_time && <div className="recipe-details__prep"> <span className="icon icon-time text-[24px]"> </span> {prep_time } {translations.prep_time} </div> } 
                    </div>
                    <h1 className="recipe-details__title h2"> { title } </h1>
                    <div className="recipe-details__subtitle "> { description } </div>
                    { published_date  && <div className="recipe-details__date "> {translations.published} { published_date } </div> }
                    <div className="recipe-details__bar">  
                        <div className="hidden lg:block"> {translations.share} </div>
                        <div className="recipe-details__actions"> 
                            <ActionBar altLinks={{}}/>
                            <ExtendedButton
                                label={`${translations.print}`}
                                style="text"
                                onClick={()=>{print()}}
                            />
                        </div>
                     
                    </div>
                    

                   
                </div>
                <div className="recipe-details__banner-image"> 
                { isVideo && 
                     <ExtendedVideo
                     videoUrl={video} 
                     autoplay={controls.autoplay} muted={controls.muted} loop={controls.loop}
                     />
                }
                { !isVideo && 
                     <ExtendedImage
                     imageUrl={image}
                     altText={title}
                     />
                }
                   
                </div>
            </div>
          
            <div className="recipe-details__jump"> 
                <ExtendedButton
                    icon="down-arrow-alt"
                    label={`${translations.jump_to_ingredients}`}
                    style="secondary"
                    onClick={()=>{jumpToSection('.recipe-details-ingredients .collapsible__title')}}
                    reverse
                />
                <ExtendedButton
                    icon="down-arrow-alt"
                    label={`${translations.jump_to_instructions}`}
                    style="secondary"
                    onClick={()=>{jumpToSection('.recipe-details-instructions .collapsible__title')}}
                    reverse
                />
            </div>
            
       
                    
            <div className="recipe-details__information"> 
                <ExtendedRecipeDetailsIngredients ingredients_toggle={ingredients_toggle} translations={translations} productAddList={productAddList} reqMedia={reqMedia} unitState={unitState} setUnitState={setUnitState} ingredients={ingredients} setQuantity={setQuantity} quantity={quantity} baseQuantity={baseQuantity} />
                <ExtendedRecipeDetailsInstructions translations={translations} unitState={unitState} instructions={instructions} quantity={quantity} controls={controls} baseQuantity={baseQuantity} tip_background={tip_background} tip_color={tip_color}/>
            </div> 
     
        </div>
    );
   
  };



