import React, { useEffect, useState } from "react";
import { Button } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Button/Button";
import { Paragraph } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Paragraph/Paragraph";
import { NewsletterInputs } from "../NewsletterInputs";
import { NewsletterInfo } from "../NewsletterInfo";
import useSubscribeCustomer from "@src/hooks/useSubscribeCustomer";
import useCreateCustomer from "@src/hooks/useCreateCustomer";
import {BusinessFields, InputField, CheckboxField} from "@stories/ecommerce/Account/AccountFoodClubFields";

export const Default = ({ settings, onClick = null }) => {
  const { requestedSuccess, serverMessage, isSubmitting, subscribe } = useSubscribeCustomer();
  const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);

    const [isBusiness, setIsBusiness] = useState(false);

  const [email, setEmail] = useState("");
  const { section, blocks } = settings;
  const show_button = true;
  const button_text = section.button_text;
  const image = section.image;
  const [dependenciesState, setDependenciesState] = useState({});
    const { createCustomer, loading, error, customerId, success } = useCreateCustomer();
    const isCustomerLoggedIn = settings.globals.is_customer_logged_in;

    const customSignupFields = settings.signupfields;

      // Function to update the global dependencies state
      const updateDependencyState = (name, value) => {
        setDependenciesState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
        if (name === "email") {
          setEmail(value);
        }
      };

    const handleBusinessToggle = () => {
        setIsBusiness(!isBusiness);
    };
    const handleInputChange = (e) => {
        const { name, value, type: inputType, checked } = e.target;
        const newValue = inputType === "checkbox" ? checked : value;
        updateDependencyState(name, newValue);
    };

  const infoBlock = blocks.find((block) => block.type === "cta_info");

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const customer_posted = params.get("customer_posted");
    if (customer_posted === "true") {
      setIsSuccess(true);
    }
  }, []);

  const handleClick = (event) => {
    event.preventDefault();
    // Since this is newsletter, set optIn fields automatically
    setDependenciesState((prevState) => ({
        ...prevState,
        ['optInSMS']: true,
        ['optInEmail']: true,
    }));
    const response = createCustomer(dependenciesState);

    console.log('registeredCustomerId', customerId);
  };

  return (
      <div className="calltoaction h-auto lg:h-[auto] relative flex flex-col lg:flex-row">
        <div
            className={"w-full h-full absolute top-0 left-0 content-over-media"}
            style={{
              backgroundImage: `url(${image})`,
              backgroundSize: "cover",
              backgroundPositionY: "center",
            }}
        ></div>
        <NewsletterInfo settings={settings} />

          {!isCustomerLoggedIn &&
        <form
            id={"at-newsletter-subscribe-form"}
            action={"/contact"}
            method={"POST"}
            className="calltoaction__container z-10 w-full lg:w-[500px] md:w-[700px] py-[24px]"
        >
          <input type="hidden" name="form_type" value="customer" />
          <input type="hidden" name="contact[email]" value={email} />
          <input type="hidden" name="contact[tags]" value="newsletter" />
          <input type="hidden" name="utf8" value="✓" />

          {success && (
              <div
                  className="success-message my-[16px] py-[16px] bg-[#36573B] px-4"
                  role="alert"
              >
                <p className="bc">Thank you for subscribing!</p>
                <p className="b3">
                  You have been successfully subscribed to our newsletter.
                </p>
              </div>
          )}

          {error && (
              <div className="error-message my-[16px] py-[16px] bg-red-500 px-4" role="alert">
                <p>{error}</p>
              </div>
          )}

          <div className="flex w-full flex-col lg:flex-row gap-y-[16px] lg:gap-x-[4px]">
            {show_button && (
                <>
                  <div className={"basis-[100%]"}>
                    <div className={`flex flex-col`}>
                        <div className={"grid grid-cols-2"}>
                            <InputField id={"name"} placeholder={"Enter your name"} type={"name"} required={true} handleInputChange={handleInputChange} />
                            <InputField id={"email"} placeholder={"Enter your email"} type={"email"} required={true} handleInputChange={handleInputChange} />
                            <InputField id={"phone"} placeholder={"Phone Number"} type={"phone"} required={true} handleInputChange={handleInputChange} />
                            <CheckboxField
                                label="Are you a business?"
                                id="isBusiness"
                                onChange={handleBusinessToggle}
                            />
                        </div>
                        {isBusiness && <BusinessFields
                            showLabels={false}
                            customSignupFields={customSignupFields}
                            handleInputChange={handleInputChange} />}
                      <div
                          className={`button button-${infoBlock.settings?.button_style}`}
                          disabled={isSubmitting} onClick={handleClick}>
                        {infoBlock.settings?.button_text}

                      </div>
                    </div>
                  </div>
                </>
            )}
          </div>

          <div className="calltoaction__cta-text">
            <Paragraph text={infoBlock.settings?.cta_text} type={"b2"} />
          </div>
        </form>}

          {isCustomerLoggedIn &&
              <div className="px-4 z-[10]">
                <p className="h5">You have already subscribed</p>
                <p className="b3">
                    <a href={'/account'}>Click here to visit your account.</a>
                </p>
              </div>
          }
      </div>
  );
};

export default Default;
