import React,  { useRef, useEffect, useState } from "react";

import {Headings} from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Headings/Headings";
import {Paragraph} from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Paragraph/Paragraph";
import ExtendedBlogTile from "@src/stories/components/ExtendedBlogTile/ExtendedBlogTile";
import ExtendedButton from '@src/stories/elements/ExtendedButton/ExtendedButton';

import './ExtendedListBlogs.scss'

export default function ExtendedListBlogs({ blocks, blog, settings, translations, section_id, type }) {
  const { autoPlay = 0 } = settings; 
  const componentRef = useRef(null);
  const [ sliderVisible, setSliderVisible ] = useState(true)

  // If type == auto, section articles automatically based from current article page

  const blogList = type == 'blocks' ? blocks : blog;
  function goToSlide(action) {
    const event = new CustomEvent(`carousel-navigate`, {
      detail: { section_id, action }
    });

    window.dispatchEvent(event);
  }

  const [carouselInitialized, setCarouselInitialized] = useState(false);

  useEffect(() => {
    setTimeout(() => {
     setCarouselInitialized(true); // This will trigger a re-render
    }, 250);
  }, []);


  useEffect(() => {
    const divWithClass = componentRef.current && componentRef.current.querySelector('.flickity-viewport');
    if (componentRef.current) {
      if (divWithClass) {
        setSliderVisible(true);
      } else {
        setSliderVisible(false);
      }
    }
  }, [componentRef.current, componentRef, carouselInitialized]);

    return (
        <div className="blog-slider default-list  pb-12 lg:pb-0" ref={componentRef}>
            <div className={'section-top'}>
                <div className="blog-slider-header"> 
                    <div className={'h3'}> {settings.title}  </div>
                    {sliderVisible && 
                        <div className='blog-slider-header__buttons'>
                           <ExtendedButton
                               icon="chevron-left"
                               style='dark-primary'
                               className="blog-slider-header__buttons-arrow prev"
                               onClick={() => goToSlide('previous')}
                           />
   
                           <ExtendedButton
                               icon="chevron-right"
                               style='dark-primary'
                               className="blog-slider-header__buttons-arrow next"
                               onClick={() => goToSlide('next')}
                           />
                       </div>
                    }
                 
                </div>
              
                <Paragraph text={settings.subtext}/>
              
            </div>

            <div className="blog-slider-carousel"
                {
                ...{
                    'x-data': "extended_carousel",
                    'x-init': `$nextTick(() => { initialise(true, false, false, true, ${autoPlay}, "${section_id}") })`,
                    'x-ref': "carousel"
                }
                }
            >

                    {blogList.map((block, id) =>  {
                        let article = {};
                        if (type == 'auto') {
                            article = block;
                        } else {
                            article = block.article;
                        }
                       
                        return (
                            <div key={id} className="carousel-item">
                                { 
                                    <ExtendedBlogTile
                                        article={article}
                                        translations={translations}
                                    /> 
                                }
                            </div>
                        )} 
                    )}
            </div>
        </div>
    )

}