import React, { useState } from "react";

import './ExtendedQuantityField.scss';

const PlusSVG = () => (
  <svg viewBox="0 0 24 24" width="24" height="24" fill="currentColor">
    <path d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
  </svg>
);

const MinusSVG = () => (
  <svg viewBox="0 0 24 24" width="24" height="24" fill="currentColor">
    <path d="M19,13H5V11H19V13Z" />
  </svg>
);

export default function ExtendedQuantityField({ initialValue = 0, quantity ,setQuantity }) {
  const handleIncrement = () => {
    setQuantity(prev => prev + 1);
  };

  const handleDecrement = () => {
    setQuantity(prev => prev - 1 > 0 ? prev - 1 : 1);
  };

  return (
    <div className="quantity-field">
      <button
        className="quantity-field__button"
        onClick={handleDecrement}
      >
        <MinusSVG />
      </button>

      <input
        type="text"
        value={quantity}
        readOnly
        className="quantity-field__input"
      />

      <button
        className="quantity-field__button"
        onClick={handleIncrement}
      >
        <PlusSVG />
      </button>
    </div>
  );
}
