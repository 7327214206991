import * as React from "react";

import {Headings} from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Headings/Headings";

export const Title = ({ settings }) => {

    const renderComponents = () => {
        return (
            <div
                className={`block-title mt-[${settings.block_settings.margin_top_mobile}px] lg:mt-[${settings.block_settings.margin_top_desktop}px] mb-[${settings.block_settings.margin_bottom_mobile}px] lg:mb-[${settings.block_settings.margin_bottom_desktop}px] text-${settings.section_settings.content_alignment_mobile} lg:text-${settings.section_settings.content_alignment_desktop}`}
            >
                <h1 className={`${settings.block_settings.heading_type} text__heading`}> {`${settings.block_settings.title}`} </h1>
                
            </div>
        )
    }

    if (settings.block_settings.render_type !== 'all') {
        if (isMobile && settings.block_settings.render_type !== 'mobile-only') return;
        if (!isMobile && settings.block_settings.render_type !== 'desktop-only') return;
    }

    return (
        <>
            {renderComponents()}
        </>
    );
}

export default Title;