import { useState } from "react";

const useCustomerLogin = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const loginCustomer = (email, password) => {
        setLoading(true);
        setError(null);

        try {
            // Create a form element programmatically
            const form = document.createElement('form');
            form.method = 'POST';
            form.action = '/account/login';
            form.style.display = 'none'; // Hide the form

            // Create form inputs for email, password, and required hidden fields
            const emailInput = document.createElement('input');
            emailInput.type = 'hidden';
            emailInput.name = 'customer[email]';
            emailInput.value = email;
            form.appendChild(emailInput);

            const passwordInput = document.createElement('input');
            passwordInput.type = 'hidden';
            passwordInput.name = 'customer[password]';
            passwordInput.value = password;
            form.appendChild(passwordInput);

            const formTypeInput = document.createElement('input');
            formTypeInput.type = 'hidden';
            formTypeInput.name = 'form_type';
            formTypeInput.value = 'customer_login';
            form.appendChild(formTypeInput);

            const utf8Input = document.createElement('input');
            utf8Input.type = 'hidden';
            utf8Input.name = 'utf8';
            utf8Input.value = '✓';
            form.appendChild(utf8Input);

            // Append the form to the document body
            document.body.appendChild(form);

            // Use Shopify's CAPTCHA protection on the form
            window.Shopify.captcha.protect(form, () => {
                // Submit the form after CAPTCHA validation
                form.submit();
            });
        } catch (err) {
            setError('Login failed. Please try again.');
            setLoading(false);
        }
    };

    return {
        loginCustomer,
        loading,
        error,
        success,
    };
};

export default useCustomerLogin;
