import shopifyHelper from "../../../shopify";
import { hasher } from "../../stores/hasher";
import api from "../../../api";

import UPDATE_CUSTOMER_DETAILS from "../../../graphql/2024_01/customer/update.graphql";
import GET_CUSTOMER_DETAILS from "../../../graphql/2024_01/customer/get.graphql";
import GET_CUSTOMER_NOTIFICATIONS from "../../../graphql/2024_01/customer/notifications.graphql";
import UPDATE_CUSTOMER_EMAIL_NOTIFICATIONS from "../../../graphql/2024_01/customer/update-email.graphql";
import UPDATE_CUSTOMER_SMS_NOTIFICATIONS from "../../../graphql/2024_01/customer/update-sms.graphql";

export const account = {
  name: "account",
  component() {
    return {
      open: false,
      hashChangedCount: 0,
      view: null,
      detailsForm: null,
      hasFormChanged: false,
      useAuthenticated: true,
      updatedSuccessfully: false,
      updatedError: false,
      updatedNotificationSuccessfully: false,
      updatedNotificationError: false,
      processing: false,
      successMessage: "Your details have been updated.",
      errorMessage: "There was a problem updating your details.",
      successNotifMessage: "Your details have been updated.",
      errorNotifMessage: "There was a problem updating your details.",
      metafieldObject: {},
      customerEmailConsent: false,
      customerPhoneConsent: false,
      init(id) {

        window.addEventListener("hashchange", this.updateHashChangedCount);
        if (this.detailsForm) {
          this.detailsForm = document.getElementById("details-form");
          this.detailsForm.addEventListener(
            "input",
            this.handleInputChanged.bind(this),
          );
        }
      },
      scrollTabIntoView() {
        const adminLinks = document.querySelector(
          ".account-navigation__tabbed",
        );
        const activeTabLink = adminLinks.querySelector(
          ".account-navigation__tabbed-link--active",
        );

        if (activeTabLink) {
          activeTabLink.scrollIntoView({
            behavior: "instant",
            inline: "start",
            block: "end",
          });
        }
      },
      handleInputChanged() {
        this.hasFormChanged = true;
      },
      async updateNotifications(id) {
        console.log('Updating Notifications');
        this.detailsForm = document.getElementById("notifications-form");
        const formData = new FormData(this.detailsForm);
        const pushNotification = formData.get("sms-notification");
        const emailNotification = formData.get("email-notification");
        this.informationForm = document.getElementById("details-form");

        const infoData = new FormData(this.informationForm);
        const email =  `${infoData.get("email")}`;
        const sms = `${infoData.get("phone")}`;
;
        await hasher.store().dispatchHash();

        const emailBody = JSON.stringify({
          query: UPDATE_CUSTOMER_EMAIL_NOTIFICATIONS.loc.source.body,
          variables: {
            input: {
              customerId: `gid://shopify/Customer/${id}`,
              emailMarketingConsent: {
                marketingState: emailNotification == 'opted_in' ? "SUBSCRIBED": "UNSUBSCRIBED" ,
                marketingOptInLevel: emailNotification == 'opted_in' ? "SINGLE_OPT_IN" : "UNKNOWN",
              }
            }
          }
        });

        const smsBody = JSON.stringify({
          query: UPDATE_CUSTOMER_SMS_NOTIFICATIONS.loc.source.body,
          variables: {
            input: {
              customerId: `gid://shopify/Customer/${id}`,
              smsMarketingConsent: {
                marketingState: pushNotification == 'opted_in' ? "SUBSCRIBED": "UNSUBSCRIBED" ,
                marketingOptInLevel: pushNotification == 'opted_in' ? "SINGLE_OPT_IN" : "UNKNOWN",
              }
            }
          }
        });

          const dataTemplate = {
            method: "POST",
            headers: {
              "X-Shopify-Access-Token": "<SHOPIFY_API_AUTHENTICATED_KEY>",
              "Content-Type": "application/json",
              "X-MindArc-Hash": hasher.store().getHash(),
            },
            request_body: UPDATE_CUSTOMER_EMAIL_NOTIFICATIONS.loc.source.body
          };

          const makeRequest = async (body) => {
            const data = { ...dataTemplate, body };
            let jsonResponse = null;

            if (this.useAuthenticated) {

              let data = {
                options: {
                  method: "POST",
                  headers: {
                    "X-Shopify-Access-Token": "<SHOPIFY_API_AUTHENTICATED_KEY>",
                    "Content-Type": "application/json",
                    "X-MindArc-Hash": hasher.store().getHash(),
                  },
                  body: body,
                  request_body: UPDATE_CUSTOMER_EMAIL_NOTIFICATIONS.loc.source.body,
                },
              };

              jsonResponse = await shopifyHelper.authenticatedFetch(data);
            } else {
              jsonResponse = await shopifyHelper.unauthenticatedFetch(
                body,
                null,
                false,
              );
            }

            return jsonResponse;
          };

          let emailResponse = await makeRequest(emailBody);
          let smsResponse = await makeRequest(smsBody);

          // Check responses
          if (emailResponse.data) {
            this.updatedNotificationSuccessfully = true;
            if( emailNotification == 'opted_in') {
              window?.braze.getUser().setEmail(email);
              window?.braze.getUser().setEmailNotificationSubscriptionType("opted_in");
            } else {
              window?.braze.getUser().setEmail(email);
              window?.braze.getUser().setEmailNotificationSubscriptionType("unsubscribed");
            }
   
          } else {
            this.updatedNotificationError = true;
            this.errorNotifMessage = "There was a problem updating your email details.";
          }

          if (smsResponse.data) {
            this.updatedNotificationSuccessfully= true;
            if( pushNotification  == 'opted_in') {
              window?.braze.getUser().setPhoneNumber(sms)
              window?.braze.getUser().addToSubscriptionGroup("560eb3d7-16fe-44a2-8295-3a9ee6719bb6");
              window?.braze.getUser().addToSubscriptionGroup("fd0ce32c-9e48-4784-86f8-1f57cb1ab463");
            } else {
              window?.braze.getUser().setPhoneNumber(sms);
              window?.braze.getUser().removeFromSubscriptionGroup("560eb3d7-16fe-44a2-8295-3a9ee6719bb6");
              window?.braze.getUser().removeFromSubscriptionGroup("fd0ce32c-9e48-4784-86f8-1f57cb1ab463");
            }
          } else {
            this.updatedNotificationError = true;
            this.errorNotifMessage = "There was a problem updating your SMS details.";
          }

          this.processing = false;
          return { emailResponse, smsResponse };
      },

      async getDetailsForm() {
        this.detailsForm = document.getElementById("details-form");
        this.updatedSuccessfully = false;
        const formData = new FormData(this.detailsForm);
        this.processing = true;

        await hasher.store().dispatchHash();
        console.log('Hash', hasher.store().getHash());
        console.log('Query', GET_CUSTOMER_DETAILS.loc.source.body);

        const body = JSON.stringify({
          query: GET_CUSTOMER_DETAILS.loc.source.body,
          variables: {
            customerId: `gid://shopify/Customer/${formData.get("id")}`,
          },
        });

        let data = {
          options: {
            method: "POST",
            headers: {
              "X-Shopify-Access-Token": "<SHOPIFY_API_AUTHENTICATED_KEY>",
              "Content-Type": "application/json",
              "X-MindArc-Hash": hasher.store().getHash(),
            },
            body: body,
            request_body: GET_CUSTOMER_DETAILS.loc.source.body,
          },
        };

        let jsonResponse = null;
        if (this.useAuthenticated) {
          jsonResponse = await shopifyHelper.authenticatedFetch(data);
        } else {
          jsonResponse = await shopifyHelper.unauthenticatedFetch(
            body,
            null,
            false,
          );
        }
        let metafieldsObjectData = {};

        if (jsonResponse.data.customer?.metafields.edges.length > 0) {
          const metaData = jsonResponse.data.customer?.metafields.edges;
          metafieldsObjectData = metaData.reduce((acc, item) => {
            const key = item.node.key;
            acc[key] = item.node.id;
            return acc;
          }, {});
        }

        this.metafieldObject = metafieldsObjectData;
        return metafieldsObjectData;
      },
      async getNotifications(id) {
        this.detailsForm = document.getElementById("details-form");
        const body = JSON.stringify({
          query: GET_CUSTOMER_NOTIFICATIONS.loc.source.body,
          variables: {
            customerId: `gid://shopify/Customer/${id}`,
          },
        });
        let data = {
          options: {
            method: "POST",
            headers: {
              "X-Shopify-Access-Token": "<SHOPIFY_API_AUTHENTICATED_KEY>",
              "Content-Type": "application/json",
              "X-MindArc-Hash": hasher.store().getHash(),
            },
            body: body,
            request_body: GET_CUSTOMER_NOTIFICATIONS.loc.source.body,
          },
        };

        let jsonResponse = null;
        if (this.useAuthenticated) {
          jsonResponse = await shopifyHelper.authenticatedFetch(data);
        } else {
          jsonResponse = await shopifyHelper.unauthenticatedFetch(
            body,
            null,
            false,
          );
        }
        this.customerEmailConsent =
          jsonResponse?.data?.customer?.emailMarketingConsent?.marketingState ==
          "SUBSCRIBED";
        this.customerPhoneConsent =
          jsonResponse?.data?.customer?.smsMarketingConsent?.marketingState ==
          "SUBSCRIBED";
        console.log(
          "emailNotification",
          jsonResponse?.data?.customer?.emailMarketingConsent?.marketingState,
        );
        console.log(
          "smsNotification",
          jsonResponse?.data?.customer?.smsMarketingConsent?.marketingState,
        );
      },
      async submitDetailsForm(event) {
        this.detailsForm = document.getElementById("details-form");
        this.updatedSuccessfully = false;
        const formData = new FormData(this.detailsForm);
        this.processing = true;

        await hasher.store().dispatchHash();
        console.log('Hash', hasher.store().getHash());


        await this.getDetailsForm();
        const metafieldArray = [
          {
            id: this.metafieldObject["business_name"],
            value: formData.get("business_name"),
          },
          {
            id: this.metafieldObject["ABN"],
            value: formData.get("abn"),
          },
          {
            id: this.metafieldObject["role"],
            value: formData.get("role"),
          },
          {
            id: this.metafieldObject["Industry"],
            value: formData.get("Industry"),
          },
          {
            id: this.metafieldObject["gift_frequency"],
            value: formData.get("gift_frequency"),
          },
          {
            id: this.metafieldObject["common_gift_recipient"],
            value: formData.get("common_gift_recipient"),
          },
          {
            id: this.metafieldObject["company_size"],
            value: formData.get("company_size"),
          },
          {
            id: this.metafieldObject["website"],
            value: formData.get("website"),
          },
        ].filter((item) => item.value != null);

        const body = JSON.stringify({
          query: UPDATE_CUSTOMER_DETAILS.loc.source.body,
          variables: {
            input: {
              id: `gid://shopify/Customer/${formData.get("id")}`,
              firstName: `${formData.get("first_name")}`,
              lastName: `${formData.get("last_name")}`,
              email: `${formData.get("email")}`,
              phone: `${formData.get("phone")}`,
              metafields: metafieldArray,
            },
          },
        });
        let data = {
          options: {
            method: "POST",
            headers: {
              "X-Shopify-Access-Token": "<SHOPIFY_API_AUTHENTICATED_KEY>",
              "Content-Type": "application/json",
              "X-MindArc-Hash": hasher.store().getHash(),
            },
            body: body,
            request_body: UPDATE_CUSTOMER_DETAILS.loc.source.body,
          },
        };

        let jsonResponse = null;
        if (this.useAuthenticated) {
          jsonResponse = await shopifyHelper.authenticatedFetch(data);
        } else {
          jsonResponse = await shopifyHelper.unauthenticatedFetch(
            body,
            null,
            false,
          );
        }

        if (jsonResponse.data.customerUpdate.userErrors.length <= 0) {
          this.updatedSuccessfully = true;
          this.updatedError = false;
        } else {
          this.updatedError = true;
          this.updatedSuccessfully = false;
          this.processing = false;
          if (
            ["phone", "email"].includes(
              jsonResponse.data.customerUpdate?.userErrors[0].field[0],
            )
          ) {
            this.errorMessage =
              jsonResponse.data.customerUpdate?.userErrors[0].message;
          } else
            this.errorMessage = "There was a problem updating your details.";
        }
        this.processing = false;
        this.response = jsonResponse;
        return jsonResponse;
      },
      updateHashChangedCount() {
        this.hashChangedCount++;
      },
      logCookbookEvent (label, firstName , lastName, email, phone , business, industry ) {
        console.log(label);
        console.log(firstName);
        console.log(lastName);
        console.log(email);
        console.log(phone);
        console.log(business);
        console.log(industry);
        window.braze?.logCustomEvent("Downloads (e-book)",
        {
         "first name": firstName,
         "last name": lastName,
         "email": email,
         "phone": phone,
         "business name": business,
         "industry": industry
        })

      },
      isActive(url) {
        const { hash, pathname } = window.location;
        let linkURL = url;

        if (url[0] === "#") {
          linkURL = `${pathname}${url}`;
        }

        return `${pathname}${hash}` === linkURL;
      },
      isActiveAccount(url) {
        // eslint-disable-next-line no-undef
        const urlParams = new URLSearchParams(document.location.search);
        const view = urlParams.get("view");
        const splitPassedURL = url.split("?");
        const passedParams = {};

        // eslint-disable-next-line no-unexpected-multiline
        splitPassedURL.forEach((item) => {
          const temp = item.split("=");
          passedParams[temp[0]] = temp[1] ? temp[1] : null;
        });

        // IF neither URL value has any VIEW parameters AND there is an /account parameter, this is the default account dashboard
        if (
          !view &&
          !passedParams.view &&
          Object.keys(passedParams).indexOf("/account") > -1
        ) {
          return true;
        } else if (view && passedParams.view) {
          // otherwise if both have VIEW parameter values, match them VIEW parameters to confirm the page we are on
          return view === passedParams.view;
        }
      },
    };
  },
};

export default account;
