import React, { useState } from 'react';
import './CustomerAddresses.scss';
import AddressAutocomplete from './AddressAutocomplete';

export const CustomerAddresses = ({ data }) => {
    const { settings, customer } = data;

    if (!customer) {
        return <p>Please log in to view your addresses.</p>;
    }

    const { addresses } = customer;

    // Find the default address from the list of addresses
    const defaultAddress = addresses.find(address => address.is_default);
    const [selectedAddress, setSelectedAddress] = useState(defaultAddress || addresses[0]);

    const handleAddressChange = (event) => {
        const addressId = event.target.value;
        console.log('Selecting Address', addressId);
        const newSelectedAddress = addresses.find(address => address.id === addressId);
        setSelectedAddress(newSelectedAddress);
    };

    const handleInputChange = (field) => (event) => {
        setSelectedAddress({ ...selectedAddress, [field]: event.target.value });
    };

    const handleAddressSelect = (address) => {
        setSelectedAddress({ ...selectedAddress, ...address });
    };

    return (
        <div className="customer-addresses-section">
            <h2>{settings.section_heading}</h2>
            {addresses && addresses.length > 0 ? (
                <>
                    <div className="address-selector">
                        <label htmlFor="address-select">Select Address: </label>
                        <select id="address-select" onChange={handleAddressChange} value={selectedAddress?.id}>
                            <option>None</option>
                            {addresses.map(address => (
                                <option key={address.id} value={address.id}>
                                    {address.firstname} {address.lastname} - {address.address1}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="selected-address">
                        <h3>Selected Address</h3>
                        <label htmlFor={'firstname'}>First Name</label>
                        <input
                            type='text'
                            value={selectedAddress?.firstname}
                            onChange={handleInputChange('firstname')}
                        />
                        <label htmlFor={'lastname'}>Last Name</label>
                        <input
                            type='text'
                            value={selectedAddress?.lastname}
                            onChange={handleInputChange('lastname')}
                        />
                        <label htmlFor={'address'}>Address</label>
                        <AddressAutocomplete
                            value={selectedAddress?.address1}
                            onChange={handleInputChange('address1')}
                            onAddressSelect={handleAddressSelect}
                        />

                        <label htmlFor={'city'}>City</label>
                        <input
                            type='text'
                            value={selectedAddress?.city}
                            onChange={handleInputChange('city')}
                        />

                        <label htmlFor={'suburb'}>Suburb</label>
                        <input
                            type='text'
                            value={selectedAddress?.suburb}
                            onChange={handleInputChange('suburb')}
                        />

                        <label htmlFor={'province'}>State</label>
                        <input
                            type='text'
                            value={selectedAddress?.province}
                            onChange={handleInputChange('province')}
                        />

                        <label htmlFor={'zip'}>Postcode</label>
                        <input
                            type='text'
                            value={selectedAddress?.zip}
                            onChange={handleInputChange('zip')}
                        />

                        <label htmlFor={'country'}>Country</label>
                        <input
                            type='text'
                            value={selectedAddress?.country}
                            onChange={handleInputChange('country')}
                        />
                    </div>
                </>
            ) : (
                <p>{settings.no_addresses_text}</p>
            )}
        </div>
    );
};
