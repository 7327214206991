import React from 'react';

const ProductTag = ({ block, product }) => {
    const { tag_name, tag_delimiter } = block.settings;
    const tags = product.tags;

    // Function to find the tag based on tag_name and tag_delimiter
    const findTag = (tags, tagName, tagDelimiter) => {
        const regex = new RegExp(`^${tagName}${tagDelimiter}`);
        const tag = tags.find(tag => regex.test(tag));
        return tag ? tag.split(tagDelimiter)[1] : null;
    };

    const tagValue = findTag(tags, tag_name, tag_delimiter);

    return (
        <div>
            {tagValue ? <span className="bc">{tagValue}</span> : null}
        </div>
    );
};

export default ProductTag;
