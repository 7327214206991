import React, { useEffect, useState } from "react";
import { EcommProductPrice } from "@mindarc/arctheme-components/frontend/src/stories/components/ecommerce/Price/Price";
import { Button } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Button/Button";
import Image from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Image/Image";
import { clsx } from "clsx";
import { getAlpineStore } from "../../../../entrypoints/theme";
import shopify from "../../../../js/shopify";

export const ExtendedProductTile = ({ product, swatches = [], siblings, show_add = false, show_price_inline = false }) => {
   

    return (
        <div
        className="product-tile"
      >
      
        <div className="product-tile__image-wrapper">
          <a href={`products/${product.handle}`}>
            <Image imageUrl={product.image} />
          </a>

        </div>
      
        <div className="product-tile__details">
          <div className="product-tile__details__title-price">
            <a href="" className="">
              <div className="product-tile__details__title">
                {product.title}
              </div>
            </a>
            
            <div className="product-tile__details__price-compare">
                <span className="product-tile__details__price-value">
                {product.price}
                </span>
            </div>
          </div>

        </div>
      </div>
    );
};

export default ExtendedProductTile;
