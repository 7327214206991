import React, { useEffect } from "react";

import ExtendedFilterCollapsible from '@src/stories/components/ExtendedFilterCollapsible/ExtendedFilterCollapsible'
import ExtendedCheckbox from "@src/stories/elements/ExtendedCheckbox/ExtendedCheckbox";
import ExtendedSlider from "@src/stories/components/ExtendedSlider/ExtendedSlider";


export default function ExtendedRecipeListingSidebar ({filters, selectedFilters, setSelectedFilters, translations}) {
  
   const handleCheck = (checked, option, filter) => {
    const updatedFilters = { ...selectedFilters };

    if (checked) {
        // Add the option to the specified filter array
        updatedFilters[filter].push(option);
    } else {
        // Remove the option from the specified filter array
        updatedFilters[filter] = updatedFilters[filter].filter(item => item !== option);
    }
   
    setSelectedFilters(updatedFilters);
   }

   const isChecked = (option, filter) => {
    return selectedFilters[filter.filter] && selectedFilters[filter.filter].includes(option);
   }

   const handleFilterClear = (filter) => {
    const updatedFilters = { ...selectedFilters, [filter]: [] };
    setSelectedFilters(updatedFilters);
   }

  return (
    <div className="recipe-listing__sidebar">
        <div className="recipe-listing__sidebar-filters">
          {filters.map((filter,index) => {
                 if(filter.options.length == 0) return null;
                return (
                  <ExtendedFilterCollapsible
                    active
                    title={filter.label}
                    filter={filter.filter}
                    key={`${filter.filter}-${index}`}
                    onClearClick={(value)=>{handleFilterClear(value)}}
                    clearLabel={translations.clear_filter || 'Clear'}
                  >
                    {filter.options.map((option, index) => {
                      return (
                        <ExtendedCheckbox
                          key={filter+option+index}
                          name={option}
                          label={option}
                          square
                          checked={isChecked(option, filter)}
                          onChange={(event)=> { handleCheck(event.target.checked, option , filter.filter) }}
                        />
                      )
                    })}
                  </ExtendedFilterCollapsible>
                )
            
          })}
        </div>
    </div>
  );
};
