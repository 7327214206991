// @ts-nocheck
import React from "react";

import RecipeTileBadges from "./components/RecipeTileBadges";
import RecipeTileImage from "./components/RecipeTileImage";

type TileProps = {
  recipe: undefined;
  yotpo_snippet: String;
  dummy_id: String;
  wishlist: boolean
};

export default function ExtendedRecipeTile(props: TileProps) {
  const { recipe, yotpo_snippet, dummy_id, wishlist = false } = props;

  const badges =
    recipe.tags && recipe.tags.map((tag) => tag.split("|")).slice(0, 3);

  return (
    <div className={`recipe-tile`}>
       { wishlist && <>
        <button
          className="product-tile__wishlist"
          x-show={`$store.wishlist.isItemInWishlist('recipes','${recipe.id}')`}
          x-on:click={`$store.wishlist.removeFromWishlist('recipes','${recipe.id}')`}
        >
          <span className={`icon-heart text-mb-red`}></span>
        </button>
        <button
          className="product-tile__wishlist"
          x-show={`!$store.wishlist.isItemInWishlist('recipes','${recipe.id}')`}
          x-on:click={`$store.wishlist.addToWishlist('recipes','${recipe.id}', ${JSON.stringify(recipe)})`}
        >
          <span className={`icon-heart`}></span>
        </button>
      
      </> }

      <div className="recipe-tile__image-wrapper">
        <a href={`${recipe.url}`}>
          <RecipeTileImage
            featuredImageUrl={recipe.featured_image}
            altText={recipe.title}
            images={recipe.images}
          />
        </a>

        {badges && <RecipeTileBadges badges={badges} />}
      </div>

      <div className="recipe-tile__details">
        <div
          className="yotpo-widget-instance h-[21px]"
          data-yotpo-instance-id={`${yotpo_snippet}`}
          data-yotpo-product-id={`${dummy_id}`}
          data-yotpo-cart-product-id={`${dummy_id}`}
          data-yotpo-section-id="product"
        ></div>
        <div className="recipe-tile__details__title-price">
          <a href={`${recipe.url}`}>
            <div className="recipe-tile__details__title">{recipe.title}</div>
          </a>
          <div className="recipe-tile__details__subtitle">
            {" "}
            {recipe.sub_title}{" "}
          </div>
        </div>
        <div className="mt-auto">
          {" "}
          <a href={`${recipe.url}`} className="recipe-tile__details__read">
            {" "}
            Read More{" "}
          </a>{" "}
        </div>
      </div>
    </div>
  );
}
