import * as React from "react";
import { ElementLink } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Link/Link";
import ImageComponent from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Image/Image";
import ExtendedCollapsible from "../ExtendedCollapsible/ExtendedCollapsible";
import helper from 'frontend/js/helpers'


const ExtendedFooter = ({ sublevel, settings, links, type }) => {
    const { locationsMeta , contactsMeta } = sublevel
  
    const locations = locationsMeta && helper.arrayToEnum(locationsMeta, 'location_name')
    const contacts = contactsMeta && helper.arrayToEnum(contactsMeta, 'contact_name')
   
    const renderComponents = () => {
        switch (type) {
            default:
                return (
                    <div className={type}>
                        <div className="footer-links grid-container">
                            <div className="footer__company-details">
                                <a href="/" className="footer__company-logo">
                                    <ImageComponent imageUrl={settings.company_icon} />
                                </a>
                                <div className="footer-links-company">
                                    { settings.company_name }
                                    <p className="sm-text">Developed by MindArc</p>
                                </div>
                            </div>
                            {links.map((list_links, id) => (
                                <div key={`default_${id}`} className="footer-links-list">
                                    <h3 className="b3">{list_links.links_title}</h3>
                                    <nav>
                                        <ul aria-label="footermenu" role="menu">
                                            {list_links.links.map((link, linkId) => (
                                                <li key={`default_child_${linkId}`} role="none" className="my-2">
                                                    <ElementLink new_tab={true} text={link.title} link={link.url} />
                                                </li>
                                            ))}
                                        </ul>
                                    </nav>
                                </div>
                            ))}
                        </div>
                    </div>
                );
            case 'footer-layout-links-only':
                return (
                    <div className={type}>
                        <div className={`footer-links lg:max-w-[${settings.links_container_width}]`}>
                            {links.map((list_links, id) => {
                                const customMenuType = list_links.settings.type
                                if (list_links.type  == 'social_list') {
                                    return (
                                        <div key={`social_list_${id}`} className={`footer-links-list lg:max-w-[${settings.column_width}]`}>
                                            <ExtendedCollapsible
                                                title={list_links.settings.menu_title}
                                                openIcon='icon-chevron-down'
                                                desktopDisabled
                                            >
                                                <nav>
                                                    <ul aria-label="footermenu" role="menu">
                                                        <li className="flex flex-row items-center gap-3" role="none">
                                                            <a className="icon icon-facebook" target={"_blank" } href={`${list_links.settings.facebook_url}`}></a> 
                                                            <div className="footer-links-list__sublink-icon">
                                                                <ElementLink text={'Facebook'} new_tab={true} link={list_links.settings.facebook_url} />
                                                            </div>
                                                        </li>
                                                        <li className="flex flex-row items-center gap-3" role="none">
                                                            <a className="icon icon-instagram" target={"_blank" }  href={`${list_links.settings.instagram_url}`}></a> 
                                                            <div className="footer-links-list__sublink-icon">
                                                                <ElementLink text={'Instagram'} new_tab={true} link={list_links.settings.instagram_url} />
                                                            </div>
                                                        </li>
                                                        <li className="flex flex-row items-center gap-3" role="none">
                                                            <a className="icon icon-youtube" target={"_blank" }  href={`${list_links.settings.youtube_url}`}></a> 
                                                            <div className="footer-links-list__sublink-icon">
                                                                <ElementLink text={'Youtube'} new_tab={true} link={list_links.settings.youtube_url} />
                                                            </div>
                                                        </li>
                                                        <li className="flex flex-row items-center gap-3" role="none">
                                                            <a className="icon icon-pintrest" target={"_blank" }  href={`${list_links.settings.pinterest_url}`}></a> 
                                                            <div className="footer-links-list__sublink-icon">
                                                                <ElementLink text={'Pinterest'}  new_tab={true} link={list_links.settings.pinterest_url} />
                                                            </div>
                                                        </li>
                                                        <li className="flex flex-row items-center gap-3" role="none">
                                                            <a className="icon icon-x" target={"_blank" }  href={`${list_links.settings.x_url}`}></a> 
                                                            <div className="footer-links-list__sublink-icon">
                                                                <ElementLink text={'X'} new_tab={true} link={list_links.settings.x_url} />
                                                            </div>
                                                        </li>
                                                        <li className="flex flex-row items-center gap-3" role="none">
                                                            <a className="icon icon-linkedin" target={"_blank" }  href={`${list_links.settings.linkedin_url}`}></a> 
                                                            <div className="footer-links-list__sublink-icon">
                                                                <ElementLink text={'LinkedIn'} new_tab={true} link={list_links.settings.linkedin_url} />
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </ExtendedCollapsible>
                                        </div>
                                    )
                                }
                       
                                return (
                                    <div key={`links-only_${id}`} className={`footer-links-list lg:max-w-[${settings.column_width}]`}>
                                        <ExtendedCollapsible
                                            title={list_links.links_title}
                                            openIcon='icon-chevron-down'
                                            desktopDisabled
                                        >
                                            <nav>
                                                <ul className={`footer-links-list-${customMenuType}`} aria-label="footermenu" role="menu">
                                                    {list_links.links.map((link, childLinkId) => {
                                                        const sublink = customMenuType == 'contact' ? contacts[link.title] : locations[link.title]
                                                        if(sublink) {
                                                            switch (customMenuType) {
                                                                case 'contact':
                                                                    let informationLink = ''
                                                                    if (helper.isValidEmail(sublink.contact_information)) {
                                                                        informationLink = `mailto:${sublink.contact_information}`
                                                                    } else if (helper.isValidPhoneNumber(sublink.contact_information)) {
                                                                        informationLink = `tel:${sublink.contact_information}`
                                                                    }
                                                            
                                                                    return (
                                                                        <li key={`links-only_contact_${childLinkId}`}>
                                                                            <div className="footer-links-list__link" > {informationLink ? <ElementLink text={link.title} link={informationLink} new_tab={true} /> : <span> {link.title} </span> } </div>
                                                                            <div role="none" className="flex flex-col footer-links-list__sublink">
                                                                                <div> {informationLink ? <ElementLink text={sublink.contact_information} link={informationLink}  new_tab={true} />  : <span> {sublink.contact_information} </span> }</div>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                case 'direction':
                                                                    return (
                                                                        <li key={`links-only_direction_${childLinkId}`}>
                                                                            <div className="footer-links-list__link"> <ElementLink new_tab={true} text={link.title} link={link.url} /> </div>
                                                                            <div role="none" className="flex flex-col footer-links-list__sublink">        
                                                                                    <div> <ElementLink new_tab={true} text={sublink.business_hours} link={link.url} /> </div>
                                                                                    <div> <ElementLink  text={sublink.driving_directions_label} link={link.url} new_tab={true} />    </div>                                            
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                default:
                                                                    break
                                                            }
                                                        }
                                                        return (
                                                            <li key={`links-only_default_${childLinkId}`} role="none" className="footer-links-list__sublink">
                                                                <ElementLink text={link.title} link={link.url}  new_tab={true} />
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </nav>
                                        </ExtendedCollapsible>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                );
        }
    };

    return (
        <footer>
            {renderComponents()}
        </footer>
    );
};

export default ExtendedFooter;