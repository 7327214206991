import React from "react";
import { ElementLink } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Link/Link";
import { Paragraph } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Paragraph/Paragraph";

import StyleguideColours from './StyleguideColours';
import StyleguideTypography from './StyleguideTypography';
import StyleguideButtons from './StyleguideButtons';
import StyleguideFormFields from './StyleguideFormFields';

export function StyleguidePage({ ...props }) {
  return (
    <div className={'p-8'} style={{ background: '#F5F5F5' }}>
      <div className={'text-center py-4'}>
        <Paragraph text={'To view the latest Arctheme components, please check the link below'} />
        <ElementLink text={'Arctheme Components'}
          link={'https://65bc451225e432e3bc38ad47-egwfldqysf.chromatic.com/'} new_tab={true} />
      </div>

      <StyleguideColours />
      <StyleguideTypography />
      <StyleguideButtons />
      <StyleguideFormFields />
    </div>
  )
}
