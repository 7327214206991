import React, { useState, useRef, useEffect } from "react";
import KeenSlider from 'keen-slider'
import ExtendedButton from "@src/stories/elements/ExtendedButton/ExtendedButton";

import "keen-slider/keen-slider.min.css";
import "./ExtendedKeenSlider.scss";

const defaultOptions = {
  initial: 0,
  slides: {
    perView: 6,
    spacing: 0
  },
}

export default function ExtendedKeenSlider({
  options = defaultOptions,
  children,
  onOptionsChange = (_) => {}
}) {

  if (!children) return;

  const [totalPages, setTotalPages] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const sliderRef = useRef(null);
  const instanceRef = useRef(null);

  useEffect(() => {
    if (sliderRef.current) {
      instanceRef.current = new KeenSlider(sliderRef.current, {
        ...options,
        slideChanged(slider) {
          setCurrentSlide(slider.track.details.rel);
          instanceRef.current = slider;
        },
        created() {
          setLoaded(true);
        },
        optionsChanged() {
          handleOptionChange()
        }
      });

      handleOptionChange();
    }

    return () => {
      if (instanceRef.current) instanceRef.current.destroy();
    };
  }, [options, children]);

  function handleOptionChange() {
    const { options } = instanceRef.current as any;
    setTotalPages(Math.ceil(React.Children.count(children) - options.slides.perView + 1));
    if (onOptionsChange) onOptionsChange(options)
  }

  function handlePreviousClick() {
    if (currentSlide === 0) return;

    const nextIndex = currentSlide - 1;
    instanceRef.current?.moveToIdx(nextIndex, true);
  }

  function handleNextClick() {
    if (currentSlide === totalPages - 1) return;

    const nextIndex = currentSlide + 1;
    instanceRef.current?.moveToIdx(nextIndex, true);
  }

  function handleDotClick(index) {
    instanceRef.current?.moveToIdx(index);
  }

  return (
    <>
      <div className="keen-slider__wrapper">
        <div
          ref={sliderRef}
          className="keen-slider"
        >
          {React.Children.map(children, (child, index) => (
            <div className="keen-slider__slide" key={index}>
              {child}
            </div>
          ))}
        </div>

        {(loaded && instanceRef.current && totalPages > 1) &&
          <>
            <ExtendedButton
              icon="chevron-left"
              style='dark-primary'
              className="keen-slider__arrow prev"
              onClick={() => handlePreviousClick()}
            />

            <ExtendedButton
              icon="chevron-right"
              style='dark-primary'
              className="keen-slider__arrow next"
              onClick={() => handleNextClick()}
            />
          </>
        }
      </div>

      {(loaded && instanceRef.current?.track.details.maxIdx >= 1) &&
        <div className="keen-slider__dots">
          {[...Array(instanceRef.current.track.details.maxIdx + 1).keys()].map((index) => {
            return (
              <button
                key={index}
                className={"keen-slider__dot" + (currentSlide === index ? " active" : "")}
                onClick={() => handleDotClick(index)}
              ></button>
            );
          })}
        </div>
      }
    </>
  );
}
