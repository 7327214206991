import * as React from "react";
import { clsx } from 'clsx';

import './ExtendedTextField.scss';

type ExtendedTextFieldProps = {
  id?: string,
  label?: string,
  className?: string,
  type?: 'default-input' | 'floating-input',
  inputType?: string,
  placeholder?: string,
  name?: string,
  icon?: string,
  xref?: string,
  xinit?: string,
  value?: string | number,
  required?: boolean,
  disabled?: boolean,
  readonly?: boolean,
  iconRight?: boolean,
  hasError?: boolean,
  errorMessage?: string,
  onChange?: Function,
  onFocus?: Function,
  onBlur?: Function
}

export default function ExtendedTextField ({
  id,
  label = '',
  className,
  type = 'default-input',
  inputType = 'text',
  placeholder,
  name,
  icon,
  iconRight = false,
  value,
  required,
  disabled,
  xref,
  xinit,
  readonly,
  hasError,
  errorMessage,
  onChange = () => {},
  onFocus = () => {},
  onBlur = () => {}
}: ExtendedTextFieldProps) {

  function handleChange (event) {
    if (onChange) onChange(event);
  }

  function handleFocus () {
    if (onFocus) onFocus();
  }

  function handleBlur () {
    if (onBlur) onBlur();
  }

  return (
    <div className={`${type} ${className}`}>
      <div
        className={clsx('text-field-wrap', {
          'has-icon': !!icon
        })}
      >
        {(icon && !iconRight) &&
          <span className={`text-field-icon icon-${icon}`}></span>
        }

        {(type !== 'floating-input' && label) &&
          <label
            htmlFor={id}
            className="form-field__label"
          >
            {label}
          </label>
        }

        <input
          id={id}
          type={inputType}
          className={clsx('form-field', {
            'has-error': hasError,
            'disabled': disabled
          })}
          name={name}
          defaultValue={value}
          placeholder={type === 'default-input' ? placeholder : ''}
          required={required}
          readOnly={readonly}
          disabled={disabled}
          onFocus={()=> handleFocus()}
          onChange={(event) => handleChange(event)}
          onBlur={() => handleBlur()}
          x-init={xinit}
          x-ref={xref}
        />

        {(icon && iconRight) &&
          <span className={`text-field-icon icon-${icon} is-right`}></span>
        }
      </div>

      {type === 'floating-input' &&
        <label htmlFor={id}>
          {placeholder}
        </label>
      }

      {(hasError && errorMessage) &&
        <div className="form-field__error-message">
          {errorMessage}
        </div>
      }
    </div>
  );
};
