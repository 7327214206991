import * as React from "react";
import Image from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Image/Image";

const LogoBlock = ({ settings, className = '' }) => {
    return (
        <div className={`header-logo-container  ${className}`}>
            <a href="/" className="header-logo-image lg:pb-0">
                <Image imageUrl={settings.additional.logo}/>
            </a>

            <a href="/" className="header-logo">
                {settings.additional.shop_title}
            </a>
        </div>
    )
}

export default LogoBlock;