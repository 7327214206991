import React, {useState} from "react";
import {Paragraph} from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Paragraph/Paragraph";
import Image from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Image/Image";

export const ExtendedProductAccordion = ({ items  }) => {
    const [activeIndex, setActiveIndex] = useState(null);

    const onTitleClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    // Filter items to only include those with a title and content
    const filteredItems = items.filter(dataitem => dataitem.title);

    return (
        <div className="product-accordion">
         
            {filteredItems.map((item, index) => (
                <div key={index} className={`border-t py-2 border-mb-neutral-500 ${ index == filteredItems.length - 1 ? 'border-b' : '' }`}>
                    <div className="accordion__header" onClick={() => onTitleClick(index)}>
                        <span className="accordion__image-container">
                            <div className="accordion__image">
                                {item.icon && <Image imageUrl={item.icon} />}
                            </div>
                            <div className="accordion__title">{item.title}</div>
                        </span>
                        { activeIndex === index ?   <span className="accordion__toggle"><i className={'icon-chevron-up'}></i></span> :   <span className="accordion__toggle"><i className={'icon-chevron-down'}></i></span> }
                      
                    </div>
                    <div className={`accordion-content font-light ${activeIndex === index ? 'active' : ''}`}
                         style={{
                             maxHeight: activeIndex === index ? '1000px' : '0',
                         }}>
                        <Paragraph text={item.content} type={'b4'} />
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ExtendedProductAccordion;