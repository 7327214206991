import * as React from "react";
import { clsx } from 'clsx';

import './ExtendedButton.scss';

type ExtendedButtonProps = {
  icon?: string,
  type?: "button" | "submit" | "reset" | undefined;
  style?: 'primary' | 'secondary' | 'tertiary' | 'text' | 'dark-primary' | 'dark-secondary' | 'dark-tertiary' | 'dark-text',
  text?: boolean,
  label?: string,
  link?: string,
  className?: string,
  reverse?: boolean,
  disabled?: boolean,
  onClick?: Function,
}

export default function ExtendedButton ({
  icon,
  type = 'button',
  style = 'primary',
  text = true,
  link,
  className = '',
  reverse,
  label,
  disabled,
  onClick,
}: ExtendedButtonProps) {
  function handleClick() {
    if (onClick) onClick();
  }

  const ButtonElement = (
    <button
      type={type}
      className={clsx(`button button-${style} ${className}`, {
        'reverse': reverse,
        'icon-only': icon && !label,
        'text-only': style === 'text' || style === 'dark-text'
      })}
      disabled={disabled}
      onClick={() => handleClick()}
    >
      {icon && <span className={`icon-${icon}`}></span>}

      {(text && label) &&
        <span className="button__label">
          {label}
        </span>
      }
    </button>
  );

  return link
    ? (
        <a href={link} className="flex items-center">
          {ButtonElement}
        </a>
      )

    : ButtonElement
}
