import React, { useEffect } from 'react';

const ProductSizeGuide = ({ useKiwiSizeApp, shop, product }) => {

    useEffect(() => {
        if (useKiwiSizeApp) {
            // @ts-ignore
            window.KiwiSizing = window.KiwiSizing || {};
            // @ts-ignore
            window.KiwiSizing.shop = shop;

            const isProductPage = window.location.pathname.includes('/product.');
            if (isProductPage) {
                // @ts-ignore
                window.KiwiSizing.data = {
                    collections: product.collections.map(collection => collection.id).join(','),
                    tags: product.tags,
                    product: product.id,
                    vendor: product.vendor,
                    type: product.type,
                    title: product.title,
                    images: product.images,
                };
            }
        }
    }, [useKiwiSizeApp, shop, product]);

    return (
        <div id={useKiwiSizeApp ? 'kiwi-size-guide' : ''} className="size-guide flex place-items-center justify-end gap-[12px]">
            <i className="icon-measure-tape text-[24px]"></i>
            <div className="bc">Size Guide</div>
        </div>
    );
};

export default ProductSizeGuide;
