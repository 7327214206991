import * as React from "react";

import ListProductsDefault from "./components/ExtendedListProductsDefault";
import ListProductsTabbed from "./components/ExtendedListProductsTabbed";


const layouts = {
    'list-tabbed': ListProductsTabbed,
    'list-default': ListProductsDefault
}

const ListProducts = (props) => {
    let type: string = props.settings?.classes;
    const LayoutComponent = layouts[type] || ListProductsDefault;
    return <LayoutComponent {...props} />
};

export default ListProducts;
