import * as React from "react";

export default function AccountPopupLoggedIn ({ settings }) {
  const {
    cta_button_food_club_text,
    cta_button_signout_text
  } = settings.block;

  return (
    <div
      className="account-popup-details absolute right-0 w-full lg:w-auto top-px"
      x-data="toggledisplay"
      x-init="$nextTick(() => { init('header-account') })"
    >
      <div
        className="bg-white z-50 p-4 shadow-small"
        x-show="is_open"
      >
        <a
          href="/account"
          className="flex items-center gap-4 hover:no-underline py-3"
        >
          <span className="text-mb-neutral-500 hover:underline">
            {cta_button_food_club_text}
          </span>

          <span className="icon-log-in ml-auto text-2xl"></span>
        </a>

        <hr className="border-mb-black my-2" />

        <a
          href="/account/logout"
          className="flex items-center gap-4 hover:no-underline py-3"
        >
          <span className="text-mb-neutral-500 hover:underline">
            {cta_button_signout_text}
          </span>

          <span className="icon-up-arrow-alt ml-auto text-2xl"></span>
        </a>
      </div>
    </div>
  )
}
