import React, { useState, useEffect, useRef } from 'react';

import ExtendedImage from "../../ExtendedImage/ExtendedImage";
import ExtendedCartWrapper from "./ExtendedCartWrapper";
import DatePicker from "react-datepicker";

import { constructFullAddress, formatPrice } from 'frontend/js/helpers';
import { updateItem } from 'frontend/js/useShopifyCart';
import ExtendedRadio from '@src/stories/elements/ExtendedRadio/ExtendedRadio';
import ExtendedTextField from '@src/stories/elements/ExtendedTextField/ExtendedTextField';
import shippingitem from "../../../../../js/alpine/components/cart/shippingitem";
import { fetchCart } from 'frontend/js/useShopifyCart';
import "react-datepicker/dist/react-datepicker.css";
import validation from "../../../../../js/alpine/components/address/validation";
import { getShippingRates } from '../../ExtendedShippingCalculator/macshipApi';
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import {mobileBreakpoint} from "../../../../../entrypoints/theme";
import { formatDateTime } from "frontend/js/helpers";


const ImagePlaceholderSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 525.5 525.5">
      <path d="M324.5 212.7H203c-1.6 0-2.8 1.3-2.8 2.8V308c0 1.6 1.3 2.8 2.8 2.8h121.6c1.6 0 2.8-1.3 2.8-2.8v-92.5c0-1.6-1.3-2.8-2.9-2.8zm1.1 95.3c0 .6-.5 1.1-1.1 1.1H203c-.6 0-1.1-.5-1.1-1.1v-92.5c0-.6.5-1.1 1.1-1.1h121.6c.6 0 1.1.5 1.1 1.1V308z"/>
      <path d="M210.4 299.5H240v.1s.1 0 .2-.1h75.2v-76.2h-105v76.2zm1.8-7.2l20-20c1.6-1.6 3.8-2.5 6.1-2.5s4.5.9 6.1 2.5l1.5 1.5 16.8 16.8c-12.9 3.3-20.7 6.3-22.8 7.2h-27.7v-5.5zm101.5-10.1c-20.1 1.7-36.7 4.8-49.1 7.9l-16.9-16.9 26.3-26.3c1.6-1.6 3.8-2.5 6.1-2.5s4.5.9 6.1 2.5l27.5 27.5v7.8zm-68.9 15.5c9.7-3.5 33.9-10.9 68.9-13.8v13.8h-68.9zm68.9-72.7v46.8l-26.2-26.2c-1.9-1.9-4.5-3-7.3-3s-5.4 1.1-7.3 3l-26.3 26.3-.9-.9c-1.9-1.9-4.5-3-7.3-3s-5.4 1.1-7.3 3l-18.8 18.8V225h101.4z"/><path d="M232.8 254c4.6 0 8.3-3.7 8.3-8.3s-3.7-8.3-8.3-8.3-8.3 3.7-8.3 8.3 3.7 8.3 8.3 8.3zm0-14.9c3.6 0 6.6 2.9 6.6 6.6s-2.9 6.6-6.6 6.6-6.6-2.9-6.6-6.6 3-6.6 6.6-6.6z"/>
  </svg>
)

const IconMarker = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="7" height="31" viewBox="0 0 7 31" fill="none">
    <path d="M1.35625 20.5205L0.30625 0H6.78125L5.6875 20.5205H1.35625ZM3.54375 31C2.52292 31 1.67708 30.6638 1.00625 29.9915C0.335417 29.3192 0 28.5153 0 27.5799C0 26.6445 0.335417 25.8553 1.00625 25.2122C1.67708 24.5398 2.52292 24.2037 3.54375 24.2037C4.56458 24.2037 5.39583 24.5398 6.0375 25.2122C6.67917 25.8553 7 26.6445 7 27.5799C7 28.5153 6.67917 29.3192 6.0375 29.9915C5.39583 30.6638 4.56458 31 3.54375 31Z" fill="black"/>
  </svg>
)



const dayMap = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6
};


export default function ExtendedCartStep2 ({  hampers, error, errorMessage, setErrorMessage, setError, customer, setChilledDeliveryMap, chilledDeliveryMap, setDeliveryMap, cart, cartItems, address, settings, shipping_id, translations, onStepChange, deliveryMap, bundleType, shipping_rates, logged_out_threshold , logged_in_threshold, free_price_bypass }) {
  const [updatingCart, setUpdatingCart] = useState(false);
  const fullAddress = constructFullAddress(address);
  const isSingleAddress = true;

  const emptyKeyObject = cartItems.reduce((acc, product) => {
    product.items.forEach(item => {
      acc[item.key] = null;
    });
    return acc;
  }, {});

  const [ itemDates, setItemDates ] = useState(emptyKeyObject);
  const [ deliveryRates, setDeliveryRates ] = useState(emptyKeyObject);
  const [ consignmentChilledDate,  setConsignmentChilledDate ] = useState({});
  const [ consignmentAllDate,  setConsignmentAllDate ] = useState({});
  const [ chilledTransitTime,  setChilledTransitTime ] = useState(0);

  const hasChilledDelivery = cartItems && cartItems.some(product =>
    product.items.some(item => item.properties._Chilled_Delivery == "true"  || item.properties._Chilled_Delivery == true)
  );

  const [ stepStatus , setStepStatus] = useState('INIT');
  const hasLoadedCart = useRef(false); // Track whether the cart has been loaded
  const shippingItems = cart.items.filter(item => item.variant_id == shipping_id);
  const totalShippingPrice = shippingItems && shippingItems.reduce((acc, item) => acc + item.price, 0);

  // Update and delete shipping items on initial cart journey load. These items would be added again on second step. Shipping items that are already matched to a bundle would not be deleted
  const updateItems = shippingItems.reduce((acc, item) => {
    // Find if any cart item matches the _bundleId with the shipping item
    const matchingCartItem = cartItems.some(cartItem =>
      cartItem.items.some(ci => ci.properties._bundleId === item.properties._bundleId)
    );
    
    // Set acc[item.key] to quantity or 0 based on the match
    acc[item.key] = matchingCartItem ? item.quantity : 0;
    
    return acc;
  }, {});

  
  const totalCartPrice = ((cart.total_price - totalShippingPrice) / 100).toFixed(2);
  
  let availFree = false;
  if(customer){
    availFree = Number(totalCartPrice) > Number(logged_in_threshold);
  } else {
    availFree = Number(totalCartPrice) > Number(logged_out_threshold);
  }
  const showFreeShippingDelivery = !hasChilledDelivery && availFree;

  function startLoading() {
    setUpdatingCart(true);
  }
  
  function stopLoading() {
    setUpdatingCart(false);
  }

  function getFormattedCurrentDate() {
    const currentDate = new Date();
    return currentDate.toISOString();
  }
  
  function addBusinessDays(date, daysToAdd) {
    let resultDate = new Date(date);
    while (daysToAdd > 0) {
      resultDate.setDate(resultDate.getDate() + 1); 
      if (resultDate.getDay() !== 0 && resultDate.getDay() !== 6) {
        daysToAdd--; // Decrement daysToAdd only for weekdays
      }
    }
    return resultDate.toISOString(); 
  }


  
  // Example usage
  const currentDateFormatted = getFormattedCurrentDate();

  const disableDates = (date, type) => {
    const consignmentDateIntegers = type == 'Chilled' ? consignmentChilledDate.map(day => dayMap[day]) : consignmentAllDate.map(day => dayMap[day]) ;

    function getWeekdayValues(map) {
      return Object.values(map).filter(value => value >= 1 && value <= 5);
    }
    
  
    function getWeekendValues(map) {
      return Object.values(map).filter(value => value === 0 || value === 6);
    }
    
    // Function to filter consignment dates based on weekdays or weekends
    function filterConsignmentDates(array, isWeekday) {
      const dayValues = isWeekday ? getWeekdayValues(dayMap) : getWeekendValues(dayMap);
      return array.filter(value => dayValues.includes(value));
    }

    let availableDates = []
    // Example usage:
    if( type == 'Weekend') {
      availableDates= filterConsignmentDates(consignmentDateIntegers, false);
    }

    else if (type == 'Weekday') {
      availableDates = filterConsignmentDates(consignmentDateIntegers, true);
    }

    else {
      availableDates = consignmentDateIntegers;
    }
    
    const day = date.getDay();
    return  availableDates.includes(day); 
  };

  useEffect(()=> {
    const loadCart = async () => {
      const isNewZealand = validation.component().isNewZealand || address['address[country]'] == "New Zealand";
      let hamperCount = 0;
      let nonHamperCount = 0;
  
      if(stepStatus == 'INIT' && !hasLoadedCart.current) {
        setUpdatingCart(true);
        hasLoadedCart.current = true; // Mark that the cart load has started
        await fetchCart(); // Wait for fetchCart to complete
          
        cartItems.forEach((itemGroup) => {
          itemGroup.items.forEach((item) => {
            const itemId = item.id.toString();
            // Check if the item matches any key in mapKeys
            if (hampers && hampers[itemId] && hampers[itemId].length > 0 && item.product_type == 'Hampers') {
              hamperCount += item.quantity;
            } else {
              nonHamperCount += item.quantity;
            }
          });
        });
        const quantityItems = hamperCount + ( nonHamperCount > 1 ? 1 : 0);
        const _getShippingRates = async () => {
          try {
          
          const response = await getShippingRates( isNewZealand ? '0000' : address["address[zip]"], isNewZealand ? 'New Zealand (NZL)' : address["address[city]"], address["address[address1]"], 1, false);
          const errorsResponse = response?.data?.object?.results || response?.data?.errors;
          if(errorsResponse){;
            setError(true);
            setErrorMessage(errorsResponse[0].errorMessage);
            return;
          }
          const results = response?.data?.object.routes.map((item, index) => {
            return {
              id: `${item.requestId}_${index}`,
              label: item.carrierService.displayName,
              rate_id: item.carrierService.id,
              abbr: item.carrierService.abbreviation,
              price: item.priceDisplay,
              eta: item.despatchOptions[0]?.etaLocal,
              transit: item.despatchOptions[0]?.totalBusinessDays
            }
          });
   
          const deliveryMapItems =  results.reduce((acc, curr) => {
              const priceFormatted = curr.price === 0 ? 'FREE' : `$${curr.price.toFixed(2)}`;
              const byPass = curr.price == 0 && !showFreeShippingDelivery; // Bypass REG rate price when treshold is met
              const byPassPrice = free_price_bypass;
              acc[curr.label] = {
                  title: curr.label,
                  consignment: shipping_rates[curr.rate_id] ? shipping_rates[curr.rate_id].name : curr.abbr,
                  price: byPass ?  `$${(Number(byPassPrice)/100).toFixed(2)}` : priceFormatted,
                  raw_price:  byPass ?  byPassPrice : curr.price * 100,  // Converting to cents
                  id: curr.rate_id,
                  transit: curr.transit,
                  eta: curr.eta,
                  display: shipping_rates[curr.rate_id] && shipping_rates[curr.rate_id].display,
                  datePicker: shipping_rates[curr.rate_id] && shipping_rates[curr.rate_id].showDate,
                  type: shipping_rates[curr.rate_id] && shipping_rates[curr.rate_id].type
              };
              return acc;
          }, {});

          setDeliveryMap(deliveryMapItems);
          setConsignmentAllDate(response?.data?.hdsZones?.allDays);
          setConsignmentChilledDate(response?.data?.hdsZones?.ChilledDayBusiness);
          
        }  catch (error) {
          setError(true);
          console.log(error);
          setErrorMessage("Failed to fetch shipping rates. Please try again later.");
        }
        }
    
        _getShippingRates();

        const _getChilledShippingRates = async () => {
          try {
          const response = await getShippingRates(address["address[zip]"], address["address[city]"], address["address[address1]"], quantityItems == 0 ? 1 : quantityItems, true); // When quantityItems == 0, this is due to cart being only bundles. Set to 1 to prevent api error

          const errorsResponse = response?.data?.object?.results || response?.data?.errors;
   
          if(errorsResponse && !isNewZealand){
            setError(true);
            setErrorMessage(errorsResponse[0].errorMessage);
            return
          }
          const results = response?.data?.object.routes.map((item, index) => {
            return {
              id: `${item.requestId}_${index}`,
              label: item.carrierService.displayName,
              rate_id: item.carrierService.id,
              abbr: item.carrierService.abbreviation,
              price: item.priceDisplay,
              eta: item.despatchOptions[0]?.etaLocal,
              transit: item.despatchOptions[0]?.totalBusinessDays
            }
          });
     
          const deliveryMapItems =  results.reduce((acc, curr) => {
            
              const priceFormatted = curr.price === 0 ? 'FREE' : `$${curr.price.toFixed(2)}`;
              const byPass = curr.price == 0 && !showFreeShippingDelivery; // Bypass REG rate price when treshold is met
              const byPassPrice = free_price_bypass;
              acc[curr.label] = {
                  title: curr.label,
                  consignment: shipping_rates[curr.rate_id] ? shipping_rates[curr.rate_id].name : curr.abbr,
                  price: byPass ?  `$${(Number(byPassPrice)/100).toFixed(2)}` : priceFormatted,
                  raw_price:  byPass ?  byPassPrice : curr.price * 100,  // Converting to cents
                  id: curr.rate_id,
                  transit: curr.transit,
                  eta: curr.eta,
                  display: shipping_rates[curr.rate_id] && shipping_rates[curr.rate_id].display,
                  datePicker: shipping_rates[curr.rate_id] && shipping_rates[curr.rate_id].showDate,
                  type: shipping_rates[curr.rate_id] && shipping_rates[curr.rate_id].type
              };
              return acc;
          }, {});
      
          setChilledDeliveryMap(deliveryMapItems);
          const chilledTransitRate = deliveryMapItems && Object.values(deliveryMapItems).find(rate => rate.type === 'Chilled Transit Time');
          setChilledTransitTime(chilledTransitRate && chilledTransitRate.transit);
          } catch (error) {
            if(!isNewZealand) {
              setError(true);
              console.log(error);
              setErrorMessage("Failed to fetch shipping rates. Please try again later.");
            }
          }
        }
    
        _getChilledShippingRates();
        setStepStatus('FETCH');
      }
      
    };
    if(stepStatus == 'INIT') {
      loadCart(); // Call the async function when the component mounts
    }

  },[])

  useEffect(() => {
    if(stepStatus == 'FETCH') {
      shippingitem.component().updateShippingProducts(updateItems, startLoading, stopLoading);
    }
    else {
      setUpdatingCart(false);
    }
  }, [stepStatus])

  const validDeliveryMap = hasChilledDelivery ? chilledDeliveryMap : deliveryMap;

  async function updateLineItems () {
    let hasError = false;
    for (const cartItem of cartItems) {
      for (const item of cartItem.items) {
        if(item.product_type == bundleType) {
          continue;
        }
        if ( !validDeliveryMap[item.properties._shipping_rate] && !validDeliveryMap[item.properties._shipping_rate]?.consignment && !deliveryRates['allKey']) {
          setUpdatingCart(false);
          setError(true);
          setErrorMessage('Please fill shipping option details.');
          hasError = true;
          return hasError
        }
        else if (deliveryRates['allKey']) {
          if(validDeliveryMap[deliveryRates['allKey']] && validDeliveryMap[deliveryRates['allKey']]?.datePicker && !itemDates['allKey']) {
            setUpdatingCart(false);
            setError(true);
            setErrorMessage('Select a date from the date picker.');
            hasError = true;
            return hasError
          }
        }
        else if (item.properties._shipping_rate) {
          if (validDeliveryMap[item.properties._shipping_rate] && validDeliveryMap[item.properties._shipping_rate].datePicker && !item.properties._delivery_date) {
            setUpdatingCart(false);
            setError(true);
            setErrorMessage('Select a date from the date picker.');
            hasError = true;
            return hasError
          }
        }

        await updateItem({
          id: item.key,
          quantity: 1,
          properties: {
            ...item.properties,
            _shipping_rate:  deliveryRates['allKey'] ? deliveryRates['allKey'] : item.properties._shipping_rate,
            _delivery_date: !displayDeliveryDate(item) ? '' : (itemDates['allKey'] ? itemDates['allKey'] : item.properties._delivery_date),
            _consignment: deliveryRates['allKey'] ? validDeliveryMap[deliveryRates['allKey']].consignment : validDeliveryMap[item.properties._shipping_rate].consignment
          }
        }, false); 
      }
    }

    return hasError
  }

  async function handleValidation() {
    setUpdatingCart(true);
    const hasError = await updateLineItems();
    await fetchCart();
    if(!hasError) {
      setError(false);
      onStepChange(2);
    }
    setUpdatingCart(false);
  }

  function displayDeliveryDate (item) {
    let deliveryCheck;
    if ( isSingleAddress) {
      deliveryCheck = deliveryRates['allKey'] ? deliveryRates['allKey'] : item.properties._shipping_rate;
    }
  
    const validDeliveryMap = hasChilledDelivery ? chilledDeliveryMap : deliveryMap;
    if(!deliveryCheck) {
      setItemDates({'allKey': null});
      return false;
    } 
    if(validDeliveryMap[deliveryCheck]) {
      return validDeliveryMap [deliveryCheck] && validDeliveryMap[deliveryCheck].datePicker;
    }
    setItemDates({'allKey': null});
    return false;
  }

  const deliveryOptions = Object.values(deliveryMap).map(({ title, price, display, datePicker, type, transit, eta }) => ({
    title,
    price,
    display,
    datePicker,
    type,
    transit,
    eta
  }));

  const chilledDeliveryOptions = Object.values(chilledDeliveryMap).map(({ title, price, display, datePicker, type, transit, eta }) => ({
    title,
    price,
    display,
    datePicker,
    type,
    transit,
    eta
  }));



  const deliveryOptionsComponent = (item, singleAddress = true) => {
    const validDeliveryOptions = hasChilledDelivery ? chilledDeliveryOptions : deliveryOptions;
    const filteredDeliveryOptions =  showFreeShippingDelivery ? validDeliveryOptions : validDeliveryOptions.filter((item) => item.price !== "FREE" && item.type !== 'Chilled Transit Time');

    return (
      filteredDeliveryOptions.map(option => {
        const deliveryOption = () => {
          return (
            <>
            <div className='flex gap-3 w-full border-t border-mb-neutral-500 py-6'>
              <ExtendedRadio name="deliveryRate" 
              value={option.title}      
              checked={deliveryRates['allKey'] ? deliveryRates['allKey'] === option.title : item.properties._shipping_rate === option.title }
              onChange={(e) => { 
                if (singleAddress) {
                  setDeliveryRates({'allKey':  e.target.value});
                }
                else { 
                setDeliveryRates( {
                ...deliveryRates,
                [item.key]: e.target.value }) 
                }
              }}
                
              />
                <div className="flex flex-col"> 
                  <p>{option.display ? option.display : option.title }</p>
                  <div className='shipping-calculator__results__eta'>
                            Estimated arrival on {formatDateTime(option.eta).split('at') && formatDateTime(option.eta).split('at')[0]}
                  </div>
                </div>
       
                
                <p className='font-medium ml-auto'>
                  {option.price}
                </p>

            
            </div>
            <div>
                {  (deliveryRates['allKey'] ? deliveryRates['allKey'] === option.title : item.properties._shipping_rate === option.title) && displayDeliveryDate(item) && 
                  <div className="extended-cart__delivery-date">
                  <div className="extended-cart__delivery-date-label"> Delivery Date </div> 
                  <div className="extended-cart__delivery-date-container"> 
                      <DatePicker   placeholderText={`${translations.select_date}`} dateFormat="dd-MM-yyyy"  minDate={item.properties._delivery_date_start || addBusinessDays(currentDateFormatted, option.type == 'Chilled' ? chilledTransitTime : option.transit) }   maxDate={item.properties._delivery_date_end} selected={itemDates['allKey'] || item.properties._delivery_date} filterDate={(date)=> disableDates(date, option.type)} onChange={(date) => {
                          if (singleAddress) {
                            setItemDates({'allKey': date});
                          }
                          else {
                              setItemDates({
                                ...itemDates,
                                [item.key]: date 
                            })
                          }
                        }
                      } />
                    <span className="extended-cart__delivery-date-icon icon icon-calendar"> </span>
                  </div>
                </div>
              }
            </div>
            </>
          )
        }
        //Show only pre order rates for pre order items
        if (item.properties._delivery_date_start && item.properties._delivery_date_end) {
          if (option.type == 'Preorder') {
            return deliveryOption();
          }
          else return null;
          
        }
        else {
          if (option.type != 'Preorder') {
            return deliveryOption();
          }

          return false
        }
       
        }
     )
    )
  }

  const isMobile = useMobileBreakpoint(mobileBreakpoint);

  const reorderedItems = cartItems.sort((a, b) => {
    // Check the product_type of each item, and move "Bundle Container" to the bottom
    if (a.items[0].product_type === "Bundle Container" && b.items[0].product_type !== "Bundle Container") {
      return 1; // Move "Bundle Container" down
    } else if (a.items[0].product_type !== "Bundle Container" && b.items[0].product_type === "Bundle Container") {
      return -1; // Keep non-"Bundle Container" items at the top
    }
    return 0; // If both are the same, maintain original order
  });

  return (
    <ExtendedCartWrapper
      title={translations.shipping_options}
      cart={cart}
      settings={settings}
      translations={translations}
      activeIndex={1}
      disabled={updatingCart}
      shipping_id={shipping_id}
      buttonLabel={translations.go_to_review}
      onNextStep={() => handleValidation()}
    >
      <div key={stepStatus}>

              
      { error && 
        ( 
          <div className="extended-cart__error-container mb-4"> 
            <div className='extended-cart__error-container-icon'> 
              <IconMarker/>
            </div>
            <div className='flex flex-col'> 
              <div className='extended-cart__error-container-title'>  Make sure to check all information </div>
              <div className='extended-cart__error-container-subtitle'> {errorMessage} </div>
            </div>
          </div>
        )
      }
        <div className="mb-6">
          <p className='font-medium'>
            {translations.address}
          </p>

          <p>{fullAddress}</p>
        </div>


        { reorderedItems[0].items[0]?.product_type !== bundleType &&
            <div className='border border-mb-neutral-500 rounded-xl p-4 pl-6 mb-8'>
                <p className='font-medium text-sm mb-2'>
                  {translations.delivery_method}
                </p>

                {deliveryOptionsComponent(reorderedItems[0].items[0])}
            </div>
          }
        

        {reorderedItems.map((cartItem, index) => {
          return (
            <div key={`${cartItem.product_title}-${index}`}>
              <div className='flex flex-col gap-8 border border-mb-neutral-500 rounded-xl p-4 pl-6 extended-cart__bordered-container'>
                {cartItem.items.map((item,  itemIndex) => {
                  // Only display delivery methods on first item
                  let showDelivery = false;
                  const hasBundle = item.product_type === bundleType;
    
                  if (hasBundle) {
                      showDelivery = false;
                  }
                  else if (index == 0 && itemIndex == 0) {
                      showDelivery = true;
                  }
                  
                  return (
                    <div key={`${item.key}-${item.quantity}`}>
                      <div className='flex gap-4 pb-4'>
                        { isMobile && ( 
                           <div className='extended-cart__item'>
                              <div className="flex flex-row gap-4 mb-2">
                                {!item.image ? <ImagePlaceholderSVG/> : 
                                  <ExtendedImage
                                    imageUrl={item.image}
                                    altText={item.product_title}
                                    className={'object-cover w-full h-full'}
                                  />
                                }
                                <div>
                                  <p className='font-medium'>
                                    {item.product_title}
                                  </p>
                                </div>

                              </div>
                           
                              <div> 
                                <div className="flex flex-row justify-between"> 
                                  <p>
                                      <span className='font-medium mr-2'>
                                        {translations.price}:
                                      </span>
          
                                      {formatPrice(item.price)}
                                  </p>
                                    
                                  <p>
                                    <span className='font-medium mr-2'>
                                      {translations.qty}:
                                    </span>
        
                                    {item.quantity}
                                  </p>
                                </div>
                              
                                  
                                <p>
                                  <span className='font-medium mr-2'>
                                    {translations.recipient_name}:
                                  </span>
      
                                  {item.properties._recipient_name}
                                </p>
        
                                <p>
                                  <span className='font-medium mr-2'>
                                    {translations.address}:
                                  </span>
                                  
                                  {fullAddress}
                                </p>
                              </div>

                          </div>
                        )}
                        { !isMobile && (
                          <div className='extended-cart__item'>
                            {!item.image ? <ImagePlaceholderSVG/> : 
                              <ExtendedImage
                                imageUrl={item.image}
                                altText={item.product_title}
                                className={'object-cover w-full h-full'}
                              />
                            }
                          <div>
                              <p className='font-medium'>
                                {item.product_title}
                              </p>
    
                              <p>
                                <span className='font-medium mr-2'>
                                  {translations.price}:
                                </span>
    
                                {formatPrice(item.price)}
                              </p>
                              
                              <p>
                                <span className='font-medium mr-2'>
                                  {translations.qty}:
                                </span>
    
                                {item.quantity}
                              </p>
                              
                              <p>
                                <span className='font-medium mr-2'>
                                  {translations.recipient_name}:
                                </span>
    
                                {item.properties._recipient_name}
                              </p>
    
                              <p>
                                <span className='font-medium mr-2'>
                                  {translations.address}:
                                </span>
                                
                                {fullAddress}
                              </p>
                          </div>
                          </div>
                        )}
                      </div>
                      

                      { item.properties._card_message && 
                        <div className="extended-cart__card-message">
                           <ExtendedTextField
                             name='_card_message'
                             label='Card Message'
                             placeholder=''
                             disabled
                             value={item.properties._card_message}
                           />
                        </div>
                      }
                     {/* { showDelivery && 
                        <div className='pt-4 pl-4 border-l border-mb-neutral-500'>
                           <p className='font-medium text-sm mb-2'>
                             {translations.delivery_method}
                           </p>
   
                           {deliveryOptionsComponent(item)}
                        </div>
                     
                     
                     } */}
                 
                    </div>
                  )
                })}
              </div>
              
              {(cartItems.length - 1 !== index) &&
                <hr className='bg-mb-neutral-500 my-6' />
              }
            </div>
          )
        })}
      </div>
    </ExtendedCartWrapper>
  )
}