import {BlogArticle} from "@mindarc/arctheme-components/frontend/src/stories/components/blog/BlogArticle/BlogArticle";
import {BlogList} from "@mindarc/arctheme-components/frontend/src/stories/components/blog/BlogList/BlogList";
import {FeaturedBlog} from "@mindarc/arctheme-components/frontend/src/stories/components/blog/FeaturedBlog/FeaturedBlog";
import {BlogArticleTile} from "@mindarc/arctheme-components/frontend/src/stories/components/blog/BlogArticleTile/BlogArticleTile";
import {SocialShare} from "@mindarc/arctheme-components/frontend/src/stories/components/blog/SocialShare/SocialShare";

export function BlogArticleComponent({ ...props }) {
    return (
        <BlogArticle reverselayout={ false } settings={props.settings.section} blocks={props.settings.blocks} />
    )
}
export function BlogListComponent({ ...props }) {
    return (
        <BlogList reverselayout={ false } settings={props.settings.section} blocks={props.settings.blocks} />
    )
}

export function FeaturedBlogComponent({ ...props }) {
    return (
        <FeaturedBlog reverselayout={ false } article={props.settings.article} settings={props.settings.section} blocks={props.settings.blocks} />
    )
}
export function BlogArticleTileComponent({ ...props }) {

    return (
        <BlogArticleTile props={props.settings} />
    )
}

export function SocialShareComponent({ ...props }) {

    return (
        <SocialShare props={props.settings} />
    )
}