export default {
  name: "shopify-search",
  component() {
    return {
      open: false,
      init() {
        // console.log('Shopify Search Filters Component Initialized.')
      },
      toggle() {
        this.open = !this.open;
      },
      close() {
        this.open = false;
      },
      openDropdown() {
        this.open = true;
      },
    };
  },
};
