import React, { useState, useEffect } from 'react';
import cloneDeep from 'lodash/cloneDeep';

import ExtendedButton from '@src/stories/elements/ExtendedButton/ExtendedButton';
import ExtendedCartWrapper from "./ExtendedCartWrapper";
import ExtendedCartAddressInfo from "./ExtendedCartAddressInfo";
import ExtendedCartItems from "./ExtendedCartItems";

import { updateItem, addToCart, fetchCart } from 'frontend/js/useShopifyCart';
import { recipientProperties, constructFullAddress } from 'frontend/js/helpers';

export default function ExtendedCartStep1({
  cart,
  cartItems,
  optionsInCart,
  hasCards,
  customer,
  card_types,
  gift_wrap_types,
  hampers,
  settings,
  translations,
  address,
  onClickAddCards,
  onCartItemsChange,
  onAddressChange,
  onStepChange,
  deliveryMap,
  shipping_id,
  bundleType,
  error,
  setError,
  errorMessage,
  setErrorMessage
}) {
  const [updatingCart, setUpdatingCart] = useState(false);
  useEffect(() => {
    setUpdatingCart(false);
  }, [cart])

  async function updateLineItems () {
    for (const cartItem of cartItems) {
      let index = 0;
      for (const item of cartItem.items) {
        const shippingProperties: any = {};

        for (const key in address) {
          const matches = key.match(/(?<=\[)[^\]]+(?=\])/g);
          if (matches?.length) {
            shippingProperties[`_shipping_${matches[0]}`] = address[key];
          }
        }

        shippingProperties._shipping_delivery_instructions = address.delivery_instructions;
        shippingProperties._shipping_address = constructFullAddress(address);
        if(item.product_type == bundleType) {
          continue;
        }

        const uniqueId = `id_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
        if (item.isNew) {
          await addToCart({
            id: item.variant_id,
            quantity: 1,
            properties: {
              ...item.properties,
              ...shippingProperties,
              [`_itemIndex-${uniqueId}`]: `${index}-${item.id}`
            }
          }, false);

        } else {
          await updateItem({
            id: item.key,
            quantity: 1,
            properties: {
              ...item.properties,
              ...shippingProperties,
              [`_itemIndex-${uniqueId}`]: `${index}-${item.id}`
            }
          }, false);
        }

        index = index + 1;
      }
    }

    for (const key in optionsInCart) {
      if (optionsInCart[key].isNew && optionsInCart[key].quantity) {
        await addToCart({
          id: optionsInCart[key].id,
          quantity: optionsInCart[key].quantity
        });

      } else if (optionsInCart[key].isUpdated) {
        await updateItem({
          id: optionsInCart[key].key,
          quantity: optionsInCart[key].quantity
        });
      }
    }


  }

  async function handleValidation() {
    setUpdatingCart(true);
    let hasError = false;

    for (const cartItem of cartItems) {
      for (const item of cartItem.items) {
        item.formErrors = {}
        if(item.product_type == bundleType) {
          hasError = false;
          continue;
        }
        if (!item.properties._recipient_name) {
          if(hampers[item.variant_id] && hampers[item.variant_id].length > 0 && item.product_type == 'Hampers') {
            hasError = true;
            const formErrorMessage = 'Please enter a recipient name.'
            item.formErrors.recipient_name = formErrorMessage;
            setErrorMessage(formErrorMessage);
          }
        }

        if (hasCards) { 
          if ( item.properties._card_type  && !item.properties._card_message && !item.properties._is_card_message_blank) {
            hasError = true;
            const formErrorMessage = 'Please enter a message.';
            item.formErrors.card_message = formErrorMessage;
            setErrorMessage(formErrorMessage);
          }

        } else {
          for (const key in recipientProperties) {
            if (key !== '_recipient_name') {
              delete item.properties[key];
            }
          }
        }
      }
    }
    if (address["address[zip]"] == '' ||  address["address[city]"] == '' || address["address[address1]"] == '') {
       hasError = true 
       const formErrorMessage = 'Please fill address information.';
       setErrorMessage(formErrorMessage);
    }

    if (hasError) {
      setError(true);
      const cartItemsCopy = cloneDeep(cartItems);
      onCartItemsChange(cartItemsCopy);
      setUpdatingCart(false);

    } else {
      setError(false);
      await updateLineItems();
      await fetchCart();
      setUpdatingCart(false);
      onStepChange(1)
    }
  }

  function handleItemDelete(id, itemIndex) {
    const groupedCartItem = cartItems.find(cartItem => cartItem.variant_id === id)
    if (groupedCartItem) groupedCartItem.items.splice(itemIndex, 1);
  }

  function handleApplyMessageToAll({ lineItem, cardMessage }) {
    lineItem.items.forEach(item => item.properties._card_message = cardMessage);
    const cartItemsCopy = cloneDeep(cartItems);
    onCartItemsChange(cartItemsCopy);
  }



  return (
    <ExtendedCartWrapper
      title={translations.address_info}
      cart={cart}
      settings={settings}
      shipping_id={shipping_id}
      translations={translations}
      activeIndex={0}
      disabled={updatingCart}
      buttonLabel={translations.go_to_shipping_options}
      onNextStep={() => handleValidation()}
    >
      <>
        <ExtendedCartAddressInfo
          customer={customer}
          corporateOrderUrl={settings.corporate_order_url}
          onAddressChange={(formObject) => onAddressChange(formObject)}
          translations={translations}
          error={error}
          errorMessage={errorMessage}
        />

        <hr className='bg-mb-neutral-500 my-6' />

        <div>
          <div className='lg:flex gap-4 items-center justify-between py-5 pr-2 lg:pr-6 pl-2 mb-8'>
            <h5 className='font-medium max-lg:mb-6'>
              {translations.add_personalised_cards}
            </h5>

            <ExtendedButton
              label={translations.add_cards_gift_wrapping}
              onClick={() => onClickAddCards(true)}
            />
          </div>

          <ExtendedCartItems
            cartItems={cartItems}
            cardTypes={card_types}
            giftWrapTypes={gift_wrap_types}
            optionsInCart={optionsInCart}
            hampers={hampers}
            hasCards={hasCards}
            updatingCart={updatingCart}
            characterLimit={settings.character_limit}
            isGiftWrapEnabled={settings.is_gift_wrap_enabled}
            translations={translations}
            bundleType={bundleType}
            onItemDelete={(id, itemIndex) => handleItemDelete(id, itemIndex)}
            onApplyMessageToAll={(params) => handleApplyMessageToAll(params)}
          />
        </div>
      </>
    </ExtendedCartWrapper>
  );
};
