import * as React from "react";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { mobileBreakpoint } from "../../../../entrypoints/theme";
import ExtendedProductTile from "@stories/components/ProductTile/ProductTile";
import {useEffect, useState , useRef } from "react";
import HeaderSearchResults from "@src/stories/ecommerce/HeaderSearch/HeaderSearchResults";
import ExtendedHeadings from "@src/stories/elements/ExtendedHeadings/ExtendedHeadings"
import ExtendedSelectField from "@src/stories/elements/ExtendedSelectField/ExtendedSelectField";

const ARTICLES_JSON = {
    "title": "Entertaining Tips from Maggie Beer",
    "handle": "test-article",
    "meta": [
        "5 min read|#8D8B45|#ffffff"
    ],
    "image": "https://cdn.shopify.com/s/files/1/0659/5107/2425/files/pea-gnocchi-1.jpg?v=1721263957",
};

const PRODUCT_JSON = {
    "title": "Maggie's Gourmet Grazing Hamper with Barossa Shiraz",
    "handle": "gourmet-grazing-hamper-with-barossa-shiraz-1",
    "price": "$99",
    "image": "https://cdn.shopify.com/s/files/1/0659/5107/2425/files/pea-gnocchi-1.jpg?v=1721263957",
};

const RECIPES_JSON = {
    "title": "Spring Pea and Mozzarella Gnocchi",
    "handle": "test-recipe",
    "meta": [
        "Lunch|#ffffff|#000000"
    ],
    "image": "https://cdn.shopify.com/s/files/1/0659/5107/2425/files/pea-gnocchi-1.jpg?v=1721263957",
};

const options = [
    { value: 'products', label: 'Products' },
    { value: 'recipes', label: 'Recipes' },
    { value: 'articles', label: 'Blogs' }
];

const PLACEHOLDER_EMPTY_PROMPT = "Empty";

export const ExtendedHeaderSearch = ({ settings }) => {
    const isMobile = useMobileBreakpoint(mobileBreakpoint);

    const [query, setQuery] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('products');
    const [articles, setArticles] = useState(Array(10).fill(ARTICLES_JSON));
    const [recipes, setRecipes] = useState(Array(10).fill(RECIPES_JSON));
    const [products, setProducts] = useState(Array(10).fill(PRODUCT_JSON));
    const suggestedProducts = products.slice(0,7);
    const ref = useRef(null);

    const handleCategoryClick = (category) => {
        if (category) {
            setSelectedCategory(category);
        }
    };

    const  onClickOutside = () => {
        if  (document.body.classList.contains('search-open')) {
            window.dispatchEvent(new CustomEvent("toggle-search"));
        }
       
    }

    useEffect(() => {
        const handleQuery = (event) => {
            if (event.detail.type === 'header') {
                setQuery(event.detail.query);
            }
        };
       
        window.addEventListener('query', handleQuery);

    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target) && event.target.id != 'predictive-search-input' && event.target.id != 'search-close'  ) {
                onClickOutside();
            }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [ref, onClickOutside]);


    return (
        <div ref={ref} className="header-search-results h-full bg-[#F5F5F5] overflow-y-scroll lg:overflow-auto">
            <div className="header-search-results-container">
                <div className="header-search-results-content">
                    <div className={`search-sidebar ${query == PLACEHOLDER_EMPTY_PROMPT  ? 'no-results' : ''}`}>
                        <div className="search-sidebar__column left">
                                <div className="search-sidebar__category"> 
                                    <div className="search-sidebar__category-header"> 
                                        Popular Searches
                                    </div>
                                    <ul className="search-sidebar__category-links"> 
                                        <li> Gourmet Hamper </li>
                                        <li> Wine Gift Hamper</li>
                                        <li> Savoury Hamper</li>
                                    </ul>
                                </div>
                                <div className="search-sidebar__category"> 
                                    <div className="search-sidebar__category-header"> 
                                        Recent Searches
                                    </div>
                                    <ul className="search-sidebar__category-links"> 
                                        <li> Pet Hamper </li>
                                    </ul>
                                </div>
                        </div>  
                        { query != PLACEHOLDER_EMPTY_PROMPT  && (
                        <div className="search-sidebar__column right">
                            <div className="search-sidebar__category"> 
                                        <div className="search-sidebar__category-header"> 
                                            Category
                                        </div>
                                        <ul className="search-sidebar__category-links"> 
                                            <li> For Her </li>
                                            <li> For Him </li>
                                            <li> Savoury Hampers </li>
                                        </ul>
                                    </div>
                                    <div className="search-sidebar__category"> 
                                        <div className="search-sidebar__category-header"> 
                                            Price 
                                        </div>
                                        <ul className="search-sidebar__category-links"> 
                                            <li> Below $99 </li>
                                            <li> $100 - $169 </li>
                                            <li> $170 - $269 </li>
                                            <li> Above $270 </li>
                                        </ul>
                                    </div>
                                    <div className="search-sidebar__category"> 
                                        <div className="search-sidebar__category-header"> 
                                            Type
                                        </div>
                                        <ul className="search-sidebar__category-links"> 
                                            <li> Gluten Free </li>
                                            <li> Savour </li>
                                            <li> Homewares </li>
                                            <li> Paper </li>
                                            <li> Sweet </li>
                                        </ul>
                                    </div>
                        </div>  
                        )}
                    </div>
                    { query != PLACEHOLDER_EMPTY_PROMPT  && (
                    <div className="flex lg:hidden search-main__results_count"> 
                        <span className="search-main__results_count-label"> See 33 Results for "Hampers" </span> <span className="hidden lg:block icon-caret-right text-[24px]"> </span>
                    </div> ) }

                    <div className={`search-main ${query == PLACEHOLDER_EMPTY_PROMPT  ? 'no-results' : ''}`}>
                        { query != PLACEHOLDER_EMPTY_PROMPT  && ( <div className="lg:hidden">
                                <ExtendedSelectField
                                    options={options}
                                    placeholder="Select one..."
                                    onChange={(option) => handleCategoryClick(option.value)}
                                />
                            </div>) }
                        { query != PLACEHOLDER_EMPTY_PROMPT  ? (
                            <>
                                <div className="search-main__tabs">
                                    <nav className="hidden lg:flex">
                                        <div className={`tab ${selectedCategory === 'products' ? 'selected' : 'default'} ${products ? 'block' : 'hidden'}`} onClick={() => handleCategoryClick('products')}>Products</div>
                                        <div className={`tab ${selectedCategory === 'recipes' ? 'selected' : 'default'} ${recipes ? 'block' : 'hidden'}`} onClick={() => handleCategoryClick('recipes')}>Recipes</div>
                                        <div className={`tab ${selectedCategory === 'articles' ? 'selected' : 'default'} ${articles ? 'block' : 'hidden'}`} onClick={() => handleCategoryClick('articles')}>Blogs</div>
                                    </nav>
                                </div>
                                {selectedCategory === 'products' && products &&
                                    <>
                                        <div className="search-main__tabs-results grid grid-cols-2 px-0 gap-x-6 py-3 lg:grid-cols-5  lg:py-4">
                                            <HeaderSearchResults settings={settings} results={products} type={'products'}/>
                                        </div>
                                    </>
                                }
                                {selectedCategory === 'articles' && articles &&
                                    <div>
                                        <div className="search-main__tabs-results grid grid-cols-2 px-0 py-3 lg:grid-cols-5 gap-x-6  gap-y-4 lg:gap-y-10  lg:h-full lg:py-4">
                                            <HeaderSearchResults settings={settings} results={articles} type={'articles'}/>
                                        </div>
                                    </div>
                                }
                                {selectedCategory === 'recipes' && articles &&
                                    <div>
                                        <div className="search-main__tabs-results grid grid-cols-2 px-0 py-3 lg:grid-cols-5 gap-x-6 gap-y-4 lg:gap-y-10  lg:h-full lg:py-4">
                                            <HeaderSearchResults settings={settings} results={recipes} type={'recipes'}/>
                                        </div>
                                    </div>
                                }

                                <div className="hidden lg:flex search-main__results_count"> 
                                    <span className="search-main__results_count-label"> See 33 Results for "Hampers" </span> <span className="hidden lg:block icon-caret-right text-[24px]"> </span>
                                </div>
                           
                            </>
                        ) : (
                            <>
                                <div className="search-main__no-results">
                                    <div className="search-main__no-results-label">
                                       {` No results found for "${PLACEHOLDER_EMPTY_PROMPT}". Please try another search.`}
                                    </div>
                                </div>

                                <div className="search-main__suggested-products">
                                     <div className="search-main__suggested-products-label">
                                        Other Popular Products
                                    </div>

                                    <div className="search-main__tabs-no-results grid grid-cols-2 px-0 lg:px-3 gap-x-6  lg:gap-x-0 lg:grid-cols-7 lg:py-4">
                                        <HeaderSearchResults settings={settings} results={suggestedProducts} type={'products'}/>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ExtendedHeaderSearch;