import * as React from "react";
import 'alpinejs'
import { TextField } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/TextField/TextField";
import HeaderMenu from "@stories/components/Header/ExtendedHeaderMenu";
import HeaderActions from "@stories/components/Header/ExtendedHeaderActions";
import LogoBlock from "@stories/components/Header/ExtendedHeaderLogo";
import MobileMenu from "@stories/components/Header/ExtendedHeaderMobileMenu";
import ExtendedTextField from "@src/stories/elements/ExtendedTextField/ExtendedTextField";


import './ExtendedHeader.scss';
import clsx from "clsx";

const dispatchCustomEvent = (eventName, detail = { message: "" }) => {
  window.dispatchEvent(new CustomEvent(eventName, { detail }));
};

const HeaderCenteredLayout = ({ colorSchema, links, item_count = '', settings, type = 'left', show_search_bar = false, localization }) => {
  return (
    <div className="w-full flex items-center relative lg:py-[16px]">
      <div className={'mobile-menu-container lg:hidden lg:basis-1/3'}>
        <MobileMenu linkList={links} settings={settings} localization={localization} />
      </div>

      <div className={'hidden lg:block flex-grow lg:basis-1/3'}>
        <HeaderMenu link_list={links} />
      </div>

      <div className={'flex relative justify-center flex-grow-0 basis-1/3'}>
        <LogoBlock settings={settings} className={''} />
      </div>

      <div className="flex items-center space-x-[24px] justify-end header-toolbar flex-grow lg:basis-1/3">
        <HeaderActions item_count={item_count} settings={settings} localization={localization} />
      </div>
    </div>
  )
}

const HeaderRightLayout = ({ colorSchema, links, item_count = '', settings, type = 'left', show_search_bar = false, localization }) => {
  return (
    <div className="container flex items-center relative">
      <MobileMenu linkList={links} settings={settings} localization={localization} />
      <HeaderActions item_count={item_count} settings={settings} localization={localization} />
      <HeaderMenu link_list={links} />
      <LogoBlock settings={settings} />
    </div>
  )
}

const HeaderLeftLayout = ({ colorSchema, links, item_count = '', settings, type = 'left', show_search_bar = false, localization }) => {

  const {
    enable_searchbar,
    header_search_bar_position,
    header_search_bar_position_mobile
  } = settings.section

  return (
    <div>
      <div
        className={clsx("grid grid-cols-3 px-4 lg:py-0 items-center relative header-container-top", {
          'lg:flex  header-container-top': !enable_searchbar || header_search_bar_position === 'horizontal',
          'lg:hidden  header-container-top': enable_searchbar && header_search_bar_position === 'vertical',
        })}
      >
        <MobileMenu linkList={links} settings={settings} localization={localization}/>
        <LogoBlock settings={settings} />

        <HeaderMenu link_list={links} settings={settings} />

        <div className={'ml-auto'}>
          <HeaderActions item_count={item_count} settings={settings} localization={localization}/>
        </div>
      </div>

      <div
        className={clsx("items-center relative py-4 vertical-layout gap-10 hidden", {
          'lg:flex': enable_searchbar && header_search_bar_position === 'vertical' ,
        })}
      >
        <LogoBlock settings={settings} />

        <div className="grow">
          <div className="flex items-center gap-[200px]">
            {enable_searchbar && 
              <ExtendedTextField
                icon="search"
                className="header-search-bar mb-4 grow"
                placeholder="Search products, recipes, blogs"
                readonly={true}
                onFocus={( )=>{ window.dispatchEvent(new CustomEvent("toggle-search"));}}
              />
            }

            <div className={'ml-auto'}>
              <HeaderActions item_count={item_count} settings={settings} localization={localization} />
            </div>
          </div>

          <HeaderMenu link_list={links} settings={settings} />
        </div>
      </div>
      
      {(header_search_bar_position_mobile === 'below_header' && enable_searchbar) && 
        <ExtendedTextField
          icon="search"
          className="header-search-bar lg:hidden mb-4 px-4"
          placeholder="Search products, recipes, blogs"
          readonly={true}
          onFocus={( )=>{ window.dispatchEvent(new CustomEvent("toggle-search"));}}
        />
      }
    </div>
  )
}

const Header2RowSplitLayout = ({ colorSchema, links, item_count = '', settings, type = 'left', show_search_bar = false, localization }) => {
  return (
    <div className={'header-rows'}>
      <div className={'header-row'}>
        <LogoBlock settings={settings} />
        <div className={'header-search'}>
          <TextField id={'search'} placeholder={'Search...'} value={''} />
        </div>
        <HeaderActions item_count={item_count} settings={settings} localization={localization} />
      </div>
      <div className={`header-row`}>
        <MobileMenu linkList={links} settings={settings} localization={localization}/>
        <HeaderMenu link_list={links} settings={settings} />
      </div>
    </div>
  )
}

const Layouts = {
  'header-layout-center': HeaderCenteredLayout,
  'header-layout-left': HeaderLeftLayout,
  'header-layout-right': HeaderRightLayout,
  'header-split-2row-left': Header2RowSplitLayout,
};

const ExtendedHeader = ({ colorSchema, links, item_count = '', settings, type = 'left', show_search_bar = false, localization }) => {
  const LayoutComponent = Layouts[type];

  if (settings.section.sticky_header) {
    document.querySelector('header')?.classList.add('is-sticky');
  }

  return (
    <div className={`header-container`} x-init="$nextTick(() => { $store.navigation.init() })">
      <LayoutComponent
        settings={settings}
        links={links}
        item_count={item_count}
        localization={localization}
      />
    </div>
  );
};

export default ExtendedHeader;
