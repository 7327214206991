import React, { useState, useEffect } from "react";
import Select from "react-select";

import "./ExtendedSelectField.scss";

type SelectOption = {
  value: any;
  label: string;
};

type ExtendedSelectProps = {
  id?: string;
  label?: string;
  placeholder?: string;
  name?: string;
  value?: any;
  options?: [SelectOption] | undefined;
  required?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  hasError?: boolean;
  errorMessage?: string;
  onChange?: Function;
  onFocus?: Function;
  takeover?: boolean;
  className?: string;
  xref?: string;
};

export default function ExtendedSelectField({
  id,
  label,
  placeholder,
  name,
  value,
  options,
  required,
  errorMessage,
  disabled,
  readonly = false,
  xref,
  hasError,
  onChange = (e) => {},
  onFocus = () => {},
  takeover = false,
  className = "",
}: ExtendedSelectProps) {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [localValue, setLocalValue] = useState(null);

  function handleChange(option) {
    setLocalValue(option);
    onChange(option);
  }

  let controlClassNames = "form-field form-field__select";

  if (hasError) {
    controlClassNames += " has-error";
  }

  useEffect(() => {
    const selectedOption =
      options?.find((option) => option.value === value) || null;
    setLocalValue(selectedOption);
  }, [value]);

  return (
    <div className={className}>
      {label && (
        <label htmlFor={id} className="form-field__label">
          {label}
        </label>
      )}

      <Select
        id={id}
        name={name}
        placeholder={placeholder}
        required={required}
        isDisabled={disabled}
        options={options}
        value={localValue}
        x-ref={xref}
        classNames={{
          control: () => controlClassNames,
          container: () =>
            `form-field__select-container ${menuIsOpen ? "is-open" : ""}`,
          dropdownIndicator: () => `form-field__select-icon`,
          menu: () => `form-field__select-menu${takeover ? "-takeover" : ""}`,
          option: (state) => {
            let baseClass = "form-field__select-option";

            if (state.isSelected) {
              baseClass += " selected";
            }

            return baseClass;
          },
        }}
        onFocus={() => onFocus()}
        onChange={(option) => handleChange(option)}
        onMenuOpen={() => setMenuIsOpen(true)}
        onMenuClose={() => setMenuIsOpen(false)}
      />

      {hasError && errorMessage && (
        <div className="form-field__error-message">{errorMessage}</div>
      )}
    </div>
  );
}
