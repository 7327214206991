import ExtendedSelectField from "@src/stories/elements/ExtendedSelectField/ExtendedSelectField";
import React, { useState } from "react";

const GoogleRating = ({ settings }) => {
  return (
    <div x-data="addressvalidation" x-init="checkRatings" className="gift-finder flex items-center justify-between">
    </div>
  );
};

export default GoogleRating;
