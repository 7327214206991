import React, { useEffect, useState } from "react";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { mobileBreakpoint } from "frontend/entrypoints/theme";
import HeroBannerOverlay from "../HeroBannerOverlay";

export default function SecondaryHeroBanner({ settings }) {
  const isMobile = useMobileBreakpoint(mobileBreakpoint);
  const { section_id, section, blocks } = settings;
  const [currentBlock, setCurrentBlock] = useState(blocks[0]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const {
    banner_type,
    is_auto_slide,
    auto_slide_duration,
    vertical_content_position_desktop,
    horizontal_content_position_desktop,
  } = section;

  const autoPlay = is_auto_slide ? auto_slide_duration * 1000 : 0;

  const headerEl = document.querySelector("header");
  const headerHeight = headerEl?.scrollHeight;

  const headerPromotionEl = document.querySelector(".header-promotion");
  const headerPromotionHeight = headerPromotionEl?.scrollHeight || 0;

  useEffect(() => {
    const handleCarouselUpdated = (event) => {
      // console.log(`Carousel Changed for ${event.detail.section_id}`);
      if (event.detail.section_id == section_id) {
        // console.log(`Carousel changed to ${event.detail.index}`);
        // If currentIndex is at the first item, wrap around to the last item
        setCurrentIndex(event.detail.index);
        setCurrentBlock(blocks[event.detail.index]);
      }
    };

    window.addEventListener("carousel-changed", handleCarouselUpdated);
  }, []);

  function goToSlide(action, notify = true) {
    const event = new CustomEvent(`carousel-navigate`, {
      detail: { section_id, action },
    });
    if (action === "next") {
      // If currentIndex is at the last item, wrap around to the first item
      const newIndex = currentIndex + 1 >= blocks.length ? 0 : currentIndex + 1;
      setCurrentIndex(newIndex);
      setCurrentBlock(blocks[newIndex]);
    } else {
      // If currentIndex is at the first item, wrap around to the last item
      const newIndex =
        currentIndex - 1 < 0 ? blocks.length - 1 : currentIndex - 1;
      setCurrentIndex(newIndex);
      setCurrentBlock(blocks[newIndex]);
    }

    window.dispatchEvent(event);
  }

  return (
    <div
      className={`hero-banner ${banner_type}`}
      style={{
        height: `calc(100vh - ${headerHeight}px - ${headerPromotionHeight}px)`,
      }}
    >
      {currentBlock && (
        <HeroBannerOverlay
          settings={settings}
          block={currentBlock}
          goToSlide={goToSlide}
        />
      )}
      <div
        className="hero-banner__carousel"
        {...{
          "x-data": "extended_carousel",
          "x-init": `initialise(false, false, false, true, ${autoPlay}, "${section_id}")`,
          "x-ref": "carousel",
        }}
      >
        {blocks.map((block, index) => {
          const { color_schema } = block;

          const block_settings = block.settings;
          let imageUrl = block_settings.image?.src ?? block_settings.image;
          let videoSource = block_settings.video;

          if (isMobile) {
            imageUrl = block_settings.mobile_image
              ? block_settings.mobile_image
              : (block_settings.image?.src ?? block_settings.image);

            videoSource = block_settings.video_mobile
              ? block_settings.video_mobile
              : block_settings.video;
          }
          const defaultUrl = videoSource?.sources?.find(source => source.url)?.url || '';
          const videoSourceUrl = videoSource?.sources[2] ?  videoSource?.sources[2].url :  defaultUrl;
          return (
            <div
              key={`hero-banner__block_${index}`}
              className="hero-banner__block"
            >
              <div className="hero-banner__block-cols">
                {block.type === "block_image" && (
                  <div className="hero-banner__image-wrap">
                    <div
                      className="hero-banner__image"
                      style={{
                        backgroundImage: `url(${imageUrl})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    ></div>
                  </div>
                )}

                {block.type === "block_video" && (
                  <video autoPlay muted loop   playsInline className="hero-banner__video">
                    <source
                      src={videoSourceUrl}
                      type="video/mp4"
                    />
                  </video>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
