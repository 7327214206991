import * as React from "react";
import Image from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Image/Image";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import ExtendedButton from "@src/stories/elements/ExtendedButton/ExtendedButton";

const ExtendedMegamenuPromoBlock = ({ blockItem, index, section_settings, button_url }) => {

    const isMobile = useMobileBreakpoint('1024px');

    let imageWidth = section_settings.image_width;
    if (imageWidth == 0){
        imageWidth = '100%'
    }
    let imageHeight = section_settings.image_height;
    if (imageHeight == 0){
        imageHeight = '100%'
    }

    // Check if mobile_image is available and use it, otherwise use the default image
    let imageUrl = blockItem.image?.src ?? blockItem.image;
    if (isMobile){
        imageUrl = blockItem.mobile_image ? blockItem.mobile_image : blockItem.image?.src ?? blockItem.image;
    }

    return (
        <div key={`cta-${index}`} className="megamenu__promo--blocks-container relative h-full">
            <a href={blockItem.url} className="megamenu__promo--blocks-container-item h-full">
                <div className="w-full h-full">
                    <img src={imageUrl} className="w-full min-w-[328px] h-full object-cover" alt={blockItem?.title}/>
                </div>
            </a>
            <div className={'bottom-0 left-0 p-4'}>
                    <div className="text-center h2 megamenu__promo--blocks-container-title">{blockItem?.title}</div>
                    <div className="text-center  megamenu__promo--blocks-container-subtitle">{blockItem?.subtitle}</div>
                    <div className="megamenu__promo--blocks-container-button">
                        <ExtendedButton
                            label={blockItem?.button_text}
                            style="dark-primary"
                            link={blockItem?.url}
                        />
                    </div>
            </div>
        </div>
    );
};

export default ExtendedMegamenuPromoBlock;
