import React, { useState } from "react";
import ExtendedBreadcrumbs from "../../../components/Breadcrumbs/ExtendedBreadcrumbs";
import Paragraph from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Paragraph/Paragraph";
import ExtendedAccordion from "@src/stories/elements/ExtendedAccordion/ExtendedAccordion";
import ExtendedButton from "@src/stories/elements/ExtendedButton/ExtendedButton";
import { Headings } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Headings/Headings";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { mobileBreakpoint } from "../../../../../entrypoints/theme";
import ExtendedFAQTabs from "../ExtendedFAQTabs";
import ExtendedTextField from "@src/stories/elements/ExtendedTextField/ExtendedTextField";

const StackedLayout = ({ blocks, settings }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [activeTab, setActiveTab] = useState("All");
  const [visibleCount, setVisibleCount] = useState(settings.load_more_limit);

  const faqItems = blocks.map((faqItem) => ({
    title: faqItem.settings.title,
    content: faqItem.settings.content,
    image: faqItem.settings.image,
    video: faqItem.settings.video,
    category: faqItem.settings.category, // Assuming each FAQ item has a category
    filter_tag: faqItem.settings.filter_tag, // Assuming each FAQ item has a category
  }));

  const filteredFaqItems = faqItems.filter((item) => {
    const matchesSearch = item.title
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    const matchesCategory = activeTab === "All" || item.filter_tag === activeTab;
    return matchesSearch && matchesCategory;
  });

  // Extract unique filter tags
  const uniqueFilterTags = [
    ...new Set(faqItems.map((item) => item.filter_tag)),
  ];

  const tabs = [
    { label: "All FAQ's", value: "All" },
    ...uniqueFilterTags.map((tag) => ({
      label: tag,
      value: tag,
    })),
  ];

  return (
    <div className={`faq`}>
      <div
        className={`flex flex-col items-${settings.content_alignment_mobile} lg:items-${settings.content_alignment_desktop} w-[${settings.width_mobile}px] lg:w-[${settings.width}px] justify-${settings.content_position_desktop} mx-auto`}
      >
        <Headings text={`${settings.title}`} type={"h2"} />
        <div className={"faq__container-subcopy"}>
          <Paragraph text={`${settings.subtext}`} />
        </div>

        {(settings.show_filter_bar || settings.show_search_bar) && (
          <div className="faq__container-filters w-full">
            {settings.show_filter_bar && (
              <ExtendedFAQTabs
                tabs={tabs}
                activeTab={activeTab}
                onTabChange={setActiveTab}
              />
            )}

            {settings.show_search_bar && (
              <div className={`faq__container-tabs`}>
                <ExtendedTextField
                  placeholder="Search our FAQ's"
                  icon="search"
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                  }}
                />
              </div>
            )}
          </div>
        )}
        <div className={"faq__container-accordion w-full"}>
          <ExtendedAccordion
            items={filteredFaqItems.slice(0, visibleCount)}
            show_header={false}
          />
        </div>
        {settings.use_load_more && visibleCount < filteredFaqItems.length && (
          <div className={"faq__container-button-container"}>
            <div className={"faq__container-button"}>
              <ExtendedButton
                label="Load More"
                style="secondary"
                onClick={() => setVisibleCount(visibleCount + 10)}
              />
            </div>
          </div>
        )}
        <div className="flex flex-col lg:flex-row gap-4 w-full"> 
          {settings.button_label_1 && settings.button_url_1 && 
            <a className={`button button-${settings.button_style_1}`} href={`${settings.button_url_1}`}> { settings.button_label_1 } </a>
          }
          {settings.button_label_2 &&  settings.button_url_2 && 
          <a className={`button button-${settings.button_style_2}`} href={`${settings.button_url_2}`}> { settings.button_label_2 } </a>
          }
        </div>
      </div>
    </div>
  );
};

export default StackedLayout;
