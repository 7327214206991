import React, { useState } from "react";
import ExtendedImage from "@src/stories/components/ExtendedImage/ExtendedImage";
import ExtendedBreadcrumbs from "@src/stories/components/Breadcrumbs/ExtendedBreadcrumbs";
import { Tooltip } from 'react-tooltip'

const ActionBar = ({label = '', altLinks}) => {
    const handleLinkClick = () => {
        navigator.clipboard.writeText(window.location.href);
        setTooltipOpen(true);
        setTimeout( () => setTooltipOpen(false), 500);
      }
  
      const [ tooltipOpen, setTooltipOpen ] = useState(false);
  
      const { x_url, instagram_url, facebook_url , linkedin_url, pinterest_url } = altLinks;
    
      const handleSocialMediaClick = (type) => {
        let url = "";
        switch(type) {
          case 'x':
            url = `http://twitter.com/share?&url=${window.location.href}`
            break;
          case 'fb':
            url = `http://www.facebook.com/sharer.php?u=${window.location.href}`
            break;
          case 'li':
            url = `https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}`
            break;
          case 'pin':
            url = `http://pinterest.com/pin/create/button/?url=${window.location.href}`
            break;
          default:
            url = "";
        }
        window.open(url);
      }
    
      return (
        <div className={'collection-banner__actions'}>
            {label != '' &&  <span className='collection-banner__actions-label'> { label } </span> }
            <span className='icon icon-link-alt'  id="tooltip-anchor" onClick={handleLinkClick}> </span>
            <Tooltip id="collection-banner__actions-tooltip"   anchorSelect="#tooltip-anchor" isOpen={tooltipOpen}  data-tooltip-delay-hide={1000} content='Copied To Clipboard'/>
            {linkedin_url ? <a className='icon icon-linkedin' href={`${linkedin_url}`}> </a> : <span className='icon icon-linkedin'  onClick={() => handleSocialMediaClick('li')}> </span> }
            {x_url ? <a className='icon icon-x' href={`${x_url}`}> </a> :  <span className='icon icon-x'  onClick={() => handleSocialMediaClick('x')}> </span>}
            {facebook_url ? <a className='icon icon-facebook' href={`${facebook_url}`}> </a> : <span className='icon icon-facebook'  onClick={() => handleSocialMediaClick('fb')}> </span> }
            {instagram_url  && <a className='icon icon-instagram' href={`${instagram_url}`}> </a>}
            { pinterest_url ? <a className='icon icon-pintrest' href={`${pinterest_url}`}> </a> : <span className='icon icon-pintrest' onClick={() => handleSocialMediaClick('pin')}> </span> }
        </div>
      )
    }


export default function ExtendedArticlePageHeader({ settings }) {

  const { image, url, title, excerpt, author , datePublished, read_time, type, type_bg, type_color } = settings;
  const altLinks = { x_url:"", instagram_url:"", facebook_url:"", linkedin_url:"", pinterest_url: "" };
  return (
    <div className="extended-article__page-header">
        
        <ExtendedBreadcrumbs
        templateName={"blog"}
        blog={{url: url, title: title }}
        shop={{ url: '/pages/articles', name: 'Article'}}
        />
        
        <div className="extended-article__page-header-tagline">
           { type && <div className={`extended-article__page-header-badge`}  style={{ backgroundColor: `${type_bg || '#FFF'}`, color: `${type_color || '#000'}` }}>
                    <span>{type}</span>
            </div> }
            {read_time && <div className="extended-article__page-header-read">    { read_time &&  `${read_time} ${read_time > 1 ? 'mins' : 'min'} read` }   </div> } 
        </div>
        
        <div className="extended-article__page-header-title"> {title} </div>
        
        <div className="extended-article__page-header-subtitle"> {excerpt} </div>
        
        {image && <ExtendedImage className="extended-article__page-header-image" imageUrl={image} /> }
        
        <div className="extended-article__page-header-bar"> 
            <div className="extended-article__page-header-information"> 
                    { author && 
                    <div className="flex flex-col"> 
                        <div>  Written By </div> <div className="font-[500]"> { author }</div>
                    </div> 
                    }
                    { datePublished && 
                    <div className="flex flex-col"> 
                        <div>  Published on </div> <div  className="font-[500]"> { datePublished }</div>
                    </div>
                    }
            </div>
            <ActionBar label="" altLinks={altLinks}/>
        </div>
    </div>
  )
}