export default {
  name: "addressvalidation",
  component() {
    return {
      googleRating: null,
      googlePlaceId: null,
      isNewZealand: false,
      init() {
        this.setupGooglePlacesAutocomplete();
      },
      getRating () {
        const savedData = localStorage.getItem("rating");
        this.googleRating = savedData;
        return savedData ? savedData : null;
      },
      storeRating(rating) {
        localStorage.setItem("rating", rating);
        this.googleRating = rating;
      },
      async checkRatings(){
        if (this.getRating()) return;
  
        const australia = { lat: -25.2744, lng: 133.7751 };
        // Create a new map, centered on Australia
        const map = new google.maps.Map(document.getElementById("map"), {
          center: australia,
          zoom: 4  
        });
        var request = {
          placeId: this.googlePlaceId,
          fields: ['name', 'rating', 'formatted_phone_number', 'geometry']
        };
        
        const service = new google.maps.places.PlacesService(map);

        service.getDetails(request, (place, status) => {
          if (status == google.maps.places.PlacesServiceStatus.OK) {
            this.storeRating(place.rating); 
          }
        });
      },
      setupGooglePlacesAutocomplete() {
        const addressField = this.$refs.address_field;

        if (addressField) {
          const checkGoogleLoaded = setInterval(() => {
            if (typeof google !== "undefined" && google.maps && google.maps.places) {
              clearInterval(checkGoogleLoaded); // Stop checking once Google Maps is ready

              // Initialize Google Places Autocomplete
              const autocomplete = new google.maps.places.Autocomplete(
                addressField,
                {
                  types: ["geocode"],
                  componentRestrictions: { country: ["au", "nz"] }, // Restrict to specific countries
                }
              );

              // Listen for place selection
              autocomplete.addListener("place_changed", () => {
                console.log("Place changed");
                const place = autocomplete.getPlace();
                const addressComponents = place.address_components;

                let province = null;

                this.isNewZealand = addressComponents.some(
                  (item) => item.types[0] === "country" && item.long_name === "New Zealand"
                );

                addressComponents.forEach((component) => {
                  const type = component.types[0];

                  switch (type) {
                    case "street_number":
                      this.$refs.address_field.value = component.long_name;
                      break;
                    case "route":
                      this.$refs.address_field.value += " " + component.long_name;
                      break;
                    case "locality":
                      this.$refs.city.value = component.long_name;
                      break;
                    case "administrative_area_level_1":
                      this.$refs.province.value = component.long_name;
                      province = component;
                      break;
                    case "postal_code":
                      console.log("Postcode", component.long_name);
                      this.$refs.zip.value = component.long_name;
                      break;
                    case "country":
                      if (this.$refs.country) {
                        this.$refs.country.value = component.long_name;
                        const event = new Event("change", { bubbles: true });
                        this.$refs.country.dispatchEvent(event);
                        this.$refs.province.value = province.long_name;
                      }
                      break;
                  }
                });
              });
            } else {
              console.log("Google Maps API not yet loaded");
            }
          }, 100);
        } else {
          console.log("No address field found");
        }
      },
    };
  },
};
