import React, { useState } from 'react';
import clsx from "clsx";

import ExtendedSelectField from '@src/stories/elements/ExtendedSelectField/ExtendedSelectField';
import ExtendedHeading from '../ExtendedHeading/ExtendedHeading';
import ExtendedImage from '../ExtendedImage/ExtendedImage';
import ExtendedKeenSlider from '../ExtendedKeenSlider/ExtendedKeenSlider';
import ExtendedProductInclusionsPopup from './components/ExtendedProductInclusionsPopup';
import { lockScroll } from 'frontend/js/helpers';

import './ExtendedProductInclusions.scss';

export default function ExtendedProductInclusions ({ settings }) {
  if (!settings?.products?.length) return;

  const hamperTypes = ['All', ...new Set(settings.products.map(product => product.hamper_type))] as string[];
  const hamperTypeOptions = hamperTypes.map(value => ({ value, label: value })) as any;

  const [selectedHamperType, setSelectedHamperType] = useState(hamperTypeOptions[0]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [products, setProducts] = useState(settings.products);
  const keenSliderOptions = {
    initial: 0,
    slides: {
      perView: 1.75,
      spacing: 0,
    },
    breakpoints: {
      '(min-width: 1024px)': {
        slides: {
          perView: 6,
        },
      },
    },
  }

  function handleProductClick(product) {
    setSelectedProduct(product);
    lockScroll(!!product)
  }

  function handleHamperTypeClick(hamperType) {
    const filteredProducts = hamperType.value === 'All'
      ? settings.products
      : settings.products.filter(product => product.hamper_type === hamperType.value);

    setProducts(filteredProducts);
    setSelectedHamperType(hamperType);
  }

  return (
    <div className='product-inclusions'>
      <div className='product-inclusions__desktop-heading'>
        <div className='product-inclusions__heading'>
          <ExtendedHeading
            type='h3'
            className='h3'
            text='This Hamper Includes'
          />

          <ul className='product-inclusions__heading__hamper-types'>
            {hamperTypeOptions.map(hamperType => {
              return (
                <li
                  key={hamperType.value}
                  className={clsx('product-inclusions__heading__hamper-types__item', {
                    'product-inclusions__heading__hamper-types__item--active': hamperType.value === selectedHamperType.value
                  })}
                  onClick={() => handleHamperTypeClick(hamperType)}
                >
                  {hamperType.label}
                </li>
              )
            })}
          </ul>
        </div>

        <div className='product-inclusions__total-count'>
          Showing {products.length} of {settings.products.length}
        </div>
      </div>

      <div className='product-inclusions__mobile-heading'>
        <ExtendedHeading
          type='h5'
          className='h5 product-inclusions__mobile-heading__title'
          text={`This Hamper Includes ${settings.products.length} products`}
        />

        <ExtendedSelectField
          options={hamperTypeOptions}
          value={selectedHamperType.value}
          onChange={(hamperType) => handleHamperTypeClick(hamperType)}
        />
      </div>

      <div className='product-inclusions__products'>
        <ExtendedKeenSlider options={keenSliderOptions}>
          {products.map(product => {
            return (
              <div
                key={product.id}
                className='product-inclusions__product'
              >
                <span
                  className='product-inclusions__product__tooltip icon-info-circle'
                  onClick={() => handleProductClick(product)}
                ></span>

                <div
                  className='product-inclusions__product__image'
                  onClick={() => handleProductClick(product)}
                >
                  <ExtendedImage
                    imageUrl={product.featured_image}
                    altText={product.title}
                  />
                </div>

                <div
                  className='product-inclusions__product__title b3'
                  onClick={() => handleProductClick(product)}
                >
                  {product.title}
                </div>
              </div>
            )
          })}
        </ExtendedKeenSlider>
      </div>
      
      {selectedProduct &&
        <ExtendedProductInclusionsPopup
          product={selectedProduct}
          addToCartUrl={settings.add_to_cart_url}
          translations={settings.translations}
          onClose={() => handleProductClick(null)}
        />
      }
    </div>
  )
}