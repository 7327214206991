import React from 'react';
import useCustomerRecover from '@src/hooks/useCustomerRecover';

const AccountForgotPassword = ({
                                   contentWidth = 800,
                                   headingFontClass = '',
                                   onCancel,
                                   showTitle = false,
                               }) => {
    const {
        email,
        setEmail,
        loading,
        errorMessage,
        successMessage,
        handleRecoverPassword,
    } = useCustomerRecover();

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        handleRecoverPassword(email);
    };

    return (
        <div className="account-reset-password">
            <div
                className={`account-reset-password-inner shell flex flex-col mx-auto w-full lg:max-w-[${contentWidth}px]`}
            >
                {showTitle && (
                    <h2 className={`account__reset--title mx-auto mb-4 ${headingFontClass}`}>
                        Recover Password
                    </h2>
                )}

                <div className="form-container">
                    {errorMessage && <div className="error-message text-red-500 mb-4">{errorMessage}</div>}
                    {successMessage ? (
                        <div className="success-message text-green-500 mb-4">{successMessage}</div>
                    ) : (
                        <form onSubmit={handleSubmit}>
                            <div className="email mb-4 default-input">
                                <div className="text-field-wrap">
                                    <label htmlFor="email">Email</label>
                                    <input
                                        className="w-full p-2 form-field"
                                        type="email"
                                        name="email"
                                        placeholder="Enter your email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>

                            <div className="submit mb-4 flex flex-col">
                                <button
                                    className="button button-primary w-full"
                                    type="submit"
                                    disabled={loading}
                                >
                                    {loading ? 'Sending...' : 'Submit'}
                                </button>
                            </div>
                        </form>
                    )}

                    <button
                        className="blabel button button-secondary w-full min-h-0 h-auto p-0 capitalize hover:underline"
                        onClick={onCancel}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AccountForgotPassword;
