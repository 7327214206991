import React, { useState, useEffect } from "react";

import { useCart } from "frontend/js/useShopifyCart";

type ProductTileAddToCartProps = {
  variant: any,
  addToCartUrl: string,
  translations: any,
  upgradeTile?: any,
  onClose?: Function,
  chilled: boolean,
  delivery_date: string,
  delivery_date_end: string
}

export default function ProductTileAddToCart({ variant, addToCartUrl, translations, upgradeTile, onClose, chilled = false , delivery_date , delivery_date_end }: ProductTileAddToCartProps) {
  const { cart } = useCart();
  const [isLoading, setIsLoading] = useState(false);

  const buttonLabel = variant?.available
    ?  upgradeTile
      ? translations?.upgrade || 'Upgrade'
      : translations?.add_to_cart || 'Add to cart'

    : translations?.sold_out || 'Sold out';

  useEffect(() => {
    setIsLoading(false);
  }, [cart])

  const dispatchCustomEvent = (eventName, detail) => {
    window.dispatchEvent(new CustomEvent(eventName, { detail }));
  };

  return ( 
    <div className="add-to-cart-button">
      <button
        className={`button ${ upgradeTile ? 'button-secondary' : 'button-primary' }`}
        disabled={isLoading}
        onClick={() => { 
          dispatchCustomEvent("add-to-cart", { id: variant.id, quantity: upgradeTile > 0 ? upgradeTile : 1, delivery_date: delivery_date, delivery_date_end: delivery_date_end, chilled: chilled });
          setIsLoading(true) }}
      >
        {upgradeTile > 0 &&
          <span className="icon-up-arrow-alt text-[24px]"></span>
        }

        {isLoading ? 'Adding To Cart...' : buttonLabel}
      </button>
    </div>
  )
}