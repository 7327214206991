import React, { useEffect } from "react";

declare global {
  interface Window {
    yotpoWidgetsContainer: any;
  }
}

function YotpoStarRatings ({ settings }) {
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (window?.yotpoWidgetsContainer) {
        window.yotpoWidgetsContainer.initWidgets()
        clearInterval(intervalId);
      }
    }, 200);
  }, [])

  return (
    <div
      className="yotpo-widget-instance"
      data-yotpo-instance-id={settings.app_id}
      data-yotpo-product-id={settings.product_id}
      data-yotpo-cart-product-id={settings.product_id}
      data-yotpo-section-id={settings.template_name}
    ></div>
  )
}

const Layouts = {
  yotpo: YotpoStarRatings
}

export default function ProductTileStarRatingsApp ({ settings }) {
  const Layout = Layouts[settings.app_name]
  return <Layout settings={settings} />
}