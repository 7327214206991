import * as React from "react";
import { useEffect, useState } from "react";
import ProductDescription from './ProductDescription';
import ProductPrice from './ProductPrice';
// import ProductPayments from './ProductPayments';
// import ProductLabels from './ProductLabels';
// import ProductSwatches from './ProductSwatches';
// import ProductVendor from './ProductVendor';
// import ProductFindInStore from './ProductFindInStore';
import ProductTag from './ProductTag';
import ProductSizeGuide from './ProductSizeGuide';
// import ProductBreadcrumbs from './ProductBreadcrumbs';
// import ProductQuantitySelector from './ProductQuantitySelector';
// import ProductVariantPicker from './ProductVariantPicker';
// import ProductAddToCart from './ProductAddToCart';
// import ProductAccordion from './ProductAccordion';
import useMobileBreakpoint from "../../../hooks/useMobileBreakpoint";
import {mobileBreakpoint} from "../../../../entrypoints/theme";
import {TitleComponent} from "../../../../components/Blocks";
import {ProductStockStatus} from "../../../../components/Product";

export const ProductMain = ({ settings }) => {
    const isMobile = useMobileBreakpoint(mobileBreakpoint)
    const [sortedBlocks, setSortedBlocks] = useState([]);
    const { product, section, shop, blocks } = settings;

    useEffect(() => {
        // Sorting blocks based on the condition
        const sorted = isMobile
            ? [...blocks].sort((a, b) => a.settings.sort_order_mobile - b.settings.sort_order_mobile)
            : blocks;
        setSortedBlocks(sorted);
    }, [isMobile, blocks]);

    return (
        <div className={`pdp-details z-20 lg:basis-[100%]`} id={`product-info-${section.id}`}>
            {/*<ProductWishlist product={product} />*/}
            {sortedBlocks.map((block, index) => (
                <div key={index} className={settings.reveal_animation_class} data-animation-delay="0.2s">
                    {(() => {
                        switch (block.type) {
                            case 'description':
                                return (<ProductDescription
                                    product={product}
                                    useDescription={true} // or true, based on your logic
                                />);
                            case 'price':
                                return  <ProductPrice
                                    product={product}
                                    templateName={settings.templateName} // or wherever you get your template name from
                                />;
                            case 'product_title':
                                // return <TitleComponent settings={settings} />;
                            case 'payment_icons':
                                // return <ProductPayments block={block} />;
                            case 'product_labels':
                                // return <ProductLabels product={product} block={block} />;
                            case 'swatches':
                                // return <ProductSwatches product={product} block={block} />;
                            case 'text':
                                 // return <ProductStockStatus block={block} />;
                            case 'product_vendor':
                                // return <ProductVendor block={block} />;
                            case 'find_in_store':
                                // return <ProductFindInStore block={block} />;
                            case 'product_tag':
                                return <ProductTag block={block} product={product} />;
                            case 'size_guide':
                                return (
                                    <ProductSizeGuide
                                        useKiwiSizeApp={block.settings.use_kiwi_size_app}
                                        shop={shop.permanent_domain}
                                        product={product}
                                    />
                                );
                            case 'product_breadcrumbs':
                                 // return <ProductBreadcrumbs block={block} />;
                            case 'inventory':
                                // return <ProductQuantitySelector block={block} showDetails={true} />;
                            case 'select':
                                // return <ProductVariantPicker product={product} productFormId="product-form-id" pickerType={section.settings.picker_type} block={block} />;
                            case 'add':
                                return (
                                    <div className="pdp-add-to-cart mt-4">
                                        {/*<ProductAddToCart block={block} />*/}
                                    </div>
                                );
                            case 'accordion':
                                // return <ProductAccordion block={block} />;
                            default:
                                return null; // Handle unknown block types or add a default component
                        }
                    })()}
                </div>
            ))}
        </div>
    );
};

export default ProductMain;
