import { Headings } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Headings/Headings";

import ExtendedQuantityField from "@src/stories/elements/ExtendedQuantityField/ExtendedQuantityField";
import ExtendedTextField from "@src/stories/elements/ExtendedTextField/ExtendedTextField";
import ExtendedTextareaField from "@src/stories/elements/ExtendedTextareaField/ExtendedTextareaField";
import ExtendedSelectField from "@src/stories/elements/ExtendedSelectField/ExtendedSelectField";
import ExtendedCheckbox from "@src/stories/elements/ExtendedCheckbox/ExtendedCheckbox";

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' }
]

export default function StyleguideFormFields() {
  return (
    <div className={'styleguide-components'}>

      <div className={'component-header'}>
        <Headings text={'Inputs & Controls'} type={'h2'} />
      </div>

      <div className={'flex items-center gap-8'}>
        <div className={'min-w-[150px] shrink-0'}>
          <h5>Quantity Field</h5>
        </div>

        <div className={'component'}>
          <ExtendedQuantityField />
        </div>
      </div>

      <div className={'flex items-center gap-8'}>
        <div className={'min-w-[150px] shrink-0'}>
          <h5>Default</h5>
        </div>

        <div className={'component grow'}>
          <ExtendedTextField placeholder="Placeholder (optional)" />
        </div>
      </div>

      <div className={'flex items-center gap-8'}>
        <div className={'min-w-[150px] shrink-0'}>
          <h5>With Icon</h5>
        </div>

        <div className={'component grow'}>
          <ExtendedTextField
            placeholder="Placeholder (optional)"
            icon="mindarc"
          />
        </div>
      </div>

      <div className={'flex items-center gap-8'}>
        <div className={'min-w-[150px] shrink-0'}>
          <h5>Disabled</h5>
        </div>

        <div className={'component grow'}>
          <ExtendedTextField
            placeholder="Placeholder (optional)"
            disabled
          />
        </div>
      </div>

      <div className={'flex items-center gap-8'}>
        <div className={'min-w-[150px] shrink-0'}>
          <h5>Error</h5>
        </div>

        <div className={'component grow'}>
          <ExtendedTextField
            placeholder="Placeholder (optional)"
            errorMessage="Error description"
            hasError
          />
        </div>
      </div>

      <div className={'flex items-center gap-8'}>
        <div className={'min-w-[150px] shrink-0'}>
          <h5>Text Area</h5>
        </div>

        <div className={'component grow'}>
          <ExtendedTextareaField placeholder="Type your message..." />
        </div>
      </div>

      <div className={'flex items-center gap-8'}>
        <div className={'min-w-[150px] shrink-0'}>
          <h5>Dropdown</h5>
        </div>

        <div className={'component grow'}>
          <ExtendedSelectField
            options={options}
            placeholder="Select one..."
          />
        </div>
      </div>

      <div className={'flex items-center gap-8'}>
        <div className={'min-w-[150px] shrink-0'}>
          <h5>Checkbox</h5>
        </div>

        <div className={'component'}>
          <ExtendedCheckbox label={'Checkbox'} />
        </div>
      </div>
    </div>
  )
}