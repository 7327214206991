import React, { useState, useEffect } from "react";

export const WishlistFilters = ({
  settings,
  openModal,
  onFilterClick = (filterType) => {},
}) => {
  const { section, blocks } = settings;

  const filters = [
    {
      name: "Products",
      type: "products",
    },
    // {
    //   name: "For Customers",
    //   type: "customers",
    // },
    // {
    //   name: "For Family",
    //   type: "family",
    // },
    {
      name: "Recipes",
      type: "recipes",
    },
  ];

  function handleFilter(filterType) {
    if (onFilterClick) onFilterClick(filterType);
  }

  return (
    <div className="wishlist__filters">
      <div className="wishlist__filters-container">
        {filters.map((filter, index) => (
          <button
            key={index}
            className={`wishlist__filters-filter`}
            onClick={() => handleFilter(filter.type)}
          >
            {filter.name}
          </button>
        ))}
      </div>

      <div className="wishlist__filters-actions">
        <button
          className={`wishlist__filters-action gap-x-[8px] flex items-center justify-center`}
          onClick={openModal}
        >
          <i className={`icon icon-cog`}></i>Edit Wishlists
        </button>
      </div>
    </div>
  );
};
