import React, { useEffect, useState } from "react";

export const CollectionSidebar = ({ isVisible }) => {
    const [filters, setFilters] = useState([]);

    useEffect(() => {
        const handleProductsResults = (event) => {
            if (event.detail.type === 'plp') {
                if (event.detail.filters) {
                    setFilters(event.detail.filters);
                }
            }
        };

        window.addEventListener('searchrequest-products-complete', handleProductsResults);

        return () => {
            window.removeEventListener('searchrequest-products-complete', handleProductsResults);
        };
    }, []);

    const renderFilterValues = (filter) => {
        if (filter.key === 'options.color') {
            return (
                <div className="grid grid-cols-3 gap-2 mt-4">
                    {filter.values.map((value) => (
                        <label className="relative cursor-pointer" key={value.key}
                               x-on:click={`filter('${filter.key}', '${value.key}')`}>
                            <input type="checkbox" className="absolute opacity-0 w-full h-full" />
                            <div className="w-full h-10 rounded" style={{ backgroundColor: value.label.toLowerCase() }}>
                            </div>
                            <span className="sr-only">{value.label}</span>
                        </label>
                    ))}
                </div>
            );
        } else if (filter.key === 'options.size') {
            return (
                <div className="grid grid-cols-4 gap-2 mt-4">
                    {filter.values.map((value) => (
                        <label className="relative cursor-pointer" key={value.key}
                               x-on:click={`filter('${filter.key}', '${value.key}')`}>
                            <input type="checkbox" className="absolute opacity-0 w-full h-full" />
                            <div className="w-full h-10 flex items-center justify-center border rounded">
                                {value.label}
                            </div>
                        </label>
                    ))}
                </div>
            );
        } else {
            return (
                <div className="flex flex-col space-y-2 mt-4">
                    {filter.values.map((value) => (
                        <label className="flex items-center cursor-pointer" key={value.key}
                               x-on:click={`filter('${filter.key}', '${value.key}')`}>
                            <input type="checkbox" className="form-checkbox" />
                            <span className="ml-2">{value.label}</span>
                            <span className="ml-auto">{value.count}</span>
                        </label>
                    ))}
                </div>
            );
        }
    };

    return (
        <>
            <div className={`fixed top-0 w-full h-full bg-black z-50 opacity-20 pointer-events-none ${isVisible ? '' : 'hidden'}`}></div>
            <div className={`collection-sidebar fixed top-0 left-0 w-[430px] h-full bg-white shadow-lg transition-transform z-50 transform ${isVisible ? 'translate-x-0' : '-translate-x-full'}`}>

                <div className={'facets py-[40px] px-[32px]'}>
                    {filters.map((filter) => (
                        <div key={filter.key}>
                            <div className="bc uppercase font-medium">{filter.label}</div>
                            {renderFilterValues(filter)}
                            <hr className={'my-[18px]'} />
                        </div>
                    ))}
                </div>

                <hr className={'my-[18px]'} />

                <div className="mt-6 text-center p-[16px]">
                    <button className="button-primary w-full" x-on:click={`clear`}>CLEAR FILTER</button>
                </div>
            </div>
        </>
    );
};

export default CollectionSidebar;
