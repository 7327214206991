import React, { useState, useEffect } from "react";
import { clsx } from 'clsx';

import './ExtendedRadio.scss';

const SvgCheckMark = () => (
  <svg
    viewBox="0 0 24 24"
    width="17"
    height="17"
    stroke="white"
    strokeWidth="3"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <polyline points="20 6 9 17 4 12" />
  </svg>
);

type ExtendedRadioProps = {
  id?: string,
  label?: string,
  name?: string,
  value?: string | number,
  checked?: boolean,
  required?: boolean,
  disabled?: boolean,
  readonly?: boolean,
  hasError?: boolean,
  errorMessage?: string,
  className?: string,
  onChange?: Function,
}

export default function ExtendedRadio ({
  id,
  label,
  name,
  value,
  checked = false,
  required,
  disabled,
  readonly,
  hasError,
  errorMessage,
  className,
  onChange = () => {},
}: ExtendedRadioProps) {

  const [isChecked, setIsChecked] = useState(checked);

  function handleChange (e) {
    setIsChecked(!isChecked);
    if (onChange) onChange(e);
  }

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <label
      className={clsx('form-field__checkbox', className, {
        'is-checked': isChecked
      })}
    >
      <input
        type="radio"
        id={id}
        name={name}
        className="form-field__checkbox-input"
        defaultValue={value}
        checked={isChecked}
        onChange={(e) => handleChange(e)}
      />

      <div className="form-field__checkbox-indicator pt-px">
        {isChecked && <SvgCheckMark />}
      </div>

      {label &&
        <span className="form-field__checkbox-label">
          {label}
        </span>
      }
    </label>
  );
};