import { Headings } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Headings/Headings";
import { ElementLink } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Link/Link";
import { Paragraph } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Paragraph/Paragraph";

export default function StyleguideTypography() {
  return (
    <div className={'styleguide-components'}>
      <div className={'typography'}>
        <div className={'component-header'}>
          <Headings text={'Typography'} type={'h2'} />
        </div>

        <div className={'component d1'}>
          Display Text
        </div>

        <div className={'component'}>
          <Headings text={'Heading 1'} type={'h1'} />
        </div>

        <div className={'component'}>
          <Headings text={'Heading 2'} type={'h2'} />
        </div>

        <div className={'component'}>
          <Headings text={'Heading 3'} type={'h3'} />
        </div>

        <div className={'component'}>
          <Headings text={'Heading 4'} type={'h4'} />
        </div>

        <div className={'component'}>
          <Headings text={'Heading 5'} type={'h5'} />
        </div>

        <div className={'component'}>
          <Headings text={'Heading 6'} type={'h6'} />
        </div>

        <div className={'component'}>
          <ElementLink text={'Link Text'} />
        </div>

        <div className={'component'}>
          <Paragraph text={'Paragraph Text: Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in repre'} />
          <Paragraph text={'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'} />
        </div>

        <div className={'component'}>
          <ul className="list">
            <li>List item</li>
            <li>List item</li>
            <li>List item</li>
          </ul>
        </div>

        <div className={'component'}>
          <small>Small</small>
        </div>

        <div className={'component'}>
          <s>Strikethrough</s>
        </div>

        <div className={'component'}>
          <em>Italics</em>
        </div>

        <div className={'component'}>
          <div>
            <strong>Strong</strong>
          </div>
          <div>
            <b>Bold</b>
          </div>
        </div>
      </div>
    </div>
  )
}