import React from "react";

import ExtendedArticleFeaturedProduct from "./ExtendedArticleFeaturedProduct";
import ExtendedArticlePageHeader from "../components/ExtendedArticlePageHeader";

export default function ExtendedArticleTwoColumn({ settings }) {
  const { content, featured_products } = settings;
  const contents = content.split('<p>[FEATURED_PRODUCTS]</p>');
  console.log(settings);
  const articleSettings = {
    image: settings?.article?.image,
    title: settings?.article?.title,
    excerpt: settings?.article?.excerpt,
    author: settings?.article?.author,
    datePublished: settings?.article?.datePublished,
    read_time: settings?.read_time,
    type: settings?.type,
    type_color: settings?.type_color,
    type_bg: settings?.type_bg
  } 

  return (
    
    <div className="extended-article flex flex-col container p-6 px-3 lg:px-16 lg:pb-28 lg:flex">
      <ExtendedArticlePageHeader settings={articleSettings}/>
      <div className=" flex flex-col lg:gap-12 lg:flex-row">
        <div className="extended-article__two-column-content">
          {contents.map((content, index) => {
            return (
              <div  key={`content_${index}`}>
                <div className="extended-article__content-section" dangerouslySetInnerHTML={{ __html: content }} />
              </div>
            )
          })}
        </div>

        {featured_products.length > 0 &&
          <div className="extended-article__two-column-products">
            <h3>Products Featured</h3>

            <div className="flex flex-col gap-6 mb-6">
              {featured_products.map(product => {
                return (
                  <ExtendedArticleFeaturedProduct
                    key={product.id}
                    product={product}
                  />
                )
              })}
            </div>
          </div>
        }
      </div>
    </div>
  )
}