import React from "react";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { mobileBreakpoint } from "../../../../entrypoints/theme";
import ExtendedSelectField from "@src/stories/elements/ExtendedSelectField/ExtendedSelectField";
import "./FAQTabs.scss";

const ExtendedFAQTabs = ({ tabs, activeTab, onTabChange }) => {
  const isMobile = useMobileBreakpoint(mobileBreakpoint);

  if (isMobile) {
    return (
      <div className="faq-tabs w-full">
        <ExtendedSelectField options={tabs} placeholder="Select topic" onChange={(option) => onTabChange(option.value)} />
      </div>
    );
  }

  return (
    <div className="faq-tabs w-full flex">
      {tabs.map((tab) => (
        <div
          key={tab.value}
          className={`tab-button basis-[30%] ${activeTab === tab.value ? "active" : ""}`}
          onClick={() => onTabChange(tab.value)}
        >
          {tab.label}
        </div>
      ))}
    </div>
  );
};

export default ExtendedFAQTabs;
