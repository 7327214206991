import * as React from "react";

const ExtendedImageComponent = ({
    imageUrl,
    alt = '',
    isLazy = true,
    classes = '',
    style = {}
}) => {
    return (
        <img
            src={imageUrl}
            alt={alt}
            loading={isLazy ? 'lazy' : undefined}
            className={classes}
            style={style}
        />
    );
};

export default ExtendedImageComponent;