import * as React from "react";
import { clsx } from "clsx";
import AccountPopup from "@stories/components/Account/AccountPopup";
import { useEffect, useRef, useState } from "react";
import Alpine from "alpinejs";
import useAnimationEnd from '../../../hooks/useAnimationEnd';

import { ElementLink } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Link/Link";
import ExtendedTextField from "@src/stories/elements/ExtendedTextField/ExtendedTextField";
import ExtendedCollapsible from "../ExtendedCollapsible/ExtendedCollapsible";
import LogoBlock from "@stories/components/Header/ExtendedHeaderLogo";
import MegamenuLinks from "@src/stories/components/Megamenu/ExtendedMegamenuLinks";
import helper from 'frontend/js/helpers'
import ExtendedLocalization from "./ExtendedLocalization";

const MobileMenu = ({ linkList, settings, localization }) => {
  const menuLinks = linkList[0]?.links;
  const [menuState, setMenuState] = useState({
    menuAnimClass: "",
    accountAnimClass: "",
    isAccountVisible: false,
    isMenuVisible: false,
  });

  const {
    help_link,
    enable_help_link,
    enable_currency
 } = settings.section;

  const mobileMenu = settings.blocks && helper.arrayToEnum(settings.blocks, 'links_title')
  const slidingDivRef = useRef(null);

  const updateMenuState = updates => setMenuState(prevState => ({ ...prevState, ...updates }));

  const dispatchCustomEvent = (eventName, detail = { message: "" }) => {
    window.dispatchEvent(new CustomEvent(eventName, { detail }));
  };

  useAnimationEnd(slidingDivRef, () => {
    if (menuState.isAccountVisible && menuState.accountAnimClass == 'slide-out-to-right') {
      updateMenuState({
        isAccountVisible: false
      })

      dispatchCustomEvent("toggle-display-close", { message: "header-account" });
    }
  });

  const menuItemClicked = (dataId) => {
    updateMenuState({
      menuAnimClass: "slide-out-left"
    })

    Alpine.store("navigation").setMenuId(`header-${dataId}`);
  };

  const handleSubMenuOpen = () => {
    updateMenuState({
      menuAnimClass: "slide-out-left",
      isAccountVisible: false,
      isMenuVisible: true
    })
  };

  const handleSubMenuClose = () => {
    updateMenuState({
      menuAnimClass: "slide-in-left",
      accountAnimClass: "slide-out-to-right",
      isMenuVisible: true,
      isAccountVisible: false
    })

    menuState.isMenuVisible = true;
    menuState.menuAnimClass = "slide-in-left";
    onMenuClick();
    dispatchCustomEvent("megamenu-close");
  };

  const onMenuClick = () => {
    updateMenuState({
      menuAnimClass: "slide-in-left",
      accountAnimClass: "slide-out-left",
      isMenuVisible: true,
      isAccountVisible: false
    })

    dispatchCustomEvent("mobile-menu-open");
  };

  const onCloseClick = () => {
    updateMenuState({
      accountAnimClass: "slide-out-to-right",
      menuAnimClass: "slide-out-left",
      isAccountVisible: false,
      isMenuVisible: false,
    })

    dispatchCustomEvent("toggle-display-close", { message: "header-account" });
    dispatchCustomEvent("mobile-menu-closed");
    dispatchCustomEvent("megamenu-close");
  };

  const handleBackClick = () => {
    updateMenuState({
      accountAnimClass: "slide-out-to-right",
      menuAnimClass: "slide-in-left",
      isMenuVisible: true,
    })
  };

  useEffect(() => {
    window.addEventListener("megamenu-menu-close", handleSubMenuClose);
    window.addEventListener("megamenu-menu-open", handleSubMenuOpen);
    window.addEventListener("mobile-menu-closed", onCloseClick);
    return () => {
      window.removeEventListener("megamenu-menu-close", handleSubMenuClose);
      window.removeEventListener("megamenu-menu-open", handleSubMenuOpen);
      window.removeEventListener("mobile-menu-closed", onCloseClick);
    };
  }, []);

  useEffect(() => {
  }, [menuState]);

  // Filter blocks to find the one with type 'account_popup'
  const accountPopupSettings = settings.blocks.find(block => block.type === 'account_popup');

  return (
    <div className="mobile-menu-container">
      <div
        className={`menu-background ${clsx({
          "slide-in-left": menuState.isMenuVisible,
          "slide-out-left force-hidden": !menuState.isMenuVisible,
        })}`}
      ></div>
      <nav
        aria-label="Mobile Navigation Overlay"
        className={`navigation-overlay ${clsx({
          "open": menuState.isMenuVisible,
          "closed": !menuState.isMenuVisible,
        })}`}
        id="mobile-nav"
        role="navigation"
      >
        <span aria-label="Mobile Navigation Button" aria-controls="mobile-menu-list">
          {!menuState.isMenuVisible ? (
            <span aria-hidden="true" onClick={() => { 
              onMenuClick(); 
              if(document.body.classList.contains('search-open')) window.dispatchEvent(new CustomEvent("toggle-search"));
              }}>
              <i className="icon-menu text-2xl"></i>
            </span>
          ) : (
            <div>
              <div className={'flex relative justify-center flex-grow-0 basis-1/3'}>
                <LogoBlock settings={settings} className={''} />
              </div>
            </div>
          )}
        </span>
      </nav>
      <ul
        id="mobile-menu-list"
        className={`menu-list ${menuState.menuAnimClass} ${menuState.isMenuVisible ? "" : "hidden"} px-4`}
        role="menu"
      >
        <ExtendedTextField
          icon="search"
          className="header-search-bar lg:hidden mb-4"
          placeholder="Search products, recipes, blogs"
          onFocus={( )=>{ window.dispatchEvent(new CustomEvent("toggle-search"));}}
        />
        {menuLinks.map((sublink, sublink_id) => 
        {
          if( mobileMenu[sublink.title]) {
            return (
              <ExtendedCollapsible
                key={'sublink'+sublink_id}
                title={sublink.title}
                openIcon='icon-chevron-down'
                desktopDisabled
            >
                <nav>
                  <ul className={`mobile-menu-container-links`} aria-label="mobilemenu" role="menu"> 
                    {mobileMenu[sublink.title] && mobileMenu[sublink.title].links.map((tertiarylink, tertiarylink_id) => {     
                      if (tertiarylink.links && tertiarylink.links.length > 0) {
                        return (
                          <li key={'tertiarylink'+tertiarylink_id}>
                              <div className="mobile">              
                                <ExtendedCollapsible
                                      nested
                                      tier={1}
                                      title={tertiarylink.title}
                                      openIcon='icon-chevron-down'
                                      desktopDisabled
                                >
                                  <ul className={`mobile-menu-container-sublinks`} aria-label="mobilemenu" role="menu"> 
                                    {tertiarylink.links && tertiarylink.links.map((quarternarylink, quarternarylink_id) => { 
                                      if(quarternarylink.links && quarternarylink.links.length > 0) {
                                          return (
                                              <div key={'quarternarylink'+quarternarylink_id}>
                                                  <ExtendedCollapsible
                                                    nested
                                                    tier={2}
                                                    title={quarternarylink.title}
                                                    openIcon='icon-chevron-down'
                                                    desktopDisabled
                                                  >
                                                     <ul className={`mobile-menu-container-tertiarylinks`} aria-label="mobilemenu" role="menu"> 
                                                      {quarternarylink.links && quarternarylink.links.map((link, link_id) => { 
                                                          return (
                                                            <div key={'link'+link_id} className="mobile-menu-item-link" > 
                                                               <MegamenuLinks subItem={link} onLinkClick={onCloseClick}/>
                                                            </div>
                                                          )
                                                      })}
                                                     </ul>
                                                  </ExtendedCollapsible>
                                              </div>
                                          )
                                      }
                                      else {
                                        return (
                                          <div key={'quarternarylink'+quarternarylink_id} className="mobile-menu-item-link" > 
                                            <MegamenuLinks subItem={quarternarylink} onLinkClick={onCloseClick}/>
                                          </div>
                                        )
                                      }
                                    })}
                                    </ul> 
                                  </ExtendedCollapsible>
                              </div>
                          </li>
                        )
                      }
                      else {
                        <div key={'tertiarylink'+tertiarylink_id} className="mobile-menu-item-link" > 
                        <MegamenuLinks subItem={sublink} onLinkClick={onCloseClick}/>
                       </div>
                      }
                      
                    })}
                  </ul>
                </nav>
              </ExtendedCollapsible>
            )
          }
          else {
            return (<div key={'sublink'+sublink_id} className="mobile-menu-item" >  <MegamenuLinks subItem={sublink} onLinkClick={onCloseClick}/></div>)
          }
        }
        )}

       {(help_link && enable_help_link) &&
         <div className="mobile-menu-item">
            <a
              href={help_link}
              className="hover:no-underline"
            >
              Help
            </a> 
          </div>
        }

      { enable_currency &&  <ExtendedLocalization localization={localization} /> }
       
      </ul>
    </div>
  );
};

export default MobileMenu;