import React, { useEffect } from "react";

declare global {
  interface Window {
    yotpoWidgetsContainer: any;
  }
}

export default function ExtendedRecipeReviews ({ settings }) {
    const { id, title, url , image , price , description, currency, snippet_id } = settings;
    useEffect(() => {
        const intervalId = setInterval(() => {
          if (window?.yotpoWidgetsContainer) {
            window.yotpoWidgetsContainer.initWidgets()
            clearInterval(intervalId);
          }
        }, 200);
      }, [])
    
      return (
        <div 
            className="yotpo-widget-instance" 
            data-yotpo-instance-id={`${snippet_id}`}
            data-yotpo-product-id={`${id}`} 
            data-yotpo-name={`${title}`} 
            data-yotpo-url={`${url}`} 
            data-yotpo-image-url={`${image}`} 
            data-yotpo-price={`${price}`} 
            data-yotpo-currency={`${currency}`} 
            data-yotpo-description={`${description}`} >
        </div>
      )
}
