import React, { useState, useEffect } from "react";
import ExtendedProductTile from "@src/stories/components/ExtendedProductTile/ExtendedProductTile";
import ExtendedRecipeTile from "@src/stories/components/ExtendedRecipeTile/ExtendedRecipeTile";

export const WishlistItems = ({ settings, wishlistItems, filter }) => {
  const { section, blocks } = settings;

  return (
    <div className="my-4 grid grid-cols-4 space-x-[16px] space-y-[16px]">
      {wishlistItems.map((item, id) => (
        <div key={id} className="grid-item">
          {filter == "products" && <ExtendedProductTile product={item} />}
          {filter == "recipes" && (
            <ExtendedRecipeTile
              recipe={item}
              yotpo_snippet={""}
              dummy_id={item.dummy_id}
            />
          )}
        </div>
      ))}
    </div>
  );
};
