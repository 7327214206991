import React from "react";
import AccountRegisterForm from "./AccountRegisterForm";
import AccountLoginForm from "./AccountLoginForm";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import {mobileBreakpoint} from "../../../../entrypoints/theme";

const AccountForm = ({ settings, isLogin, showBusinessFields, handleBusinessCheckbox }) => {
    const isMobile = useMobileBreakpoint(mobileBreakpoint);

    return (
        <div>
            {/* Register Form */}
            {!isLogin && (
                <>
                    <AccountRegisterForm settings={settings} showBusinessFields={showBusinessFields} handleBusinessCheckbox={handleBusinessCheckbox} />
                </>
            )}

            {/* Login Form */}
            {isLogin && <AccountLoginForm />}

        </div>
    );
};

export default AccountForm;
