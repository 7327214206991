import React, { useState } from 'react';
import ExtendedImage from "@src/stories/components/ExtendedImage/ExtendedImage";
import ExtendedVideo from "@src/stories/elements/ExtendedVideo/ExtendedVideo";
import { shortCodeParser } from 'frontend/js/helpers';
import parse from 'html-react-parser';
import ExtendedCollapsible from "@src/stories/components/ExtendedCollapsible/ExtendedCollapsible";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";


const InstructionRow = ({tag , string, controls, tip_background, tip_color }) => {
    if ( tag == '[TIP]') {
        return ( 
             <div className={`recipe-details-instructions__content tip`}  style={{ backgroundColor: tip_background, color: tip_color }}> { string } </div>
        )
    }

    else if ( tag == '[IMG]'  ) {
        return (
            <div className={`recipe-details-instructions__content media`}> <ExtendedImage imageUrl={string} /> </div>
        )
    }

    else if ( tag == '[VID]' ) {
        const { autoplay,  loop,  muted } = controls;
        return (
            <div className={`recipe-details-instructions__content media`}> <ExtendedVideo videoUrl={string} autoplay={autoplay} muted={muted} loop={loop} /></div>
        )
    }

    else if ( tag == '[HEADING]') {  
        return (
            <div className={`recipe-details-instructions__content heading`}> {string} </div>
        )
    }


    else  {
        const formattedString = string.split(' ');
        const boldString = formattedString[0];
        const resultString = formattedString .slice(1).join(' ');
        return ( <div className={`recipe-details-instructions__content`}>  <span>  <span className="font-[500]"> { boldString } </span>  {parse(resultString)} </span> </div> )
    }
}

const InstructionContent = ({instructions, quantity, controls, unitState, baseQuantity, tip_background, tip_color }) => {
    let mediaIndexOffset = 0;
    let headingIndex = 0;
    const isMobile = useMobileBreakpoint('1024px');
    return(
        instructions.map((instruction, index) => {
            const { parsedString, isContent, tag } = shortCodeParser(instruction, quantity, unitState, ()=>{} , baseQuantity);
            if (isContent) mediaIndexOffset = mediaIndexOffset + 1;
            if (tag == '[HEADING]') {
                headingIndex = index + 1;
            }
            return (
                <div className={`recipe-details-instructions__row ${index == 0 ? 'first' : ''} ${tag == '[TIP]' ? 'tip' : ''} ${headingIndex == index ? 'heading' : ''} ${tag == '[IMG]' || tag == '[VID]' ? 'media' : ''}`}> 
                    { (!(tag == '[VID]' || tag == '[IMG]') || !isMobile )  &&  <div className="recipe-details-instructions__number h3">  { !isContent ? index  - mediaIndexOffset + 1 : ''} </div> }
                  
                    <InstructionRow tag={tag} string={parsedString}  controls={controls} tip_background={tip_background} tip_color={tip_color}/>
                </div>
            )
    
       })
    )

}


export default function ExtendedRecipeDetailsIntructions  ({instructions, quantity, controls, unitState, translations, baseQuantity, tip_background, tip_color }) {
    const isMobile = useMobileBreakpoint('1024px');
    return (
        <div className="recipe-details-instructions">
            <div className="flex-col flex lg:hidden">
                <ExtendedCollapsible
                    key={'recipe-instructions'}
                    title={`${translations.methods}`}
                    active
                    openIcon='icon-chevron-down'
                >
                    <InstructionContent instructions={instructions} quantity={quantity} controls={controls} unitState={unitState} baseQuantity={baseQuantity} tip_background={tip_background} tip_color={tip_color} />
                </ExtendedCollapsible> 
             </div>
            <div className="hidden lg:flex flex-col">
                <InstructionContent instructions={instructions} quantity={quantity} controls={controls} unitState={unitState} baseQuantity={baseQuantity} tip_background={tip_background} tip_color={tip_color} />
            </div>
        </div>
    );
   
  };



