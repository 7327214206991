import React, { useState } from 'react';
import { Tooltip } from 'react-tooltip'

const ActionBar = ({label = '', altLinks}) => {
    const handleLinkClick = () => {
      navigator.clipboard.writeText(window.location.href);
      setTooltipOpen(true);
      setTimeout( () => setTooltipOpen(false), 500);
    }

    const [ tooltipOpen, setTooltipOpen ] = useState(false);

    const { x_url, instagram_url, facebook_url , linkedin_url } = altLinks;
  
    const handleSocialMediaClick = (type) => {
      let url = "";
      switch(type) {
        case 'x':
          url = `http://twitter.com/share?&url=${window.location.href}`
          break;
        case 'fb':
          url = `http://www.facebook.com/sharer.php?u=${window.location.href}`
          break;
        case 'li':
          url = `https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}`
          break;
        default:
          url = "";
      }
      window.open(url);
    }
  
    return (
      <div className={'collection-banner__actions'}>
          {label != '' &&  <span className='collection-banner__actions-label'> { label } </span> }
          <span className='icon icon-link-alt'  id="tooltip-anchor" onClick={handleLinkClick}> </span>
          <Tooltip id="collection-banner__actions-tooltip"   anchorSelect="#tooltip-anchor" isOpen={tooltipOpen}  data-tooltip-delay-hide={1000} content='Copied To Clipboard'/>
          {linkedin_url ? <a className='icon icon-linkedin' href={`${linkedin_url}`}> </a> : <span className='icon icon-linkedin'  onClick={() => handleSocialMediaClick('li')}> </span> }
          {x_url ? <a className='icon icon-x' href={`${x_url}`}> </a> :  <span className='icon icon-x'  onClick={() => handleSocialMediaClick('x')}> </span>}
          {facebook_url ? <a className='icon icon-facebook' href={`${facebook_url}`}> </a> : <span className='icon icon-facebook'  onClick={() => handleSocialMediaClick('fb')}> </span> }
          {instagram_url  && <a className='icon icon-instagram' href={`${instagram_url}`}> </a>}
      </div>
    )
  }

  export default ActionBar;
