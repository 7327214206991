import React, { useEffect } from "react";
import {Avatar} from '@mindarc/arctheme-components/frontend/src/stories/components/elements/Avatar/Avatar';
import {Headings} from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Headings/Headings";
import {Paragraph} from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Paragraph/Paragraph";

import './Testimonials.scss';

export const Testimonials = ({ title, subtext, reviews }) => {
    useEffect(() => {
        const intervalId = setInterval(() => {
            if (window?.yotpoWidgetsContainer) {
                console.log('Init Yotpo');
                window.yotpoWidgetsContainer.initWidgets()
                clearInterval(intervalId);
            }
        }, 200);
    }, [])

    return (
        <div className={'testimonials'}>
            <div className="yotpo-widget-instance" data-yotpo-instance-id="819363"
                 data-yotpo-product-id="{{product.id}}" data-yotpo-cart-product-id="{{item.product.id}}"></div>

            <div className={'testimonials-top'}>
                <Headings text={title} type={'h2'}/>
                <Paragraph text={subtext}/>
            </div>

            <div
                className={`testimonials__container lg:grid-cols-` + reviews.length}
                style={{gridTemplateColumns: `repeat(${reviews.length}, minmax(0, 1fr))`}}
            >
                {reviews.map((blockItem, index) => (
                    <div key={`review-${index}`} className="testimonial">
                        <div className={'testimonial__ratings'}>
                            {(() => {
                                let rows = [];
                                for (let i = 0; i < blockItem.settings.star_rating; i++) {
                                    // @ts-ignore
                                    rows.push(<StarIcon key={`star-${i}`} className="text-yellow-400"/>);
                                }
                                return rows;
                            })()}
                        </div>

                        <div className="testimonial__quote"
                             dangerouslySetInnerHTML={{__html: blockItem.settings.blurb.replace(/\n/g, '<br />')}}
                        />

                        <div className="testimonial__image">
                            <Avatar
                                imageUrl={'https://via.placeholder.com/150'}
                                size={'50px'}
                            />
                        </div>

                        <p className="testimonial__author">{blockItem.settings.author}</p>
                        <p className="testimonial__role">{blockItem.settings['position-role']}, {blockItem.settings.company}</p>
                    </div>
                ))}

            </div>
        </div>
    )
}

function StarIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <polygon
                points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"/>
        </svg>
    )
}
