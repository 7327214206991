import React, { useState, useEffect, useRef} from 'react';

import ExtendedRecipeListingList from './components/ExtendedRecipeListingList';
import ExtendedRecipeListingToolbar from './components/ExtendedRecipeListingToolbar';
import ExtendedRecipeListingSidebar from './components/ExtendedRecipeListingSidebar';
import ExtendedRecipeListingMobileFilter from './components/ExtendedRecipeListingMobileFilter';
import './recipe-listing.scss';

export default function ExtendedRecipeListing  ({settings}) {
    const { all_recipes, yotpo_snippet, translations, promotion_blocks, color_schema, items_per_grid, 
      theme_filter, season_filter, meal_filter,  meat_filter, fish_filter, grains_filter, vegetables_filter, fruit_filter, courses_filter, collections_filter, features_filter, baking_filter, diet_filter, occasions_filter, wishlist } = settings;
    /* Empty Filter or intially fill with metafield filter values */
    const emptyFilterState = {
        theme: theme_filter ? [...theme_filter] : [],
        meal:  meal_filter ?  [...meal_filter] : [],
        season: season_filter ? [...season_filter] : [],
        meat: meat_filter ? [...meat_filter] : [],
        fish_seafood: fish_filter ? [...fish_filter] : [],
        grains_pulses: grains_filter ? [...grains_filter] : [],
        vegetables: vegetables_filter ? [...vegetables_filter] : [],
        fruit: fruit_filter ? [...fruit_filter] : [],
        courses: courses_filter ? [...courses_filter] : [],
        collections: collections_filter ? [...collections_filter] : [],
        features: features_filter ? [...features_filter] : [],
        baking: baking_filter ? [...baking_filter] : [],
        by_diet: diet_filter ? [...diet_filter] : [],
        by_occasions: occasions_filter ? [...occasions_filter] : []
    }

    const [ selectedFilters, setSelectedFilters ] = useState(emptyFilterState);
    const [ selectedSort, setSelectedSort ] = useState("default");
    const [ gridItems, setGridItems ] = useState([]);
    const [ itemCount, setItemCount ] = useState(items_per_grid);
    const [  maxCount, setMaxCount ] = useState(0);

    const promoBlocks = useRef(promotion_blocks);

    const SORT_OPTIONS = [
        { value: "default", label: `${translations.sort_default}` },
        { value: "ascending", label: `${translations.sort_ascending}` },
        { value: "descending", label: `${translations.sort_descending}` }
    ]
  
    const allThemes = new Set();
    const allSeasons = new Set();
    const allMeals = new Set();
    const allMeat = new Set();
    const allFish = new Set();
    const allGrains = new Set();
    const allVegetables = new Set();
    const allFruit = new Set();
    const allCourses = new Set();
    const allCollections = new Set();
    const allFeatures = new Set();
    const allBaking = new Set();
    const allDiet = new Set();
    const allOccasions = new Set();

    all_recipes.forEach(item => {
        item.filters.theme.forEach(theme => allThemes.add(theme));
        item.filters.season.forEach(season => allSeasons.add(season));
        item.filters.meal.forEach(meal => allMeals.add(meal));
        item.filters.meat.forEach(filter => allMeat.add(filter));
        item.filters.fish_seafood.forEach(filter => allFish.add(filter));
        item.filters.grains_pulses.forEach(filter => allGrains.add(filter));
        item.filters.vegetables.forEach(filter => allVegetables.add(filter));
        item.filters.fruit.forEach(filter => allFruit.add(filter));
        item.filters.courses.forEach(filter => allCourses.add(filter));
        item.filters.collections.forEach(filter => allCollections.add(filter));
        item.filters.features.forEach(filter => allFeatures.add(filter));
        item.filters.baking.forEach(filter => allBaking.add(filter));
        item.filters.by_diet.forEach(filter => allDiet.add(filter));
        item.filters.by_occasions.forEach(filter => allOccasions.add(filter));
    });

    const themesArray = Array.from(allThemes);
    const seasonsArray = Array.from(allSeasons);
    const mealsArray = Array.from(allMeals);
    const meatArray = Array.from(allMeat);
    const fishArray = Array.from(allFish);
    const grainsArray = Array.from(allGrains);
    const vegetablesArray = Array.from(allVegetables);
    const fruitArray = Array.from(allFruit);
    const coursesArray = Array.from(allCourses);
    const collectionsArray = Array.from(allCollections);
    const featuresArray = Array.from(allFeatures);
    const bakingArray = Array.from(allBaking);
    const dietArray = Array.from(allDiet);
    const occasionsArray = Array.from(allOccasions);


    const FILTERS_LIST = [
        { 
          filter: 'theme',
          label: 'Theme',
          options: themesArray.filter(item => !theme_filter.includes(item))
        },
        { 
          filter: 'meal',
          label: 'Meal',
          options: mealsArray.filter(item => !meal_filter.includes(item))
        },
        { 
          filter: 'season',
          label: 'By Season',
          options: seasonsArray.filter(item => !season_filter.includes(item))
        },
        { 
          filter: 'meat',
          label: 'Meat',
          options: meatArray.filter(item => !meat_filter.includes(item))
        },
        { 
          filter: 'fish_seafood',
          label: 'Fish & Seafood',
          options: fishArray.filter(item => !fish_filter.includes(item))
        },
        { 
          filter: 'grains_pulses',
          label: 'Grains & Pulses',
          options: grainsArray.filter(item => !grains_filter.includes(item))
        },
        { 
          filter: 'vegetables',
          label: 'Vegetables',
          options: vegetablesArray.filter(item => !vegetables_filter.includes(item))
        },
        { 
          filter: 'fruit',
          label: 'Fruit',
          options: fruitArray.filter(item => !fruit_filter.includes(item))
        },
        { 
          filter: 'courses',
          label: 'Courses',
          options: coursesArray.filter(item => !courses_filter.includes(item))
        },
        { 
          filter: 'collections',
          label: 'Collections',
          options: collectionsArray.filter(item => !collections_filter.includes(item))
        },
        { 
          filter: 'features',
          label: 'As Featured On TV',
          options: featuresArray.filter(item => !features_filter.includes(item))
        },
        { 
          filter: 'baking',
          label: 'Baking',
          options: bakingArray.filter(item => !baking_filter.includes(item))
        },
        { 
          filter: 'by_diet',
          label: 'By Diet',
          options: dietArray.filter(item => !diet_filter.includes(item))
        },
        { 
          filter: 'by_occasions',
          label: 'By Occasions',
          options: occasionsArray.filter(item => !occasions_filter.includes(item))
        }
    ];

    const filteredRecipes = all_recipes.filter(item => {
        if (selectedFilters.theme && selectedFilters.meal && selectedFilters.season ) {
            const themeMatch = selectedFilters.theme.length === 0 || item.filters.theme.some(theme => selectedFilters.theme.includes(theme));
            const mealMatch = selectedFilters.meal.length === 0 || item.filters.meal.some(meal => selectedFilters.meal.includes(meal));
            const seasonMatch = selectedFilters.season.length === 0 || item.filters.season.some(season => selectedFilters.season.includes(season));
            const meatMatch = selectedFilters.meat.length === 0 || item.filters.meat.some(filter => selectedFilters.meat.includes(filter));
            const fishMatch = selectedFilters.fish_seafood.length === 0 || item.filters.fish_seafood.some(filter => selectedFilters.fish_seafood.includes(filter));
            const grainsMatch = selectedFilters.grains_pulses.length === 0 || item.filters.grains_pulses.some(filter => selectedFilters.grains_pulses.includes(filter));
            const vegetablesMatch = selectedFilters.vegetables.length === 0 || item.filters.vegetables.some(filter => selectedFilters.vegetables.includes(filter));
            const fruitMatch = selectedFilters.fruit.length === 0 || item.filters.fruit.some(filter => selectedFilters.fruit.includes(filter));
            const coursesMatch = selectedFilters.courses.length === 0 || item.filters.courses.some(filter => selectedFilters.courses.includes(filter));
            const collectionsMatch = selectedFilters.collections.length === 0 || item.filters.collections.some(filter => selectedFilters.collections.includes(filter));
            const featuresMatch = selectedFilters.features.length === 0 || item.filters.features.some(filter => selectedFilters.features.includes(filter));
            const bakingMatch = selectedFilters.baking.length === 0 || item.filters.baking.some(filter => selectedFilters.baking.includes(filter));
            const dietMatch = selectedFilters.by_diet.length === 0 || item.filters.by_diet.some(filter => selectedFilters.by_diet.includes(filter));
            const occasionsMatch = selectedFilters.by_occasions.length === 0 || item.filters.by_occasions.some(filter => selectedFilters.by_occasions.includes(filter));
            return themeMatch && mealMatch && seasonMatch && meatMatch && fishMatch && grainsMatch && vegetablesMatch && fruitMatch && coursesMatch && collectionsMatch && featuresMatch && bakingMatch && dietMatch && occasionsMatch;
        } 
        return true;
    })


    const sortedRecipes = filteredRecipes.sort((a, b) => {
        if (selectedSort == 'descending') {
            return(b.title.localeCompare(a.title))
        }
        return(a.title.localeCompare(b.title))
    } );

    const recipeResults = selectedSort == 'default' ? filteredRecipes : sortedRecipes;


    const getPromoBlocksToBeInserted = (supposedToBeCurrentlyDisplayed) => {
        const blocksForNextPage = [];
    
        promoBlocks.current.forEach(block => {
          block.position.sort((a, b) => a - b);
          block.position.forEach(position => {
            if (position <= supposedToBeCurrentlyDisplayed) {
              blocksForNextPage.push({
                position: position - 1,
                block
              });
            }
          });
        });
        return blocksForNextPage;
      };
  
    const insertPromoBlocks = (recipesToBeAdded, promoBlocksForNextPage) => {
        let recipesCopy = recipesToBeAdded.concat([]);
        promoBlocksForNextPage.forEach(({ position, block }) => {
          recipesCopy.splice(position, 0, block);
        });
    
        return recipesCopy;
      };
    

      useEffect(() => {
        // Parse URL parameters on initial load 
        
        const searchParams = new URLSearchParams(window.location.search);
        
        const queryObject: Record<string, string[]> = { ...emptyFilterState };
    
  
        searchParams.forEach((value, key) => {
          const parseKey = key && key.split('-')[1];
          const currVal = value.split(',');
          if(parseKey) {
            switch(parseKey) {
              case 'theme':
                queryObject[parseKey] = [...emptyFilterState.theme,...currVal];
                break;
              case 'meal':
                queryObject[parseKey] = [...emptyFilterState.meal,...currVal];
                break;
              case 'season':
                queryObject[parseKey] =  [...emptyFilterState.season,...currVal];
                break;
              default:
                  break;
            }
          }
        });


        setSelectedFilters(queryObject);
      }, []); 

    useEffect(() => {
        // Append url parameters based on filters

        const convertToUrlParams = (filters: Record<string, string[]>) => {
            const queryParams = Object.entries(filters)
            .filter(([_key, values]) => values.length > 0)
            .map(([key, values]) => `filter-${key}=${encodeURIComponent(values.join(','))}`) 
            .join('&');
        
            return queryParams;
        };

        const filteredSelectedFilters  = {
          theme: selectedFilters.theme.filter(item => !theme_filter.includes(item)),
          meal: selectedFilters.meal.filter(item => !meal_filter.includes(item)),
          season: selectedFilters.season.filter(item => !season_filter.includes(item)),
          meat: selectedFilters.meat.filter(item => !meat_filter.includes(item)),
          fish_seafood: selectedFilters.fish_seafood.filter(item => !fish_filter.includes(item)),
          grains_pulses: selectedFilters.grains_pulses.filter(item => !grains_filter.includes(item)),
          vegetables: selectedFilters.vegetables.filter(item => !vegetables_filter.includes(item)),
          fruit: selectedFilters.fruit.filter(item => !fruit_filter.includes(item)),
          courses: selectedFilters.courses.filter(item => !courses_filter.includes(item)),
          collections: selectedFilters.collections.filter(item => !collections_filter.includes(item)),
          features: selectedFilters.features.filter(item => !features_filter.includes(item)),
          baking: selectedFilters.baking.filter(item => !baking_filter.includes(item)),
          by_diet: selectedFilters.by_diet.filter(item => !diet_filter.includes(item)),
          by_occasions: selectedFilters.by_occasions.filter(item => !occasions_filter.includes(item))
        };

        console.log(filteredSelectedFilters);
      
        const urlParams = convertToUrlParams(filteredSelectedFilters);
        if (urlParams != '') window.history.pushState(null, '', `?${urlParams}`);
        else  { 
            const newUrl = `${window.location.pathname}`;
            window.history.replaceState(null, '', newUrl);
        }  

        const promoBlocksItems = insertPromoBlocks(recipeResults, getPromoBlocksToBeInserted(itemCount))
        setMaxCount(promoBlocksItems.length);
        setGridItems( promoBlocksItems.slice(0, itemCount));
        
    }, [selectedFilters, itemCount, selectedSort]);

    const toolBarFilters  = {
      theme: selectedFilters.theme.filter(item => !theme_filter.includes(item)),
      meal: selectedFilters.meal.filter(item => !meal_filter.includes(item)),
      season: selectedFilters.season.filter(item => !season_filter.includes(item)),
      meat: selectedFilters.meat.filter(item => !meat_filter.includes(item)),
      fish_seafood: selectedFilters.fish_seafood.filter(item => !fish_filter.includes(item)),
      grains_pulses: selectedFilters.grains_pulses.filter(item => !grains_filter.includes(item)),
      vegetables: selectedFilters.vegetables.filter(item => !vegetables_filter.includes(item)),
      fruit: selectedFilters.fruit.filter(item => !fruit_filter.includes(item)),
      courses: selectedFilters.courses.filter(item => !courses_filter.includes(item)),
      collections: selectedFilters.collections.filter(item => !collections_filter.includes(item)),
      features: selectedFilters.features.filter(item => !features_filter.includes(item)),
      baking: selectedFilters.baking.filter(item => !baking_filter.includes(item)),
      by_diet: selectedFilters.by_diet.filter(item => !diet_filter.includes(item)),
      by_occasions: selectedFilters.by_occasions.filter(item => !occasions_filter.includes(item))
    };
    
    return (
        <div className="recipe-listing container">
            <ExtendedRecipeListingMobileFilter translations={translations} filters={FILTERS_LIST} color_schema={color_schema} selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} />
            <ExtendedRecipeListingToolbar sortOptions={SORT_OPTIONS} emptyFilterState={emptyFilterState} recipes={gridItems} selectedFilters={toolBarFilters} setSelectedFilters={setSelectedFilters}  selectedSort={selectedSort} setSelectedSort={setSelectedSort} translations={translations}  recipesCount={recipeResults.length}/>

            <div className="recipe-listing__content">
                <ExtendedRecipeListingSidebar selectedFilters={selectedFilters}  setSelectedFilters={setSelectedFilters} filters={FILTERS_LIST} translations={translations} />
                <ExtendedRecipeListingList recipes={gridItems} yotpo_snippet={yotpo_snippet} translations={translations} maxCount={maxCount} setItemCount={setItemCount} itemCount={itemCount} loadCount={items_per_grid} recipesCount={recipeResults.length} wishlist={wishlist}/>
            </div>
        
        </div>
    );
   
  };



