export default {
  name: "carousel",
  component() {
    return {
      active: 0,
      hasInit: false,
      flickity: null,
      showPagination: false,
      showNavigation: true,
      fadePartial: true,
      init() {},
      initialise(
        showPageDots = false,
        showNavigation = true,
        fadePartial = true,
        checkWidth = true,
      ) {
        this.showPagination = showPageDots;
        this.showNavigation = showNavigation;
        this.fadePartial = fadePartial;

        if (!this.hasInit) {
          if (checkWidth) {
            const totalWidth = this.calculateTotalWidth();
            const containerWidth = this.$refs.carousel.offsetWidth;

            if (totalWidth <= containerWidth) {
              // console.log('Carousel items fit within the container. No need to initialize Flickity.');
              return;
            }
          }

          // console.log('Initialised Carousel');

          let flickityScript =
            "https://unpkg.com/flickity@2.3.0/dist/flickity.pkgd.min.js";
          let flickityStylesheet =
            "https://unpkg.com/flickity@2.3.0/dist/flickity.css";

          // Check if the script already exists
          const existingScript = document.querySelector(
            `script[src="${flickityScript}"]`,
          );

          // Check if the stylesheet already exists
          const existingStylesheet = document.querySelector(
            `link[href="${flickityStylesheet}"]`,
          );

          // Only append the script if it doesn't already exist
          if (!existingScript) {
            const script = document.createElement("script");
            script.type = "text/javascript";
            script.src = `${flickityScript}`;
            document.head.appendChild(script);
          }

          // Only append the stylesheet if it doesn't already exist
          if (!existingStylesheet) {
            const stylesheet = document.createElement("link");
            stylesheet.type = "text/css";
            stylesheet.rel = "stylesheet";
            stylesheet.href = `${flickityStylesheet}`;
            document.head.appendChild(stylesheet);
          }

          this.hasInit = true;
          script.addEventListener("load", () => {
            if (typeof Flickity !== "undefined") {
              this.$nextTick(() => {
                this.initializeFlickity();
                if (this.flickity) {
                  this.flickity.reloadCells();
                  this.flickity.resize();
                }
              });
            }
          });
        } else {
          // console.log('Flickity Already Loaded, Initialising');
          if (typeof Flickity !== "undefined") {
            this.$nextTick(() => {
              this.initializeFlickity();
              if (this.flickity) {
                this.flickity.reloadCells();
                this.flickity.resize();
              }
            });
          }
        }
      },

      destroy() {
        if (this.flickity) {
          this.$nextTick(() => {
            return new Promise((resolve) => {
              this.flickity.destroy();
              resolve();
            }).then(() => {
              // CLEANUP ON CHILDREN FOR TRANSFORM AS DESTROY RETAINS THIS
              const children = this.$refs.carousel?.children;

              if (children) {
                for (let i = 0; i < children.length; i++) {
                  const child = children[i];
                  child.style.transform = "";
                  child.style.setProperty("transform", "");
                }
              }
              this.hasInit = false;
              // console.log('Component destroyed');
            });
          });
        }
      },
      initializeFlickity() {
        if (this.flickity) {
          this.flickity.destroy();
        }
        this.flickity = new Flickity(this.$refs.carousel, {
          wrapAround: true,
          resize: true,
          contain: true,
          draggable: true,
          pageDots: this.showPagination,
          cellAlign: "left",
          setGallerySize: false,
          imagesLoaded: true,
          prevNextButtons: this.showNavigation,
        });
        this.flickity.on("change", (i) => (this.active = i));

        // Observe changes to the carousel and reinitialize Flickity
        const observer = new MutationObserver((mutationsList, observer) => {
          for (let mutation of mutationsList) {
            if (mutation.type === "childList") {
              this.flickity.reloadCells();
              this.flickity.resize();
            }
          }
        });

        if (this.fadePartial) {
          this.flickity.on("scroll", () => this.applyOpacity());
          this.flickity.on("settle", () => this.applyOpacity());

          this.applyOpacity(); // Apply initial opacity
        }

        observer.observe(this.$refs.carousel, {
          childList: true,
          subtree: true,
        });
      },
      applyOpacity() {
        if (!this.flickity) return;
        if (this.flickity) {
          const cells = this.flickity.getCellElements();
          const margin = 50; // Adjust this value for the desired threshold allowance

          cells.forEach((cell) => {
            const cellRect = cell?.getBoundingClientRect();
            if (this.$refs.carousel) {
              const parentRect = this.$refs.carousel?.getBoundingClientRect();

              // Determine if the cell is partially outside the viewport with margin allowance
              const isPartiallyVisible =
                (cellRect.left < parentRect.left - margin &&
                  cellRect.right > parentRect.left + margin) ||
                (cellRect.right > parentRect.right + margin &&
                  cellRect.left < parentRect.right - margin);

              cell.classList.toggle(
                "flickity-partially-visible",
                isPartiallyVisible,
              );
            }
          });
        }
      },
      prev() {
        this.flickity.previous();
      },
      next() {
        this.flickity.next();
      },
      calculateTotalWidth() {
        const children = this.$refs.carousel.children;
        let totalWidth = 0;
        for (let i = 0; i < children.length; i++) {
          totalWidth += children[i].offsetWidth;
        }
        return totalWidth;
      },
    };
  },
};
