import ListProducts from "@mindarc/arctheme-components/frontend/src/stories/components/ecommerce/ListProducts/ListProducts";
import recentlyviewed from "../js/alpine/components/recentlyviewed";
import ExtendedRecommendedProducts from "../src/stories/components/RecommendedProducts/ExtendedRecommendedProducts";
import ExtendedListProducts from "../src/stories/components/ExtendedListProducts/ExtendedListProducts";
import ExtendedListBlogs from "../src/stories/components/ExtendedListBlogs/ExtendedListBlogs";
import ExtendedProductInclusionsList from "../src/stories/components/ExtendedRecipeProductInclusionsList/ExtendedRecipeProductInclusionsList";
import ExtendedRecipeRecommendationsList from "../src/stories/components/ExtendedRecipeRecommendationsList/ExtendedRecipeRecommendationsList";
import Wishlist from "@src/stories/sections/Wishlist/Wishlist";
import { useEffect, useState } from "react";
import shopify from "../js/shopify";

const sampleProducts = [
  {
    product: {
      id: "1",
      title: "Product A",
      image: "https://via.placeholder.com/150",
      price: 99.99,
      url: "/",
    },
  },
  {
    product: {
      id: "2",
      title: "Product B",
      image: "https://via.placeholder.com/150",
      price: 149.99,
      url: "/",
    },
  },
  {
    product: {
      id: "3",
      title: "Product C",
      image: "https://via.placeholder.com/150",
      price: 199.99,
      url: "/",
    },
  },
];

export function RecommendedProductList({ ...props }) {
  const [products, setProducts] = useState([]);
  const { product } = props.settings;

  useEffect(() => {
    async function fetchProductRecommendations(productId) {
      const recommendedProducts =
        await shopify.getRecommendedProducts(productId);
      setProducts(recommendedProducts);
    }

    if (product?.id) {
      fetchProductRecommendations(product.id);
    }
  }, [product]);

  const recentlyviewedproducts = recentlyviewed.component().get();

  if (products.length === 0 || recentlyviewedproducts.length === 0) {
    return null;
  }

  return (
    <ExtendedRecommendedProducts
      products={products}
      settings={props.settings.section}
      wishlist={props.settings.globals.enable_wishlist}
      recentlyviewed_products={recentlyviewedproducts}
    />
  );
}

export function ProductsList({ ...props }) {
  let productData = sampleProducts;

  const type = props.settings.section.type;

  if (type == "products" && props.settings.blocks.length > 0) {
    productData = props.settings.blocks;
  } else if (props.settings.collection.products.length > 0) {
    productData = props.settings.collection.products;
  }

  return (
    <ExtendedListProducts
      products={productData}
      type={type}
      settings={props.settings.section}
      wishlist={props.settings.globals.enable_wishlist}
      localization={props.settings.localization}
      translations={props.settings.translations}
      section_id={props.settings.section_id}
      add_to_cart={props.settings.routes?.add_to_cart_url}
    />
  );
}

export function BlogsList({ ...props }) {
  const type = props.settings.section.type;
  const blocks = props.settings.blocks;
  const blogArticles = props.settings.blog;

  return (
    <ExtendedListBlogs
      blocks={blocks}
      blog={blogArticles}
      type={type}
      settings={props.settings.section}
      translations={props.settings.translations}
      section_id={props.settings.section_id}
    />
  );
}

export function RecentlyViewedProductsList({ ...props }) {
  // console.log('Retrieiving Products from Recently Viewed Component');
  const products = recentlyviewed.component().get();

  if (products.length <= 0) {
    return false;
  }

  const productData = products.map((item) => ({
    ...item,
    featured_image: item.featuredImage?.src,
    images: item.media.map((mediaItem) => mediaItem.src),
    selected_or_first_available_variant: item.variants[0],
  }));

  return (
    <ExtendedListProducts
      products={productData}
      settings={props.settings.section}
      translations={props.settings.translations}
      localization={props.settings.localization}
      section_id={props.settings.section_id}
      add_to_cart={props.settings.routes?.add_to_cart_url}
    />
  );
}

export function RecipeInclusionProductsList({ ...props }) {
  return (
    <ExtendedProductInclusionsList
      products={props.settings.products}
      translations={props.settings.translations}
      localization={props.settings.localization}
      section_id={props.settings.section_id}
      add_to_cart={props.settings.routes?.add_to_cart_url}
    />
  );
}

export function RecipeRecommendationsList({ ...props }) {
  return (
    <ExtendedRecipeRecommendationsList
      recipes={props.settings.recipes}
      translations={props.settings.translations}
      localization={props.settings.localization}
      section_settings={props.settings}
      section_id={props.settings.section_id}
      add_to_cart={props.settings.routes?.add_to_cart_url}
      wishlist={props.settings?.enable_wishlist}
    />
  );
}

export function RecipeSliderList({ ...props }) {
  const all_recipes = props.settings.all_recipes;
  const recipeHandles = props.settings.recipes;
  const matchingRecipes = all_recipes.filter((recipe) =>
    recipeHandles.some((handle) => recipe.url === handle.handle),
  );
  return (
    <ExtendedRecipeRecommendationsList
      type="categories"
      recipes={matchingRecipes}
      translations={props.settings.translations}
      localization={props.settings.localization}
      section_settings={props.settings}
      section_id={props.settings.section_id}
      add_to_cart={props.settings.routes?.add_to_cart_url}
      wishlist={props.settings?.enable_wishlist}
    />
  );
}

export function WishlistComponent({ ...props }) {
  return <Wishlist settings={props.settings} />;
}
