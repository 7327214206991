import React, {useEffect, useState} from "react";

import { Paragraph } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Paragraph/Paragraph";

import ExtendedButton from "@src/stories/elements/ExtendedButton/ExtendedButton";
import ExtendedTile from "@src/stories/components/ExtendedTile/Tile";
import ExtendedHeading from "@src/stories/components/ExtendedHeading/ExtendedHeading";

import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { mobileBreakpoint } from "../../../../entrypoints/theme";

export const ExtendedMultiColumnBlock = ({
    settings,
    columns,
    headerText,
    subtext,
    tagline,
    columns_count,
    columns_count_mobile
}) => {

    let isMobile = useMobileBreakpoint(mobileBreakpoint);
    const [isLoaded, setIsLoaded] = useState(false);

    const isFirstColumnDefaultLayout = columns[0]?.settings.layout === 'default';
    let firstBlockHeight = isMobile ? columns[0]?.settings.mobile_block_height : columns[0]?.settings.block_height;

    if (!isFirstColumnDefaultLayout) {
        firstBlockHeight = 0;
    }

    const gapBetweenBlocksX = isMobile ? settings.section.gap_mobile : settings.section.gap;
    const gapBetweenBlocksY = isMobile ? settings.section.gap_y_mobile : settings.section.gap_y;

    useEffect(() => {
        if (isMobile !== undefined) {
            setIsLoaded(true);
        }
    }, [isMobile]);

    if (!isLoaded) {
        return null; // or a loading spinner
    }

    if (settings.section.render_type !== 'all') {
        if (isMobile && settings.section.render_type !== 'mobile-only') return;
        if (!isMobile && settings.section.render_type !== 'desktop-only') return;
    }

    return (
        <>
            <div className="multicolumn">
                {(tagline || headerText || subtext) && (
                    <div className="px-4">
                        <div className="multicolumn-content-top w-full lg:w-[700px] mb-4 lg:mb-10">
                            {tagline && <Paragraph text={tagline} type={'bc'} />}
                            {headerText && <ExtendedHeading text={headerText} type={'h3'} className="h3"/>}
                            {subtext && <Paragraph text={subtext} type={'b1'} />}
                        </div>
                    </div>
                )}

                <div
                    className={`flex w-full lg:h-[${firstBlockHeight}px] flex-wrap justify-evenly`}
                    {...(settings.section.use_carousel && !isMobile && {
                        'x-data': "carousel()",
                        'x-init': "$nextTick(() => { initialise(false, true, false) })",
                        'x-ref': "carousel"
                    })}
                    {...(settings.section.use_carousel && isMobile && {
                        'x-data': "carousel()",
                        'x-init': "$nextTick(() => { destroy() })",
                        'x-ref': "carousel"
                    })}
                >
                    {columns.map((column, index) => {
                        const isOdd = columns.length % 2;
                        const isLast = columns.length === (index + 1);
                        const columnWidth = isMobile ? column.settings.width_mobile : column.settings.width;
                        const columnsCount = isMobile ? columns_count_mobile : columns_count;
                        const defaultFlexBasis = `${100 / columnsCount}%`;

                        const flexBasis = columnWidth > 0
                            ? `calc(${columnWidth}% - ${gapBetweenBlocksX}px)`
                            : `calc(${defaultFlexBasis} - ${gapBetweenBlocksX}px)`;

                        const columnHeight = isMobile ? column.settings.mobile_block_height : column.settings.block_height;
                        const heightStyle = columnHeight > 0 ? `${columnHeight}px` : 'auto';
                        const imageHeight = isMobile ? column.settings.image_height_mobile : column.settings.image_height;
                        const imageHeightStyle = imageHeight > 0 ? `${imageHeight}px` : 'auto';

                        return (
                            <div
                                key={column.key || index}
                                style={{
                                    flexBasis: (isOdd && isLast && isMobile) ? '100%' : flexBasis,
                                    height: column.settings.layout == 'default' && heightStyle,
                                    marginLeft: !settings.section.use_carousel && index % columnsCount !== 0 ? `${gapBetweenBlocksX / 2}px` : 0,
                                    marginRight: !settings.section.use_carousel && (index + 1) % columnsCount !== 0 ? `${gapBetweenBlocksX / 2}px` : 0,
                                    marginTop: `${gapBetweenBlocksY / 2}px`,
                                    marginBottom: `${gapBetweenBlocksY / 2}px`,
                                    ...(settings.section.use_carousel && {
                                        marginLeft: `${gapBetweenBlocksX / 2}px`,
                                        marginRight: `${gapBetweenBlocksX / 2}px`,
                                    })
                                }}
                                className={`tile-container box-border overflow-hidden ${isLast ? 'last-column' : ''}`}
                            >
                                <ExtendedTile data={column} layout={column.settings.layout} height={imageHeightStyle} />
                                {settings.section.show_dividers && <div className={'multicolumn__tiledivider'}>|</div>}
                            </div>
                        );
                    })}
                </div>

                {(settings.section.button_text) &&
                    <div className="multicolumn-cta">
                        <ExtendedButton
                          style={settings.section.button_style}
                          label={settings.section.button_text}
                          link={settings.section.button_url}
                        />
                    </div>
                }
            </div>
        </>
    );
};

export default ExtendedMultiColumnBlock;
