import React from "react";
import "./FoodClubSignup.scss"; // Importing the SCSS file

const FoodClubSignup = ({ translations }) => {
  return (
    <div className="foodclub-signup my-[24px]">
      <p className="foodclub-signup__header b2 font-medium">
        Join Maggie's Food Club
      </p>

      <p className="foodclub-signup__content b2 font-normal">
        Become part of a wonderful community of discerning food lovers. It's
        completely free!
      </p>

      <a
        href="/account/register"
        className="foodclub-signup__button button button-secondary"
      >
        Join Maggie's Food Club
      </a>

      <p className="foodclub-signup__disclaimer blabel">
        By subscribing you agree to with our{" "}
        <a href="#" className="foodclub-signup__link">
          Privacy Policy
        </a>
        .
      </p>
    </div>
  );
};

export default FoodClubSignup;
