import React, { useRef, useState, useEffect } from "react";

export default function ProductTileSaleCountdown({ className = '', label = '', endDate }) {
  const [saleCountdown, setSaleCountdown] = useState(null);
  const endDateRef = useRef(endDate);
  const timer = useRef(null);

  function updateCountdown() {
    const now: any = new Date();
    const timeDifference = endDateRef.current - now;

    if (timeDifference <= 0) {
      setSaleCountdown(null);
      clearInterval(timer);
      return;
    }

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const duration = days > 0
      ? `${days}D ${hours}H ${minutes}M`
      : `${hours}H ${minutes}M`;

    setSaleCountdown(duration)
  }

  useEffect(() => {
    updateCountdown()
    timer.current = setInterval(() => updateCountdown(), 60000);
  });

  if (!saleCountdown) return;

  return (
    <div className={className}>
      <span>{label} {saleCountdown}</span>
    </div>
  )
}