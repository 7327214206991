import React from 'react';
import Paragraph from "@stories/elements/Paragraph";

const ProductDescription = ({ product, useDescription = false }) => {
    const description = product.description;
    const metafieldDescription = product.metafields?.ap21?.atfStatement;
    return (
       ((description || metafieldDescription) && <div className="pdp-description">
            <Paragraph text={description && useDescription ? description : metafieldDescription} type={'b3'} />
        </div> )
    );
};

export default ProductDescription;
