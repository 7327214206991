import React, { useState, useEffect } from "react";
import clsx from "clsx";

import ExtendedSelectField from '@src/stories/elements/ExtendedSelectField/ExtendedSelectField';
import ExtendedButton from '@src/stories/elements/ExtendedButton/ExtendedButton';
import ExtendedBlogTile from "../ExtendedBlogTile/ExtendedBlogTile";
import { getArticles, getBlogArticles } from "./query";

import "./ExtendedArticles.scss";

export default function ExtendedArticles({ settings }) {

  const tabOptions: any = [
    { label: 'View all', value: '' },
    ...settings.blocks
  ]

  const [selectedTab, setSelectedTab] = useState(tabOptions[0]);
  const [articles, setArticles] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [isFetching, setIsFetching] = useState(true);

  async function _getArticles(cursor) {
    const { edges, pageData } = selectedTab.value
      ? await getBlogArticles(selectedTab.value, cursor)
      : await getArticles(cursor);

    if (cursor) {
      const articlesCopy = articles.concat(edges);
      setArticles(articlesCopy);

    } else {
      setArticles(edges);
    } 

    setPageInfo(pageData);
    setIsFetching(false);
  }

  function handleLoadMore() {
    if (!pageInfo.hasNextPage) return;

    setIsFetching(true);
    _getArticles(pageInfo.endCursor);
  }

  useEffect(() => {
    _getArticles('');
  }, [selectedTab]);

  return (
    <div>
      <div className='extended-articles__heading'>
        <div className='extended-articles__heading__title'>{settings.title}</div>

        <ul className='extended-articles__heading__tabs'>
          {tabOptions.map(tab => {
            return (
              <li
                key={tab.value}
                className={clsx('extended-articles__heading__tab', {
                  'extended-articles__heading__tab--active': tab.value === selectedTab.value
                })}
                onClick={() => setSelectedTab(tab)}
              >
                {tab.label}
              </li>
            )
          })}
        </ul>

        <ExtendedSelectField
          className="extended-articles__heading__mobile-tabs"
          options={tabOptions}
          value={selectedTab.value}
          onChange={(option) => setSelectedTab(option)}
        />
      </div>

      <div className="grid grid-cols-2 lg:grid-cols-4">
        {articles.map(item => {
          const { node } = item;
          let read_time = node.read_time?.value;
          
          if (!read_time) {
            const readDuration = Math.ceil(node.content.split(' ').length / 200);
            read_time = readDuration > 1 ? readDuration : 1;
          }

          const article = {
            ...node,
            url: node.onlineStoreUrl,
            featured_image: node.image?.url,
            read_time,
          }

          return (
            <ExtendedBlogTile
              key={article.id}
              article={article}
              translations={settings.translations}
            />
          )
        })}
      </div>

      {pageInfo?.hasNextPage &&
        <div className='extended-articles__footer'>
          <ExtendedButton
            style="secondary"
            label={isFetching ? '' : 'Load more'}
            icon={isFetching ? 'loader-alt animate-spin text-xl' : ''}
            disabled={isFetching}
            onClick={() => handleLoadMore()}
          />
        </div>
      }
    </div>
  )
}