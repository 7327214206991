import { useState } from "react";

const useCustomerRecover = () => {
    const [email, setEmail] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleRecoverPassword = async (email) => {
        setLoading(true);
        setErrorMessage(null);
        setSuccessMessage(null);

        try {
            // Create the form dynamically
            const form = document.createElement("form");
            form.method = "POST";
            form.action = "/account/recover";

            // Add the email input field
            const emailInput = document.createElement("input");
            emailInput.type = "hidden";
            emailInput.name = "email";
            emailInput.value = email;
            form.appendChild(emailInput);

            // Add hidden utf8 input for the form (required by Shopify)
            const utf8Input = document.createElement("input");
            utf8Input.type = "hidden";
            utf8Input.name = "utf8";
            utf8Input.value = "✓";
            form.appendChild(utf8Input);

            // Append form to the body
            document.body.appendChild(form);

            // Use Shopify's CAPTCHA protection to handle form submission
            window.Shopify.captcha.protect(form, () => {
                // Submit the form after CAPTCHA validation
                form.submit();
                setSuccessMessage("Password recovery email has been sent.");
            });
        } catch (error) {
            setErrorMessage("An unexpected error occurred.");
        } finally {
            setLoading(false);
        }
    };

    return {
        email,
        setEmail,
        loading,
        errorMessage,
        successMessage,
        handleRecoverPassword,
    };
};

export default useCustomerRecover;
