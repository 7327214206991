import React from "react";

import ExtendedImage from "@src/stories/components/ExtendedImage/ExtendedImage";

type RecipeTileImageProps = {
  featuredImageUrl: string,
  altText: string,
  images?: Array<string>,
  hoverImageEnabled?: boolean
}

export default function RecipeTileImage({
  featuredImageUrl,
  altText,
  images,
  hoverImageEnabled = true
}: RecipeTileImageProps) {

  return (
    <div className="product-tile__images">
      <div className="product-tile__image-hover">
        <ExtendedImage
          imageUrl={featuredImageUrl}
          altText={altText}
          className="product-tile__image"
        />
      </div>

      <ExtendedImage
        imageUrl={featuredImageUrl}
        altText={altText}
        className="product-tile__image"
      />
    </div>
  )
}