import React, { useState } from "react";
import Paragraph from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Paragraph/Paragraph";
import ExtendedButton from "@src/stories/elements/ExtendedButton/ExtendedButton";
import { Headings } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Headings/Headings";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { mobileBreakpoint } from "../../../../../entrypoints/theme";
import { clsx } from "clsx";
import ExtendedImageComponent from "../../../elements/ExtendedImage/ExtendedImage";

const OverlayedLayout = ({ reverselayout, description, settings, blocks }) => {
  const isMobile = useMobileBreakpoint(mobileBreakpoint);

  let image = isMobile
    ? settings.image_mobile?.src ||
      settings.image_mobile ||
      settings.image?.src ||
      settings.image
    : settings.image?.src || settings.image;

  let video = isMobile
    ? (settings.video_mobile?.sources?.[0]?.url ??
      settings.video_mobile ??
      settings.video?.sources?.[0]?.url ??
      settings.video)
    : (settings.video?.sources?.[0]?.url ?? settings.video);

  let videoPoster = settings.video?.preview_image.src;
  let heading = settings.title;
  let type = "twi-overlayed";
  const imageWidth = isMobile ? settings.width_mobile : settings.width;

  if (settings) {
    if (settings.content) {
      description = settings.content;
    }
    if (settings.reverse_layout) {
      reverselayout = settings.reverse_layout;
    }

    if (settings.classes) {
      type = settings.classes;
    }
  }

  if (settings.render_type !== "all") {
    if (isMobile && settings.render_type !== "mobile-only") return null;
    if (!isMobile && settings.render_type !== "desktop-only") return null;
  }

  return (
    <div className={`${type}`}>
      <div className={`image-container overlay content-over-media`}>
        {image ? (
          <ExtendedImageComponent
            imageUrl={image}
            classes={"object-cover h-full w-full"}
          />
        ) : video ? (
          <video
            className={"object-cover h-full w-full"}
            autoPlay
            loop
            muted
            poster={videoPoster}
            preload="metadata"
            playsInline
          >
            <source src={video} type="video/mp4"></source>
          </video>
        ) : null}
        <div className={`twi-container justify-center`}>
          <div className={`twi-content h-full 
                ${settings.padding_area == 'inside' &&
          ` pt-[${ settings.padding_top_mobile }px]` +
          ` lg:pt-[${ settings.padding_top_desktop }px]` +
          ` pb-[${ settings.padding_bottom_mobile }px]` +
          ` lg:pb-[${ settings.padding_bottom_desktop }px]` +
          ` px-[${ settings.padding_left_right_mobile }px]` +
          ` lg:px-[${ settings.padding_left_right_desktop }px]`} 
          justify-${settings.content_position_desktop}`}>
            <div className={`w-full flex flex-col gap-y-[24px] items-${settings.content_alignment_mobile} lg:items-${settings.content_alignment_desktop} text-${settings.content_alignment_mobile} lg:text-${settings.content_alignment_desktop}`}>
              <Headings text={heading} type={settings.heading_type} />
              <Paragraph text={description} type={"b3"} />
              <div className={"flex gap-[24px] items-center"}>
                {blocks.map((block, index) => (
                  <div key={block.key || index}>
                    {block.settings.button_text && (
                      <ExtendedButton
                        style={block.settings.button_style}
                        label={block.settings.button_text}
                        link={block.settings.button_url}
                        icon={block.settings.button_icon}
                        reverse={block.settings.button_reverse}
                        icon_size={20}
                        onClick={undefined}
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverlayedLayout;
