import React from 'react';

import ExtendedButton from '@src/stories/elements/ExtendedButton/ExtendedButton';

import CartTitle from './components/CartTitle';
import CartItems from './components/CartItems';

import { useCart } from 'frontend/js/useShopifyCart';


export default function ExtendedMinicart ({ hampers, style = 'drawer', settings, shipping_id,  gift_wrap_types, card_types }) {
  const { cart } = useCart(true);

  const excludedIds = [
    // ...card_types.map(item => item.id),
    // ...gift_wrap_types.map(item => item.id),
    Number(shipping_id)
  ];
  
  const filteredCartItems = cart && cart.items.filter(
    (cartItem) => !excludedIds.includes(cartItem.variant_id)
  );

  const shippingItems =  cart && cart.items.filter((item) => item.id === Number(shipping_id));
  const totalShippingPrice = shippingItems && shippingItems.reduce((acc, item) => acc + item.price, 0);

  return (
    <>
      <div
        className='extended-minicart__overlay'
        x-cloak="true"
        x-show="$store.global.isMinicartVisible"
      />

      <div className={`extended-minicart__wrapper ${style}`}
        x-cloak="true"
        x-transition:enter="transition ease-out duration-300"
        x-transition:enter-start="off-screen-right"
        x-transition:enter-end="right-50"
        x-transition:leave="transition ease-in duration-300"
        x-transition:leave-start="right-50"
        x-transition:leave-end="off-screen-right"
        x-show="$store.global.isMinicartVisible"
      >
        <div className={'extended-minicart overflow-auto'}>
          <CartTitle item_count={filteredCartItems && filteredCartItems.length} />

          <CartItems
            cartItems={filteredCartItems}
            hampers={hampers}
            show_total_price={settings.show_total_price}
          />

          {cart.items.length === 0
            ? <div className={'flex flex-col gap-y-[16px] text-center h-full justify-center p-[16px]'}>
                <div className={'b2 text-[#54565A]'}>
                  {settings.empty_text_one}

                  <br/>

                  {settings.empty_text_two}
                </div>

                <a
                  x-on:click="$store.global.toggleMinicart()"
                  className={'minicart-empty__button button button-outline'}
                >
                  {settings.empty_button}
                </a>
              </div>

            : <div className='extended-minicart__footer'>
                <div className="extended-minicart__total">
                  <h5 className='h5'>
                    Total
                  </h5>

                  <h5 className='h5'>
                    {`$${((cart.total_price - totalShippingPrice) / 100).toFixed(2)}`}
                  </h5>
                </div>

                <div className='extended-minicart__checkout-button'>
                  <ExtendedButton
                    style="primary"
                    label="Checkout"
                    link='/cart'
                  />
                </div>
              </div>
          }
        </div>
      </div>
    </>
  );
}
