import shopifyHelper from "../../shopify";

export const account = {
    name: 'recipe',
    component() {
        return {
            productList: {},
            productIdList: [],
            getProducts() {
                return this.productList;
            },
            async updateProducts(list) {
                const queryProductList = list.map(id => `gid://shopify/Product/${id}`);
                const query = `query Products {
                    nodes(ids: [${queryProductList.map(product => `"${product}"`)}]) {
                      ... on Product {
                        id
                        title
                        handle
                        variants  (first:10 ) {
                            nodes  {
                              id
                              title
							}
						}
                      metafields: metafields(identifiers: [
                            { namespace: "custom", key: "pre_order_end_delivery_date" },
                            { namespace: "custom", key: "pre_order_delivery_date" },
                            { namespace: "custom", key: "chilled" }
                        ]) {
                            key
                            namespace
                            value
                        }
                      }
                    }
                  }`;
            
                let jsonResponse = null;
               
                jsonResponse = await shopifyHelper.unauthenticatedFetch(query, null , true);
                let products = {}
                if (jsonResponse) {
                    products = jsonResponse.data.nodes.reduce((acc, node) => {
                        if(node) {
                            const id = node && node.id.split('/').pop();  // Extract the ID from the gid
                            acc[id] = { handle: node.handle, variantId: node.variants.nodes[0].id };    // Create an object with the handle
                            return acc;
                        }
                        return acc;
                    }, {});
                }
             
                return products;
            }
        }
    }
};

export default account;
