import React, { useRef, useEffect } from "react";

import ExtendedArticleOneColumn from "./components/ExtendedArticleOneColumn";
import ExtendedArticleTwoColumn from "./components/ExtendedArticleTwoColumn";

import "./ExtendedArticle.scss";

const Layouts = {
  "One Column": ExtendedArticleOneColumn,
  "Two Columns": ExtendedArticleTwoColumn,
}

export default function ExtendedArticle({ settings }) {
  const { template_layout , type } = settings;
  const LayoutComponent = template_layout ? Layouts[template_layout] : Layouts["One Column"];
  const hasLoggedBraze = useRef(false);
  useEffect(() => {
      if(!hasLoggedBraze.current) {
        hasLoggedBraze.current = true;
        setTimeout(() => {
            window.braze?.logCustomEvent("Blog or Recipe Page Views", { "recipe_category": type });
        }, 2000); 
      }
  }, []); 
  return <LayoutComponent settings={settings} />
}