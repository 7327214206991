import React, {useEffect, useState} from "react";
import ToggleSwitch from "@stories/elements/ToggleSwitch/ToggleSwitch";
import {getAlpineStore} from "../../../../entrypoints/theme";

export const CollectionToolbar = ({ toggleSidebar }) => {
    const store = getAlpineStore('modelview');

    const [selectedOption, setSelectedOption] = useState("Popular");
    const [modelView, setModelView] = useState(store.isModelView);

    useEffect(() => {
        setModelView(store.isModelView)
    }, []);

    const handleSelectChange = (event) => {
        const newValue = event.target.value;
        setSelectedOption(newValue);

        // console.log('Sort updated', newValue);
        // Emit the event to sort products based on the selected sort option
        const sortEvent = new CustomEvent('searchrequest-sortchanged', {
            detail: {
                sortOption: newValue
            }
        });
        window.dispatchEvent(sortEvent);
    };

    const handleToggleChange = (isChecked) => {
        // console.log("Toggle is now:", isChecked);
        window.dispatchEvent(new CustomEvent('modelview-toggle', {
            detail: { isModelView: isChecked }
        }));
    };

    return (
        <div className="flex items-center space-x-4 border-b pb-4 mb-4 justify-end">
            <div className={'flex toolbar-items border'}>
                {/* Filters Button */}
                <button className="flex items-center bc focus:outline-none" onClick={toggleSidebar}>
                    <i className={'icon icon-filter-simple'}></i>
                    FILTERS
                </button>

                {/* Sort By Dropdown */}
                <div className="relative flex items-center justify-center">
                    <select
                        value={selectedOption}
                        onChange={handleSelectChange}
                        className="bc py-2 px-3 border-0 uppercase font-medium text-black"
                    >
                        <option value="">SORT BY</option>
                        <option value="Popular">Popular</option>
                        <option value="Newest">Newest</option>
                        <option value="LowToHigh">Price - Low to High</option>
                        <option value="HighToLow">Price - High to Low</option>
                    </select>
                    <i className={'icon icon-chevron-down'}></i>
                </div>

                {/* Model View Toggle */}
                <div className="flex items-center px-[20px]">
                    <ToggleSwitch label={'Model View'} onChange={handleToggleChange} initialChecked={modelView} />
                </div>
            </div>
        </div>
    );
};

export default CollectionToolbar;
