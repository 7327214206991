import React from "react";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { mobileBreakpoint } from "../../../../entrypoints/theme";
import OverlayedLayout from "./layouts/Overlayed";
import TwoColumnLayout from "./layouts/TwoColumn";
import TwoColumnGridLayout from "./layouts/TwoColumnGrid";
import StackedLinkImages from "./layouts/StackedLinkImages";

const layouts = {
  "overlayed": OverlayedLayout,
  "2column": TwoColumnLayout,
  "twocolumn-grid": TwoColumnGridLayout,
  "stacked-link-images": StackedLinkImages,
};

export const ExtendedTextWithImage = ({
  reverselayout,
  description,
  settings,
  shipping_rates,
  shipping_cost_id, 
  logged_in_threshold,
  logged_out_threshold,  
  free_price_bypass, 
  customer,
  blocks
}) => {
  const isMobile = useMobileBreakpoint(mobileBreakpoint);
  let Layout = layouts[settings.layout_type] || TwoColumnLayout;
  if (isMobile) {
    Layout = layouts[settings.layout_type_mobile] || TwoColumnLayout;
  }

  return (
    <div
      className={`${settings.reveal_animation_class} relative`}
      style={{
        paddingTop: `${settings.padding_top}px`,
        paddingBottom: `${settings.padding_bottom}px`,
      }}
    >
      <div className="relative w-full h-full justify-end items-end">
        <Layout
          blocks={blocks}
          description={description}
          reverselayout={reverselayout}
          settings={settings}
          shipping_rates={shipping_rates}
          shipping_cost_id={shipping_cost_id} 
          logged_in_threshold={logged_in_threshold} 
          logged_out_threshold={logged_out_threshold}  
          free_price_bypass={free_price_bypass} 
          customer={customer}
        />
      </div>
    </div>
  );
};

export default ExtendedTextWithImage;
