import React, { useState } from "react";
import Paragraph from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Paragraph/Paragraph";
import ExtendedButton from "@src/stories/elements/ExtendedButton/ExtendedButton";
import { Headings } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Headings/Headings";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { mobileBreakpoint } from "../../../../../entrypoints/theme";
import { clsx } from "clsx";
import ExtendedImageComponent from "../../../elements/ExtendedImage/ExtendedImage";

const TwoColumnLayout = ({ reverselayout, description, settings, blocks }) => {
  const isMobile = useMobileBreakpoint(mobileBreakpoint);

  let image = isMobile
    ? settings.image_mobile?.src ||
      settings.image_mobile ||
      settings.image?.src ||
      settings.image
    : settings.image?.src || settings.image;

  let video = isMobile
    ? (settings.video_mobile?.sources?.[0]?.url ??
      settings.video_mobile ??
      settings.video?.sources?.[0]?.url ??
      settings.video)
    : (settings.video?.sources?.[0]?.url ?? settings.video);

  let videoPoster = settings.video?.preview_image.src;
  let heading = settings.title;
  let type = "twi-twocolumn";
  const imageWidth = isMobile ? settings.width_mobile : settings.width;

  if (settings) {
    if (settings.content) {
      description = settings.content;
    }
    if (settings.reverse_layout) {
      reverselayout = settings.reverse_layout;
    }

    if (settings.classes) {
      type = settings.classes;
    }
  }

  if (settings.render_type !== "all") {
    if (isMobile && settings.render_type !== "mobile-only") return null;
    if (!isMobile && settings.render_type !== "desktop-only") return null;
  }

  return (
    <section className={`w-full ${type}`}>
      <div
        className={`twi-twocolumn__container lg:h-[${settings.container_height}px] overflow-hidden relative`}
        style={{
          backgroundImage: `url(${settings.background_image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}>
        <div
          className={`twi-twocolumn__content gap-x-[20px] 
          ${settings.padding_area == 'inside' && 
          ` pt-[${ settings.padding_top_mobile }px]` +
          ` lg:pt-[${ settings.padding_top_desktop }px]` +
          ` pb-[${ settings.padding_bottom_mobile }px]` +
          ` lg:pb-[${ settings.padding_bottom_desktop }px]` +
          ` px-[${ settings.padding_left_right_mobile }px]` +
          ` lg:px-[${ settings.padding_left_right_desktop }px]`} 
          ${clsx({
            "flex-col": isMobile,
            "flex-col-reverse": isMobile && settings.reverse_layout_mobile,
            "flex-row-reverse": reverselayout && settings.reverse_layout,
          })}`}
        >
          <div
            className="twi-container"
            style={{
              flexBasis: `calc(${100 - parseFloat(imageWidth)}%)`,
            }}
          >
            <div
              className={`twi-content h-full justify-${settings.content_position_desktop}`}
            >
              <div
                className={`w-full flex flex-col items-${settings.content_alignment_mobile} lg:items-${settings.content_alignment_desktop}`}
              >
                <Paragraph text={settings.tagline} type={"twi-twocolumn__tagline bc"} />
                <Headings text={heading} type={settings.heading_type} />
                <Paragraph text={description} type={"twi-twocolumn__description b3 py-[16px]"} />

                <div
                  className={`buttons-container flex flex-col lg:flex-row w-full mb-12 lg:mb-4 gap-y-[16px] gap-x-[8px] self-${settings.content_alignment_mobile} lg:self-${settings.content_alignment_desktop}`}
                >
                  {blocks.map((block, index) => (
                    <div className={'flex gap-[24px] items-center'} key={block.key || index}>
                      {block.settings.button_text && (
                        <ExtendedButton
                          label={block.settings.button_text}
                          style={block.settings.button_style}
                          link={block.settings.button_url}
                          icon={block.settings.button_icon}
                          reverse={block.settings.button_reverse}
                          className={`twi-twocolumn__button`}
                          icon_size={20}
                          onClick={undefined}
                        />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div
            className="flex flex-col justify-center space-y-4 lg:basis-1/2"
            style={{ flexBasis: imageWidth + "%" }}
          >
            <div className={`flex h-full w-full`}>
              {image ? (
                <ExtendedImageComponent
                  imageUrl={image}
                  classes={"object-cover h-full w-full"}
                />
              ) : video ? (
                <video
                  className={"object-cover h-full w-full"}
                  autoPlay
                  loop
                  muted
                  poster={videoPoster}
                  preload="metadata"
                  playsInline
                >
                  <source src={video} type="video/mp4"></source>
                </video>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TwoColumnLayout;
