import React from "react";
import { clsx } from 'clsx';

import { Paragraph } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Paragraph/Paragraph";

import ExtendedButton from "@src/stories/elements/ExtendedButton/ExtendedButton";
import ExtendedHeading from "../ExtendedHeading/ExtendedHeading";
import ExtendedImageComponent from "../../elements/ExtendedImage/ExtendedImage";

import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { mobileBreakpoint } from "../../../../entrypoints/theme";

const TextOverlayedLayout = ({ data, title, tagline, content, titleFontType, contentFontType, hasTextContent }) => {
  const isMobile = useMobileBreakpoint(mobileBreakpoint);

  let imageUrl = data.settings.image?.src ?? data.settings.image;
  if (isMobile) {
    imageUrl = data.settings.image_mobile ? data.settings.image_mobile : data.settings.image?.src ?? data.settings.image;
  }

  return (
    <div className={clsx([
      `tileitem color-${data.color_schema}`,
      `px-[${data.settings.padding_left_right_mobile}px]`, 
      `lg:px-[${data.settings.padding_left_right_desktop}px]`, 
      `pt-[${data.settings.padding_top_mobile}px]`, 
      `lg:pt-[${data.settings.padding_top_desktop}px]`, 
      `pb-[${data.settings.padding_bottom_mobile}px]`, 
      `lg:pb-[${data.settings.padding_bottom_desktop}px]`,
      `gap-y-[${data.settings.image_gap_mobile}px]`,
      `lg:gap-y-[${data.settings.image_gap}px]`,
    ])}>
      {data.settings.image &&
        <div className={`image-container relative`}>
          <ExtendedImageComponent
            imageUrl={imageUrl}
            alt={title}
            classes={`object-cover h-full ${data.settings.image_width || 'w-full'}`}
          />
        </div>
      }

      {hasTextContent &&
        <div className={clsx([
            `block-title`,
            `tile__info ${data.settings.image ? 'flex absolute w-full z-[5]' : ''}`,
            `text-${data.settings.content_alignment_mobile}`,
            `lg:text-${data.settings.content_alignment_desktop}`
        ])}>
          <Paragraph text={tagline} type={'bc'} />

          <ExtendedHeading
            text={title}
            type={titleFontType || 'h3'}
            className={titleFontType || 'h3'}
          />

          <Paragraph
            text={content}
            type={`${contentFontType} mt-[${data.settings.content_gap}px]`}
          />

          {data.settings.show_button && (
            <ExtendedButton
              style={data.settings.button_style}
              label={data.settings.button_text}
              link={data.settings.make_tile_clickable ? '' : data.settings.button_url}
            />
          )}
        </div>
      }
    </div>
  )
}

const TextContent = ({ data, title, tagline, content, titleFontType, contentFontType }) => (
  <div className={`tile__info ${data.settings.image ? 'flex w-full z-[5]' : ''} block-title text-${data.settings.content_alignment_mobile} lg:text-${data.settings.content_alignment_desktop}`}>
    {tagline && <Paragraph text={tagline} type={'bc'} />}

    {title &&
      <ExtendedHeading
        text={title}
        type={titleFontType || 'h3'}
        className={titleFontType || 'h3'}
      />
    }

    {content &&
      <Paragraph
        text={content}
        type={`${contentFontType} mt-[${data.settings.content_gap}px]`}
      />
    }

    {data.settings.show_button && (
      <ExtendedButton
        style={data.settings.button_style}
        label={data.settings.button_text}
        link={data.settings.make_tile_clickable ? '' : data.settings.button_url}
      />
    )}
  </div>
)

const TextUnderneathLayout = (props) => {
  const { data, height, hasTextContent } = props;
  const isMobile = useMobileBreakpoint(mobileBreakpoint);
  const maxWidth = isMobile
    ? data?.settings?.image_max_width_mobile
    : data?.settings?.image_max_width;

  let imageUrl = data.settings.image?.src ?? data.settings.image;

  if (isMobile) {
    imageUrl = data.settings.image_mobile
      ? data.settings.image_mobile
      : imageUrl;
  }

  const tileContent = (
    <div className={clsx([
      `tileitem color-${data.color_schema}`,
      `px-[${data.settings.padding_left_right_mobile}px]`,
      `lg:px-[${data.settings.padding_left_right_desktop}px]`,
      `pt-[${data.settings.padding_top_mobile}px]`,
      `lg:pt-[${data.settings.padding_top_desktop}px]`,
      `pb-[${data.settings.padding_bottom_mobile}px]`,
      `lg:pb-[${data.settings.padding_bottom_desktop}px]`,
      `gap-y-[${data.settings.image_gap_mobile}px]`,
      `lg:gap-y-[${data.settings.image_gap}px]`,
    ])}>
      {data.settings.image && <div className={`image-container relative`}>
        <ExtendedImageComponent
          imageUrl={imageUrl}
          alt={data.settings.title}
          classes={`object-cover h-[${height}] ${data.settings.image_width || 'w-full'}`}
          style={{
            mixBlendMode: data?.settings?.image_blend_mode || '',
            maxWidth: maxWidth > 0 ? maxWidth : '100%',
          }}
        />
      </div>}

      {hasTextContent &&
        <>
          {data.settings.make_tile_clickable
            ? <TextContent {...props} />

            : <a href={data.settings.button_url}>
                <TextContent {...props} />
              </a>
          }
        </>
      }
    </div>
  );

  return (
    <>
      {data.settings.make_tile_clickable
        ? <a href={data.settings.button_url}>
            {tileContent}
          </a>

        : tileContent
      }
    </>
  );
}

const Layouts = {
  'default': TextOverlayedLayout,
  'stacked': TextUnderneathLayout,
};

export default function ExtendedColumn({ data, layout = 'stacked', height }) {
  const isMobile = useMobileBreakpoint(mobileBreakpoint);
  const LayoutComponent = Layouts[layout];

  const { tagline, title, content, tagline_mobile, title_mobile, content_mobile } = data.settings;

  const taglineProp = isMobile ? tagline_mobile : tagline;
  const titleProp = isMobile ? title_mobile : title;
  const contentProp = isMobile ? content_mobile : content;

  const titleFontTypeProp = isMobile
    ? data.settings.title_font_type_mobile
    : data.settings.title_font_type;

  const contentFontTypeProp = isMobile
    ? data.settings.content_font_type_mobile
    : data.settings.content_font_type;

  const hasTextContent = isMobile
    ? !!(tagline_mobile || title_mobile || content_mobile)
    : !!(tagline || title || content);

  return (
    <LayoutComponent
      data={data}
      title={titleProp}
      tagline={taglineProp}
      content={contentProp}
      titleFontType={titleFontTypeProp}
      contentFontType={contentFontTypeProp}
      height={height}
      hasTextContent={hasTextContent}
    />
  );
};