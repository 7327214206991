import React, { useState } from "react";

const WishlistManager = ({ settings, onClose }) => {
  const [currentState, setCurrentState] = useState("list"); // Possible states: 'list', 'delete', 'edit', 'add'
  const [selectedList, setSelectedList] = useState(null);
  const [newWishlistName, setNewWishlistName] = useState("");

  // Mock wishlist data
  const wishlists = [
    { id: 1, name: "For Customers" },
    { id: 2, name: "For Family" },
    { id: 3, name: "Recipes" },
  ];

  // Handlers for changing state
  const handleDeleteClick = (list) => {
    setSelectedList(list);
    setCurrentState("delete");
  };

  const handleEditClick = (list) => {
    setSelectedList(list);
    setCurrentState("edit");
  };

  const handleAddClick = () => {
    setSelectedList(null);
    setNewWishlistName("");
    setCurrentState("add");
  };

  const handleConfirm = () => {
    // Handle confirm based on the current state
    if (currentState === "delete") {
      console.log(`Deleting wishlist: ${selectedList.name}`);
    } else if (currentState === "edit") {
      console.log(`Editing wishlist: ${selectedList.name}`);
    } else if (currentState === "add") {
      console.log(`Adding new wishlist: ${newWishlistName}`);
    }

    // Reset to default state
    setCurrentState("list");
    onClose(); // Close the modal after confirm
  };

  return (
    <div className="wishlist-manager-modal fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="wishlist-manager bg-white p-6 rounded-lg shadow-lg relative">
        {currentState === "list" && (
          <div className="wishlist-manager__list">
            <h5 className="wishlist-manager__title flex items-start">
              Update Wishlist
            </h5>
            <ul className="wishlist-manager__items">
              {wishlists.map((list) => (
                <li key={list.id} className="wishlist-manager__item">
                  <span>{list.name}</span>
                  <div className="wishlist-manager__actions">
                    <div
                      className="wishlist-manager__edit"
                      onClick={() => handleEditClick(list)}
                    >
                      <i className="icon icon-edit"></i>
                    </div>
                    <div
                      className="wishlist-manager__delete"
                      onClick={() => handleDeleteClick(list)}
                    >
                      <i className="icon icon-trash"></i>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            <div className="wishlist-manager__actions flex flex-row">
              <button
                className="wishlist-manager__add-btn button button-tertiary basis-1/2"
                onClick={handleAddClick}
              >
                + Add new List
              </button>
              <button
                className="wishlist-manager__confirm-btn button button-primary basis-1/2"
                onClick={onClose}
              >
                Confirm
              </button>
            </div>
          </div>
        )}

        {currentState === "delete" && selectedList && (
          <div className="wishlist-manager__delete-confirm">
            <h2 className="wishlist-manager__title">Delete Wishlist</h2>
            <p className="wishlist-manager__message">
              Are you sure to delete "{selectedList.name}" wishlist?
            </p>
            <div className="wishlist-manager__actions">
              <button
                className="wishlist-manager__confirm-btn button button-primary basis-full"
                onClick={handleConfirm}
              >
                Confirm
              </button>
            </div>
          </div>
        )}

        {currentState === "edit" && selectedList && (
          <div className="wishlist-manager__edit-form">
            <h5 className="wishlist-manager__title flex items-start">
              Edit Wishlist Name
            </h5>
            <input
              type="text"
              className="wishlist-manager__input"
              value={selectedList.name}
              onChange={(e) =>
                setSelectedList({ ...selectedList, name: e.target.value })
              }
            />
            <div className="wishlist-manager__actions">
              <button
                className="wishlist-manager__confirm-btn button button-primary basis-full"
                onClick={handleConfirm}
              >
                Confirm
              </button>
            </div>
          </div>
        )}

        {currentState === "add" && (
          <div className="wishlist-manager__add-form">
            <h5 className="wishlist-manager__title flex items-start">
              Add New List
            </h5>
            <input
              type="text"
              className="wishlist-manager__input"
              placeholder="Wishlist Name"
              value={newWishlistName}
              onChange={(e) => setNewWishlistName(e.target.value)}
            />
            <div className="wishlist-manager__actions">
              <button
                className="wishlist-manager__confirm-btn button button-primary basis-full"
                onClick={handleConfirm}
              >
                Confirm
              </button>
            </div>
          </div>
        )}

        {/* Close Button for Modal */}
        <div
          className="wishlist-manager__close-btn absolute top-4 right-4 text-2xl cursor-pointer"
          onClick={onClose}
        >
          <i className="icon icon-close"></i>
        </div>
      </div>
    </div>
  );
};

export default WishlistManager;
