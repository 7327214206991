import * as React from "react";

export const Headings = ({ text = '', type = 'h1', classes = ''}) => {
    const CustomTag = type as keyof JSX.IntrinsicElements;

    return (
        <CustomTag className={`text__heading ${type} ${classes}`}>
            {text}
        </CustomTag>
    );
};

export default Headings;