import React, { useState } from "react";
import ExtendedSelectField from "@src/stories/elements/ExtendedSelectField/ExtendedSelectField";
import { ExtendedCollectionMobileFilterTakeover } from "./ExtendedCollectionMobileFilterTakeover";

const options = [
    { value: 'products', label: 'Products' },
    { value: 'recipes', label: 'Recipes' },
    { value: 'articles', label: 'Blogs' }
];

export default function ExtendedCollectionMobileFilter ({filters, color_schema}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const onFocus = () => {
    setIsModalOpen(true);
  }

  const onClose = () => {
    setIsModalOpen(false);
  }

  return (
    <div className="main-collection__mobile-sidebar">
        <div className="main-collection__mobile-sidebar-select">
          <ExtendedSelectField
              options={options}
              takeover
              onFocus={onFocus}
              placeholder="Filters"
          />
        </div>
        <div className="main-collection__mobile-sidebar-filters">
          <ExtendedCollectionMobileFilterTakeover modal_id={'mobile-filter-takeover'} filters={filters} isOpen={isModalOpen}  onClose={onClose} color_schema={color_schema} />
        </div>
    </div>
  );
};
