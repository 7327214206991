import React from "react";
import { clsx } from 'clsx';

import ExtendedBreadcrumbs from "../../../components/Breadcrumbs/ExtendedBreadcrumbs";
import Paragraph from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Paragraph/Paragraph";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { mobileBreakpoint } from "../../../../../entrypoints/theme";
import ActionBar from "@stories/sections/ExtendedCollectionBanner/ActionBar";

const StackedLayout = ({ blocks, settings, customMetafield }) => {
  const isMobile = useMobileBreakpoint(mobileBreakpoint);
  const { breadcrumbs_only } = settings.section;
  const filteredBlocks = breadcrumbs_only ? blocks.filter(item => item.type === 'breadcrumbs'): blocks;
  const {recipe_listing_banner_image, recipe_listing_banner_description } = customMetafield;
  return (
    <div className="flex flex-col text-center h-full"
         style={{
           backgroundImage: `url(${settings.section.text_content_background_image})`,
           backgroundSize: "cover",
           backgroundPosition: "center",
         }}
    >
      {filteredBlocks.map((block, index) => {
        switch (block.type) {
          case "description":
            return (
              <div
                key={index}
                className={`${block.settings.text_style} text-${block.settings.content_alignment_mobile} lg:text-${block.settings.content_alignment_desktop} pt-[${block.settings.padding_top_mobile}px] lg:pt-[${block.settings.padding_top_desktop}px] pb-[${block.settings.padding_bottom_mobile}px] lg:pb-[${block.settings.padding_bottom_desktop}px] px-[${block.settings.padding_left_right_mobile}px] lg:px-[${block.settings.padding_left_right_desktop}px]`}
              >
                <Paragraph text={recipe_listing_banner_description? recipe_listing_banner_description: block.settings.text} type={`b2`} />
              </div>
            );
          case "title":
            return (
              <div
                key={index}
                className={`text-${block.settings.content_alignment_mobile} lg:text-${block.settings.content_alignment_desktop} pt-[${block.settings.padding_top_mobile}px] lg:pt-[${block.settings.padding_top_desktop}px] pb-[${block.settings.padding_bottom_mobile}px] lg:pb-[${block.settings.padding_bottom_desktop}px] px-[${block.settings.padding_left_right_mobile}px] lg:px-[${block.settings.padding_left_right_desktop}px]`}
              >
                <h1
                  className={`hidden lg:block ${block.settings.heading_type}`}
                >
                  {block.settings.title}
                </h1>
                <h1
                  className={`lg:hidden block ${block.settings.heading_type_mobile}`}
                >
                  {block.settings.title}
                </h1>
              </div>
            );
          case "tagline":
            return (
              <div
                key={index}
                className={`text-${block.settings.content_alignment_mobile} lg:text-${block.settings.content_alignment_desktop} pt-[${block.settings.padding_top_mobile}px] lg:pt-[${block.settings.padding_top_desktop}px] pb-[${block.settings.padding_bottom_mobile}px] lg:pb-[${block.settings.padding_bottom_desktop}px] px-[${block.settings.padding_left_right_mobile}px] lg:px-[${block.settings.padding_left_right_desktop}px]`}
              >
                <h1
                  className={`hidden lg:block ${block.settings.heading_type}`}
                >
                  {block.settings.text}
                </h1>
                <h1
                  className={`lg:hidden block ${block.settings.heading_type_mobile}`}
                >
                  {block.settings.text}
                </h1>
              </div>
            );
          case "sociallinks":
            return (
              <div
                key={index}
                className={clsx([
                  `pt-[${block.settings.padding_top_mobile}px]`,
                  `lg:pt-[${block.settings.padding_top_desktop}px]`,
                  `pb-[${block.settings.padding_bottom_mobile}px]`,
                  `lg:pb-[${block.settings.padding_bottom_desktop}px]`,
                  `px-[${block.settings.padding_left_right_mobile}px]`,
                  `lg:px-[${block.settings.padding_left_right_desktop}px]`,
                  {
                    'lg:hidden': block.settings.render_type === 'mobile-only',
                    'max-lg:hidden': block.settings.render_type === 'desktop-only',
                  }
                ])}
              >
                <ActionBar label={block.settings.action_text} altLinks={{
                  x_url: block.settings.x_url,
                  facebook_url: block.settings.facebook_url,
                  instagram_url: block.settings.instagram_url,
                  linkedin_url: block.settings.linkedin_url,
                }}/>
              </div>
            );
          case "image":
            const imageUrl = isMobile
              ? (block.settings.image_mobile ? block.settings.image_mobile : block.settings.image )
              : block.settings.image;
            const imageSrc = recipe_listing_banner_image ? recipe_listing_banner_image : imageUrl;
            return (
              <div
                key={index}
                className={`w-full pt-[${block.settings.padding_top_mobile}px] lg:pt-[${block.settings.padding_top_desktop}px] pb-[${block.settings.padding_bottom_mobile}px] lg:pb-[${block.settings.padding_bottom_desktop}px] px-[${block.settings.padding_left_right_mobile}px] lg:px-[${block.settings.padding_left_right_desktop}px]`}
              >
                <img className="w-full" src={imageSrc} alt="" />
              </div>
            );
          case "button":
            return (
              <div className={`flex flex-row gap-4 justify-${block.settings.content_alignment_mobile} lg:justify-${block.settings.content_alignment_desktop} pt-[${block.settings.padding_top_mobile}px] lg:pt-[${block.settings.padding_top_desktop}px] pb-[${block.settings.padding_bottom_mobile}px] lg:pb-[${block.settings.padding_bottom_desktop}px] px-[${block.settings.padding_left_right_mobile}px] lg:px-[${block.settings.padding_left_right_desktop}px]`}> 
               { block.settings.text && 
                <div
                  key={index}
                >
                  <a
                    href={`${block.settings.url}`}
                    className={`button button-${block.settings.button_type}`}
                  >
                    {block.settings.text}
                  </a>
                </div>}
                  { block.settings.text_2 && 
                  <div
                    key={index}
                    className={``}
                  >
                    <a
                      href={`${block.settings.url_2}`}
                      className={`button button-${block.settings.button_type_2}`}
                    >
                      {block.settings.text_2}
                    </a>
                  </div>}
                </div>
            );
          case "breadcrumbs":
            return (
              <div
                key={index}
                className={clsx([
                  `text-${block.settings.content_alignment_mobile}`,
                  `lg:text-${block.settings.content_alignment_desktop}`,
                  `pt-[${block.settings.padding_top_mobile}px]`,
                  `lg:pt-[${block.settings.padding_top_desktop}px]`,
                  `pb-[${block.settings.padding_bottom_mobile}px]`,
                  `lg:pb-[${block.settings.padding_bottom_desktop}px]`,
                  `px-[${block.settings.padding_left_right_mobile}px]`,
                  `lg:px-[${block.settings.padding_left_right_desktop}px]`,
                  {
                      'lg:hidden': block.settings.render_type === 'mobile-only',
                      'max-lg:hidden': block.settings.render_type === 'desktop-only',
                  }
                ])}
              >
                <ExtendedBreadcrumbs
                  templateName={"page"}
                  shop={settings.shop}
                  page={settings.page}
                  request={settings.request}
                  isRootHomepage={settings.is_root_homepage}
                />
              </div>
            );
            case "actions":
                return (
                    <div
                        key={index}
                        className={`text-${block.settings.content_alignment_mobile} lg:text-${block.settings.content_alignment_desktop} pt-[${block.settings.padding_top_mobile}px] lg:pt-[${block.settings.padding_top_desktop}px] pb-[${block.settings.padding_bottom_mobile}px] lg:pb-[${block.settings.padding_bottom_desktop}px] px-[${block.settings.padding_left_right_mobile}px] lg:px-[${block.settings.padding_left_right_desktop}px]`}
                    >
                        <div className={'actions__container flex items-center gap-x-[8px]'}>
                            <div className={'actions__container-action flex items-center gap-x-[8px]'}>
                                <i className={`icon icon-phone-call text-[20px]`}></i> <a href={`tel:${block.settings.phone}`}>Call Us</a>
                            </div>
                            <div className={'actions__container-action flex items-center gap-x-[8px]'}>
                                <i className={`icon icon-mail-send text-[20px]`}></i> <a href={`mailto:${block.settings.email}`}>Email</a>
                            </div>
                            <div className={'actions__container-action flex items-center gap-x-[8px]'}>
                                <i className={`icon icon-face text-[20px]`}></i> <a href={`${block.settings.chat}`}>Live Chat</a>
                            </div>

                        </div>
                    </div>
                );
          default:
            return null;
        }
      })}
    </div>
  );
};

export default StackedLayout;
