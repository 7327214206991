// @ts-nocheck
import React from "react";

import ExtendedImage from "@src/stories/components/ExtendedImage/ExtendedImage";

export default function ExtendedBlogTile ({ article, translations }) {
  const { title, featured_image, excerpt, read_time, url }  = article;

  return (
    <div className={`article-tile`}>
        <div className={`article-tile__image-container`}>
            <a href={`${url}`}>
                <ExtendedImage
                    imageUrl={featured_image}
                    altText={title}
                    className="article-tile__image"
                />
            </a>
        </div>

        <div className={`article-tile__content`}>
           
                <div className={`article-tile__tagline min-h-[21px]`}>
                  {   read_time &&  `${read_time} ${read_time > 1 ? 'mins' : 'min'} read` }  
                </div>
            
            
            <div className={`article-tile__title h6`}>
                <a href={`${url}`}>
                    {title}
                </a>
            </div>

            <div className={`article-tile__excerpt`}>
                {excerpt}
            </div>
        </div>

        <div className={`article-tile__read`}>
            <a href={`${url}`}> {translations.read_more} </a>
        </div>
    </div>
  );
};
