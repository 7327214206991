import React from "react";
import ExtendedImageComponent from "../../../elements/ExtendedImage/ExtendedImage";
import ExtendedShippingCalculator  from "@src/stories/components/ExtendedShippingCalculator/ExtendedShippingCalculator";
import Paragraph from '@src/stories/elements/Paragraph';

import './StackedLinkImages.scss'

/* Extension specifically for delivery page */

const DeliverySVG =  () => ( 
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M21 7C22.11 7 23 7.89 23 9V17H21C21 17.7956 20.6839 18.5587 20.1213 19.1213C19.5587 19.6839 18.7956 20 18 20C17.2044 20 16.4413 19.6839 15.8787 19.1213C15.3161 18.5587 15 17.7956 15 17H9C9 17.7956 8.68393 18.5587 8.12132 19.1213C7.55871 19.6839 6.79565 20 6 20C5.20435 20 4.44129 19.6839 3.87868 19.1213C3.31607 18.5587 3 17.7956 3 17H1V13C1 11.89 1.89 11 3 11L6 7H21ZM9 8.5H6.5L4.54 11H9V8.5ZM18 15.5C17.6022 15.5 17.2206 15.658 16.9393 15.9393C16.658 16.2206 16.5 16.6022 16.5 17C16.5 17.3978 16.658 17.7794 16.9393 18.0607C17.2206 18.342 17.6022 18.5 18 18.5C18.3978 18.5 18.7794 18.342 19.0607 18.0607C19.342 17.7794 19.5 17.3978 19.5 17C19.5 16.6022 19.342 16.2206 19.0607 15.9393C18.7794 15.658 18.3978 15.5 18 15.5ZM6 15.5C5.60217 15.5 5.22065 15.658 4.93934 15.9393C4.65804 16.2206 4.5 16.6022 4.5 17C4.5 17.3978 4.65804 17.7794 4.93934 18.0607C5.22065 18.342 5.60217 18.5 6 18.5C6.39783 18.5 6.77935 18.342 7.06066 18.0607C7.34196 17.7794 7.5 17.3978 7.5 17C7.5 16.6022 7.34196 16.2206 7.06066 15.9393C6.77935 15.658 6.39783 15.5 6 15.5Z" fill="black"/>
  </svg> 
)

const StackedLinkImages = ({ settings, blocks, shipping_rates, shipping_cost_id, logged_in_threshold, logged_out_threshold,  free_price_bypass , customer }) => {
  return (
    <section className={`w-full twi-stackedimages py-8 px-[14px] lg:py-16 lg:px-8`}>
      <div
        className={`twi-stackedimages__container lg:h-[${settings.container_height}px] overflow-hidden relative w-full flex flex-col lg:flex-row`}
        style={{
          backgroundImage: `url(${settings.background_image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}>

        <div className="flex lg:w-1/2 justify-center">
          <div>
            <ExtendedShippingCalculator settings={settings} shipping_rates={shipping_rates} shipping_cost_id={shipping_cost_id} logged_in_threshold={logged_in_threshold} logged_out_threshold={logged_out_threshold}  free_price_bypass={free_price_bypass} customer={customer}>
              <div className="flex flex-col shipping-calculator-delivery__header"> 
                <div className="flex flex-row gap-2 items-center"> 
                  <DeliverySVG />

                  <Paragraph
                      text={settings?.header || 'Delivery'}
                      type='b3 shipping-calculator-delivery__heading'
                  />
                </div>

                { settings.subtitle &&  
                  <Paragraph
                      text={settings.subtitle}
                      type='b3 shipping-calculator-delivery__subheading'
                  /> 
                }
              </div>
            </ExtendedShippingCalculator>
          </div>
        </div>   
        
        <div
          className={`twi-stackedimages_content gap-x-[20px] lg:w-1/2`}
        >
       

        {blocks.map((block, index) => (
        <div className={`twi-stackedimages_content-block flex flex-row gap-[24px] items-center lg:max-h-[181px] ${index == 0 ? 'first' : '' }`} key={block.settings.key || index}>
            <div className="flex flex-col twi-stackedimages_content-text">  
                <div className={'twi-stackedimages_content-header h5'}> 
                    { block.settings.header }
                </div>
                <a href={`${block.settings.link}`} className="h-full w-full my-6 flex lg:hidden "> 
                    <ExtendedImageComponent imageUrl={block.settings.image} classes={"object-cover h-full w-full"}/>
                </a>
    
                <div className={'twi-stackedimages_content-subheader'}> 
                    { block.settings.subheader }
                </div>
                <div className={'twi-stackedimages_content-link mb-3 lg:mb-0'}> 
                    <a className={`underline`} href={`${block.settings.link}`}> {block.settings.link_label} </a>
                </div>
            </div> 

            <a href={`${block.settings.link}`} className="twi-stackedimages_content-image h-full  hidden lg:flex"> 
                <ExtendedImageComponent imageUrl={block.settings.image} classes={"object-cover h-full w-full"}/>
            </a>
        </div>
        ))}
        </div>
      </div>
    </section>
  );
};

export default StackedLinkImages;
