import React, { useState, useEffect } from "react";

export const WishlistActions = ({ settings }) => {
  const { section, blocks } = settings;
  return (
    <div className="wishlist__actions">
      <button className={`button button-primary`}>Add all to cart</button>
      <button className={`button button-text`}>Sort by</button>
    </div>
  );
};
