import React, { useEffect, useState } from "react";

import { Headings } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Headings/Headings";
import { Paragraph } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Paragraph/Paragraph";
import ExtendedTextField from "@src/stories/elements/ExtendedTextField/ExtendedTextField";
import ExtendedButton from "@src/stories/elements/ExtendedButton/ExtendedButton";

export const ExtendedNotifyModal = ({ modal_id, isOpen, onClose, product_id, product_name }) => {
    if (!isOpen) return null;
    const [email, setEmail] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    
    };
    const handleSubmit = () => {
        setIsSuccess(true);
        window.braze?.getUser().setEmail(email); 
        console.log( 'brazeInput' , {"product_id":`${product_id}`, "product_name":`${product_name}`});
        window.braze?.logCustomEvent("cart_product_added", {"product_id":`${product_id}`, "product_name":`${product_name}`});
        onClose();
    };

    return (
        <div id={`${modal_id}`} className={`fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-90 overflow-y-auto w-full h-full z-50`} style={{backdropFilter: "blur(6px)"}}>
            <div
                className={`border shadow-lg rounded-md bg-white $ p-5  w-[300px] lg:w-[1000px]`}>
                <div className="flex justify-between items-center py-4">
                    <Headings text={'Enter Email'} type={'h5'}/>
                    <button className="p-0 h-6 w-6 text-[24px] border-none" onClick={onClose}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M16.192 6.34375L11.949 10.5858L7.70697 6.34375L6.29297 7.75775L10.535 11.9998L6.29297 16.2418L7.70697 17.6558L11.949 13.4137L16.192 17.6558L17.606 16.2418L13.364 11.9998L17.606 7.75775L16.192 6.34375Z" fill="black"/>
                        </svg>
                    </button>
                </div>


                <div className="flex flex-col justify-between gap-2">
                    <ExtendedTextField type={'default-input'} id={'email'}  placeholder={'Enter your email here..'} value={email} onChange={handleEmailChange}/>
                    <ExtendedButton
                        style="primary"
                        label={ isSuccess ? 'Submitted' : 'Confirm'}
                        onClick={() => {  handleSubmit() }}
                    />
                </div>
            </div>
        </div>
    )
}

ExtendedNotifyModal.propTypes = {};
