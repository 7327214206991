import * as React from "react";

export default function ExtendedImage ({ imageUrl = '', altText = '', className = '' }) {
  return (
    <img
      src={imageUrl}
      alt={altText}
      className={className}
      width="auto"
      height="auto"
      loading="lazy"
    />
  );
}
