import React, { useState, useEffect } from "react";
import { clsx } from 'clsx';

import './ExtendedCheckbox.scss';

const SvgCheckMark = ({square}) => (
  square ? 
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<rect x="0" y="0.5" width="18" height="18" rx="2.5" fill="black"/>
<rect x="0" y="0.5" width="18" height="18" rx="2.5" stroke="black"/>
<path d="M15.5309 5.37583L15.5296 5.37701L7.88778 12.1137L4.47078 9.10138C4.47071 9.10132 4.47064 9.10125 4.47056 9.10119C4.37155 9.01329 4.23682 8.96384 4.09629 8.96384C3.95572 8.96384 3.82098 9.0133 3.72196 9.10124C3.72191 9.10129 3.72186 9.10133 3.7218 9.10138L3.15601 9.60015C3.15595 9.60021 3.15587 9.60028 3.1558 9.60034C3.05608 9.68763 3 9.80639 3 9.93028C3 10.0542 3.05611 10.173 3.15587 10.2603C3.15592 10.2603 3.15596 10.2604 3.15601 10.2604L7.51733 14.1051C7.51741 14.1052 7.5175 14.1052 7.51758 14.1053C7.61659 14.1932 7.75131 14.2427 7.89183 14.2427C8.03235 14.2427 8.16705 14.1932 8.26606 14.1053C8.26614 14.1052 8.26623 14.1052 8.26631 14.1051L16.844 6.54348C16.844 6.54345 16.8441 6.54342 16.8441 6.54341C16.9439 6.4561 17 6.3373 17 6.21335C17 6.08957 16.944 5.97091 16.8445 5.88364C16.8443 5.8835 16.8442 5.88336 16.844 5.88322L16.2785 5.37754V5.37753L16.2765 5.37583C16.0687 5.19829 15.7386 5.19829 15.5309 5.37583Z" fill="white" stroke="white" stroke-width="0.25"/>
</svg> :   
  <svg
    viewBox="0 0 24 24"
    width="18"
    height="18"
    stroke="white"
    strokeWidth="3"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <polyline points="20 6 9 17 4 12" />
  </svg>
);

type ExtendedCheckboxProps = {
  id?: string,
  label?: string,
  name?: string,
  value?: string | number,
  checked?: boolean,
  required?: boolean,
  disabled?: boolean,
  readonly?: boolean,
  hasError?: boolean,
  errorMessage?: string,
  className?: string,
  onChange?: Function,
  square?: boolean,
  checkCallback?: Function
}

export default function ExtendedCheckbox ({
  id,
  label,
  name,
  value,
  checked = false,
  required,
  disabled,
  readonly,
  hasError,
  errorMessage,
  className,
  square = false,
  onChange = () => {}
}: ExtendedCheckboxProps) {

  const [isChecked, setIsChecked] = useState(checked);

  function handleChange (value) {
    setIsChecked(!isChecked);
    if (onChange) onChange(value);
  }

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <label
      className={clsx('form-field__checkbox', className, {
        'is-checked': isChecked
      })}
    >
      <input
        type="checkbox"
        id={id}
        name={name}
        className="form-field__checkbox-input"
        defaultValue={value}
        checked={isChecked}
        onChange={(value) => handleChange(value)}
      />

      <div className={`form-field__checkbox-indicator ${square ? 'square': '' }`}>
        {isChecked && <SvgCheckMark square={square}/>}
      </div>

      <span className="form-field__checkbox-label">
        {label}
      </span>
    </label>
  );
};