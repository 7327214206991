import React, { useState, useEffect } from "react";
import useCreateCustomer from "@src/hooks/useCreateCustomer";
import useCustomerLogin from "@src/hooks/useCustomerLogin";
import {BusinessFields, InputField, CheckboxField} from "@stories/ecommerce/Account/AccountFoodClubFields";

const REQUIRED_FIELDS = ['first_name', 'last_name', 'email', 'confirmEmail', 'password', 'password_confirmation' ];
const EMPTY_KEYS = REQUIRED_FIELDS.reduce((acc, key) => {
    acc[key] = "";
    return acc;
  }, {});

const AccountRegisterForm = ({ settings, showBusinessFields, handleBusinessCheckbox }) => {
    const { createCustomer, loading, error, customerId, success } = useCreateCustomer();
    const [dependenciesState, setDependenciesState]: any = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [errorKeys, setErrorKeys] = useState(EMPTY_KEYS);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { loginCustomer, loading: loginLoading, error: loginError, success: loginSuccess } = useCustomerLogin();

    const customSignupFields = settings.signupfields.filter(item => item.show_on_register_form).sort((a, b) => {
        const orderA = a.register_form_order ? parseInt(a.register_form_order) : Infinity;
        const orderB = b.register_form_order ? parseInt(b.register_form_order) : Infinity;
        return orderA - orderB;
    });

    // Handle input change and update global dependency state
    const handleInputChange = (e) => {
        const { name, value, type: inputType, checked } = e.target;

        // For checkboxes, use the checked value, otherwise use the input value
        const newValue = inputType === "checkbox" ? checked : value;

    
        // Update the global dependency state
        updateDependencyState(name, newValue);
    };

    // Function to update the global dependencies state
    const updateDependencyState = (name, value) => {
        setDependenciesState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
       function validatePassword(password) {
        const regex = /^(?=.*[A-Z])(?=.*\d)(?=.*[~`!@#$%^&*()\-_\+=\[\]{}|\\;:"<>,./?])[A-Za-z\d~`!@#$%^&*()\-_\+=\[\]{}|\\;:"<>,./?]{8,}$/;
        return regex.test(password);
      }

    const handleValidation = () => {
        const detectMissingKeys = (dependenciesState) => {
            // Filter to find missing or empty fields
            const missingKeys = REQUIRED_FIELDS.filter((key) => {
              return !(key in dependenciesState) || dependenciesState[key] === "" ;
            });
          
            return missingKeys;
          };
          if(dependenciesState?.password) {
            if(!validatePassword(dependenciesState?.password)) {
                setErrorMessage("Password must be at least 8 characters long and include at least one uppercase letter, one number, and one special character.");
                return false;
            }
          }
          const missingKeys = detectMissingKeys(dependenciesState);
          console.log(dependenciesState);
          if (missingKeys.length > 0) {
            setErrorKeys((prevState) => {
             
                const updatedErrorKeys = missingKeys.reduce((acc, key) => {
                  acc[key] = "Please fill required field";
                  return acc;
                }, {});

                Object.keys(prevState).forEach((key) => {
                  if (!missingKeys.includes(key)) {
                    updatedErrorKeys[key] = "";
                  }
                });
              
                return { ...prevState, ...updatedErrorKeys };
              });
            setErrorMessage("Please fill required field(s)");
            return false;
          } else {
            setErrorKeys(EMPTY_KEYS)
            setErrorMessage("");
            console.log("All required fields are present.");
            return true;
          }
    }

    const handleClick = async (event) => {
        event.preventDefault();
        const success = await handleValidation();
        if (!success) return;
        setIsSubmitting(true);

        setDependenciesState((prevState) => ({
            ...prevState,
            ...(prevState.optInSMS === undefined && { optInSMS: false }),
            ...(prevState.optInEmail === undefined && { optInEmail: false }),
        }));
        const createResponse  = await createCustomer(dependenciesState);
        console.log('createResponse ', createResponse );
        console.log('success ', success );
        if (createResponse) {
            const email = dependenciesState.email;
            const password = dependenciesState.password;

            // Log the customer in after successful creation
            await loginCustomer(email, password);
        }

        setIsSubmitting(false);
    };


    return (
        <form id="registerForm" className="account-page__form space-y-6">
            <div className="account-page__input-group flex space-x-4">
                <InputField label="First Name" id="first_name" type="text" placeholder={`First Name`} required handleInputChange={handleInputChange}/>
                <InputField label="Last Name" id="last_name" type="text" placeholder={`Last Name`} required  handleInputChange={handleInputChange}/>
            </div>
            { (errorKeys['first_name'] != '' ||  errorKeys['last_name'] != ''  ) &&
            <div className='text-mb-red text-xs pl-3;'>
                {errorKeys['first_name'] || errorKeys['last_name']}
            </div>
            }
            <InputField label="Email" id="email" type="email" required placeholder={`Email`} handleInputChange={handleInputChange}/>
            { (errorKeys['email'] != '' ) &&
            <div className='text-mb-red text-xs pl-3;'>
                {errorKeys['email']}
            </div>
            }
            <InputField label="Confirm Email" id="confirmEmail" type="email" placeholder={`Confirm Email`} required  handleInputChange={handleInputChange} />
            { (errorKeys['confirmEmail'] != '' ) &&
            <div className='text-mb-red text-xs pl-3;'>
                {errorKeys['confirmEmail']}
            </div>
            }
            <InputField label="Phone Number" id="phone" type="tel" placeholder={`Phone (optional)`} handleInputChange={handleInputChange}/>
            { (errorKeys['phone'] != '' ) &&
            <div className='text-mb-red text-xs pl-3;'>
                {errorKeys['phone']}
            </div>
            }
            {/* Birthday Fields */}
            <BirthdayField />

            {/* Password Fields */}
            <InputField label="Password" id="password" type="password" placeholder={`Password`} required  handleInputChange={handleInputChange}/>
            { (errorKeys['password'] != '' ) &&
            <div className='text-mb-red text-xs pl-3;'>
                {errorKeys['password']}
            </div>
            }
            <InputField label="Confirm Password" id="password_confirmation" placeholder={`Confirm Password`} type="password" required  handleInputChange={handleInputChange}/>
            { (errorKeys['password_confirmation'] != '' ) &&
            <div className='text-mb-red text-xs pl-3;'>
                {errorKeys['password_confirmation']}
            </div>
            }
            {/* Checkboxes */}
            <div className="account-page__checkboxes flex space-x-4">
                <CheckboxField
                    label="Are you a business?"
                    id="isBusiness"
                    onChange={(e) => handleBusinessCheckbox(e.target.checked)}
                />
                <CheckboxField label="Opt in to receive SMS" id="optInSMS"  onChange={handleInputChange}/>
            </div>

            <div className="account-page__checkboxes flex space-x-4"> 
                <CheckboxField label="Opt in to receive Email" id="optInEmail"  onChange={handleInputChange}/>
            </div>

            {/* Business Fields */}
            {showBusinessFields && (
                <BusinessFields customSignupFields={customSignupFields} handleInputChange={handleInputChange} />
            )}

            {success && (
                <div
                    className="success-message my-[16px] py-[16px] bg-[#36573B] px-4"
                    role="alert"
                >
                    <p className="bc">Thank you for registering!</p>
                    <p className="b3">
                        You have been successfully registered. Redirecting
                    </p>
                </div>
            )}

            {error && (
                <div className="error-message my-[16px] py-[16px] bg-mb-red-light border-mb-red text-mb-black px-4" role="alert">
                    <p>{error}</p>
                </div>
            )}

            {errorMessage && (
                <div className="error-message my-[16px] py-[16px] bg-mb-red-light border-mb-red text-mb-black px-4" role="alert">
                    <p>{errorMessage}</p>
                </div>
            )}

            <div className={'flex flex-col'}>
                <div onClick={handleClick} className="account-page__submit-btn button button-primary w-full">
                    {isSubmitting || loading || loginLoading ? "Submitting..." : "Create Account"}
                </div>
            </div>

            <p className="account-page__login-link text-center">
                    Already have an account? <a x-on:click="$event.preventDefault(), handleFormToggle(true), isLogin = true"
                    className="account-page__login-btn underline cursor-pointer">Log In
                </a>
            </p>
        </form>
    );
};


const BirthdayField = () => {
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 100 }, (v, i) => currentYear - i);
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    return (
        <div className="account-page__input-group flex flex-col">
            <label htmlFor="birthdayDay" className="account-page__label">Birthday</label>
            <div className="account-page__input-container default-input flex space-x-4">
                <select id="birthdayDay" name="birthdayDay" className="account-page__input form-field">
                    <option value="DD">DD</option>
                    {[...Array(31).keys()].map(day => (
                        <option key={day + 1} value={day + 1}>{String(day + 1).padStart(2, '0')}</option>
                    ))}
                </select>

                <select id="birthdayMonth" name="birthdayMonth" className="account-page__input form-field">
                    <option value="MM">MM</option>
                    {months.map((month, idx) => (
                        <option key={idx} value={String(idx + 1).padStart(2, '0')}>{month}</option>
                    ))}
                </select>

                <select id="birthdayYear" name="birthdayYear" className="account-page__input form-field">
                    <option value="YYYY">YYYY</option>
                    {years.map(year => (
                        <option key={year} value={year}>{year}</option>
                    ))}
                </select>
            </div>
        </div>
    );
};


export default AccountRegisterForm;
