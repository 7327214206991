import React, { useState, useEffect } from 'react';
import {Headings} from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Headings/Headings";
import {Paragraph} from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Paragraph/Paragraph";
import {Button} from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Button/Button";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import {mobileBreakpoint} from "../../../../entrypoints/theme";

export const ExtendedBanner = ({ banners, useCarousel = true, section_settings }) => {
    const isMobile = useMobileBreakpoint(mobileBreakpoint);

    let showButtons = section_settings.show_buttons;
    if (isMobile){
        showButtons = section_settings.show_buttons_mobile
    }
    return (
        <div className={`banner h-[${section_settings.mobile_height}px] lg:h-screen`}>
            <div className={'banner-carousel h-full'}
                 {...(useCarousel && {
                     'x-data': "carousel",
                     'x-init': `$nextTick(() => { initialise(false, ${showButtons}, false) })`,
                     'x-ref': "carousel"
                 })}
            >

                {banners.map((blockItem, index) => {
                    let imageUrl = blockItem.settings.image?.src ?? blockItem.settings.image;
                    if (isMobile) {
                        imageUrl = blockItem.settings.mobile_image ? blockItem.settings.mobile_image : blockItem.settings.image?.src ?? blockItem.settings.image;
                    }

                    return (<div key={index}>
                        {blockItem.type === 'block_image' &&
                            <>
                                <div className={'banner_overlay'}>
                                    <div className={`banner_overlay_content text-center w-[${section_settings.mobile_content_width}px] lg:w-[${section_settings.content_width}px]`}>
                                        <Paragraph text={blockItem.settings.tagline} type={'bc'}/>
                                        <Headings text={blockItem.settings.title} type={'h2'}/>
                                        <Paragraph text={blockItem.settings.subtext} type={'b2'}/>
                                        <div className={'banner_overlay_cta'}>
                                            {blockItem.settings.button_text && <Button
                                                type={blockItem.settings.button_style}
                                                label={blockItem.settings.button_text}
                                                link={blockItem.settings.button_url}
                                                onClick={undefined}/>}
                                        </div>
                                    </div>
                                </div>
                                <div className="banner-image content-over-media w-screen h-screen">
                                    {blockItem.settings.button_url || blockItem.settings.button_text ? (
                                        <div
                                            className="h-full w-full absolute top-0 left-0"
                                            style={{
                                                backgroundImage: `url(${imageUrl})`,
                                                backgroundSize: "cover",
                                                backgroundPositionY: "center",
                                                backgroundPositionX: "center"
                                            }}
                                        >
                                        </div>
                                    ) : (
                                        <div
                                            className="h-full w-full absolute top-0 left-0 flex items-center justify-center"
                                            style={{
                                                backgroundImage: `url(${imageUrl})`,
                                                backgroundSize: "cover",
                                                backgroundPositionY: "center"
                                            }}
                                        />
                                    )}

                                </div>

                            </>
                        }
                        {blockItem.type === 'block_video' &&
                            <video autoPlay muted loop style={{width: '100%', height: '100%', objectFit: 'cover'}}>
                                <source src={blockItem.settings.video.sources[2].url} type="video/mp4"/>
                            </video>}
                    </div>)
                })}

            </div>

        </div>
    );
};

export default ExtendedBanner;