// @ts-nocheck
import React, { useState, useEffect } from "react";
import ProductTileImage from "@src/stories/components/ExtendedProductTile/components/ProductTileImage";
import {
  formatPrice
} from "frontend/js/helpers";
import { useCart } from "frontend/js/useShopifyCart";


type TileProps = {
  product: undefined,
  currentVariant?: undefined,
  addToCartUrl?:  undefined,
  starRatingsApp?: '',
  translations?: object,
  hideAddToCart?: boolean,
  hideWishlist?: boolean,
  hideTags?: boolean,
  hideCompare?: boolean,
  upgradeTile?: undefined,
  currencySymbol?: string,
  onClose?: undefined
};

type ProductTileAddToCartProps = {
  variant: any,
  addToCartUrl: string,
  translations: any,
  upgradeTile?: any,
  onClose?: Function
}

export function ProductTileAddToCart({ variant, addToCartUrl, translations, upgradeTile, onClose }: ProductTileAddToCartProps) {
  const { cart } = useCart();
  const [isLoading, setIsLoading] = useState(false);

  const buttonLabel = variant?.available
    ?  upgradeTile
      ? translations?.upgrade || 'Upgrade'
      : translations?.add_to_cart || 'Add to cart'

    : translations?.sold_out || 'Sold out';

  useEffect(() => {
    setIsLoading(false);
  }, [cart])

  const dispatchCustomEvent = (eventName, detail) => {
    window.dispatchEvent(new CustomEvent(eventName, { detail }));
  };

  return ( 
    <div className="add-to-cart-button">
      <button
        className={`button ${ upgradeTile ? 'button-secondary' : 'button-primary' }`}
        disabled={isLoading}
        // x-on:click={`$store.global.handleAddToCart('${variant.id}', ${upgradeTile > 0 ? upgradeTile : 1})`}
        onClick={() => { 
          dispatchCustomEvent("add-to-cart", { id: variant.id, quantity: upgradeTile > 0 ? upgradeTile : 1 });
          setIsLoading(true);
          onClose(); }}
      >
        {upgradeTile > 0 &&
          <span className="icon-up-arrow-alt text-[24px]"></span>
        }

        {isLoading ? 'Adding To Cart...' : buttonLabel}
      </button>
    </div>
  )
}

export default function ExtendedUpgradeModalProductTile (props: TileProps) {

  const {
    product,
    currentVariant = '',
    addToCartUrl = '',
    translations = {},
    hideAddToCart = false,
    upgradeTile = 0,
    currencySymbol = '',
    onClose = () => {}
  } = props;


  const { price = 0 } = product
  const currentPrice = formatPrice(price,  currencySymbol);

  return (
    <div className={`product-tile ${upgradeTile > 0 ? 'upgrade' : '' }`}>
      <div className="flex flex-col w-full"> 
         <a href={`/products/${product.handle}`} className="product-upgrade-modal__product-title b3 lg:hidden"> {product.title} </a> 
        
          <div className="flex flex-row w-full mt-2 lg:mt-0">
            <div className="product-tile__image-wrapper lg:w-auto">
              <a href={`/products/${product.handle}`}>
                <ProductTileImage
                  featuredImageUrl={product.featured_image}
                  altText={product.title}
                  images={product.images}
                />
              </a>
            </div>

            <div className="product-tile__details ml-[24px] flex flex-col lg:flex-row max-w-[126px] lg:max-w-none">
              <div className="product-tile__details__title-price">
                <a className="hidden lg:block" href={`/products/${product.handle}`}>
                  <div className="product-tile__details__title">
                    {product.title}
                  </div>
                </a>

                <span className="product-tile__details__price-value h5 text-[24px]">
                       {currentPrice}
                  </span>
              </div>
              { !hideAddToCart  &&
                  <ProductTileAddToCart
                  variant={currentVariant}
                  addToCartUrl={addToCartUrl}
                  translations={translations}
                  upgradeTile={upgradeTile}
                  onClose={onClose}
                />
              }
            </div>
          </div>
      </div>
    </div>
  );
};
