import { useEffect, useState } from "react";

import CompleteTheLook from "../src/stories/components/CompleteTheLook/CompleteTheLook";
import { CustomerAddresses } from "../src/stories/sections/CustomerAddresses/CustomerAddresses";
import ExtendedMobileAccountMenu from "@src/stories/components/ExtendedMobileAccountMenu/ExtendedMobileAccountMenu";
import ExtendedRecipeDetails from "@src/stories/sections/ExtendedRecipeDetails/ExtendedRecipeDetails";
import ExtendedRecipeListing from "@src/stories/sections/ExtendedRecipeListing/ExtendedRecipeListing";
import GiftFinder from "@src/stories/client/GiftFinder/GiftFinder";
import FoodClubSignup from "@src/stories/client/FoodClubSignup/FoodClubSignup";
import SignupPopup from "@src/stories/client/SignupPopup/SignupPopup";

import shopify from "../js/shopify";
import GoogleRating from "@src/stories/client/GoogleRating/GoogleRating";

export function CompleteTheLookComponent({ ...props }) {
  const [products, setProducts] = useState([]);
  const { product } = props.settings;

  useEffect(() => {
    async function fetchRelatedProducts() {
      const relatedProducts = await shopify.getRelatedProductsByTag(
        product,
        "related:",
      );
      setProducts(relatedProducts);
    }

    fetchRelatedProducts();
  }, [product]);

  // Only render CompleteTheLook if products are found
  if (products.length === 0) {
    return null;
  }

  return (
    <CompleteTheLook
      products={products}
      settings={props.settings.section}
      title={"Complete the Look"}
    />
  );
}

export function ExtendedMobileAccountMenuComponent({ ...props }) {
  return <ExtendedMobileAccountMenu settings={props.settings} />;
}

export function CustomerAddressesComponent({ ...props }) {
  return <CustomerAddresses data={props.settings} />;
}

export function RecipeDetailPageComponent({ ...props }) {
  return <ExtendedRecipeDetails recipe={props.settings} />;
}

export function RecipeListingPageComponent({ ...props }) {
  return <ExtendedRecipeListing settings={props.settings} />;
}

export function GiftFinderComponent({ ...props }) {
  return <GiftFinder settings={props.settings} />;
}

export function FoodClubSignupComponent({ ...props }) {
  return <FoodClubSignup settings={props.settings} />;
}
export function SignupPopupComponent({ ...props }) {
  return <SignupPopup settings={props.settings} />;
}

export function GoogleRatingComponent({ ...props }) {
  return <GoogleRating settings={props.settings} />;
}
