import React, { useState } from 'react';
import { clsx } from 'clsx';

type ExtendedTextareaFieldProps = {
  id?: string,
  name?: string,
  placeholder?: string,
  label?: string,
  value?: string | number,
  required?: boolean,
  disabled?: boolean,
  readonly?: boolean,
  hasError?: boolean,
  errorMessage?: string,
  onChange?: Function,
  maxCharacters?: number
}

export default function ExtendedTextareaField ({
  id,
  name,
  placeholder,
  label,
  value,
  required,
  disabled,
  readonly,
  hasError,
  errorMessage,
  onChange = () => {},
  maxCharacters = 0
}: ExtendedTextareaFieldProps) {
  const [text, setText] = useState(value);
  function handleChange (e) {
    const { value } = e.target;
    if (value.length <=  maxCharacters) {
      setText(value);
    }
    if (onChange) onChange();
  }

  return (
    <div>
      {label &&
        <label
          htmlFor={id}
          className="form-field__label"
        >
          {label}
        </label>
      }
      <textarea
        id={id}
        className={clsx('form-field', {
          'has-error': hasError,
          'disabled': disabled
        })}
        name={name}
        defaultValue={value}
        placeholder={placeholder}
        required={required}
        readOnly={readonly}
        disabled={disabled}
        maxLength={maxCharacters == 0 ?  null : maxCharacters}
        onChange={(e) => handleChange(e)}
      />

      {(hasError && errorMessage) &&
        <div className="form-field__error-message">
          {errorMessage}
        </div>
      }

      {maxCharacters != 0 && !disabled && 
        ( <div className="flex justify-end text-[12px] font-[400]">
          {`${text.length}/${maxCharacters}`}  
        </div> ) 
      }
    </div>
  );
};
