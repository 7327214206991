import React, { useState, useEffect } from "react";
import ExtendedImage from "@src/stories/components/ExtendedImage/ExtendedImage";
import ExtendedQuantityField from "@src/stories/elements/ExtendedQuantityField/ExtendedQuantityField";
import ExtendedButton from "@src/stories/elements/ExtendedButton/ExtendedButton";
import { shortCodeParser } from "frontend/js/helpers";
import ExtendedCollapsible from "@src/stories/components/ExtendedCollapsible/ExtendedCollapsible";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import parse from "html-react-parser";
import FoodClubSignup from "@src/stories/client/FoodClubSignup/FoodClubSignup";

const IngredientsContent = ({
  productAddList,
  ingredients,
  setQuantity,
  quantity,
  unitState,
  setUnitState,
  reqMedia,
  translations,
  baseQuantity,
  ingredients_toggle
}) => {
  let mediaIndexOffset = 0;
  const { media1, media2, title1, title2, qty1, qty2 } = reqMedia;
  const { parsedString: parsedString1 } = shortCodeParser(
    qty1,
    quantity,
    unitState,
    () => {},
    baseQuantity,
  );
  const { parsedString: parsedString2 } = shortCodeParser(
    qty2,
    quantity,
    unitState,
    () => {},
    baseQuantity,
  );
  const isMobile = useMobileBreakpoint("1024px");

  const dispatchCustomEvent = (eventName, detail) => {
    window.dispatchEvent(new CustomEvent(eventName, { detail }));
  };

  return (
    <>
      <div className="recipe-details-ingredients__container">
        <div className="recipe-details-ingredients__slider">
          <div className="hidden lg:block h5">
            {translations.servings}
          </div>
          <div className="text-[12px] flex items-center lg:hidden">

            {translations.units}
          </div>
          <div className="w-[194px] flex items-center">

            <ExtendedQuantityField
              initialValue={1}
              quantity={quantity}
              setQuantity={setQuantity}
            />
          </div>
        </div>
        <div className="recipe-details-ingredients__slider">
          <div className="h5 hidden lg:block"> {translations.ingredients} </div>
          <div className="text-[12px] flex items-center lg:hidden">

            {translations.units}
          </div>
          <div className="flex flex-row gap-6 w-[194px]">
            <ExtendedButton
              onClick={() => {
                setUnitState("metric");
              }}
              style={unitState == "metric" ? "primary" : "secondary"}
              label={"Metric"}
            />
            { ingredients_toggle == 'metric-cups' && 
            <ExtendedButton
              onClick={() => {
                setUnitState("imperial");
              }}
              style={unitState == "imperial" ? "primary" : "secondary"}
              label={"Cups"}
            />
            }
          </div>
        </div>
      </div>
      {(title1 || title2) && (
        <div className="recipe-details-ingredients__prereq-container">
          <div className="recipe-details-ingredients__prereq-title">
            {translations.base}
          </div>
          {title1 && (
            <div className="recipe-details-ingredients__prereq">
              <ExtendedImage
                imageUrl={media1}
                className="recipe-details-ingredients__prereq-image"
              />
              <div className="flex flex-col">
                <div className="text-[16px] font-[400]">
                  {translations.batch_label}
                </div>
                <div className="text-[16px] font-[500]">
                  {parsedString1} batch {title1}
                </div>
              </div>
            </div>
          )}
          {title2 && (
            <div className="recipe-details-ingredients__prereq">
              <ExtendedImage
                imageUrl={media2}
                className="recipe-details-ingredients__prereq-image"
              />
              <div className="flex flex-col">
                <div className="text-[16px] font-[400]">
                  {translations.batch_label}
                </div>
                <div className="text-[16px] font-[500]">
                  {parsedString2} batch {title2}
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      <div className="recipe-details-ingredients__items">
        {ingredients.map((ingredient, index) => {
          const { parsedString, isContent, tag, isProduct, productId } =
            shortCodeParser(
              ingredient,
              quantity,
              unitState,
              productAddList,
              baseQuantity,
            );
          if (isContent) mediaIndexOffset = mediaIndexOffset + 1;
          const variantId = productAddList[productId]?.variantId
            .split("/")
            .pop();

          return (
            <div className="flex flex-row justify-between">
              <div
                className={`recipe-details-ingredients__item ${tag == "[HEADING]" ? "heading" : ""} ${index == 0 ? "first" : ""} `}
              >
                {parse(parsedString)}
              </div>

              {isProduct && !isMobile && (
                <button
                  className={`recipe-details-ingredients__items-button ${tag == "[HEADING]" ? "heading" : ""} ${index == 0 ? "first" : ""}  `}
                  onClick={() => { 
                    dispatchCustomEvent("add-to-cart", { id: variantId, quantity: 1 })}}
                >
                  {translations.quick_add}
                  <span className="recipe-details-ingredients__items-button-icon icon icon-plus text-[24px]">
                    {" "}
                  </span>
                </button>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default function ExtendedRecipeDetailsIngredients({
  productAddList,
  ingredients,
  setQuantity,
  quantity,
  unitState,
  setUnitState,
  reqMedia,
  translations,
  baseQuantity,
  ingredients_toggle
}) {
  return (
    <div className="recipe-details-ingredients">
      <div className="flex flex-col lg:hidden">
        <ExtendedCollapsible
          key={"recipe-ingredients"}
          title={`${translations.ingredients}`}
          active
          openIcon="icon-chevron-down"
        >
          <IngredientsContent
            translations={translations}
            productAddList={productAddList}
            ingredients={ingredients}
            setQuantity={setQuantity}
            quantity={quantity}
            unitState={unitState}
            setUnitState={setUnitState}
            reqMedia={reqMedia}
            baseQuantity={baseQuantity}
            ingredients_toggle={ingredients_toggle}
          />
        </ExtendedCollapsible>
      </div>
      <div className="hidden lg:flex flex-col">
        <IngredientsContent
          translations={translations}
          productAddList={productAddList}
          ingredients={ingredients}
          setQuantity={setQuantity}
          quantity={quantity}
          unitState={unitState}
          setUnitState={setUnitState}
          reqMedia={reqMedia}
          baseQuantity={baseQuantity}
          ingredients_toggle={ingredients_toggle}
        />
      </div>
      <FoodClubSignup translations={translations} />
    </div>
  );
}
