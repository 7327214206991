import React, { useState, useEffect, useRef } from 'react';
import useMobileBreakpoint from '@src/hooks/useMobileBreakpoint';
import {mobileBreakpoint} from "../../../../entrypoints/theme";
import ExtendedButton from '@src/stories/elements/ExtendedButton/ExtendedButton';
import './CollectionList.scss'

const CollectionList = ({ settings }) => {
    const { section, blocks, section_id } = settings;
    const isMobile = useMobileBreakpoint(mobileBreakpoint);
    const [ sliderVisible, setSliderVisible ] = useState(true);
    const componentRef = useRef(null);

    function goToSlide(action) {
        const event = new CustomEvent(`carousel-navigate`, {
          detail: { section_id, action }
        });
    
        window.dispatchEvent(event);
      }
    
      const [carouselInitialized, setCarouselInitialized] = useState(false);
    
      useEffect(() => {
        setTimeout(() => {
         setCarouselInitialized(true); // This will trigger a re-render
        }, 250);
      }, []);

      useEffect(() => {
        const divWithClass = componentRef.current && componentRef.current.querySelector('.flickity-viewport');
        if (componentRef.current) {
          if (divWithClass) {
            setSliderVisible(true);
          } else {
            setSliderVisible(false);
          }
        }
      }, [componentRef.current, componentRef, carouselInitialized]);

    if (section.render_type !== 'all') {
        if (isMobile && section.render_type !== 'mobile-only') return null;
        if (!isMobile && section.render_type !== 'desktop-only') return null;
    }

    return (
        <div className={`color-${section.color_schema}`} ref={componentRef}>

            <div
                className={`collection-list section-collection-list-${section.id} flex ${section.reveal_animation_class} ${section.container_type} lg:justify-${section.content_position_desktop} pt-[${section.padding_top_mobile}px] lg:pt-[${section.padding_top_desktop}px] pb-[${section.padding_bottom_mobile}px] lg:pb-[${section.padding_bottom_desktop}px] px-[${section.padding_left_right_mobile}px] lg:px-[${section.padding_left_right_desktop}px]`}
            >
                <div className={`flex flex-col w-full lg:max-w-[${section.content_width}]`}>
                    <div className="collection-list__header"> 
                        <h3 className="text-center mb-8 h3">{section.section_title}</h3>
                        {sliderVisible && 
                            <div className='collection-list__header-button-container'>
                            <ExtendedButton
                                icon="chevron-left"
                                style='dark-primary'
                                className="collection-list__header-button-container-arrow prev"
                                onClick={() => goToSlide('previous')}
                            />
    
                            <ExtendedButton
                                icon="chevron-right"
                                style='dark-primary'
                                className="collection-list__header-button-container-arrow next"
                                onClick={() => goToSlide('next')}
                            />
                        </div>
                        }
                    </div>
                  
                    <div className="flex h-[428px] lg:h-[465px] ml-4 lg:ml-8"         
                        {
                            ...{
                                'x-data': "extended_carousel",
                                'x-init': `$nextTick(() => { initialise(false, false, false, false, false, "${section_id}") })`,
                                'x-ref': "carousel"
                            }
                        }
                    >
                        {blocks.map((block, index) => {
                            if (block.type === 'collection') {
                                const { collection, image, collection_title, collection_subtitle, collection_cta } = block.settings;
                                return (
                                    <div key={index} className="collection-item justify-between flex flex-col items-center text-center">
                                
                                        {image ? (
                                            <img src={image} alt={collection.title}/>
                                        ) : (
                                            <img src={collection.featured_image} alt={collection.title}/>
                                        )}
                                 
                                        <div className={'flex-auto collection-info w-[232px] lg:w-auto flex flex-col lg:flex-col'}>
                                            <div className={'flex-auto lg:basis-1/2 items-start text-left'}>
                                                <h3 className="collection-list__title mb-2">{collection_title}</h3>
                                                {isMobile && <p className="lg:basis-[40%] mb-[16px] pb-[16px] text-[14px] lg:text-[18px] lg:pb-0 lg:mb-0">{collection_subtitle}</p>}
                                            </div>
                                            <div className={'w-full flex justify-between lg:items-end lg:self-end'}>
                                                <div className={'flex flex-col lg:justify-between text-left lg:flex-row w-auto lg:w-full'}>
                                                    {!isMobile && <p className="lg:basis-[40%] mb-[16px] pb-[16px] lg:pb-0 lg:mb-0">{collection_subtitle}</p>}
                                                    <a href={`/collections/${collection}`}className={'button button-secondary'}>
                                                       {collection_cta == ''  ? `See our ${collection_title}` : `${collection_cta}` } 
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                            return null;
                        })}
                    </div>

                    <div className="collection-cta hidden lg:flex gap-x-[16px] items-center self-center pt-[40px]">
                        {blocks.map((block, index) => {
                            if (block.type === 'cta') {
                                const { button_text, button_url, button_style } = block.settings;
                                return (
                                    <div key={index} className="collection-cta-item">
                                        {button_text && button_url && (
                                            <a href={button_url} className={`button ${button_style}`}>
                                                {button_text}
                                            </a>
                                        )}
                                    </div>
                                );
                            }
                            return null;
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CollectionList;
