import { lockScroll } from '../../../js/helpers';

import { addToCart } from '../../useShopifyCart';

export default {
  name: 'global',
  store() {
    return {
      isMobileMenuVisible: false,
      isMinicartVisible: false,
      isMinicartDialogVisible: false,
      isPredictiveSearchVisible: false,
      isWindowScrolled: false,
      cart: null,
      init() {
        // console.log('Arctheme Global Store Initialized.')

        window.addEventListener('scroll', this.onWindowScrollHandler.bind(this))
        window.addEventListener('keydown', this.onKeyDownHandler.bind(this))
        window.addEventListener('mobile-menu-open', this.openMobileMenu)
        window.addEventListener('mobile-menu-closed', this.closeMobileMenu)
        window.addEventListener('toggle-search', this.toggleSearch.bind(this))
        window.addEventListener('add-to-cart', this.handleAddToCart.bind(this))
        window.addEventListener('wishlist', this.handleWishlist.bind(this))
        this.initLiquidAJaxCart()
      },
      get bodyClasses() {
        let classes = []

        if (this.isMobileMenuVisible) {
          classes.push('mobile-menu-visible')
        }
          if (this.isMinicartVisible) {
              // Remove comment when mini cart task is done/being done.
              // classes.push('minicart-open')
          }
          if (this.isPredictiveSearchVisible) {
              classes.push('search-open')
          }

        return classes || ''
      },
      openMobileMenu() {
        this.isMobileMenuVisible = true
          document.body.classList[this.isMobileMenuVisible ? 'add' : 'remove']('mobile-menu-visible')
      },
      closeMobileMenu() {
        this.isMobileMenuVisible = false
          document.body.classList[this.isMobileMenuVisible ? 'add' : 'remove']('mobile-menu-visible')
      },
      toggleMobileMenu() {
        this.isMobileMenuVisible = !this.isMobileMenuVisible
      },
      toggleSearch() {
        this.isPredictiveSearchVisible = !this.isPredictiveSearchVisible
        const headerSearchBar =  document.querySelector('.header-container');
        headerSearchBar.querySelector('.form-field').blur();
        document.body.classList[this.isPredictiveSearchVisible ? 'add' : 'remove']('no-scroll');
        setTimeout(function() {
          document.querySelector('#predictive-search-input').focus();
        }, 500);

      },
      toggleMinicartDialogOff() {
        this.isMinicartDialogVisible = false;
      },
      toggleMinicart() {
        this.isMinicartVisible = !this.isMinicartVisible
        lockScroll(this.isMinicartVisible);

        // Remove comment when mini cart task is done/being done.
        // document.body.classList[this.isMinicartVisible ? 'add' : 'remove']('no-scroll')
      },
      initLiquidAJaxCart() {
        document.addEventListener("liquid-ajax-cart:request-end", (event) => {
          const { requestState, cart, previousCart, sections } = event.detail;

          if (requestState.requestType === 'add') {
            if (requestState.responseData?.ok) {
              // Remove comment when mini cart task is done/being done.
              //this.isMinicartVisible = true
              //document.body.classList.add('no-scroll');
            }
          }

          this.cart = cart
        });
      },
      async handleAddToCart(event) {
        console.log(event.detail, 'Add Item to Cart - Global Function');
        const { detail: { title , price , type , ids, id, delivery_date, delivery_date_end, chilled } } = event
        // Add properties if found in form on PDP page add


        for (let i = 0; i < event.detail.quantity; i++) {
          const uniqueId = `id_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
          let properties = {"_itemIndex": uniqueId};
          const propertiesToAdd = document.querySelectorAll('.line-item-property input');
          if (propertiesToAdd) {
            if (propertiesToAdd.length > 0) {
              propertiesToAdd.forEach((property) => {
                properties[property.getAttribute('data-name')] = property.value;
              });
            }
          }
  
          const propertiesAddToCart = {...properties, "_delivery_date_start": delivery_date,  "_delivery_date_end": delivery_date_end, "_Chilled_Delivery": chilled }
          window.braze?.logCustomEvent("Add To Cart", 
          {
           "product_id": id, 
           "product_name": title,
           "product_price": price, 
           "product_category": type, 
           "products_in_cart": ids
          })

          await addToCart({
            id: Number(event.detail.id),
            quantity: 1,
            properties: propertiesAddToCart || {}
          });
  
        }


   
    
        this.isMinicartDialogVisible = true;

        setTimeout(() => {
          this.isMinicartDialogVisible = false;
        }, 2000); 

      },

      async handleBulkAddToCart(productIds) {
        console.log(productIds);
        const bulkItems = JSON.parse(productIds).map((id) => {
          return ( {
            id: Number(id),
            quantity: 1
          })
        })
        console.log(bulkItems);
        await addToCart({ items: bulkItems });
      },
      handleWishlist() {
        console.log('Add Item to Wishlist - Global Function');
      },
      onWindowScrollHandler() {
        const isScrolled = window.scrollY > 0

        this.isWindowScrolled = isScrolled
        document.body.classList[isScrolled ? 'add' : 'remove']('scrolled')
      },
        onKeyDownHandler(event) {
            if (event.key === 'Escape') {
                this.isMinicartVisible = false;
                this.isPredictiveSearchVisible = false;
            }
        },
    }
  }
}
