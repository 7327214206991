import { useEffect, useState } from 'react';

// Hook to check and track the URL hash
const useHashChecker = () => {
    const [hash, setHash] = useState(window.location.hash);

    useEffect(() => {
        // Function to update the state based on URL hash
        const updateHash = () => {
            setHash(window.location.hash);
        };

        // Set initial hash
        updateHash();

        // Listen to hash changes
        window.addEventListener('hashchange', updateHash);

        // Cleanup the event listener on unmount
        return () => {
            window.removeEventListener('hashchange', updateHash);
        };
    }, []);

    return hash;
};

export default useHashChecker;
