import React, { useState } from "react";
import ExtendedSelectField from "@src/stories/elements/ExtendedSelectField/ExtendedSelectField";

const ACCOUNT_ENUM = {
  account: "Account Information",
  orders: "Orders",
  cookbooks: "Free eCookbooks",
  wishlist: "Wishlist"
};

export default function ExtendedMobileAccountMenu ({settings}) {
  const { links } = settings;
  const options = links.map((link) => ({value: link.url, label: link.title }));
  const urlParse = window.location.href.split("?view=")[1];
  const parseAccount = () => {
    if ( ACCOUNT_ENUM[urlParse] ) {
      return ACCOUNT_ENUM[urlParse]
    }
    else if (window.location.href.includes('orders')) {
      return ACCOUNT_ENUM['orders']
    }
    else return ACCOUNT_ENUM['account']
  }
  const [currentMenu, setCurrentMenu ] = useState(parseAccount());
  
  const handleChange = (event) => {
    window.location.href = event.value;
    setCurrentMenu(event.value);
  }

  return (
    <div className="account-menu__select w-full">
          <ExtendedSelectField
              options={options}
              className={"w-full"}
              placeholder={currentMenu}
              onChange={(value)=> handleChange(value)}
          />
    </div>
  );
};