import React from "react";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import {mobileBreakpoint} from "../../../../../entrypoints/theme";

export default function RecipeTileBadges({ badges }) {
  const isMobile = useMobileBreakpoint(mobileBreakpoint);
  const badgeArray = isMobile ? badges.slice(0, 2) : badges.slice(0, 3);
  return (
    <div className={`product-tile__badges`}>
      {badgeArray.map((badge, index) => (
        <div key={`badge_${index}`}>
          <div
            className="product-tile__badge "
            style={{ backgroundColor: `${badge[2] || '#FFF'}`, color: `${badge[3] || '#000'}` }}
          >
            <span>{badge[0]}</span>
            <span className={`icon-${badge[1] || 'no-icon'}`}></span>
          </div>
        </div>
      ))}
    </div>
  )
}