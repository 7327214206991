import * as React from "react";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";

export const SocialLinks = ({ type = 'default', links, settings }) => {
    const isMobile = useMobileBreakpoint('1024px');

    links.forEach(link => {
        const iconName = link.name.toLowerCase() + "_icon";
        if (settings[iconName]) {
            link.icon = settings[iconName];
        }
    });

    const renderComponents = () => {
        switch (type) {
            case 'default':
                return (

                    <div className={`social-links mb-[${settings.margin_bottom_mobile}px] lg:mb-[${settings.margin_bottom_desktop}px]`}>
                        {links.map((link, linkId) => (
                            <a key={linkId} href={link.url} target="_blank">
                                <img src={link.icon} alt={link.name}/>
                            </a>
                        ))}
                    </div>
                )
        }
    }

    if (settings.render_type !== 'all') {
        if (isMobile && settings.render_type !== 'mobile-only') return;
        if (!isMobile && settings.render_type !== 'desktop-only') return;
    }

    return (
        <>
            {renderComponents()}
        </>
    );
}

export default SocialLinks;