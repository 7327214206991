import React, { useState, useEffect } from "react";

const HeaderMenu = ({ link_list, settings }) => {
    const [hoveredLink, setHoveredLink] = useState(null);
    const menuLinks = link_list[0].links;
    const { section: { hovered_background, hovered_color }} = settings;
    const {
        show_caret
     } = settings.section;
    

    useEffect(() => {
        window.addEventListener("megamenu-closed", handleMenuClose);

        if (hoveredLink !== null) {
            const event = new CustomEvent('megamenu-hover', {
                detail: { message: `header-${hoveredLink}` }
            });
            window.dispatchEvent(event);
        }

        return () => {
            // console.log('Reset');
            setHoveredLink(null);
        };
    }, []);

    const handleMenuClose = () => {
        // console.log('Megamenu closed');
        setHoveredLink(null);
    };

    return (
        <div className="header-links hidden">
            <div className="block lg:flex lg:h-[72px]">
                <ul className="header-menu inline-flex">
                    {menuLinks.map((link, id) => {
                        const titleLowerCase = link.title.toLowerCase();
                        const dataId = titleLowerCase.replace(' ', '-');
                        const activeClass = link.active ? 'active' : 'inactive';
                        const hoverClass = hoveredLink === dataId ? 'hovered' : '';
                        const hoverStyle = (hoverClass === 'hovered' || activeClass == 'active') && hovered_background && hovered_color
                        ? { color: hovered_color, backgroundColor: hovered_background }
                        : {};
                        return (
                            <li data-id={`header-${dataId}`} className={`items-center lg:h-full flex lg:px-3`} style={hoverStyle}  key={id}>
                                <a
                                    href={link.url}
                                    className={`${activeClass} ${hoverClass} menu-link hover:underline`}
                                    onMouseEnter={() => setHoveredLink(dataId)}
                                >
                                    {link.title}
                                </a>
                                { link.url == "#" &&  show_caret &&
                                ( hoveredLink === dataId ? <span className="icon-chevron-up text-[24px]"> </span> : <span className="icon-chevron-down text-[24px]"> </span> )    
                                }
                           
                               
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default HeaderMenu;