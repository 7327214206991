import React from "react";
import Image from "../../elements/ExtendedImage/ExtendedImage";
import { Headings } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Headings/Headings";
import "./FeaturedCompanies.scss";

const ExtendedFeaturedCompanies = ({ settings, title, logos }) => {
  return (
    <div className="featured-companies">
      <div className="marquee-container">
        {title && (
          <div className="featured-companies__title">
            <Headings text={title} type="h2" />
          </div>
        )}

        <div className="featured-companies-slider">
          <div className="slide-track">
            {logos.concat(logos).map((logo, index) => (
              <div key={index} className="slide">
                <a href={logo.settings.url}>
                  <Image
                    imageUrl={logo.settings.logo}
                    classes="featured-companies__image"
                  />
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExtendedFeaturedCompanies;
