import React from 'react';
import useMobileBreakpoint from '@src/hooks/useMobileBreakpoint';
import { mobileBreakpoint } from '../../../../entrypoints/theme';
import ExtendedCheckbox from "@stories/elements/ExtendedCheckbox/ExtendedCheckbox";
import ExtendedTextField from "@stories/elements/ExtendedTextField/ExtendedTextField";
import ExtendedTextareaField from "@stories/elements/ExtendedTextareaField/ExtendedTextareaField";
import clsx from "clsx";


const ContactUsForm = ({ settings }) => {
    const { section } = settings;
    const { content_alignment_desktop , content_alignment_mobile } = section;
    const isMobile = useMobileBreakpoint(mobileBreakpoint);

    if (section.render_type !== 'all') {
        if (isMobile && section.render_type !== 'mobile-only') return null;
        if (!isMobile && section.render_type !== 'desktop-only') return null;
    }

    const alignmentMap = {
        start: 'left',
        center: 'center',
        end: 'right',
      };
      
      const mobileAlignment = alignmentMap[content_alignment_mobile];

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="space-y-4">
                <div className={`flex text-${alignmentMap[content_alignment_mobile]} lg:text-${alignmentMap[content_alignment_desktop]} justify-${content_alignment_mobile} lg:justify-${content_alignment_desktop}`}> 
                    <p className="b3 font-semibold">{section.subheading}</p>
                </div>
                <div className={`flex text-${alignmentMap[content_alignment_mobile]} lg:text-${alignmentMap[content_alignment_desktop]} justify-${content_alignment_mobile} lg:justify-${content_alignment_desktop}`}> 
                    <h1>{section.heading}</h1>
                </div>
                <div className={`flex text-${alignmentMap[content_alignment_mobile]} lg:text-${alignmentMap[content_alignment_desktop]} justify-${content_alignment_mobile} lg:justify-${content_alignment_desktop}`}> 
                    <p>{section.description}</p>
                </div>
            
                <div className="space-y-2">
                    <div  className={`flex items-center text-${alignmentMap[content_alignment_mobile]} lg:text-${alignmentMap[content_alignment_desktop]} justify-${content_alignment_mobile} lg:justify-${content_alignment_desktop} space-x-2`} >
                        <i className="icon icon-envelope"></i>
                        <span>{section.email}</span>
                    </div>
                    <div className={`flex items-center text-${alignmentMap[content_alignment_mobile]} lg:text-${alignmentMap[content_alignment_desktop]} justify-${content_alignment_mobile} lg:justify-${content_alignment_desktop} space-x-2`}>
                        <i className="icon icon-phone"></i>
                        <span>{section.phone}</span>
                    </div>
                </div>
            </div>
            <form action={section.submit_url} method={section.submit_action} className="space-y-4">
                <div className="default-input space-y-2">
                    <label htmlFor="name" className="b3">Name</label>
                    <ExtendedTextField name={'name'} placeholder={'Name'} />
                </div>
                <div className="flex gap-x-2 w-full items-center">
                    <div className="w-full default-input">
                        <label htmlFor="email" className="b3">Email</label>
                        <ExtendedTextField name={'email'} placeholder={'Email'} />
                    </div>
                    <div className="w-full default-input">
                        <label htmlFor="phone" className="b3">Phone</label>
                        <ExtendedTextField name={'phone'} placeholder={'Phone'} />
                    </div>
                </div>
                <div className="default-input space-y-2">
                    <label htmlFor="message" className="b3">Message</label>
                    <ExtendedTextareaField placeholder={"Type your message..."} name={'message'} />
                </div>
                <div className="flex items-start space-x-2">
                    <ExtendedCheckbox label={'I accept the terms'} />
                </div>
                <button type="submit" className="button button-primary">Submit</button>
            </form>
        </div>
    );
};

export default ContactUsForm;
