import React, { useState, useEffect } from "react";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { mobileBreakpoint } from "@src/../entrypoints/theme";

import AccountForm from "../AccountForm";
import useUrlContains from "@src/hooks/useUrlContains";

export const Default = ({ settings }) => {
    const [isLogin, setIsLogin] = useState(false);
    const [showBusinessFields, setShowBusinessFields] = useState(false);
    const isLoginPage = useUrlContains("account/login");

    useEffect(() => {
        setIsLogin(isLoginPage);
    }, [isLoginPage]);

    const handleBusinessCheckbox = (isChecked) => {
        setShowBusinessFields(isChecked);
    };

    return (
        <div className="account-page min-h-screen flex flex-col items-center justify-center">
            <div className="account-page__container w-full space-y-8">
                <div className="account-page__wrapper flex flex-col md:flex-row w-full gap-8">
                    {/* Left Side (Form Section) */}
                    <div className="account-page__form-container w-full lg:basis-[480px] p-8 mx-auto space-y-[24px] justify-center items-center">
                        <h2 className={`flex w-full items-center justify-center`}>{isLogin ? 'Log In' : 'Register'}</h2>
                        <AccountForm
                            isLogin={isLogin}
                            showBusinessFields={showBusinessFields}
                            handleBusinessCheckbox={handleBusinessCheckbox}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Default;
