import React from 'react';

import ExtendedHeading from '../../ExtendedHeading/ExtendedHeading';
import ExtendedImage from '../../ExtendedImage/ExtendedImage';
import ProductTileAddToCart from '../../ExtendedProductTile/components/ProductTileAddToCart';
import Paragraph from '@src/stories/elements/Paragraph';

export default function ExtendedProductInclusionsPopup({ product, addToCartUrl, translations, onClose , isSellable = false }) {
  const firstAvailableVariant = !isSellable ?  product.variants?.find(variant => variant.available) : product.selected_or_first_available_variant;

  function handleClose () {
    if (onClose) onClose();
  }

  return (
    <div className='product-inclusions__popup'>
      <div className='product-inclusions__popup__content'>
        <span
          className='product-inclusions__popup__close-icon icon-close mobile'
          onClick={() => handleClose()}
        ></span>

        <div className='product-inclusions__popup__image'>
          <ExtendedImage
            imageUrl={product.featured_image}
            altText={product.title}
          />
        </div>

        <div className='product-inclusions__popup__text-content'>
          <span
            className='product-inclusions__popup__close-icon icon-close desktop'
            onClick={() => handleClose()}
          ></span>

          <div className='product-inclusions__popup__details'>
            <ExtendedHeading
              type='h4'
              className='h4'
              text={product.title}
            />

            <Paragraph text={product.description} type='b2' />

            {((product.is_sellable && firstAvailableVariant) || isSellable)  &&
              <ProductTileAddToCart
                variant={firstAvailableVariant}
                addToCartUrl={addToCartUrl}
                translations={translations}
              />
            }
          </div>
        </div>
      </div>
    </div>
  )
}