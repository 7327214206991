import { useEffect, useState } from 'react';

// Hook to check if the URL contains a specific string
const useUrlContains = (searchString) => {
    const [urlContainsString, setUrlContainsString] = useState(false);

    useEffect(() => {
        // Function to check if the URL contains the search string
        const checkUrl = () => {
            const currentUrl = window.location.href;
            console.log('Current URL: ' , currentUrl);
            setUrlContainsString(currentUrl.includes(searchString));
        };

        // Check the URL initially
        checkUrl();

        // Listen to changes in the URL (e.g., hash changes)
        window.addEventListener('hashchange', checkUrl);
        window.addEventListener('popstate', checkUrl); // For back/forward navigation

        // Cleanup the event listeners on unmount
        return () => {
            window.removeEventListener('hashchange', checkUrl);
            window.removeEventListener('popstate', checkUrl);
        };
    }, [searchString]);

    return urlContainsString;
};

export default useUrlContains;
