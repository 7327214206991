import * as React from "react";

const isFunctionEmpty = (func) => {
    const funcStr = func.toString().replace(/\s/g, '');
    return funcStr === '()=>' || funcStr === '()=>{}' || funcStr === 'function(){}';
};

const MegamenuLinks = ({ hasSubLinks = false, subItem, onLinkHover  = (subItem) => {}, onLinkLeave = () => {}, onLinkClick = (subItem) => {}, tier = 'primary' }) => {

    let isTitle = false;
    const parseTitle = (title) => {
        if (title.includes('|')) {
            const [firstPart, secondPart] = title.split('|').map(part => part.trim());
            if (secondPart === 'HEADER') {
                isTitle = true;
                return firstPart;
            }
        }
        return title;
    };

    const titleText = parseTitle(subItem.title);
    const hideTitle = titleText == '#';
    return (
        <div
            key={subItem.handle}
            {...(hasSubLinks && {'data-link-id': subItem.handle})}
            className={`megamenu__dropdown--links-${tier}`}
            {...(!isTitle && {
                onMouseEnter: () => onLinkHover(subItem),
                onMouseDown: () => onLinkHover(subItem),
                onMouseLeave: onLinkLeave,
                onClick: () => onLinkClick(subItem),
            })}
        >
            {!isTitle && !hideTitle &&
                <a href={subItem.url}>
                <span >{titleText}</span>
            </a>}
            {isTitle &&  !hideTitle && <a href="#">
                <span>{titleText}</span>
            </a>}
            {!isTitle &&  !hideTitle && !isFunctionEmpty(onLinkClick) && hasSubLinks && <i className={'icon-arrow-right lg:hidden'}></i>}
        </div>
    );
};

export default MegamenuLinks;
