import React,  { useRef, useEffect, useState } from "react";

import {
  formatPriceWithoutTrailingZeros,
} from "frontend/js/helpers";
import ExtendedRecipeProductTile from "./ExtendedRecipeProductTile/ExtendedRecipeProductTile";
import ExtendedButton from '@src/stories/elements/ExtendedButton/ExtendedButton';
import { lockScroll } from 'frontend/js/helpers';
import ExtendedProductInclusionsPopup from "../ExtendedProductInclusions/components/ExtendedProductInclusionsPopup";
import { useCart } from "frontend/js/useShopifyCart";
import '../../components/ExtendedListProducts/ExtendedListProducts.scss'

export default function ExtendedRecipeProductInclusionsList({ products, title, translations, section_id, add_to_cart, localization }) {
  const { currency_symbol } = localization.country;
  const componentRef = useRef(null);
  const [ sliderVisible, setSliderVisible ] = useState(true)
  const [selectedProduct, setSelectedProduct] = useState(null);

  function goToSlide(action) {
    const event = new CustomEvent(`carousel-navigate`, {
      detail: { section_id, action }
    });

    window.dispatchEvent(event);
  }

  const [carouselInitialized, setCarouselInitialized] = useState(false);

  useEffect(() => {
    setTimeout(() => {
     setCarouselInitialized(true); // This will trigger a re-render
    }, 250);
  }, []);


  useEffect(() => {
    const divWithClass = componentRef.current && componentRef.current.querySelector('.flickity-viewport');
    if (componentRef.current) {
      if (divWithClass) {
        setSliderVisible(true);
      } else {
        setSliderVisible(false);
      }
    }
  }, [componentRef.current, componentRef, carouselInitialized]);

  function handleProductClick(product) {
    setSelectedProduct(product);
    lockScroll(!!product)
  }

  const { cart } = useCart();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(false);
  }, [cart])

  const sellableProducts = products.filter(product => product.is_sellable);
  const variantIds = sellableProducts.map(product => product.selected_or_first_available_variant.id);
  const totalPrice = sellableProducts.reduce((sum, product) => {
    return sum + product.selected_or_first_available_variant.price;
  }, 0);

  const currentPrice = formatPriceWithoutTrailingZeros(totalPrice,  currency_symbol);

    return (
      products.length > 0 &&
        <div className="recipe-inclusions product-slider default-list  pb-12 lg:pb-0" ref={componentRef}>
            <div className={'section-top'}>
                <div className="product-slider-header"> 
                    <div className="flex flex-col lg:flex-row gap-8 w-full"> 
                      <div className={'h3 lg:h2'}> {translations.title}  </div>
                        { variantIds.length > 0 && 
                          <button
                            className={`button button-primary`}
                            disabled={isLoading}
                            x-on:click={`$store.global.handleBulkAddToCart('[${variantIds}]')`}
                            onClick={() => setIsLoading(true)}
                          >
                            {isLoading ? 'Adding To Cart...' : `${currentPrice} - Add to cart`}
                          </button>
                        }
                    </div>
                    {sliderVisible && 
                        <div className='product-slider-header__buttons'>
                           <ExtendedButton
                               icon="chevron-left"
                               style='dark-primary'
                               className="product-slider-header__buttons-arrow prev"
                               onClick={() => goToSlide('previous')}
                           />
   
                           <ExtendedButton
                               icon="chevron-right"
                               style='dark-primary'
                               className="product-slider-header__buttons-arrow next"
                               onClick={() => goToSlide('next')}
                           />
                       </div>
                    }
                 
                </div>
              
              
            </div>

            <div className="product-slider-carousel"
                {
                ...{
                    'x-data': "extended_carousel",
                    'x-init': `$nextTick(() => { initialise(true, false, false, true, false, "${section_id}") })`,
                    'x-ref': "carousel"
                }
                }
            >

                    {products.map((product, id) => (
                        <div key={id} className="carousel-item">
                            {
                               <ExtendedRecipeProductTile
                                key={id}
                                product={product}
                                addToCartUrl={add_to_cart}
                                currentVariant={product.selected_or_first_available_variant}
                                translations={translations}
                                currencySymbol={currency_symbol}
                                handleProductClick={(product)=> handleProductClick(product)}
                                /> 
                        
                            }
                               
                        </div>
                    ))}
            </div>

            {selectedProduct &&
            <ExtendedProductInclusionsPopup
              product={selectedProduct}
              isSellable={selectedProduct.is_sellable}
              addToCartUrl={add_to_cart}
              translations={translations}
              onClose={() => handleProductClick(null)}
            />
          }
        </div>
    )

}