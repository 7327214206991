import * as React from "react";

import {Paragraph} from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Paragraph/Paragraph";
import ExtendedHeadings from "../../elements/ExtendedHeadings/ExtendedHeadings";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import {mobileBreakpoint} from "../../../../entrypoints/theme";

export const CollectionHeader = ({ settings }) => {
    const isMobile = useMobileBreakpoint(mobileBreakpoint);

    if (settings.section_settings.collection_header_type == 'image') {
        return (
            <div className="collection-header__with-image relative w-full h-full">
                {settings.section_settings.image &&
                    // To be put into a separate component
                    <picture>
                        <source
                            media="(max-width: 767px)"
                            srcSet={settings.mobile_image}
                            data-alt={settings.mobile_image.alt}
                            width={settings.mobile_image.width}
                            height={settings.mobile_image.height}
                        />
                        <source
                            media="(max-width: 1440px)"
                            srcSet={settings.section_settings.image}
                        />
                        <img
                            src={settings.section_settings.image}
                            className="w-full h-auto"
                            width={settings.section_settings.image.width}
                            height={settings.section_settings.image.height}
                            alt={settings.section_settings.image.alt}
                        />
                    </picture>
                }
                <div className={`absolute top-0 left-0 w-full flex flex-col text-center h-full justify-${settings.section_settings.content_vertical_position_mobile} lg:justify-${settings.section_settings.content_vertical_position_desktop} pt-[${settings.section_settings.padding_top_mobile}px] lg:pt-[${settings.section_settings.padding_top_desktop}px] pb-[${settings.section_settings.padding_bottom_mobile}px] lg:pb-[${settings.section_settings.padding_bottom_desktop}px] px-[${settings.section_settings.padding_left_right_mobile}px] lg:px-[${settings.section_settings.padding_left_right_desktop}px]`}>
                    <div className={`mb-[${settings.section_settings.title_margin_bottom_mobile}px] lg:mb-[${settings.section_settings.title_margin_bottom_desktop}px]`}>
                        {isMobile &&
                            <ExtendedHeadings
                                text={settings.section_settings.title}
                                classes={`collection-header__with-image__title ${settings.section_settings.heading_type_mobile}`}
                            />
                        }
                        {!isMobile &&
                            <ExtendedHeadings
                                text={settings.section_settings.title}
                                classes={`collection-header__with-image__title ${settings.section_settings.heading_type_desktop}`}
                            />
                        }
                    </div>
                    {settings.section_settings.show_description &&
                        <Paragraph
                            text={settings.description}
                            type={`${settings.section_settings.body_type} self-center w-full lg:max-w-[${settings.section_settings.content_width}]`}
                        />
                    }
                </div>
            </div>
        )
    } else {
        return (
            <div className={`collection-header-without-image flex flex-col text-center h-full justify-${settings.section_settings.content_vertical_position_mobile} lg:justify-${settings.section_settings.content_vertical_position_desktop} pt-[${settings.section_settings.padding_top_mobile}px] lg:pt-[${settings.section_settings.padding_top_desktop}px] pb-[${settings.section_settings.padding_bottom_mobile}px] lg:pb-[${settings.section_settings.padding_bottom_desktop}px] px-[${settings.section_settings.padding_left_right_mobile}px] lg:px-[${settings.section_settings.padding_left_right_desktop}px]`}>
                {isMobile &&
                    <ExtendedHeadings
                        text={settings.section_settings.title}
                        classes={`collection-header-without-image__title ${settings.section_settings.heading_type_mobile } mb-[${settings.section_settings.title_margin_bottom_mobile}px] lg:mb-[${settings.section_settings.title_margin_bottom_desktop}px]`}
                    />
                }
                {!isMobile &&
                    <ExtendedHeadings
                        text={settings.section_settings.title}
                        classes={`collection-header-without-image__title ${settings.section_settings.heading_type_desktop } mb-[${settings.section_settings.title_margin_bottom_mobile}px] lg:mb-[${settings.section_settings.title_margin_bottom_desktop}px]`}
                    />
                }
                {settings.section_settings.show_description &&
                    <Paragraph
                        text={settings.description}
                        type={`${settings.section_settings.body_type} self-center w-full lg:max-w-[${settings.section_settings.content_width}]`}
                    />
                }
            </div>
        )
    }
};

export default CollectionHeader;