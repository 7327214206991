import React from "react";
import { useState, useRef, useEffect } from "react";

import "./Wishlist.scss";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { mobileBreakpoint } from "../../../../entrypoints/theme";
import { WishlistHeader } from "./WishlistHeader";
import { WishlistActions } from "./WishlistActions";
import { WishlistFilters } from "./WishlistFilters";
import { WishlistEmpty } from "./WishlistEmpty";
import { WishlistItems } from "./WishlistItems";
import WishlistManager from "./WishlistManager";

const Wishlist = ({ settings }) => {
  const { section, blocks } = settings;
  const [wishlistItems, setWishlistItems] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentFilter, setCurrentFilter] = useState("products");

  const openModal = () => setIsModalVisible(true);
  const closeModal = () => setIsModalVisible(false);
  const showWelcomeMessageHeader = false;
  const showItemsMessage = false;
  const isMobile = useMobileBreakpoint(mobileBreakpoint);

  const requestWishlistList = (listType) => {
    console.log("Requesting for wishlist list:", listType);
    setCurrentFilter(listType);
    const event = new CustomEvent("requestWishlistProducts", {
      detail: {
        type: listType,
        message: `Requesting wishlist ${listType}`,
      },
    });
    window.dispatchEvent(event);
  };
  useEffect(() => {
    requestWishlistList(currentFilter);
    const handleWishlistProducts = (event) => {
      setWishlistItems(event.detail.items);
      console.log("Wishlist Items received.", event.detail.items);
    };

    window.addEventListener("responseWishlistProducts", handleWishlistProducts);
  }, []);

  if (section.render_type !== "all") {
    if (isMobile && section.render_type !== "mobile-only") return;
    if (!isMobile && section.render_type !== "desktop-only") return;
  }

  return (
    <div className="wishlist-container">
      {showWelcomeMessageHeader && <WishlistHeader settings={settings} />}
      <WishlistFilters
        settings={settings}
        openModal={openModal}
        onFilterClick={requestWishlistList}
      />
      {currentFilter === "products" && <WishlistActions settings={settings} />}

      {isModalVisible && (
        <WishlistManager settings={settings} onClose={closeModal} />
      )}
      {wishlistItems.length > 0 && (
        <div className="wishlist__items py-2">
          {showItemsMessage && (
            <div className="wishlist__items-message py-2">
              Below are items that you've added to your wishlist
            </div>
          )}

          <WishlistItems
            settings={settings}
            wishlistItems={wishlistItems}
            filter={currentFilter}
          />
        </div>
      )}

      {wishlistItems.length <= 0 && <WishlistEmpty settings={settings} />}
    </div>
  );
};

export default Wishlist;
