import React from 'react';

export default function ExtendedCartBreadcrumbs ({ translations, activeIndex }) {
  const steps = [
    translations.address_info,
    translations.shipping_options,
    translations.review,
  ]

  return (
    <div className='text-sm flex gap-2'>
      {steps.map((step, index) => {
        return (
          <span key={index}>
            <span className={activeIndex === index ? 'font-medium' : ''}>
              {step}
            </span>
            
            {((steps.length - 1) !== index) &&
              <span className='ml-2'>
                &mdash;
              </span>
            }
          </span>
        )
      })}
    </div>
  )
}