import * as React from "react";

type HeadingsProps = {
  text: string,
  type: string,
  className?: string
}

export default function ExtendedHeading({
  text = '',
  type = 'h1',
  className = ''
}: HeadingsProps) {

  const CustomTag = type as keyof JSX.IntrinsicElements;

  return (
    <CustomTag className={`text__heading ${className}`}>
      {text}
    </CustomTag>
  );
};
