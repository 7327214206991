const BASE_URL = 'https://sync.fusedship.com/machship-api';


function constructPayload(postcode, suburb, address, quantity = 1, chilled = false) {
  return {
    "items": [
        {
            "weight": 5,
            "sku": "HAMPER",
            "quantity": quantity
        }
    ],
    "companyId": 35118,
    "fromLocation": {
        "postcode": "2143",
        "suburb": "Regents Park"
    },
    "toAddressLine1": address,
    "toLocation": {
        "postcode": postcode,
        "suburb": suburb
    },
    ...(chilled && { questionIds:[40]})

  }
}

export async function getShippingRates(postcode, suburb, address, quantity , chilled) {
  const url = `${BASE_URL}/return-routes`;
  const payload = constructPayload(postcode, suburb , address, quantity,  chilled)

  return await fetch(url, {
    method: 'POST',
    body: JSON.stringify(payload),
  }).then(response => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return response.json();

  }).then(data => {
    return data;

  }).catch(error => {
    throw new Error(error);
  });
}

export async function getSuburbs(postcode) {
  const url = `${BASE_URL}/location-request`;
  const params = new URLSearchParams({
    postcode,
    domain: window?.Shopify?.shop,
    platform: 'Shopify',
    cdn: `https://${window?.Shopify?.cdnHost}`
  }).toString();

  return await fetch(`${url}?${params}`)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      return response.json();

    }).then(data => {
      return data;

    }).catch(error => {
      throw new Error(error);
    });
}