import { Headings } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Headings/Headings";

const colors = [
  'bg-mb-dark-leaf',
  'bg-mb-white',
  'bg-mb-red-light',
  'bg-mb-red',
  'bg-mb-skim-milk',
  'bg-mb-black',
  'bg-mb-green-light',
  'bg-mb-green',
  'bg-mb-neutral-200',
  'bg-mb-neutral-300',
  'bg-mb-neutral-500',
  'bg-mb-neutral-600',
  'bg-mb-neutral-700',
  'bg-mb-success-light',
  'bg-mb-success',
  'bg-mb-key',
  'bg-mb-fruit-punch',
  'bg-mb-grass',
  'bg-mb-ice',
  'bg-mb-maggie-living',
  'bg-mb-teal-green',
]

export default function StyleguideColours() {
  return (
    <div className={'styleguide-components'}>
      <div className={'component-header'}>
        <Headings text={'Colours'} type={'h2'} />
      </div>

      <div className={'grid grid-cols-11 justify-between items-center gap-4'}>
        {colors.map(color => (
          <div
            key={color}
            className={`${color} flex text-white h-20 w-20 text-center justify-center items-center p-8 border`}>
          </div>
        ))}
      </div>
    </div>
  )
}