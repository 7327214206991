import React, { useState, useEffect, useCallback } from 'react';

const AddressAutocomplete = ({ value, onChange, onAddressSelect }) => {
    const [suggestions, setSuggestions] = useState([]);

    const allowed_countries = 'au,nz';

    const fetchSuggestions = useCallback(async (query) => {
        try {
            const response = await fetch(`https://nominatim.openstreetmap.org/search?q=${query}&format=json&addressdetails=1&limit=5&countrycodes=${allowed_countries}`);
            const data = await response.json();
            setSuggestions(data);
        } catch (error) {
            console.error("Error fetching address suggestions:", error);
        }
    }, []);

    const debounceFetchSuggestions = useCallback(debounce(fetchSuggestions, 300), [fetchSuggestions]);

    const handleInputChange = (event) => {
        const query = event.target.value;
        onChange(event);

        if (query.length > 2) {
            debounceFetchSuggestions(query);
        } else {
            setSuggestions([]);
        }
    };

    const handleSuggestionClick = (suggestion) => {
        const address = suggestion.address;
        const selectedAddress = {
            address1: address.road || '',
            city: address.city || address.town || '',
            suburb: address.suburb || '',
            province: address.state || '',
            zip: address.postcode || '',
            country: address.country || '',
        };
        onAddressSelect(selectedAddress);
        setSuggestions([]);
    };

    return (
        <div className="address-autocomplete">
            <input
                type="text"
                value={value}
                onChange={handleInputChange}
                placeholder="Start typing an address..."
            />
            {suggestions.length > 0 && (
                <ul className="suggestions-list">
                    {suggestions.map(suggestion => (
                        <li
                            key={suggestion.place_id}
                            onClick={() => handleSuggestionClick(suggestion)}
                        >
                            {suggestion.display_name}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

// Debounce function
function debounce(func, wait) {
    let timeout;
    return function (...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
    };
}

export default AddressAutocomplete;
