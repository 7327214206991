import React, { useState, useEffect } from "react";
import "./SignupPopup.scss";
import ExtendedImage from "@src/stories/components/ExtendedImage/ExtendedImage";
import ExtendedTextField from "@src/stories/elements/ExtendedTextField/ExtendedTextField";
import ExtendedCheckbox from "@src/stories/elements/ExtendedCheckbox/ExtendedCheckbox";
import useCreateCustomer from "@src/hooks/useCreateCustomer";
import {BusinessFields, InputField, CheckboxField} from "@stories/ecommerce/Account/AccountFoodClubFields";

const SignupPopup = ({ settings }) => {
  const { section } = settings;
  const [isBusiness, setIsBusiness] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [fadeIn, setFadeIn] = useState(false);
  const { createCustomer, loading, error, customerId, success } = useCreateCustomer();
  const [dependenciesState, setDependenciesState] = useState({});
  const customSignupFields = settings.signupfields;

  // Configurable object that can be updated later from Shopify settings
  const config = {
    discountText: section.discount_text,
    subText: section.sub_text,
    description: section.description,
    fields: {
      email: "Email",
      businessCheckbox: "Are you a business?",
      companyName: "Company Name",
      industryType: "Industry Type",
      role: "Your Role (Optional)",
      companySize: "Company Size (Optional)",
      recipient:
        "Who are the typical recipients of your company's gifts? (Optional)",
    },
    buttonText: "SUBSCRIBE",
    privacyText:
      "*By submitting your email address you are agreeing to receive marketing communications.",
  };

  // Function to handle native cookies
  const setCookie = (name, value, days) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
  };

  const getCookie = (name) => {
    const cookieArr = document.cookie.split(";");
    for (let i = 0; i < cookieArr.length; i++) {
      const cookiePair = cookieArr[i].split("=");
      if (name === cookiePair[0].trim()) {
        return decodeURIComponent(cookiePair[1]);
      }
    }
    return null;
  };
  console.log(getCookie("popupDismissed"));
  // Show popup after a delay if not dismissed before
  useEffect(() => {

    const timeout = setTimeout(() => {
      if (true) {
        setIsVisible(true);
        setTimeout(() => setFadeIn(true), 10); // Trigger fade-in effect
      }
    }, 3000); // Show after 3 seconds

    return () => clearTimeout(timeout);
  }, []);

  const handleClose = () => {
    setFadeIn(false);
    setTimeout(() => {
      setIsVisible(false);
      setCookie("popupDismissed", "true", 7); // Hide for 7 days
    }, 300);
  };

  const handleBusinessToggle = () => {
    setIsBusiness(!isBusiness);
  };

  // Function to update the global dependencies state
  const updateDependencyState = (name, value) => {
    setDependenciesState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle input change and update global dependency state
  const handleInputChange = (e) => {
    const { name, value, type: inputType, checked } = e.target;

    // For checkboxes, use the checked value, otherwise use the input value
    const newValue = inputType === "checkbox" ? checked : value;

    // Update the global dependency state
    updateDependencyState(name, newValue);
  };


  const handleClick = (event) => {
    event.preventDefault();
    // Since this is newsletter, set optIn fields automatically
    setDependenciesState((prevState) => ({
      ...prevState,
      ['optInSMS']: true,
      ['optInEmail']: true,
    }));

    const response = createCustomer(dependenciesState);
  };

  if (!isVisible) return null;

  return (
    <div
      className={`popup-subscription ${fadeIn ? "popup-subscription--visible" : ""}`}
    >
      <div className="popup-subscription__overlay" onClick={handleClose}></div>
      <div className="popup-subscription__content">
        <div className="popup-subscription__close" onClick={handleClose}>
          <i className="icon icon-close"></i>
        </div>
        {section.image && (
          <ExtendedImage
            imageUrl={section.image}
            altText="popup_image"
            className="popup-subscription__image"
          />
        )}

        <div className={`popup-subscription__details space-y-[16px] p-[8px]`}>
          {config.discountText && <h4 className="popup-subscription__discount">{config.discountText}</h4>}
          {config.subText && <p className="popup-subscription__subtext">{config.subText}</p>}
          {config.description && <p className="popup-subscription__description">{config.description}</p>}
        </div>

        <form className={`flex flex-col space-y-[12px] text-left`}>
          <div className="popup-subscription__input-group">
            <label>{config.fields.email}</label>
            <input
              type="email"
              name={"email"}
              className="popup-subscription__input"
              placeholder="Enter your email"
              onChange={handleInputChange}
              required
            />
          </div>

          {/* Checkboxes */}
          <div className="account-page__checkboxes flex space-x-4">
            <CheckboxField
                label="Are you a business?"
                id="isBusiness"
                onChange={handleBusinessToggle}
            />
          </div>

          {isBusiness && (
              <BusinessFields customSignupFields={customSignupFields} handleInputChange={handleInputChange} />
          )}

          {success && (
              <div
                  className="success-message my-[16px] py-[16px] bg-[#36573B] px-4"
                  role="alert"
              >
                <p className="bc">Thank you for subscribing!</p>
                <p className="b3">
                  You have been successfully subscribed.
                </p>
              </div>
          )}

          {error && (
              <div className="error-message my-[16px] py-[16px] bg-red-500 px-4" role="alert">
                <p>{error}</p>
              </div>
          )}
          <div
              className={`popup-subscription__submit button button-primary`}
              onClick={handleClick}>
            {config.buttonText}

          </div>
        </form>
        <p className="popup-subscription__privacy blabel">
          {config.privacyText}
        </p>
      </div>
    </div>
  );
};

export default SignupPopup;
