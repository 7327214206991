import React from 'react';

import PrimaryHeroBanner from './layouts/PrimaryHeroBanner';
import SecondaryHeroBanner from './layouts/SecondaryHeroBanner';

import './ExtendedHeroBanner.scss';

const layouts = {
  primary: PrimaryHeroBanner,
  secondary: SecondaryHeroBanner,
}

export default function ExtendedHeroBanner ({ settings }) {
  const bannerType = settings?.section?.banner_type || 'primary'
  const Layout = layouts[bannerType];

  return <Layout settings={settings} />
};