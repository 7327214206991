import React, { useState } from "react";

import ExtendedButton from "@src/stories/elements/ExtendedButton/ExtendedButton";

const sortOptions = [
  { value: "Popular", label: "Popular" },
  { value: "Newest", label: "Newest" },
  { value: "LowToHigh", label: "Price - Low to High" },
  { value: "HighToLow", label: "Price - High to Low" },
]

export default function ExtendedCollectionToolbar ({ settings }) {
  const [selectedOption, setSelectedOption] = useState('');
  const [displayLabel, setDisplayLabel] = useState('Sort by');

  const { all_products_count = 0, translations } = settings;
  const selectedFilters = [
    'Option 1',
    'Option 2',
    'Option 3',
    'Option 4',
  ]

  function handleRemoveFilter (filter) {
    console.log('handleRemoveFilter', filter)
  }

  function handleClearSelections () {
    console.log('handleClearSelections')
  }

  function handleSelectChange (event) {
    const { value } = event.target;
    console.log('handleSelectChange', value)
    setSelectedOption(value);
    const selected = sortOptions.find(option => option.value === value);
    setDisplayLabel(selected?.label || 'Sort by');
  }

  return (
    <div className="main-collection__header">
      <div className="main-collection__header-toolbar">
        <div className="main-collection__header-product-count">
          {all_products_count} {translations.products}
        </div>

        <div className="main-collection__header-filters">
          {selectedFilters.map((filter, index) => {
            return (
              <div
                key={filter + index}
                className="main-collection__header-filters__item"
              >
                {filter}
                <span className="relative cursor-pointer" aria-hidden="true" onClick={() => handleRemoveFilter(filter)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M16.192 6.34424L11.949 10.5862L7.70697 6.34424L6.29297 7.75824L10.535 12.0002L6.29297 16.2422L7.70697 17.6562L11.949 13.4142L16.192 17.6562L17.606 16.2422L13.364 12.0002L17.606 7.75824L16.192 6.34424Z" />
                  </svg>
                </span> 
              </div>
            )
          })}

          <div  className="main-collection__header-filters__item-clear-container hidden lg:block">
            <ExtendedButton
              style="text"
              label={translations.clear_selections}
              className="main-collection__header-filters__item-clear"
              onClick={() => handleClearSelections()}
            />
          </div>
        </div>

        <div  className="main-collection__header-filters__item-clear-container block lg:hidden">
            <ExtendedButton
              style="text"
              label={translations.clear_selections}
              className="main-collection__header-filters__item-clear"
              onClick={() => handleClearSelections()}
            />
          </div>
      </div>

      <div className="main-collection__header-sort-wrap">
        <div className="main-collection__header-sort">
          <span className="main-collection__header-sort__display-label">
            {displayLabel}
          </span>

          <select
            defaultValue={selectedOption}
            className="main-collection__header-sort__field"
            onChange={(event) => handleSelectChange(event)}
          >
            {sortOptions.map((option, index) => {
              return (
                <option
                  key={option.value+index}
                  value={option.value}
                >
                  {option.label}
                </option>
              )
            })}
          </select>

          <i className="icon-chevron-down"></i>
        </div>
      </div>
    </div>
  );
};
