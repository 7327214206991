import React, { useState, useRef, useEffect } from "react";
import { clsx } from 'clsx';

import ExtendedImage from "../../ExtendedImage/ExtendedImage";
import { formatPrice } from "frontend/js/helpers";

const ImagePlaceholderSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 525.5 525.5">
      <path d="M324.5 212.7H203c-1.6 0-2.8 1.3-2.8 2.8V308c0 1.6 1.3 2.8 2.8 2.8h121.6c1.6 0 2.8-1.3 2.8-2.8v-92.5c0-1.6-1.3-2.8-2.9-2.8zm1.1 95.3c0 .6-.5 1.1-1.1 1.1H203c-.6 0-1.1-.5-1.1-1.1v-92.5c0-.6.5-1.1 1.1-1.1h121.6c.6 0 1.1.5 1.1 1.1V308z"/>
      <path d="M210.4 299.5H240v.1s.1 0 .2-.1h75.2v-76.2h-105v76.2zm1.8-7.2l20-20c1.6-1.6 3.8-2.5 6.1-2.5s4.5.9 6.1 2.5l1.5 1.5 16.8 16.8c-12.9 3.3-20.7 6.3-22.8 7.2h-27.7v-5.5zm101.5-10.1c-20.1 1.7-36.7 4.8-49.1 7.9l-16.9-16.9 26.3-26.3c1.6-1.6 3.8-2.5 6.1-2.5s4.5.9 6.1 2.5l27.5 27.5v7.8zm-68.9 15.5c9.7-3.5 33.9-10.9 68.9-13.8v13.8h-68.9zm68.9-72.7v46.8l-26.2-26.2c-1.9-1.9-4.5-3-7.3-3s-5.4 1.1-7.3 3l-26.3 26.3-.9-.9c-1.9-1.9-4.5-3-7.3-3s-5.4 1.1-7.3 3l-18.8 18.8V225h101.4z"/><path d="M232.8 254c4.6 0 8.3-3.7 8.3-8.3s-3.7-8.3-8.3-8.3-8.3 3.7-8.3 8.3 3.7 8.3 8.3 8.3zm0-14.9c3.6 0 6.6 2.9 6.6 6.6s-2.9 6.6-6.6 6.6-6.6-2.9-6.6-6.6 3-6.6 6.6-6.6z"/>
  </svg>
)

const EMTPY_STATE = { id: 0, title: 'No Thanks' };

type ExtendedCustomSelectFieldProps = {
  id?: string,
  label?: string,
  className?: string,
  placeholder?: string,
  name?: string,
  value?: string | number,
  options: any,
  disabled?: boolean,
  hasError?: boolean,
  errorMessage?: string,
  onChange?: Function,
  lineItem?: undefined
}

export default function ExtendedCustomSelectField ({
  id,
  label = '',
  className,
  placeholder,
  name,
  value,
  options,
  disabled,
  hasError,
  errorMessage,
  lineItem,
  onChange = () => {},
}: ExtendedCustomSelectFieldProps) {

  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options.find(option => option.id === value) || '');
  function handleOptionSelect (option) {

    if(option.title == '') {
      setSelectedOption(EMTPY_STATE);
    } else {
      setSelectedOption(option);
    }

    setIsOpen(false);

    if (onChange) onChange(option.id);
  }

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={dropdownRef}
      className={clsx('extended-custom-select-field', className, {
        'pointer-events-none': disabled,
        'is-open': isOpen
      })}
    >
      <div className='text-field-wrap'>
        {label &&
          <label
            htmlFor={id}
            className="form-field__label"
          >
            {label}
          </label>
        }

        <input
          type="hidden"
          name={name}
          value={selectedOption?.id || ''}
        />

        <input
          id={id}
          type="text"
          className={clsx('form-field cursor-pointer', {
            'has-error': hasError,
          })}
          readOnly
          value={selectedOption?.title || ''}
          placeholder={placeholder }
          onClick={() => { setIsOpen(!isOpen); }}
        />
            <div className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer" style={{ top: "75%", transform: "translateY(-75%)"}}> <span className='icon icon-chevron-down text-[24px]'></span> </div>
      </div>
  

      {options?.length > 0 &&
        <ul
          className={clsx('absolute w-full z-10 py-3 border border-black border-t-0 rounded-b-lg bg-white flex-col max-h-[225px] overflow-auto', {
            'flex': isOpen,
            'hidden': !isOpen
          })}
        >
          <li
            className="p-3 cursor-pointer hover:bg-mb-neutral-200"
            onClick={() => {  
            handleOptionSelect(EMTPY_STATE) 
         
          }}
          >
            {`${EMTPY_STATE.title}`}
          </li>

          {options.map(option => (
            <li
              key={option.id}
              className="flex items-center gap-4 cursor-pointer hover:bg-mb-neutral-200 p-3"
              onClick={() => { handleOptionSelect(option);}}
            >
              <div className="extended-cart__option-image">
                 { !option.featured_image?.src && <ImagePlaceholderSVG/> }
                 { option.featured_image?.src  && ( 
                    <ExtendedImage
                    imageUrl={option.featured_image?.src}
                    altText={option.title}
                    className={'w-full h-full object-cover'}
                  />
                 )}
              
              </div>

              <div className="title break-all">
                {option.title}
              </div>

              <div className="ml-auto">
                {formatPrice(option.price)}
              </div>
            </li>
          ))}
        </ul>
      }

      {(hasError && errorMessage) &&
        <div className="form-field__error-message">
          {errorMessage}
        </div>
      }
    </div>
  );
};
