import React, { useState, useEffect, useRef } from "react";
import ExtendedButton from "@src/stories/elements/ExtendedButton/ExtendedButton";
import { ExtendedNotifyModal } from "./ExtendedNotifyModal";
import { ExtendedUpgradeModal } from "./ExtendedUpgradeModal";
import { useCart } from "frontend/js/useShopifyCart";

const AddToCartButton = ({
  quantity,
  addToCartUrl,
  variant,
  buttonLabel,
  redirect,
  onClose,
  buttonType,
  delivery_date,
  product,
  delivery_date_end,
  chilled
}) => {
  const addForm = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const { cart } = useCart();
  useEffect(() => {
    setIsLoading(false);
  }, [cart])
;
  const itemIds = cart.items.map(item => item.id);

  const dispatchCustomEvent = (eventName, detail) => {
    window.dispatchEvent(new CustomEvent(eventName, { detail }));
  };
  return (
    <ajax-cart-product-form>
      <form
        action={addToCartUrl}
        noValidate
        ref={addForm}
        data-prodify-product-form
      >
        <input type="hidden" name="id" value={variant && variant.id} />

        <input type="hidden" name="quantity" value={quantity} />

        <div>
          <button
            className={`button button-primary`}
            disabled={isLoading}
            onClick={() => { 
              dispatchCustomEvent("add-to-cart", { id: variant.id, quantity: quantity, title: product.title, price: Number(product.price)/100, type: variant.title, ids: itemIds, delivery_date: delivery_date, delivery_date_end: delivery_date_end, chilled: chilled  });
              setIsLoading(true);
              onClose(); }}
          >
            {isLoading ? 'Adding To Cart...' : buttonLabel}
          </button>
        </div>

        <div
          data-ajax-cart-errors="form"
          className="absolute text-[10px] text-mb-red"
        ></div>
      </form>
    </ajax-cart-product-form>
  );
};

const ProductAddToCart = ({ product, routes, translations, localization }) => {
  const { variants, delivery_date, delivery_date_end, chilled, siblings, is_sellable, hamper_products_size } = product;
  const { add_to_cart_url } = routes;
  const [productStatus, setProductStatus] = useState("add");
  const [productQuantity, setProductQuantity] = useState(1);
  const [buttonLabel, setButtonLabel] = useState(translations?.add_to_cart);
  const [isNotifyOpen, setIsNotifyOpen] = useState(false);
  const [isUpgradeOpen, setIsUpgradeOpen] = useState(false);
  const standardVariant = variants.find((obj) => obj.title === "Standard");
  const preOrderVariant = variants.find((obj) => obj.title === "Pre-Order");
  const filteredSiblings = siblings && siblings.sibling_products.filter(
    (prod) =>
      Number(product.price) < Number(prod.price) && product.title != prod.title,
  );
  const currentVariant =
    productStatus == "pre" ? preOrderVariant : standardVariant;

  // is sellable metafield is used to determine if non hamper products are sellable, if hamper = sellable automaticalyy
  const isSellable = hamper_products_size > 0 ? true : is_sellable;

  const handleUpgradeClose = () => {
    document.querySelector(".pdp-details")?.classList.remove("z-40");
    document.querySelector("#sticky-bar")?.classList.remove("modal-open");
    document.body.classList.remove("modal-open");
    setIsUpgradeOpen(false);
  };

  const handleUpgradeOpen = () => {
    document.querySelector(".pdp-details")?.classList.add("z-40");
    document.querySelector("#sticky-bar")?.classList.add("modal-open");
    document.body.classList.add("modal-open");
    setIsUpgradeOpen(true);
  };

  useEffect(() => {
    if (delivery_date != "" && delivery_date_end != "") {
      if ( preOrderVariant && JSON.parse(preOrderVariant?.available)) {
        setProductStatus("pre");
        setButtonLabel(translations?.pre_order);
      } else {
        setProductStatus("notify_pre");
        setButtonLabel(translations?.notify_me_pre);
      }
    } else {
    
      if ( standardVariant && JSON.parse(standardVariant?.available)) {
        setProductStatus("add");
        setButtonLabel(translations?.add_to_cart);
      } else {
        setProductStatus("notify");
        setButtonLabel(translations?.notify_me);
      }
    }
  }, []);


  return (
    isSellable &&
    <div className="flex flex-col lg:flex-row items-center justify-between gap-[16px] py-2">
      <div className="quantity-row quantity-field w-full lg:w-[190px] h-[48px] ">
        <div className="w-full h-full flex items-center justify-center">
          <span
            className="quantity-field__button text-[24px] px-[4px] py-[8px] icon-minus"
            onClick={() => {
              setProductQuantity(
                productQuantity - 1 > 0 ? productQuantity - 1 : 0,
              );
            }}
          ></span>
          <input
            className="quantity-field__input"
            type="text"
            id="quantity-field__input"
            value={productQuantity}
          />
          <span
            className="quantity-field__button text-[24px] px-[4px] py-[8px] icon-plus"
            onClick={() => {
              setProductQuantity(productQuantity + 1);
            }}
          ></span>
        </div>
      </div>

      <div className="product___add-to-cart w-full block">
        <div className="add-to-cart-button">
          {productStatus == "notify" || productStatus == "notify_pre" ? (
            <ExtendedButton
              style="primary"
              label={buttonLabel}
              icon="bell"
              onClick={() => {
                setIsNotifyOpen(true);
              }}
            />
          ) : filteredSiblings && filteredSiblings.length > 0 ? (
            <ExtendedButton
              style="primary"
              label={buttonLabel}
              onClick={() => handleUpgradeOpen()}
            />
          ) : (
            <AddToCartButton
              quantity={productQuantity}
              buttonType={"primary"}
              addToCartUrl={add_to_cart_url}
              buttonLabel={buttonLabel}
              variant={currentVariant}
              product={product}
              delivery_date={delivery_date}
              delivery_date_end={delivery_date_end}
              chilled={chilled}
              redirect={"close"}
              onClose={() => {}}
            />
          )}
        </div>
      </div>

      <ExtendedNotifyModal
        modal_id={"notify_me_modal"}
        isOpen={isNotifyOpen}
        onClose={() => setIsNotifyOpen(false)}
        product_name={product.title}
        product_id={
          productStatus == "notify" ? standardVariant?.id : preOrderVariant?.id
        }
      />
      <ExtendedUpgradeModal
        modal_id={"upgrade_me_modal"}
        isOpen={isUpgradeOpen}
        onClose={() => handleUpgradeClose()}
        product={product}
        add_to_cart_url={add_to_cart_url}
        translations={translations}
        localization={localization}
        delivery_date={delivery_date}
        delivery_date_end={delivery_date_end}
        chilled={chilled}
        variant={currentVariant}
        productQuantity={productQuantity}
      />
    </div>
  );
};

export default ProductAddToCart;
