import React from "react";
import './Timeline.scss';
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import ExtendedButton from "@src/stories/elements/ExtendedButton/ExtendedButton";
import {mobileBreakpoint} from "../../../../entrypoints/theme";
import Paragraph from "@stories/elements/Paragraph";

const Timeline = ({ settings }) => {

    const { section, blocks } = settings;
    const isMobile = useMobileBreakpoint(mobileBreakpoint);

    if (section.render_type !== 'all') {
        if (isMobile && section.render_type !== 'mobile-only') return;
        if (!isMobile && section.render_type !== 'desktop-only') return;
    }

    return (
        <div className={section.custom_column_class}>
            <div className="timeline relative flex flex-col w-full">
                {blocks.map((block, index) => (
                    <div
                        key={index}
                        className="timeline-item flex lg:px-6"
                        style={
                            block.settings.background_image
                                ? {
                                    backgroundImage: `url(${block.settings.background_image})`,
                                    backgroundRepeat: "no-repeat",
                                }
                                : {}
                        }
                    >
                        <div className="flex justify-between flex-auto lg:basis-[30%]">
                            <div
                                className="flex lg:ml-[24px] text-left h3 hidden lg:block"
                                style={{ marginTop: "60px" }}
                            >
                                {block.settings.timeline_title}
                            </div>
                            <div
                                className={`flex justify-end indicator-line ${
                                    index === 0 ? "first-line" : index === blocks.length - 1 ? "last-line" : ""
                                } flex h-full w-[2px] lg:mr-[80px] relative`}
                            >
                                <div
                                    className="absolute rounded-full w-[15px] h-[15px] bg-black timeline-dot"
                                ></div>
                            </div>
                        </div>

                        <div className="flex flex-col overflow-hidden lg:basis-[70%]  pr-0 p-5  pt-[64px] lg:px-5 lg:py-10">
                            <div className="mb-8 flex flex-col-reverse lg:flex-col relative">
                            
                                    {block.settings.image && (
                                        <div className="w-full p-4">
                                            <img
                                                src={block.settings.image}
                                                alt={block.settings.title}
                                                className="w-full h-auto"
                                            />
                                        </div>
                                    )}
                           
                                <div className="w-full p-4">
                                    <h5 className="mb-4">{block.settings.title}</h5>
                                    <div className="timeline-subcopy mb-4 text-[16px] lg:text-[18px]">
                                        <Paragraph text={block.settings.text} />
                                    </div>
                                    <div className="flex flex-col lg:flex-row gap-4"> 
                                        {block.settings.cta_link_1 && block.settings.cta_text_1 && (
                                            <a
                                                href={block.settings.cta_link_1}
                                                className={`button button-${block.settings.cta_button_1}`}
                                            >   
                                                {block.settings.cta_icon_1 && <span className={`icon-${block.settings.cta_icon_1}`}></span>}
                                                {block.settings.cta_text_1}
                                            </a>
                                        )}
                                        {block.settings.cta_link_2 && block.settings.cta_text_2 && (
                                            <a
                                                href={block.settings.cta_link_2}
                                                className={`button button-${block.settings.cta_button_2} `}
                                            >
                                                {block.settings.cta_icon_2 && <span className={`icon-${block.settings.cta_icon_2}`}></span>}
                                                {block.settings.cta_text_2}
                                            </a>
                                        )}
                                    </div>
                                </div>
                                <div className="w-full px-[16px] block lg:hidden">
                                    <div className="flex lg:ml-[24px] text-left h3">
                                        {block.settings.timeline_title}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

        </div>
    );
};

export default Timeline;
