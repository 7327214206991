import * as React from "react";

const Video = ({ videoUrl, type = 'video/mp4', autoplay = false, loop = false , muted = false }) => {
    return (
        <video width={'100%'} autoPlay={autoplay} muted={autoplay ? true : muted } loop={autoplay ? true : loop } controls={!autoplay}>
            <source src={videoUrl} type={type}/>
        </video>
    );
};

export default Video;