import React, { useState, useEffect, useRef } from 'react';

import ExtendedImage from "../../ExtendedImage/ExtendedImage";
import ExtendedCartWrapper from "./ExtendedCartWrapper";
import DatePicker from "react-datepicker";

import { constructFullAddress, formatPrice} from 'frontend/js/helpers';
import helper from 'frontend/js/helpers';;
import { fetchCart, updateItem } from 'frontend/js/useShopifyCart';
import ExtendedRadio from '@src/stories/elements/ExtendedRadio/ExtendedRadio';
import ExtendedTextField from '@src/stories/elements/ExtendedTextField/ExtendedTextField';
import shippingitem from "../../../../../js/alpine/components/cart/shippingitem";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import {mobileBreakpoint} from "../../../../../entrypoints/theme";
import "react-datepicker/dist/react-datepicker.css";

const ImagePlaceholderSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 525.5 525.5">
      <path d="M324.5 212.7H203c-1.6 0-2.8 1.3-2.8 2.8V308c0 1.6 1.3 2.8 2.8 2.8h121.6c1.6 0 2.8-1.3 2.8-2.8v-92.5c0-1.6-1.3-2.8-2.9-2.8zm1.1 95.3c0 .6-.5 1.1-1.1 1.1H203c-.6 0-1.1-.5-1.1-1.1v-92.5c0-.6.5-1.1 1.1-1.1h121.6c.6 0 1.1.5 1.1 1.1V308z"/>
      <path d="M210.4 299.5H240v.1s.1 0 .2-.1h75.2v-76.2h-105v76.2zm1.8-7.2l20-20c1.6-1.6 3.8-2.5 6.1-2.5s4.5.9 6.1 2.5l1.5 1.5 16.8 16.8c-12.9 3.3-20.7 6.3-22.8 7.2h-27.7v-5.5zm101.5-10.1c-20.1 1.7-36.7 4.8-49.1 7.9l-16.9-16.9 26.3-26.3c1.6-1.6 3.8-2.5 6.1-2.5s4.5.9 6.1 2.5l27.5 27.5v7.8zm-68.9 15.5c9.7-3.5 33.9-10.9 68.9-13.8v13.8h-68.9zm68.9-72.7v46.8l-26.2-26.2c-1.9-1.9-4.5-3-7.3-3s-5.4 1.1-7.3 3l-26.3 26.3-.9-.9c-1.9-1.9-4.5-3-7.3-3s-5.4 1.1-7.3 3l-18.8 18.8V225h101.4z"/><path d="M232.8 254c4.6 0 8.3-3.7 8.3-8.3s-3.7-8.3-8.3-8.3-8.3 3.7-8.3 8.3 3.7 8.3 8.3 8.3zm0-14.9c3.6 0 6.6 2.9 6.6 6.6s-2.9 6.6-6.6 6.6-6.6-2.9-6.6-6.6 3-6.6 6.6-6.6z"/>
  </svg>
)

export default function ExtendedCartStep3 ({ cart, cartItems, address, settings, translations, card_types, deliveryMap, chilledDeliveryMap, shipping_id, bundleType }) {
const [updatingCart, setUpdatingCart] = useState(true);
const [groupedItems, setGroupedItems] = useState([]);
const fullAddress = constructFullAddress(address);
const [ stepStatus , setStepStatus] = useState('INIT');
const hasLoadedCart = useRef(false); // Track whether the cart has been loaded
const submitCheckoutBtn = useRef(null); 

const hasChilledDelivery = cartItems.some(product =>
  product.items.some(item => item.properties._Chilled_Delivery === "true" || item.properties._Chilled_Delivery == true)
);


const onlyBundleItems = cartItems.filter(cartItem => 
  cartItem.items.every(item => item.product_type === bundleType)
);

useEffect(() => {
  // Define an async function to fetch the cart on mount
  const loadCart = async () => {
    if(stepStatus == 'INIT' && !hasLoadedCart.current) {
      setUpdatingCart(true);
      hasLoadedCart.current = true; // Mark that the cart load has started
      await fetchCart(); // Wait for fetchCart to complete
      setStepStatus('FETCH');
    }
    
  };
  if(stepStatus == 'INIT') {
    loadCart(); // Call the async function when the component mounts
  }
}, []);


const groupItemsByDeliveryDateAndShippingRate = (data) => {
    const groupedItems = {};
    const consignmentCounts = {};
    data.forEach((entry) => {
        entry.items.forEach((item) => {
            const { _delivery_date, _shipping_rate, _consignment } = item.properties;
            const { product_type } = item;
            if( product_type == bundleType ) return false;
            // Create a unique key for each group based on delivery date and shipping rate
            const groupKey = `${_delivery_date}-${_shipping_rate}`;
            const validDeliveryMap = hasChilledDelivery  ? chilledDeliveryMap : deliveryMap;
            if (!groupedItems[groupKey]) {
                groupedItems[groupKey] = {
                    _delivery_date,
                    _shipping_rate,
                    consignmentKey: '',
                    shipping_price: validDeliveryMap[_shipping_rate] && validDeliveryMap[_shipping_rate].raw_price,
                    items: []
                };
            }

            // Initialize the consignment count for this group and consignment
                if (!consignmentCounts[_consignment]){ 
                    consignmentCounts[_consignment] = [groupKey]; 
                } else if (!consignmentCounts[_consignment].includes(groupKey)) {
                    consignmentCounts[_consignment].push(groupKey); 
                }

                const consignmentKey = `${_consignment} Consignment ${consignmentCounts[_consignment].length + onlyBundleItems.length}`;
                groupedItems[groupKey].consignmentKey = consignmentKey;
                
                // Push the current item into the appropriate group
                groupedItems[groupKey].items.push(item);
       

        });
    });

    return Object.values(groupedItems);  // Convert the grouped object back to an array
};

async function updateLineItems (items) {
  for (const cartItem of items) {
    for (const item of cartItem.items) {
      if(item.product_type == bundleType) {
        continue;
      }


      const prefixesToRemove = ['_itemIndex'];
      const keysToRemove = ['_delivery_date_end', '_Chilled_Delivery', '_delivery_date_start'];

      const filteredProperties = Object.keys(item.properties)
        .filter(key => !prefixesToRemove.some(prefix => key.startsWith(prefix) &&
        !keysToRemove.includes(key)))
        .reduce((obj, key) => {
          obj[key] = item.properties[key];
          return obj;
        }, {});


      await updateItem({
        id: item.key,
        quantity: 1,
        properties: {
          ...filteredProperties,
          "_bundle": cartItem.consignmentKey,
          "_groupId": cartItem.consignmentKey
        }
      }, true);
      
    }
  }
}

useEffect(() => {
  const updateShippingProductAndCart = async () => {
      await shippingitem.component().checkAndUpdateShippingProduct(groupItemsByDeliveryDateAndShippingRate(cartItems), shipping_id);
      setUpdatingCart(false);
      setStepStatus('FINALIZE');
  };

  if(stepStatus == 'FETCH') {
    setGroupedItems(groupItemsByDeliveryDateAndShippingRate(cartItems));
    updateShippingProductAndCart();
  }

}, [stepStatus])

const cardEnum = card_types && helper.arrayToEnum(card_types, 'id')
const isMobile = useMobileBreakpoint(mobileBreakpoint);

async function handleValidation () {
  await updateLineItems(groupItemsByDeliveryDateAndShippingRate(cartItems))
  submitCheckoutBtn.current.submit();
}

const validDeliveryMap = hasChilledDelivery  ? chilledDeliveryMap : deliveryMap;

  return (
    <ExtendedCartWrapper
      title={translations.review}
      cart={cart}
      settings={settings}
      translations={translations}
      activeIndex={2}
      disabled={updatingCart}
      checkout
      shipping_id={shipping_id}
      submitRef={submitCheckoutBtn}
      buttonLabel={translations.go_to_checkout}
      onNextStep={() => handleValidation()}
    >
      <>
      { groupedItems.length > 0 && groupedItems[0].items[0] && 
                            <div className="border border-mb-neutral-500 rounded-xl p-4 pl-6 mb-8">
                              <div>
                                <p className='font-medium text-sm mb-2'>
                                    {'Shipping'}
                                </p>
    
                            
                              <div className='flex gap-3 w-full'>
                                <ExtendedRadio checked />
    
                                <p>{validDeliveryMap[groupedItems[0].items[0].properties._shipping_rate] ? validDeliveryMap[groupedItems[0].items[0].properties._shipping_rate].display : groupedItems[0].items[0].properties._shipping_rate}</p>
                                
                                <p className='font-medium ml-auto'>
                                  { groupedItems[0].items[0].properties._shipping_rate && validDeliveryMap[groupedItems[0].items[0].properties._shipping_rate] && validDeliveryMap[groupedItems[0].items[0].properties_shipping_rate]?.price}
                                </p>
                              </div>
                          
                          </div>
                          { groupedItems[0].items[0].properties._delivery_date  && 
                            <div className="extended-cart__delivery-date pt-6">
                              <div className="extended-cart__delivery-date-label"> Delivery Date </div> 
                              <div className="extended-cart__delivery-date-container"> 
                                <DatePicker selected={groupedItems[0].items[0].properties._delivery_date} disabled />
                                <span className="extended-cart__delivery-date-icon icon icon-calendar"> </span>
                              </div>
                            </div>
                            }
                          </div>
          }
        {groupedItems.map((cartItem, index) => {
        const consignmentLabel = cartItem.consignmentKey;
          return (
            <div key={index}>
              <div className='flex flex-col gap-8 border border-mb-neutral-500 rounded-xl p-4 pl-6 extended-cart__bordered-container'>
                <div className="extended-cart__consignment"> {`${consignmentLabel}`} </div>
                {cartItem.items.map((item, itemIndex) => {
     
                  console.log(validDeliveryMap);
                  let showDelivery = false;
                  if (index == 0 && itemIndex == 0) {
                      showDelivery = true;
                  }
                  return (
                    <div key={item.key}>
                      <div className='flex gap-4 pb-4'>
                      { isMobile && ( 
                           <div className='extended-cart__item'>
                              <div className="flex flex-row gap-4 mb-2">
                                {!item.image ? <ImagePlaceholderSVG/> : 
                                  <ExtendedImage
                                    imageUrl={item.image}
                                    altText={item.product_title}
                                    className={'object-cover w-full h-full'}
                                  />
                                }
                                <div>
                                  <p className='font-medium'>
                                    {item.product_title}
                                  </p>
                                </div>

                              </div>
                           
                              <div> 
                                <div className="flex flex-row justify-between"> 
                                  <p>
                                      <span className='font-medium mr-2'>
                                        {translations.price}:
                                      </span>
          
                                      {formatPrice(item.price)}
                                  </p>
                                    
                                  <p>
                                    <span className='font-medium mr-2'>
                                      {translations.qty}:
                                    </span>
        
                                    {item.quantity}
                                  </p>
                                </div>
                              
                                  
                                <p>
                                  <span className='font-medium mr-2'>
                                    {translations.recipient_name}:
                                  </span>
      
                                  {item.properties._recipient_name}
                                </p>
        
                                <p>
                                  <span className='font-medium mr-2'>
                                    {translations.address}:
                                  </span>
                                  
                                  {fullAddress}
                                </p>
                              </div>

                          </div>
                        )}
                        { !isMobile && (
                          <div className='extended-cart__item'>
                            {!item.image ? <ImagePlaceholderSVG/> : 
                              <ExtendedImage
                                imageUrl={item.image}
                                altText={item.product_title}
                                className={'object-cover w-full h-full'}
                              />
                            }
                          <div>
                              <p className='font-medium'>
                                {item.product_title}
                              </p>
    
                              <p>
                                <span className='font-medium mr-2'>
                                  {translations.price}:
                                </span>
    
                                {formatPrice(item.price)}
                              </p>
                              
                              <p>
                                <span className='font-medium mr-2'>
                                  {translations.qty}:
                                </span>
    
                                {item.quantity}
                              </p>
                              
                              <p>
                                <span className='font-medium mr-2'>
                                  {translations.recipient_name}:
                                </span>
    
                                {item.properties._recipient_name}
                              </p>
    
                              <p>
                                <span className='font-medium mr-2'>
                                  {translations.address}:
                                </span>
                                
                                {fullAddress}
                              </p>
                          </div>
                          </div>
                        )}
                      </div>

                      { item.properties._card_message && 
                        <div className="extended-cart__card-message pb-4">
                            <div className="extended-cart__card-message-label"> Card </div> 
                            <div className="extended-cart__card-message-information"> 
                                <div className="flex flex-row gap-4"> 
                                <div className="flex flex-row gap-1"> <div className="text-[14px] font-[500]"> Card Type: </div> <div className="text-[14px]"> {cardEnum[item.properties._card_type].title} </div> </div>
                                <div className="flex flex-row gap-1"> <div className="text-[14px] font-[500]"> Price: </div> <div className="text-[14px]"> {formatPrice(cardEnum[item.properties._card_type].price)} </div>  </div> </div>
                            </div> 
                           <ExtendedTextField
                             name='_card_message'
                             placeholder=''
                             disabled
                             value={item.properties._card_message}
                           />
                        </div>
                      }
                   
                    {/* {showDelivery && 
                      <div className='pt-4 pl-4 border-l border-mb-neutral-500'>
                           <p className='font-medium text-sm mb-2'>
                                {'Shipping'}
                           </p>

                       
                          <div className='flex gap-3 w-full'>
                            <ExtendedRadio checked />

                            <p>{validDeliveryMap[item.properties._shipping_rate] ? validDeliveryMap[item.properties._shipping_rate].display : item.properties._shipping_rate}</p>
                            
                            <p className='font-medium ml-auto'>
                              { item.properties._shipping_rate && validDeliveryMap[item.properties._shipping_rate] && validDeliveryMap[item.properties_shipping_rate]?.price}
                            </p>
                          </div>
                      
                      </div>}
                      { showDelivery && item.properties._delivery_date && 
                          <div className="extended-cart__delivery-date pt-6">
                            <div className="extended-cart__delivery-date-label"> Delivery Date </div> 
                            <div className="extended-cart__delivery-date-container"> 
                              <DatePicker selected={item.properties._delivery_date} disabled />
                              <span className="extended-cart__delivery-date-icon icon icon-calendar"> </span>
                            </div>
                          </div>
                      } */}
                    </div>
                  )
                })}
              </div>
              
              {(groupedItems.length - 1 !== index) &&
                <hr className='bg-mb-neutral-500 mt-6' />
              }
            </div>
          )
        })}
        { onlyBundleItems.length > 0 && 
          <div className='flex flex-col gap-8 border border-mb-neutral-500 rounded-xl p-4 pl-6'>
            <div> Consignment Bundles </div>
          
            {onlyBundleItems.map((bundles)=> {
              return(
                bundles.items.map(item => {
                  return (
                  
                    <div key={item.key}>
                      <div className='flex gap-4 pb-4'>
                        <div className='w-[150px] h-[150px]'>
                        {!item.image ? <ImagePlaceholderSVG/> : 
                            <ExtendedImage
                              imageUrl={item.image}
                              altText={item.product_title}
                              className={'object-cover w-full h-full'}
                            />
                          }
                        </div>
    
                        <div>
                          <p className='font-medium'>
                            {item.product_title}
                          </p>
    
                          <p>
                            <span className='font-medium mr-2'>
                              {translations.price}:
                            </span>
    
                            {formatPrice(item.price)}
                          </p>
                          
                          <p>
                            <span className='font-medium mr-2'>
                              {translations.qty}:
                            </span>
    
                            {item.quantity}
                          </p>
                          
                          <p>
                            <span className='font-medium mr-2'>
                              {translations.recipient_name}:
                            </span>
    
                            {item.properties._recipient_name}
                          </p>
    
                          <p>
                            <span className='font-medium mr-2'>
                              {translations.address}:
                            </span>
                            
                            {fullAddress}
                          </p>
                        </div>
                      </div>
                    </div>
                  
                  )
                })
              )
            })}
          </div>
        }
        

      </>
    </ExtendedCartWrapper>
  )
}