import React, { useState, useEffect } from "react";
import Paragraph from "../../../elements/Paragraph";

export const PromoScrollingLayout = ({ settings }) => {
    const { blocks, section: { icon_color, url } } = settings;

    const renderMarqueeContent = (marquee, marquee_id) => (
        <div className="marquee-bar-content" key={marquee_id}>
            {marquee.settings.image && (
                <img src={marquee.settings.image} alt="Icon" className="marquee-bar-image h-5" />
            )}
            {marquee.settings.icon && (
                <span
                    style={{ color: icon_color }}
                    className={`marquee-bar-icon h-5 icon-${marquee.settings.icon}`}
                >
                </span>
            )}
            <Paragraph text={marquee.settings.promo_bar_content} />
        </div>
    );

    return (
        <a href={url} className="marquee-bar-banner">
            <div className="marquee-bar-track">
                {blocks.map((marquee, marquee_id) => renderMarqueeContent(marquee, marquee_id))}
                {blocks.map((marquee, marquee_id) => renderMarqueeContent(marquee, marquee_id))}
                {blocks.map((marquee, marquee_id) => renderMarqueeContent(marquee, marquee_id))}
            </div>
        </a>
    );
};
