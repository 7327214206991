import * as React from "react";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { mobileBreakpoint } from "../../../../entrypoints/theme";
import ExtendedProductTile from "@stories/components/ProductTile/ProductTile";
import PromotionTile from "@stories/ecommerce/PromotionalTile/PromotionTile";
import CollectionSidebar from "@stories/ecommerce/Collection/CollectionSidebar";
import CollectionToolbar from "@stories/ecommerce/Collection/CollectionToolbar";
import { useState } from "react";

export const Collection = ({ settings }) => {
    const [products, setProducts] = useState([]);
    const isMobile = useMobileBreakpoint(mobileBreakpoint);
    const [isSidebarVisible, setSidebarVisible] = useState(false);
    const toggleSidebar = () => {
        setSidebarVisible(!isSidebarVisible);
    };

    const {
        columns_mobile,
        columns_desktop,
        column_gap_mobile,
        column_gap_desktop,
        row_gap_mobile,
        row_gap_desktop,
        id,
    } = settings.section;

    React.useEffect(() => {
        const handleProductsResults = (event) => {
            if (event.detail.type === 'plp') {
                setProducts(event.detail.products);
            }
        };

        const handleEscapeKey = (event) => {
            if (event.key === 'Escape') {
                setSidebarVisible(false);
            }
        };

        window.addEventListener('product-list', handleProductsResults);
        window.addEventListener('keydown', handleEscapeKey);

        // Cleanup the event listeners on component unmount
        return () => {
            window.removeEventListener('product-list', handleProductsResults);
            window.removeEventListener('keydown', handleEscapeKey);
        };
    }, []);

    return (
        <>
            <CollectionSidebar isVisible={isSidebarVisible} />
            <CollectionToolbar toggleSidebar={toggleSidebar} />
            <div
                id="product-grid"
                className={`collection product-grid grid grid-cols-${columns_mobile} lg:grid-cols-${columns_desktop} gap-x-[${column_gap_mobile}px] lg:gap-x-[${column_gap_desktop}px] gap-y-[${row_gap_mobile}px] lg:gap-y-[${row_gap_desktop}px] auto-cols-max`}
                data-id={id}
            >
                {settings.promotion_blocks.map(promotion => (
                    <PromotionTile promotion={promotion} />
                ))}
                {products.map(product => (
                    <div key={product.id} className={'collection-tile'}>
                        <ExtendedProductTile product={product} siblings={undefined}  />
                    </div>
                ))}
            </div>
        </>
    );
}

export default Collection;