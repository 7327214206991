import { StatusIndicator } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/StatusIndicator/StatusIndicator";
import {EcommFindInStore} from "@mindarc/arctheme-components/frontend/src/stories/components/ecommerce/FindInstore/FindInstore";
import {useSyncExternalStore} from "react";
import { findinstore } from '../js/alpine/components/findinstore';
import { hasher } from '../js/alpine/stores/hasher';
import ExtendedProductAccordion from "../src/stories/components/ProductAccordion/ExtendedProductAccordion";
import useMobileBreakpoint from "../src/hooks/useMobileBreakpoint";
import ExtendedProductGallery from "../src/stories/components/ProductGallery/ExtendedProductGallery";
import ProductMain from "../src/stories/ecommerce/Product/ProductMain";
import ExtendedProductSwatches from "../src/stories/components/ProductSwatches/ExtendedProductSwatches";
import ExtendedProductTile from "../src/stories/components/ExtendedProductTile/ExtendedProductTile";
import ProductPayments from "@src/stories/ecommerce/Product/ProductPayments";
import ProductAddToCart from "@src/stories/ecommerce/Product/ProductAddToCart";
import ExtendedProductVariantSelect from "@src/stories/ecommerce/Product/ExtendedProductVariantSelect";


export function ProductAddToCartComponent({ ...props }) {
    return (
        <ProductAddToCart product={props.settings.product} routes={props.settings.routes} translations={props.settings.translations}  localization={props.settings.localization}/>
    )
}

export function ProductVariantSelectComponent({ ...props }) {
    return (
        <ExtendedProductVariantSelect props={props}/>
    )
}

export function ProductTileComponent({ settings }) {
    const { product, selected_or_first_available_variant, add_to_cart_url, star_ratings_app, translations } = settings;

    return (
        <ExtendedProductTile
            product={product}
            currentVariant={selected_or_first_available_variant}
            addToCartUrl={add_to_cart_url}
            starRatingsApp={star_ratings_app}
            translations={translations}
        />
    )
}

export function ProductStockStatus({ ...props }) {
    return (
        <StatusIndicator message={ "In Stock" } status={ "success" } show_indicator={props.settings.show_indicator}/>
    )
}

export function ProductPaymentsComponent({ ...props }) {
    return (
        <ProductPayments settings={props.settings.block_settings} />
    )
}

export function PDPMainComponent({ ...props }) {

    // console.log(props);
    return (
        <ProductMain settings={props.settings}/>
    )
}

export function ProductSwatchesComponent({ ...props }) {
    const swatches = props.settings.swatches;
    const currentColor = props.settings.current_colour;

    return (
        <div className={ 'product__swatches-component' }>
            <ExtendedProductSwatches swatches={swatches} currentColor={currentColor}/>
        </div>
    )
}

export function ProductGalleryComponent({ ...props }) {
    let productImages = [];

    props.settings.additional.map((image, index) => (
        productImages.push({ url: image.src, alt: image.alt , videoSrc: image.sources && image.sources[1] })
    ));
    const videoUrl = props.settings.video_url
        ? `https://player.vimeo.com/video/${props.settings.video_url}?autoplay=1&quality=720p&loop=1&autopause=0&background=1`
        : null;

    return (
        <>
             <div className={'product-gallery'}>
                <ExtendedProductGallery images={productImages} galleryType={'tiled'} videoUrl={videoUrl}/>
            </div>
        </>
    )
}

export function FindInStoreBlock({ ...props }) {
    // console.log('Find in Store Block SETUP')
    async function storeStockCheck(){
        // console.log('Finding Store Stock');
        findinstore.component().sku = props.settings.product_sku;

        await hasher.store().dispatchHash();
        await findinstore.component().getStockForProduct(props.settings.product_sku);
        await findinstore.component().getDeliveryMethodsShopify();
    }

    function useInventoryLevels() {
        return useSyncExternalStore(findinstore.component().subscribe, findinstore.component().getInventoryLevels);
    }

    function useDeliveryMethods() {
        return useSyncExternalStore(findinstore.component().subscribe, findinstore.component().getDeliveryMethods);
    }

    return (
        <>
            <EcommFindInStore
                title={'Find in Store'}
                description={'Please enter your postcode to check the delivery and collection options. Make your selection as you proceed through the checkout.'}
                buttonText={'Search'}
                clickEvent={storeStockCheck}
                inventoryLevels={useInventoryLevels()}
                deliveryMethods={useDeliveryMethods()}
            />
        </>
    )
}

export function Accordion({ ...props }) {
    const { settings: block  } = props;
    const parseDetails = (details) => {
        // Function to strip HTML tags
        const stripHtmlTags = (html) => {
            const doc = new DOMParser().parseFromString(html, 'text/html');
            return doc.body.textContent || "";
        };

        // Strip HTML tags and split by semicolon characters
        const cleanDetails = stripHtmlTags(details);
        return cleanDetails.split(';').map((item, index) => <li key={index}>{item.trim()}</li>);
    };

    const sampleItems =  [
        {
            title: block.settings.additional_info_1,
            content: block.settings.additional_content_1
        },
        {
            title:   block.settings.additional_info_2,
            content:  block.settings.additional_content_2
        },
        {
            title:   block.settings.additional_info_3,
            content:  block.settings.additional_content_3
        },
        {
            title:   block.settings.additional_info_4,
            content:  block.settings.additional_content_4
        },
        {
            title:   block.settings.additional_info_5,
            content:  block.settings.additional_content_5
        }
    ];

    return (
        <ExtendedProductAccordion items={sampleItems} show_header={true}/>
    )
}


