import React, { useState, useEffect } from "react";

import { Headings } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Headings/Headings";
import { Paragraph } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Paragraph/Paragraph";
import ExtendedButton from "@src/stories/elements/ExtendedButton/ExtendedButton";

export const FoodClub = ({ settings }) => {
  const { blocks, section } = settings;
  const redirect = section.foodclub_redirect;
  const heading = section.foodclub_section_title;
  const disclaimer = section.foodclub_disclaimer;
  const button_text = section.foodclub_cta_label;
  const button_url = section.foodclub_cta_url;

  return (
    <div className="calltoaction-blocks-cta h-auto lg:min-h-[504px]  gap-[32px] lg:gap-[12px] xl:gap-[48px] relative flex flex-col">
      <div className="calltoaction-blocks-cta__header">
        <Headings text={heading} type={"h3"} />
      </div>

      <div className="calltoaction-blocks-cta__content gap-[24px] flex flex-col lg:flex-row">
        {blocks.map((block, block_id) => (
          <div
            className="calltoaction-blocks-cta__column flex-col"
            key={block_id}
          >
            {block.settings.icon && (
              <span
                className={`calltoaction-blocks-cta__icon icon-${block.settings.icon}`}
              >
                {" "}
              </span>
            )}
            <Headings text={block.settings.heading} type={"h5"} />
            <Paragraph text={block.settings.subcopy} />
          </div>
        ))}
      </div>

      <div className="calltoaction-blocks-cta__disclaimer flex flex-col gap-y-[16px]">
        <div className="calltoaction-blocks-cta__button w-full">
          <ExtendedButton
            label={button_text}
            style="dark-primary"
            link={redirect ? "/account/login" : button_url}
          />
        </div>
        <Paragraph text={disclaimer} />
      </div>
    </div>
  );
};

export default FoodClub;
