import React from "react";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import {mobileBreakpoint} from "../../../../../entrypoints/theme";
export default function ProductTileBadges({ badges }) {
  const isMobile = useMobileBreakpoint(mobileBreakpoint);
  const narrowBadges = isMobile && badges.length >= 3;
  const badgeArray = narrowBadges ? badges.slice(0, 3) : badges;
  return (
    <div className={`product-tile__badges ${narrowBadges ? 'narrow' : ''}`}>
      {badgeArray.map((badge, index) => (
        <div key={`badge_${index}`}>
          <div
            className="product-tile__badge "
            style={{ backgroundColor: `${badge[3] || '#FFF'}`, color: `${badge[4] || '#000'}` }}
          >
            <span>{badge[1]}</span>
            <span className={`icon-${badge[2] || 'no-icon'}`}></span>
          </div>
        </div>
      ))}
    </div>
  )
}