import React from "react";

import ExtendedImage from "@src/stories/components/ExtendedImage/ExtendedImage";

import "./ExtendedFeaturedBlog.scss";

export default function ExtendedFeaturedBlog ({ settings }) {
  const { article, translations } = settings;

  return (
    <div className="featured-blog">
      <div className="featured-blog__header"> 
        <div className="featured-blog__header-title">
          {settings.header}
        </div>
      </div>

      <div className="featured-blog__main"> 
        <a
          href={`${article.url}`}
          className="featured-blog__image"
        >
          <ExtendedImage
            imageUrl={article.image}
            altText={article.title}
          />
        </a>

        <div className="featured-blog__content">
            <div className="flex items-center gap-2">
              <div className="pl-0 pr-2 lg:py-2 lg:px-3 font-medium text-sm">
                {article.read_time} {article.read_time > 1 ? 'mins' : 'min'} read
              </div>

              {article.type &&
                <div className="px-2 py-[1px] lg:py-2 lg:px-3 text-xs text-white bg-mb-dark-leaf rounded leading-5">
                  {article.type}
                </div>
              }
            </div>

            <div className="featured-blog__title">
              <a href={`${article.url}`}>
                <h4>{article.title}</h4>
              </a>
            </div>

            <div className="featured-blog__subtitle">
              {article.excerpt}
            </div>

            <div className="featured-blog__read">
              <a href={article.url}>
                {translations.read_more}
              </a>
            </div>
        </div>
      </div>
    </div>
  );
};
