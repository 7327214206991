import React from "react";

import ExtendedFilterCollapsible from '@src/stories/components/ExtendedFilterCollapsible/ExtendedFilterCollapsible'
import ExtendedCheckbox from "@src/stories/elements/ExtendedCheckbox/ExtendedCheckbox";
import ExtendedSlider from "@src/stories/components/ExtendedSlider/ExtendedSlider";


export default function ExtendedCollectionSidebar ({filters}) {
  return (
    <div className="main-collection__sidebar">
        <div className="main-collection__sidebar-filters">
          {filters.map((filter,index) => {
            switch(filter.filter) {
              case 'price':
                return (
                  <ExtendedFilterCollapsible
                    active
                    key={`${filter.filter}-${index}`}
                    title={filter.label}
                    filter={filter.filter}
                  >
                    {filter.options.map((option, index) => {
                      return (
                        <ExtendedCheckbox
                          key={filter+option+index}
                          label={option}
                        />
                      )
                    })}
                    <ExtendedSlider
                      min={0}
                      max={10000}
                      attachCurrency
                    ></ExtendedSlider>
                  </ExtendedFilterCollapsible>
                )
              default:
                return (
                  <ExtendedFilterCollapsible
                    active
                    title={filter.label}
                    filter={filter.filter}
                    key={`${filter.filter}-${index}`}
                  >
                    {filter.options.map((option, index) => {
                      return (
                        <ExtendedCheckbox
                          key={filter+option+index}
                          label={option}
                        />
                      )
                    })}
                  </ExtendedFilterCollapsible>
                )
            }
          })}
        </div>
    </div>
  );
};
