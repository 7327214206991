import React from 'react';
import AlpineWidget from "@mindarc/arctheme-components/frontend/src/stories/components/AlpineWidget";

export default function CartTitle({ item_count }) {
  return (
    <AlpineWidget template={`
      <div class="relative text-center px-2 py-6 font-medium">
        <span
          class="icon-close absolute left-0 top-[calc(50%-16px)] text-3xl cursor-pointer"
          @click="$store.global.toggleMinicart()"
        ></span>

        <div class="b2">
          ${item_count} item${item_count > 1 ? 's' : ''} in Cart
        </div>
      </div>
    `} />
  )
}
