import { useState, useCallback } from 'react';

const useSubscribeCustomer = () => {
    const [isNewsletterOpen, setIsNewsletterOpen] = useState(false);
    const [requestedSuccess, setRequestedSuccess] = useState(false);
    const [serverMessage, setServerMessage] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const toggleNewsletter = useCallback(() => {
        setIsNewsletterOpen((prev) => !prev);
    }, []);

    const subscribe = useCallback(async (email, data) => {
        const valid = true;

        if (valid) {
            if (email) {
                console.log('Valid Form, submitting...', email);
                const myForm = document.querySelector('#at-newsletter-subscribe-form');

                window.Shopify.captcha.protect(myForm, () => {
                    const nameParts = data.name.split(' ');

                    myForm.elements['contact[first_name]'].value = nameParts[0]; // First part is first name
                    if (nameParts.length > 1) {
                        myForm.elements['contact[last_name]'].value = nameParts.slice(1).join(' '); // Everything after the first part is last name
                    }
                    myForm.elements['contact[email]'].value = email;
                    myForm.elements['contact[tags]'].value = 'newsletter';
                    myForm.elements['utf8'].value = '✓';
                    myForm.elements['form_type'].value = 'customer';
                    myForm.submit();
                });
                console.log('Submitting to Shopify');
                setIsSubmitting(true);
            } else {
                console.log('Email is required')
            }
        } else {
            console.log('Form is invalid');
        }
    }, []);

    return {
        isNewsletterOpen,
        toggleNewsletter,
        requestedSuccess,
        serverMessage,
        isSubmitting,
        subscribe,
    };
};

export default useSubscribeCustomer;
