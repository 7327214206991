import { useEffect } from "react";
import clsx from "clsx";

import AccountPopupLoggedIn from "../src/stories/components/Account/AccountPopupLoggedIn";
import AccountPopupLoggedOut from "../src/stories/components/Account/AccountPopupLoggedOut";

export default function AccountPopupComponent({ ...props }) {
  let isMouseOut = true;
  const { settings } = props;

  function handleMouseLeave() {
    isMouseOut = true;

    if (settings.trigger !== 'hover') return;

    setTimeout(() => {
      if (isMouseOut) {
        const event = new CustomEvent('toggle-display-close', {
          detail: { message: 'header-account' }
        });

        window.dispatchEvent(event);
      }
    }, 200)
  }

  function handleMouseEnter () {
    isMouseOut = false;
  }

  useEffect(() => {
    const eventName = 'toggle-display-trigger-leave';
    const handleToggleDisplay = (event) => {
      if (event.detail.message === 'header-account') {
        handleMouseLeave()
      }
    };

    window.addEventListener(eventName, handleToggleDisplay);

    return () => {
      window.removeEventListener(eventName, handleToggleDisplay);
    };
  }, []);

  return (
    <div
      className={clsx(['account-popup relative', settings.container_type])}
      onMouseEnter={() => handleMouseEnter()}
      onMouseLeave={() => handleMouseLeave()}
    >
      {props.settings.is_customer_logged_in
        ? <AccountPopupLoggedIn settings={settings} />
        : <AccountPopupLoggedOut settings={settings} />
      }
    </div>
  )
}