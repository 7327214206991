import React, { useState, useEffect } from 'react';

import ExtendedButton from '@src/stories/elements/ExtendedButton/ExtendedButton';
import ExtendedCartBreadcrumbs from "./ExtendedCartBreadcrumbs";
import DatePicker from "react-datepicker";

import { formatPrice } from 'frontend/js/helpers';

export default function ExtendedCartWrapper ({
  children,
  title,
  cart,
  settings,
  translations,
  activeIndex,
  disabled,
  buttonLabel,
  onNextStep,
  checkout,
  shipping_id,
  submitRef
}) {
  const [loading, setLoading ] = useState(disabled)

  useEffect(()=> {
    setLoading(disabled);
  },[disabled])  

  const shippingItems = cart.items.filter((item) => item.id === shipping_id);
  const totalShippingPrice = shippingItems && shippingItems.reduce((acc, item) => acc + item.price, 0);

  return (
    <div className='extended-cart container lg:flex'>
      <div className='lg:w-2/3 py-4 lg:py-10 lg:px-6 flex flex-col gap-6'>
        <ExtendedCartBreadcrumbs
          translations={translations}
          activeIndex={activeIndex}
        />

        <div className='flex justify-between items-center'>
          <h3 className='h3 extended-cart__title'>
            {title}
          </h3>

          <a
            href="/"
            className='underline text-lg'
          >
            {translations.back_to_shopping}
          </a>
        </div>

        {children}
      </div>

      <div className='lg:w-1/3 py-6 lg:p-6 flex flex-col gap-4'>
        <div className='flex items-center justify-between'>
          <h5>{translations.total}</h5>

          <h5>{formatPrice((cart.total_price - totalShippingPrice) || 0)}</h5>
        </div>
        {
          checkout && (
            <>
              <form id="checkout" action="/checkout" method="post" ref={submitRef}></form>
            </>
          )
        }
        { 
          <ExtendedButton
            label={ loading ? 'Updating cart...' : buttonLabel}
            style='primary'
            disabled={loading}
            icon={buttonLabel}
            onClick={() => onNextStep()}
          />
        }
      

        <div>
          <h6 className='mb-2'>
            {settings.contact_us_title}
          </h6>

          <p>{settings.contact_us_subtitle}</p>
        </div>

        <a
          className='flex items-center gap-4 no-underline'
          href={`mailto:${settings.contact_us_email}`}
        >
          <span className='icon-envelope text-2xl'></span>
          <span className='hover:underline'>
            {settings.contact_us_email}
          </span>
        </a>

        <a
          className='flex items-center gap-4 no-underline'
          href={`tel:${settings.contact_us_phone}`}
        >
          <span className='icon-phone text-2xl'></span>
          <span className='hover:underline'>
            {settings.contact_us_phone}
          </span>
        </a>
      </div>
    </div>
  )
}