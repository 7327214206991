import * as React from "react"
import {PromoCenteredLayout} from './layouts/PromoCenteredLayout';
import {PromoScrollingLayout} from './layouts/PromoScrollingLayout';

import './ExtendedPromotionBar.scss';

const layouts = {
    "scrolling": PromoScrollingLayout,
    "centered": PromoCenteredLayout,
}

export default function ExtendedPromoBar ({ settings }) {
    const layoutType = settings?.section?.layout_type || 'centered'
    const Layout = layouts[layoutType];

    return <Layout settings={settings} />
};
