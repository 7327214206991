import * as React from "react";
import { useRef } from "react";
import "./ExtendedLocalization.scss"

const ExtendedLocalization = ({ localization }) => {
  const selectForm = useRef(null)

  return (      
    <form
        method="post"
        ref={selectForm}
        action="/localization"
        acceptCharset="UTF-8"
        className="localization-form border-0"
        encType={'multipart/form-data'}
        onSubmit={() => selectForm.current.submit()}
    >
        <input type="hidden" name="form_type" defaultValue="localization"/>
        <input type="hidden" name="utf8" defaultValue="✓"/>
        <input type="hidden" name="_method" defaultValue="put"/>
        <input type="hidden" name="return_to" defaultValue={`${window.location.href}`}/>
        <input className="hidden" type="submit" />

        <div className="localization-form__select">
            <select
                className="localization-form-selector"
                name="country_code"
                aria-labelledby="FooterCountryLabelNoScript"
                onChange={() => selectForm.current.submit()}
                defaultValue={localization.country.iso_code}
            >
                { localization.available_countries.map((country, index)=> {
                    return (
                        <option
                            key={`${country.iso_code}_${index}`}
                            value={`${country.iso_code}`}
                        >
                            {`${country.currency.symbol} ${country.currency.iso_code}`}
                        </option>
                    )
                })}
            </select>

            <div className="localization-form-caret">
                <span className="icon-chevron-down"></span>
            </div> 
        </div>
    </form>
  )
}

export default ExtendedLocalization;