import React, { useState } from "react";
import StackedLayout from "./layouts/Stacked";
import TwoColumnLayout from "./layouts/TwoColumn";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { mobileBreakpoint } from "../../../../entrypoints/theme";

const layouts = {
  stacked: StackedLayout,
  "2column": TwoColumnLayout,
};

export const ExtendedFAQ = ({ settings, blocks, props }) => {
  const isMobile = useMobileBreakpoint(mobileBreakpoint);
  let Layout = layouts[settings.layout_type] || StackedLayout;

  let sortedBlocks = blocks;

  if (settings.metaobject_entries) {
    const faqMetaobjectItems = props.faqs;
    const transformedBlocks = faqMetaobjectItems.map(item => ({
      type: 'column_block',
      settings: {
        title: item.title,
        filter_tag: item.filter_tag,
        content: item.content,
        image: item.image
      }
    }));
    sortedBlocks = transformedBlocks;
  } else {
    if (isMobile) {
      // Sort blocks based on the sort_order setting
      sortedBlocks = [...blocks].sort(
          (a, b) => a.settings.sort_order_mobile - b.settings.sort_order_mobile,
      );
    }
  }

  if (isMobile) {
    Layout = layouts[settings.layout_type_mobile] || StackedLayout;
  }

  return (
    <div
      className={`${settings.reveal_animation_class} relative`}
      style={{
        paddingTop: `${settings.padding_top}px`,
        paddingBottom: `${settings.padding_bottom}px`,
      }}
    >
      <div className="relative w-full h-full justify-end items-end">
        <Layout blocks={sortedBlocks} settings={settings} />
      </div>
    </div>
  );
};

export default ExtendedFAQ;
