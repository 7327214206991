import React, { useState } from "react";

import ExtendedButton from "@src/stories/elements/ExtendedButton/ExtendedButton";


export default function ExtendedRecipeListingToolbar ({ sortOptions, selectedFilters, translations ,recipes, setSelectedFilters, emptyFilterState, selectedSort, setSelectedSort, recipesCount}) {
    const [displayLabel, setDisplayLabel] = useState(`${translations.sort_by}`);
    const filters: Record<string, string[]> = selectedFilters;
    const displayFilters = Object.entries(filters).flatMap(([filter, values]) => 
        values.map(value => ({ filter, value }))
    );
 
    function handleRemoveFilter (option, filter) {
        const updatedFilters = { ...selectedFilters };
        updatedFilters[filter] = updatedFilters[filter].filter(item => item !== option);
        setSelectedFilters(updatedFilters);
    }

    function handleClearSelections () {
        setSelectedFilters(emptyFilterState);
    }

    function handleSelectChange (event) {
        const { value } = event.target;
        setSelectedSort(value);
        const selected = sortOptions.find(option => option.value === value);
        if(selected.value == "default") {
            setDisplayLabel(`${translations.sort_by}`);
        }
        else  setDisplayLabel(selected?.label || `${translations.sort_by}`);
    }

  return (
    <div className="recipe-listing__header">
      <div className="recipe-listing__header-toolbar">
        <div className="recipe-listing__header-product-count">
          {recipesCount} {translations.recipe_count_label}
        </div>

        <div className="recipe-listing__header-filters">
          {displayFilters.map((filter, index) => {
      
            return (
              <div
                key={`${filter.value}-${index}`}
                className="recipe-listing__header-filters__item"
              >
                { `${filter.value}`}
                <span className="relative cursor-pointer" aria-hidden="true" onClick={() => handleRemoveFilter(filter.value, filter.filter )}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M16.192 6.34424L11.949 10.5862L7.70697 6.34424L6.29297 7.75824L10.535 12.0002L6.29297 16.2422L7.70697 17.6562L11.949 13.4142L16.192 17.6562L17.606 16.2422L13.364 12.0002L17.606 7.75824L16.192 6.34424Z" />
                  </svg>
                </span> 
              </div>
            )
          })}

          <div  className="recipe-listing__header-filters__item-clear-container hidden lg:block">
            <ExtendedButton
              style="text"
              label={translations.clear_selections}
              className="recipe-listing__header-filters__item-clear"
              onClick={() => handleClearSelections()}
            />
          </div>
        </div>

        <div  className="recipe-listing__header-filters__item-clear-container block lg:hidden">
            <ExtendedButton
              style="text"
              label={translations.clear_selections}
              className="recipe-listing__header-filters__item-clear"
              onClick={() => handleClearSelections()}
            />
          </div>
      </div>

      <div className="recipe-listing__header-sort-wrap">
        <div className="recipe-listing__header-sort">
          <span className="recipe-listing__header-sort__display-label">
            {displayLabel}
          </span>

          <select
            defaultValue={selectedSort}
            className="recipe-listing__header-sort__field"
            onChange={(event) => handleSelectChange(event)}
          >
            {sortOptions.map((option, index) => {
              return (
                <option
                  key={option.value+index}
                  value={option.value}
                >
                  {option.label}
                </option>
              )
            })}
          </select>

          <i className="icon-chevron-down"></i>
        </div>
      </div>
    </div>
  );
};
