import React from "react";
import ExtendedButton from "@src/stories/elements/ExtendedButton/ExtendedButton";
import ExtendedImage from "../../elements/ExtendedImage/ExtendedImage";

import './PromotionTile.scss'

export const PromotionTile = ({ promotion }) => {

    return (
        <div className="promotion-tile relative">
            <div className="category-block__title absolute flex flex-col bottom-0 left-0 p-4 lg:p-6 h-full" style={{ color: `${promotion.color}`, backgroundColor:`${promotion.bg_color}` }}>
                <ExtendedImage
                    imageUrl={promotion.image}
                    isLazy={true}
                    classes={'object-cover w-full min-h-[345px]'} 
                />
                <div className="promotion-tile-header h4">{promotion.title}</div>
                <div className="promotion-tile-subheader">{promotion.subtitle}</div>
                <div className="promotion-tile-button">
                    <ExtendedButton
                        label={promotion.link_label}
                        style={'dark-primary'}
                        link={promotion.link}
                    />
                </div>
              
                
            
            </div>
        </div>
    );
};

export default PromotionTile;
