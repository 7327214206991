import * as React from "react";
import {Headings} from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Headings/Headings";
import ExtendedProductTile from "@stories/components/ProductTile/ProductTile";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import {mobileBreakpoint} from "../../../../entrypoints/theme";

const CompleteTheLook = ({ products, title }) => {
    const isMobile = useMobileBreakpoint(mobileBreakpoint)

    let headingClass = 'h3'
    if (isMobile){
        headingClass = 'h4'
    }

    return (
        <div className="container recommendedproducts lg:px-[106px]">
            <div className="recommendedproducts__header">
                <div className="flex items-center mb-4">
                    <div className="mr-8 text-left">
                        <Headings text={title} type={headingClass}/>
                    </div>

                </div>
            </div>
            <div className="flex-1 border-b border-gray-300 hidden lg:flex my-[14px]"></div>
            <div className="recommendedproducts__container flex-col w-full max-w-full" x-data={"carousel"}
                 x-init={`initialise(${isMobile},${!isMobile},true)`}>
                <div className={'carousel-controls lg:hidden flex gap-x-[18.5px] justify-end text-[24px]'}>
                    <div className={'left'} x-on:click={'prev'}>
                        <i className={'icon icon-arrow-left'}></i>
                    </div>
                    <div className={'right'} x-on:click={'next'}>
                        <i className={'icon icon-arrow-right'}></i>
                    </div>
                </div>
                <div x-ref="carousel" className="carousel w-full h-auto">
                    {products.map((product) => (
                        <div key={product.handle} className="carousel-item w-full">
                            <ExtendedProductTile product={product} swatches={product.swatches} siblings={undefined}/>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CompleteTheLook;
