import shopify from "frontend/js/shopify";

const fields = `
  edges {
    node {
      id
      title
      excerpt(truncateAt: 180)
      image {
        url
      }
      content
      onlineStoreUrl
      read_time: metafield(key: "read_time", namespace: "custom") {
        value
      }
    }
  }
  pageData: pageInfo {
    endCursor
    hasNextPage
    hasPreviousPage
    startCursor
  }
`;

async function requestShopify(query) {
  try {
    return await shopify.requestStorefront(query);

  } catch (e) {
    console.error('Failed to get articles', e);
  }
}

export async function getArticles(cursor) {
  const response = await requestShopify(`{
    articles(first: 12 ${cursor ? `, after: "${cursor}"` : ''}) {
      ${fields}
    }
  }`);

  const { edges, pageData } = response.data.articles;
  return { edges, pageData }
}

export async function getBlogArticles(handle, cursor) {
  const response = await requestShopify(`{
    blog(handle: "${handle}") {
      articles(first: 12 ${cursor ? `, after: "${cursor}"` : ''}) {
        ${fields}
      }
    }
  }`);

  const { edges, pageData } = response.data.blog.articles;
  return { edges, pageData }
}