import React from "react";

import ExtendedImage from "@src/stories/components/ExtendedImage/ExtendedImage";

type ProductTileImageProps = {
  featuredImageUrl: string,
  altText: string,
  images?: Array<string>,
  hoverImageEnabled?: boolean
}

export default function ProductTileImage({
  featuredImageUrl,
  altText,
  images,
  hoverImageEnabled = true
}: ProductTileImageProps) {

  if (!hoverImageEnabled || images && (images.length === 1)) {
    return (
      <ExtendedImage
        imageUrl={featuredImageUrl}
        altText={altText}
        className="product-tile__image"
      />
    )
  }

  return (
    <div className="product-tile__images">
      <div className="product-tile__image-hover">
        <ExtendedImage
          imageUrl={images && images[1]}
          altText={altText}
          className="product-tile__image"
        />
      </div>

      <ExtendedImage
        imageUrl={featuredImageUrl}
        altText={altText}
        className="product-tile__image"
      />
    </div>
  )
}