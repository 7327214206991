import React,  { useRef, useEffect, useState } from "react";
import clsx from "clsx";

import ExtendedButton from '@src/stories/elements/ExtendedButton/ExtendedButton';
import ExtendedSelectField from '@src/stories/elements/ExtendedSelectField/ExtendedSelectField';
import ExtendedRecipeTile from "../ExtendedRecipeTile/ExtendedRecipeTile";
import '../../components/ExtendedListProducts/ExtendedListProducts.scss'

export default function ExtendedRecipeRecommendationsList({ recipes, type = "list", section_id, section_settings, wishlist = false  }) {
  const { title,  button_label , button_url, yotpo_snippet, dummy_id, border, tabbed = false, filter_option_1, filter_value_1, filter_option_2, filter_value_2, filter_option_3, filter_value_3  } = section_settings;
  const componentRef = useRef(null);
  const [ sliderVisible, setSliderVisible ] = useState(true)
  const showDots = type == 'list';
  const showBottomButton = type == 'categories';
  const tabOptions: any = [
    { label: 'Everything', value: 'All' },
    { label: filter_value_1 , value: `${filter_option_1}-${filter_value_1}` },
    { label: filter_value_2, value:  `${filter_option_2}-${filter_value_2}` },
    { label: filter_value_3, value:  `${filter_option_3}-${filter_value_3}` }
  ]

  const FILTER_MAP = {
    [`${filter_option_1}-${filter_value_1}`]: recipes.filter(item=> item.filters?.[`${filter_option_1}`].includes(filter_value_1) ),
    [`${filter_option_2}-${filter_value_2}`]: recipes.filter(item=> item.filters?.[`${filter_option_2}`].includes(filter_value_2) ),
    [ `${filter_option_3}-${filter_value_3}`]: recipes.filter(item=> item.filters?.[`${filter_option_3}`].includes(filter_value_3))
  }
  const [selectedTab, setSelectedTab] = useState(tabOptions[0]);

  if (recipes.length == 0) return;

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (window?.yotpoWidgetsContainer) {
        window.yotpoWidgetsContainer.initWidgets()
        clearInterval(intervalId);
      }
    }, 200);
  }, [])

  function goToSlide(action) {
    const event = new CustomEvent(`carousel-navigate`, {
      detail: { section_id: tabbed ? selectedTab.value : section_id, action }
    });

    window.dispatchEvent(event);
  }

  const [carouselInitialized, setCarouselInitialized] = useState(false);

  useEffect(() => {
    setTimeout(() => {
     setCarouselInitialized(true); // This will trigger a re-render
    }, 2000);
  }, []);


  useEffect(() => {
    const divWithClass = componentRef.current && componentRef.current.querySelector('.flickity-viewport');
    if (componentRef.current) {
      if (divWithClass) {
        setSliderVisible(true);
      } else {
        setSliderVisible(false);
      }
    }
  }, [componentRef.current, componentRef, carouselInitialized, selectedTab]);


    return (
        <div className={`recipe-recommendations product-slider  default-list ${type == 'categories' ? 'categories' : 'pb-12 lg:pb-0'}  ${border ? 'slider-border' : ''} `} ref={componentRef}>
            <div className={`section-top ${type == 'categories' ? 'categories' : ''}`}>
                <div className="product-slider-header"> 
                  <div className="flex flex-col lg:flex-row gap-8 w-full"> 
                    <div className={'h3 lg:h2'}> {title}  </div>
                    { tabbed && (
                      <>
                        <ul className='recipe-recommendations__heading__tabs'>
                          {tabOptions.map(tab => {
                            return (
                              <li
                                key={`${tab.value}-${tab.label}`}
                                className={clsx('recipe-recommendations__heading__tab', {
                                  'recipe-recommendations__heading__tab--active': tab.value === selectedTab.value
                                })}
                                onClick={() => setSelectedTab(tab)}
                              >
                                {tab.label}
                              </li>
                            )
                          })}
                        </ul>
                        <ExtendedSelectField
                          className="recipe-recommendations__heading__mobile-tabs"
                          options={tabOptions}
                          value={selectedTab.value}
                          takeover
                          onChange={(option) => setSelectedTab(option)}
                        /> 
                      </>
                    )}
                    { !tabbed && button_url && <a className="button button-secondary  !hidden lg:!inline-flex" href={`${button_url}`}> { button_label} </a>  }
                  </div>
               
                    {sliderVisible && 
                        <div className='product-slider-header__buttons'>
                           <ExtendedButton
                               icon="chevron-left"
                               style='dark-primary'
                               className="product-slider-header__buttons-arrow prev"
                               onClick={() => goToSlide('previous')}
                           />
   
                           <ExtendedButton
                               icon="chevron-right"
                               style='dark-primary'
                               className="product-slider-header__buttons-arrow next"
                               onClick={() => goToSlide('next')}
                           />
                       </div>
                    }
                 
                </div>
              
            </div>

                {  !tabbed && <div className={`product-slider-carousel !flex`}
                    {
                    ...{
                        'x-data': "extended_carousel",
                        'x-init': `$nextTick(() => { initialise(${showDots}, false, false, true, false, "${section_id}") })`,
                        'x-ref': "carousel"
                    }
                    }
                >
                      {recipes.map((recipe, id) => (
                            <div key={`${selectedTab}-${id}`} className="carousel-item">
                                { <ExtendedRecipeTile recipe={recipe} yotpo_snippet={yotpo_snippet} dummy_id={recipe.dummy_id} wishlist={wishlist} />}
                            </div>
                        ))}
                </div>
                }
           
                {  tabbed && <div className={`product-slider-carousel ${!sliderVisible ? '!flex' : (selectedTab.value === 'All' ? '!flex' : '!hidden')}`}
                    {
                    ...{
                        'x-data': "extended_carousel",
                        'x-init': `$nextTick(() => { initialise(${showDots}, false, false, true, false, "${tabOptions[0].value}") })`,
                        'x-ref': "carousel"
                    }
                    }
                >
                      {recipes.map((recipe, id) => (
                            <div key={`${selectedTab}-${id}`} className="carousel-item">
                                { <ExtendedRecipeTile recipe={recipe} yotpo_snippet={yotpo_snippet} dummy_id={recipe.dummy_id}  wishlist={wishlist}/>}
                            </div>
                        ))}
                </div>
                }
               {tabbed && Object.entries(FILTER_MAP).map(([key,list], index)=> {
                  return (
                    <div className={`product-slider-carousel ${ !sliderVisible ? '!flex' : key == selectedTab.value ? '!flex' : '!hidden'}`}
                    {
                    ...{
                        'x-data': "extended_carousel",
                        'x-init': `$nextTick(() => { initialise(${showDots}, false, false, true, false, "${tabOptions[index+1].value}") })`,
                        'x-ref': "carousel"
                    }
                    }
                >
                        {list.map((recipe, id) => (
                            <div key={`${selectedTab}-${id}`} className="carousel-item">
                                { <ExtendedRecipeTile recipe={recipe} yotpo_snippet={yotpo_snippet} dummy_id={recipe.dummy_id} wishlist={wishlist}/>}
                            </div>
                        ))}
                </div>
                  )
               })}

            { showBottomButton && button_url && 
            <div className="pt-4 pb-6 px-3 lg:hidden"> 
              <a className="button button-secondary  " href={`${button_url}`}> { button_label} </a>  
            </div>
            }        
    
        </div>
    )
}