
import React, { useState, useEffect } from "react";
import ExtendedSelectField from "@src/stories/elements/ExtendedSelectField/ExtendedSelectField";  

const ExtendedProductVariantSelect = ({ props }) => {
  const { product, translations  } = props.settings;
  const { siblings } = product;

  const { sibling_products } = siblings;
  
  const options = sibling_products.filter((prod) => prod.title != product.title).map((product) => ({
    value: product.url,
    label: product.title
  })); 

  const handleChange = (option) => {
    window.location.href = option.value;
  }

  return (
    <div className={'product-variants-select'}>
        <div className={'product-variants-select__label'}> {translations?.variants} </div>
        <ExtendedSelectField
            options={options}
            placeholder={product.title}
            onChange={(option) => handleChange(option)}
        />
    </div>
  )
}


export default ExtendedProductVariantSelect;



