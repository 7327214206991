import ExtendedCheckbox from "@stories/elements/ExtendedCheckbox/ExtendedCheckbox";
import "./AccountFoodClubFields.scss";

export const InputField = ({ label, id, placeholder, type, required = false , handleInputChange}) => (
    <div className="account-page__input-container default-input">
        {label && <label htmlFor={id} className="account-page__label">{label}</label>}
        <input type={type} id={id} name={id} className="account-page__input form-field w-full" placeholder={placeholder} required={required}
               onChange={handleInputChange}
        />
    </div>
);

export const CheckboxField = ({ label, id, onChange }) => (
    <ExtendedCheckbox label={label} name={id} id={id} onChange={onChange} />
);

export const BusinessFields = ({ customSignupFields, handleInputChange, showLabels = true}) => {
    console.log(customSignupFields);
    return (
        <div className="account-page__business-fields">
            {customSignupFields.map((field, index) => {
                return renderFieldOrSelect(field, handleInputChange, index, showLabels);
            })}
        </div>
    );
};

export const renderFieldOrSelect = (field, handleInputChange, key, showLabels) => {
    if (field.field_options && field.field_options.length > 0) {
        return (
            <div key={key} className="account-page__input-container default-input">
                {showLabels && <label htmlFor={field.field_id} className="account-page__label">
                    { field.register_form_label ? field.register_form_label : field.name }
                </label>}
                <select
                    id={field.field_id}
                    name={field.field_id}
                    className="account-page__input form-field"
                    onChange={handleInputChange}
                    required={field.is_required}
                >
                    <option value="" >{`${field.name}`}</option>
                    {field.field_options.map((option, index) => (
                        <option key={index} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
            </div>
        );
    } else {
        return (
            <InputField
                key={key}
                label={showLabels ? (field.register_form_label ? field.register_form_label : field.name) : ""}
                id={field.field_id}
                placeholder={field.field_placeholder}
                type="text"
                required={field.required}
                handleInputChange={handleInputChange}
            />
        );
    }
};
