import * as React from "react";
import { useState, useRef, useEffect } from "react";

import ExtendedCollectionToolbar from "@src/stories/ecommerce/ExtendedCollection/ExtendedCollectionToolbar";
import ExtendedCollectionSidebar from "@src/stories/ecommerce/ExtendedCollection/ExtendedCollectionSidebar";
import ExtendedCollectionMobileFilter from "@src/stories/ecommerce/ExtendedCollection/ExtendedCollectionMobileFilter";
import ExtendedCollectionProductList from "@src/stories/ecommerce/ExtendedCollection/ExtendedCollectionProductList";



const FILTERS_PLACEHOLDER = [
    { 
      filter: 'price',
      label: 'Price',
      options: [
        'Price Option 1',
        'Price Option 2',
        'Price Option 3'
      ]
    },
    { 
      filter: 'contains',
      label: 'Contains',
      options: [
        'Option 1',
        'Option 2',
        'Option 3'
      ]
    },
    { 
      filter: 'type',
      label: 'Type',
      options: [
        'Option 1',
        'Option 2',
        'Option 3'
      ]
    },
    { 
      filter: 'occasion',
      label: 'Occasion',
      options: [
        'Option 1',
        'Option 2',
        'Option 3'
      ]
    },
    { 
      filter: 'sustainable',
      label: 'Sustainable',
      options: [
        'Option 1',
        'Option 2',
        'Option 3'
      ]
    }
  ];

const MOBILE_FILTERS_PLACEHOLDER = [ ...FILTERS_PLACEHOLDER, 
    { 
    filter: 'sortby',
    label: 'Sort by',
    options: [
      'Sort 1',
      'Sort 2',
      'Sort 3'
    ]
  } 
];

const TOTAL_PRODUCTS_PER_PAGE = 8;

export const SearchResults = ({ settings }) => {
    const { products, color_schema,  localization } = settings;
    const [gridItems, setGridItems] = useState([]);

    const promoBlocks = useRef(settings.promotion_blocks);

    const getPromoBlocksToBeInserted = (supposedToBeCurrentlyDisplayed) => {
        const blocksForNextPage = [];
    
        promoBlocks.current.forEach(block => {
          block.position.sort((a, b) => a - b);
          block.position.forEach(position => {
            if (position <= supposedToBeCurrentlyDisplayed) {
              blocksForNextPage.push({
                position: position - 1,
                block
              });
            }
          });
        });
        return blocksForNextPage;
      };

    const insertPromoBlocks = (productsToBeAdded, promoBlocksForNextPage) => {

        let productsCopy = productsToBeAdded.concat([]);
        promoBlocksForNextPage.forEach(({ position, block }) => {
          productsCopy.splice(position, 0, block);
        });
    
        return productsCopy;
      };

    useEffect(() => {
        setGridItems(insertPromoBlocks(products, getPromoBlocksToBeInserted(TOTAL_PRODUCTS_PER_PAGE)));
    }, []);


    return (
        <div className="main-collection container">
            <ExtendedCollectionMobileFilter filters={MOBILE_FILTERS_PLACEHOLDER} color_schema={color_schema} />
            <ExtendedCollectionToolbar settings={settings} />
  
        <div className={`main-collection__content ${color_schema ? `color-${color_schema}` : ''}`}>
          <ExtendedCollectionSidebar filters={FILTERS_PLACEHOLDER} />
          <ExtendedCollectionProductList settings={settings} products={gridItems} localization={localization} />
        </div>
      </div>
    )
}

export default SearchResults;