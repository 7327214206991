import React, {useState} from "react";

import { Paragraph } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Paragraph/Paragraph";
import Image from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Image/Image";
import Video from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Video/Video";
import ExtendedVideo from "@src/stories/elements/ExtendedVideo/ExtendedVideo";

import "./ExtendedAccordion.scss";

const Media = ({videoItem, imageItem , index}) => {
    if (videoItem) {
        return (<ExtendedVideo key={`accordion__content-column-${index}`} videoUrl={videoItem.sources[0].url} autoplay={false}/>)
    }

    else if (imageItem) {
        return ( <Image imageUrl={imageItem} />)
    }
    return null
}


export const ElementAccordion = ({ items, show_caret = true , show_header }) => {
    const [activeIndex, setActiveIndex] = useState(null);

    const onTitleClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    return (
        <div className="accordion">
            { show_header &&
            <h4 className="py-4">Additional Information</h4> }

            {items.map((item, index) => (
                <div key={index} className={`accordion__container border-b py-2 lg:py-3 ${index === 0 ? 'border-t' : ''}`}>
                    <div className="accordion__header"
                         onClick={() => onTitleClick(index)}>
                        <span className={'accordion__image-container'}>
                            <div className={'accordion__title'}>{item.title}</div>
                        </span>
                        { show_caret ? <span className={`accordion__toggle ${activeIndex === index ? 'icon-chevron-up' : 'icon-chevron-down'}`}> </span> 
                        : <span className={'accordion__toggle'}>{activeIndex === index ? '-' : '+'}</span> }
                    </div>
                    <div className={`accordion__content ${(item.image || item.video) ? 'accordion__content-media' : ''} font-light ${activeIndex === index ? 'active' : ''}`}
                        style={{
                            maxHeight: activeIndex === index ? '400px' : '0', // Adjust maxHeight for content size
                        }}>
                        <div className={'accordion__content-column'}>
                             <Media videoItem={item.video} imageItem={item.image} index={index}/>
                        </div>
                        <div className={'accordion__content-column'}>
                            <Paragraph text={item.content} />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export  default ElementAccordion;