import UPDATE_CUSTOMER_DETAILS from "../../../graphql/2024_01/customer/update.graphql";
import shopifyHelper from "../../../shopify";
import { hasher } from "../../stores/hasher";

export default {
    name: 'foodclub',
    component() {
        return {
            showBusinessFields: false, // Boolean to track the visibility of business fields
            isLogin: false, // Boolean to track whether the user is in the login or register view
            metafieldObject: {},
            useAuthenticated: false,
            errorMessage: "",
            init() {
                // Check URL hash and set the initial form state
                const hash = window.location.hash;

                if (hash === '#login') {
                    this.isLogin = true;
                } else if (hash === '#register') {
                    this.isLogin = false;
                }

                this.toggleForm(); // Toggle forms based on the isLogin state
            },

            toggleForm() {
                // Toggle between login and register forms
                if (this.isLogin) {

                    this.$refs.signUpBtn?.classList.remove('border-black');
                    this.$refs.loginBtn?.classList.add('border-black');
                } else {
                    this.$refs.signUpBtn?.classList.add('border-black');
                    this.$refs.loginBtn?.classList.remove('border-black');
                }

                // Scroll to the top of the page after form toggle
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth' // Adds smooth scrolling
                });
            },

            handleFormToggle(isLogin) {
                console.log('isLogin', isLogin)
                // Set to true for login, false for register
                this.isLogin = isLogin;
                this.toggleForm();
            },

            handleBusinessCheckbox() {
                // Update the boolean based on checkbox state
                this.showBusinessFields = this.$refs.showBusiness.checked;
            },

            async submitRegister(event) {
                this.detailsForm = document.getElementById("registerForm");
                this.updatedSuccessfully = false;
                const formData = new FormData(this.detailsForm);
                this.processing = true;

                await hasher.store().dispatchHash();

                const metafieldArray = [
                    {
                        namespace: "custom",
                        key: "business_name",
                        value: formData.get("business_name"),
                    },
                    {
                        namespace: "custom",
                        key: "ABN",
                        value: formData.get("abn"),
                    },
                    {
                        namespace: "custom",
                        key: "role",
                        value: formData.get("role"),
                    },
                    {
                        namespace: "custom",
                        key: "Industry",
                        value: formData.get("industry"),
                    },
                    {
                        namespace: "custom",
                        key: "gift_frequency",
                        value: formData.get("gift_frequency"),
                    },
                    {
                        namespace: "custom",
                        key: "common_gift_recipient",
                        value: formData.get("common_gift_recipient"),
                    },
                    {
                        namespace: "custom",
                        key: "company_size",
                        value: formData.get("company_size"),
                    },
                    {
                        namespace: "custom",
                        key: "website",
                        value: formData.get("website"),
                    },
                ].filter((item) => item.value != null);

                const customerPayload = {
                    customer: {
                        first_name: formData.get("first_name"),
                        last_name: formData.get("last_name"),
                        email: formData.get("email"),
                        phone: formData.get("phone"),
                        password: formData.get("password"),
                        password_confirmation: formData.get("password_confirmation"),
                        tags: "Test1234",
                        metafields: metafieldArray,
                    }
                }

                console.log('SMS', formData.get('optInSMS'));
                if (formData.get('optInSMS') && formData.get("phone")){
                    customerPayload.customer.sms_marketing_consent = {
                        state: "subscribed",
                        opt_in_level: "single_opt_in"
                    }
                }
                if (formData.get('optInEmail')){
                    customerPayload.customer.email_marketing_consent = {
                        state: "subscribed",
                        opt_in_level: "single_opt_in"
                    }
                }

                console.log('Submitting data', customerPayload);



                let jsonResponse = null;
                if (this.useAuthenticated) {
                    jsonResponse = await shopifyHelper.authenticatedFetch(data);
                } else {
                    jsonResponse = await shopifyHelper.unauthenticatedRestFetch(
                        'customers',
                        customerPayload,
                    );
                }

                console.log('Response', jsonResponse);


                if (jsonResponse.customer) {
                    this.updatedSuccessfully = true;
                    this.updatedError = false;
                    this.isLogin = true;
                    // document.querySelector('#loginForm input[type="email"]').value = formData.get("email");
                    // document.querySelector('#loginForm input[type="password"]').value = formData.get("password");
                    // document.querySelector('#loginForm button[type="submit"]').form.submit()
                    const myForm = document.querySelector('#loginForm');
                    window.Shopify.captcha.protect(myForm, () => {
                        myForm.elements["customer[email]"].value = formData.get("email");
                        myForm.elements["customer[password]"].value = formData.get("password");
                        myForm.elements["utf8"].value = '✓';
                        myForm.elements["form_type"].value = 'customer';
                        myForm.submit();
                    });
                    return true;
                } else {
                    this.updatedError = true;
                    this.updatedSuccessfully = false;
                    this.processing = false;
                    if (jsonResponse.errors) {
                        // Initialize an empty string to accumulate error messages
                        let errorMessage = "";

                        // Loop through the keys in the errors object
                        Object.keys(jsonResponse.errors).forEach((key) => {
                            // Check if the key is either "email" or "phone"
                            if (["phone", "email"].includes(key)) {
                                // Append the error message with key and the corresponding error message
                                errorMessage += `${key} ${jsonResponse.errors[key].join(", ")}\n`;
                            } else {
                                // Handle other types of errors
                                errorMessage += `Error: ${jsonResponse.errors[key].join(", ")}\n`;
                            }
                        });

                        // Set the constructed error message
                        this.errorMessage = errorMessage;
                    } else {
                        // Set a generic error message if no specific errors are found
                        this.errorMessage = "There was a problem updating your details.";
                    }
                }
                this.processing = false;
                this.response = jsonResponse;
                return jsonResponse;
            },
        };
    },
};
