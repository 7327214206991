import React from "react";

import { Default } from "./Layouts/Default";
import { FoodClub } from "./Layouts/FoodClub";

const layouts = {
  newsletter: Default,
  blockCTA: FoodClub,
};

export default function NewsletterSignup({ settings, onClick }) {
  const { section } = settings;
  const sectionLayoutType = section?.newsletter_type || "newsletter";
  const Layout = layouts[sectionLayoutType];

  return <Layout settings={settings} onClick={onClick} />;
}
