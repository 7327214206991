import React from "react";
import ExtendedSlider from "@src/stories/components/ExtendedSlider/ExtendedSlider";

import ExtendedFilterCollapsible from '@src/stories/components/ExtendedFilterCollapsible/ExtendedFilterCollapsible'
import ExtendedCheckbox from "@src/stories/elements/ExtendedCheckbox/ExtendedCheckbox";
import ExtendedButton from "@src/stories/elements/ExtendedButton/ExtendedButton";

export const ExtendedRecipeListingMobileFilterTakeover = ({ modal_id, isOpen, filters, selectedFilters, setSelectedFilters, onClose, color_schema, translations }) => {
    if (!isOpen) return null;

    const handleCheck = (checked, option, filter) => {
        const updatedFilters = { ...selectedFilters };
    
        if (checked) {
            // Add the option to the specified filter array
            updatedFilters[filter].push(option);
        } else {
            // Remove the option from the specified filter array
            updatedFilters[filter] = updatedFilters[filter].filter(item => item !== option);
        }
    
        setSelectedFilters(updatedFilters);
       }
    
       const isChecked = (option, filter) => {
        return selectedFilters[filter.filter] && selectedFilters[filter.filter].includes(option);
       }
    
       const handleFilterClear = (filter) => {
        const updatedFilters = { ...selectedFilters, [filter]: [] };
        setSelectedFilters(updatedFilters);
       }

    return (
        <div className="recipe-listing__mobile-sidebar-filters">
        <div id={`${modal_id}`} className={`recipe-listing__mobile-sidebar-takeover ${color_schema ? `color-${color_schema}` : ''}`} style={{backdropFilter: "blur(6px)"}}>
            <div className="recipe-listing__mobile-sidebar-takeover-header">
                <div className="recipe-listing__mobile-sidebar-takeover-header-sticky"> <span> Filters </span> <span className="icon-chevron-up text-[24px]" onClick={() => onClose()}></span></div>
       
            </div>
            <div className='recipe-listing__mobile-sidebar-takeover-container'>
                <div className="flex flex-col justify-between gap-2">
                {filters.map((filter,index) => {
                        if(filter.options.length == 0) return null;
                        return (
                        <ExtendedFilterCollapsible
                            active
                            title={filter.label}
                            filter={filter.filter}
                            key={`${filter.filter}-${index}`}
                            onClearClick={(value)=>{handleFilterClear(value)}}
                            clearLabel={translations.clear_filter || 'Clear'}
                        >
                            {filter.options.map((option, index) => {
                            return (
                                <ExtendedCheckbox
                                key={filter+option+index}
                                name={option}
                                label={option}
                                square
                                checked={isChecked(option, filter)}
                                onChange={(event)=> { handleCheck(event.target.checked, option , filter.filter) }}
                                />
                            )
                            })}
                        </ExtendedFilterCollapsible>
                        )
                    
                })}
                </div>
            </div>
            <div className="recipe-listing__mobile-sidebar-takeover-button-container">
                <ExtendedButton
                label={'Search'}
                style="secondary"
                onClick={() => onClose()}
                />
            </div>
        </div>
        </div>
    )
}

ExtendedRecipeListingMobileFilterTakeover.propTypes = {};
