import * as React from "react";
import { useState, useEffect } from "react";
import { Paragraph } from "@src/stories/elements/Paragraph";
import "./Marquee.scss";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";

const ExtendedMarquee = ({
  settings,
  props,
  marquee_blocks,
  marquee_speed,
  marquee_play_pause,
  block_gap,
}) => {
  const isMobile = useMobileBreakpoint(settings.mobile_breakpoint);
  const [isPaused, setIsPaused] = useState(false);
  const [animationDuration, setAnimationDuration] = useState(marquee_speed);
  const { globals } = props;

  const getFontType = (marquee) =>
    isMobile ? marquee.settings.font_type_mobile : marquee.settings.font_type;

  const handleMouseEnter = () => {
    if (settings.pause_on_hover) {
      setIsPaused(true);
    }
  };

  const handleMouseLeave = () => {
    if (settings.pause_on_hover) {
      setIsPaused(false);
    }
  };

  useEffect(() => {
    const calculateDuration = () => {
      if (marquee_speed && marquee_speed > 0) {
        const viewportWidth = window.innerWidth;
        const duration = viewportWidth / marquee_speed;
        setAnimationDuration(duration);
      } else {
        setAnimationDuration(null);
      }
    };

    calculateDuration();
    window.addEventListener("resize", calculateDuration);

    return () => {
      window.removeEventListener("resize", calculateDuration);
    };
  }, [marquee_speed]);

  const shouldPlayMarquee = (marquee) => {
    return isMobile ? settings.marquee_play_pause_mobile : marquee_play_pause;
  };

  const renderMarqueeContent = (marquee, marquee_id) => {
    const text =
      globals.is_customer_logged_in &&
      marquee.settings.text.includes("Track My Impact")
        ? "View Your Impact"
        : marquee.settings.text;

    return (
      <div
        className={`marquee-content ${
          !marquee_speed || marquee_speed === 0 ? "flex-grow" : ""
        }`}
        key={marquee_id}
        style={{
          marginRight: `${marquee_speed && marquee_speed > 0 ? block_gap : 0}px`,
        }}
      >
        {marquee.settings.icon && (
          <img
            src={marquee.settings.icon}
            alt="Icon"
            className="marquee-icon h-5"
          />
        )}
        <Paragraph text={text} type={getFontType(marquee)} />
      </div>
    );
  };

  return (
    <div
      className="marquee-banner"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={`marquee-track ${settings.justify_setting} ${shouldPlayMarquee(settings) && !isPaused ? "play" : "pause"} ${!marquee_speed || marquee_speed === 0 ? "flex" : ""}`}
        style={{
          animationDuration: animationDuration
            ? `${animationDuration}s`
            : "unset",
        }}
      >
        {Array.from({ length: settings.render_iterations }).map(
          (_, iteration) =>
            marquee_blocks.map((marquee, marquee_id) =>
              marquee.settings.url ? (
                <a
                  href={marquee.settings.url}
                  key={`${iteration}-${marquee_id}`}
                  className="marquee-link"
                >
                  {renderMarqueeContent(marquee, `${iteration}-${marquee_id}`)}
                </a>
              ) : (
                renderMarqueeContent(marquee, `${iteration}-${marquee_id}`)
              ),
            ),
        )}
      </div>
    </div>
  );
};

export default ExtendedMarquee;
