export default {
    name: 'nosto',
    component() {
        return {
            recommendations: Alpine?.$persist([]).as('recommnendations'),
            init() {
                // console.log("Alpine nosto init called")
                this.debug();
                this.fetchRecommendations();
            },
            debug() {
                nostojs(api => {
                    // console.log("Avaiable methods are:")
                    // console.log(api)

                    api.loadRecommendations()
                        .then(data => {
                            // console.log("Avaiable data is: ")
                            // console.log(data)
                        })
                });

            },
            fetchRecommendations() {
                // console.log('fetch recommendations called')
                nostojs(api => {
                    api.loadRecommendations()
                    .then(data => {
                            // Parsed template from Nosto
                        // console.log("Avaiable data is: ")
                        // console.log(data.recommendations)
                    })
            });
        }
    }
}
}

