import React from "react";
import StackedLayout from "./layouts/Stacked";
import TwoColumnLayout from "./layouts/TwoColumn";
import OverlayLayout from "./layouts/Overlay";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { mobileBreakpoint } from "../../../../entrypoints/theme";

const layouts = {
  stacked: StackedLayout,
  "2column": TwoColumnLayout,
  "overlay": OverlayLayout,
};

const ExtendedPageHeader = ({ settings, blocks, customMetafield }) => {
  const isMobile = useMobileBreakpoint(mobileBreakpoint);
  let Layout = layouts[settings.section.layout_type] || StackedLayout;
  let sortedBlocks = blocks;
  if (isMobile) {
    // Sort blocks based on the sort_order setting
    sortedBlocks = [...blocks].sort(
      (a, b) => a.settings.sort_order_mobile - b.settings.sort_order_mobile,
    );
  }

  if (isMobile) {
    Layout = layouts[settings.section.layout_type_mobile] || StackedLayout;
  }

  return (
    <div
      className={`${settings.section.reveal_animation_class} relative`}
      style={{
        paddingTop: `${settings.section.padding_top}px`,
        paddingBottom: `${settings.section.padding_bottom}px`,
      }}
    >
      <div className="relative w-full h-full justify-end items-end">
        <Layout blocks={sortedBlocks} settings={settings} customMetafield={customMetafield}/>
      </div>
    </div>
  );
};

export default ExtendedPageHeader;
