export default {
    getPublicAccessToken(){
        if (Shopify.shop === 'maggiebeer-dev.myshopify.com'){
        return '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'
        }
        return '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'
       
    },
    getStorefrontToken(){
        if (Shopify.shop === 'maggiebeer-dev.myshopify.com'){
            return '9a4ed9aea2e03c45ddd1d8dd2adb3cb7'
        }
        return '1a917b57ec15235a7f345e5d2f66609e'
    },

    getBrazeToken(){
        if (Shopify.shop === 'maggiebeer-dev.myshopify.com'){
            return '813e1c8e-282a-4446-baee-d1fc906c81cb'
        }
        return '813e1c8e-282a-4446-baee-d1fc906c81cb'
    }
}
