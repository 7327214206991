import React from "react";

export default function ProductTileBadges({ badges }) {
  return (
    <div className="search-tile__badges">
      {badges.map((badge, index) => (
        <div key={`badge_${index}`}>
          <div
            className="search-tile__badge"
            style={{ backgroundColor: `${badge[1] || '#FFF'}`, color:`${badge[2] || '#000000'}` }}
          >
            <span>{badge[0]}</span>
          </div>
        </div>
      ))}
    </div>
  )
}