import React, { useState } from "react";
import Paragraph from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Paragraph/Paragraph";
import ExtendedButton from "@src/stories/elements/ExtendedButton/ExtendedButton";
import { Headings } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Headings/Headings";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { mobileBreakpoint } from "../../../../../entrypoints/theme";
import { clsx } from "clsx";
import ExtendedImageComponent from "../../../elements/ExtendedImage/ExtendedImage";

const TwoColumnGridLayout = ({ reverselayout, description, settings, blocks }) => {
    const isMobile = useMobileBreakpoint(mobileBreakpoint);

    const getImage = (isMobile, settings) => {
        return isMobile
            ? settings.image_mobile?.src || settings.image_mobile || settings.image?.src || settings.image
            : settings.image?.src || settings.image;
    };

    const getVideo = (isMobile, settings) => {
        return isMobile
            ? (settings.video_mobile?.sources?.[0]?.url ?? settings.video_mobile ?? settings.video?.sources?.[0]?.url ?? settings.video)
            : (settings.video?.sources?.[0]?.url ?? settings.video);
    };

    const [primaryImage, setPrimaryImage] = useState(getImage(isMobile, settings));
    const [primaryVideo, setPrimaryVideo] = useState(getVideo(isMobile, settings));
    const [primaryVideoPoster, setPrimaryVideoPoster] = useState(settings.video?.preview_image.src);

    const handleThumbnailClick = (block) => {
        console.log('Block Clicked', block);
        if (block.type == 'media') {
            const newPrimaryImage = block.settings.image || null;
            const newPrimaryVideo = block.settings.video?.sources[0]?.url || null;
            const newPrimaryVideoPoster = block.settings.videoPoster || primaryVideoPoster;

            console.log('primary image', newPrimaryImage);
            console.log('primary video', newPrimaryVideo);
            setPrimaryImage(newPrimaryImage);
            setPrimaryVideo(newPrimaryVideo);
            setPrimaryVideoPoster(newPrimaryVideoPoster);
        }
    };

    let heading = settings.title;
    let type = "twi-twocolumn";
    const imageWidth = isMobile ? settings.width_mobile : settings.width;

    if (settings) {
        if (settings.content) {
            description = settings.content;
        }
        if (settings.reverse_layout) {
            reverselayout = settings.reverse_layout;
        }

        if (settings.classes) {
            type = settings.classes;
        }
    }

    if (settings.render_type !== "all") {
        if (isMobile && settings.render_type !== "mobile-only") return null;
        if (!isMobile && settings.render_type !== "desktop-only") return null;
    }

    console.log('TwoColumnGrid', blocks);
    return (
        <section className={`w-full ${type}`}>
            <div className={`twi-twocolumn__container lg:h-[${settings.container_height}px] overflow-hidden relative`}>
                <div className={`twi-twocolumn__content gap-x-[20px] 
                ${settings.padding_area == 'inside' &&
                ` pt-[${ settings.padding_top_mobile }px]` +
                ` lg:pt-[${ settings.padding_top_desktop }px]` +
                ` pb-[${ settings.padding_bottom_mobile }px]` +
                ` lg:pb-[${ settings.padding_bottom_desktop }px]` +
                ` px-[${ settings.padding_left_right_mobile }px]` +
                ` lg:px-[${ settings.padding_left_right_desktop }px]`} 
                ${clsx({
                    "flex-col": isMobile,
                    "flex-col-reverse": isMobile && settings.reverse_layout_mobile,
                    "flex-row-reverse": reverselayout && settings.reverse_layout,
                })}`}>
                    <div className="flex flex-col justify-center space-y-4 lg:basis-1/2"
                         style={{flexBasis: imageWidth + "%"}}>
                        <div className={`twi-twocolumngrid__primary-thumbnail flex h-full w-full`}>
                            {primaryImage ? (
                                <ExtendedImageComponent imageUrl={primaryImage} classes={"object-cover h-full w-full"}/>
                            ) : primaryVideo ? (
                                <video className={"object-cover h-full w-full"} autoPlay loop muted poster={primaryVideoPoster}
                                       preload="metadata" playsInline>
                                    <source src={primaryVideo} type="video/mp4"></source>
                                </video>
                            ) : null}
                        </div>
                        <div className="twi-twocolumngrid__thumbnails">
                            {blocks.map((block, index) => (
                                (block.settings.image || block.settings.video) && (
                                    <div key={block.key || index} className="twi-twocolumngrid__secondary-thumbnail flex-1"
                                         onClick={() => handleThumbnailClick(block)}>
                                        {block.settings.image ? (
                                            <ExtendedImageComponent imageUrl={block.settings.image}
                                                                    classes={"object-cover w-full"}/>
                                        ) : block.settings.video ? (
                                            <video className={"object-cover w-full"} autoPlay loop muted
                                                   poster={block.settings.videoPoster} preload="metadata" playsInline>
                                                <source src={block.settings.video.sources[0].url} type="video/mp4"></source>
                                            </video>
                                        ) : null}
                                    </div>
                                )
                            ))}
                        </div>
                    </div>
                    <div className="twi-container" style={{flexBasis: `calc(${100 - parseFloat(imageWidth)}%)`}}>
                        <div className={`twi-content h-full justify-${settings.content_position_desktop}`}>
                            <div
                                className={`w-full flex flex-col items-${settings.content_alignment_mobile} lg:items-${settings.content_alignment_desktop}`}>
                                <Paragraph text={settings.tagline} type={"bc"}/>
                                <Headings text={heading} type={settings.heading_type}/>
                                <Paragraph text={description} type={"b3 py-[16px]"}/>
                                <div
                                    className={`twi-twocolumngrid__buttons-container flex gap-x-[8px] self-${settings.content_alignment_mobile} lg:self-${settings.content_alignment_desktop}`}>
                                    {blocks.map((block, index) => (
                                        <div key={block.key || index}>
                                            {block.settings.button_text && (
                                                <ExtendedButton
                                                    label={block.settings.button_text}
                                                    style={block.settings.button_style}
                                                    link={block.settings.button_url}
                                                    icon={block.settings.button_icon}
                                                    reverse={block.settings.button_reverse}
                                                    icon_size={20}
                                                    onClick={undefined}
                                                />
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TwoColumnGridLayout;
