import React from "react";

import {Headings} from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Headings/Headings";
import {Paragraph} from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Paragraph/Paragraph";
import {EcommProductCard} from "@mindarc/arctheme-components/frontend/src/stories/components/ecommerce/ProductCard/ProductCard";
import AlpineWidget from "@mindarc/arctheme-components/frontend/src/stories/components/AlpineWidget";

export default function ListProductsTabbed({ products, settings }) {
	return (
		<div className="tabbed-list">
			<div className={'section-top px-8'}>
				<Headings text={settings.title} type={'h2'} />
				<Paragraph text={settings.subtext} />
			</div>

			<div className="carousel-container" x-data="carouselFilter()">
				<div className="tabbed-list__container">
					<div className={'tabbed-list__tabs'}>
						{products.map((product, i) => (
							<AlpineWidget key={product.id} template={`<a key=${i} class='carousel-tab-link button-link'
								x-on:click="changeActive(${i})" x-bind:class="{ 'selected': ${i} === active }">${product.settings.title}</a>`} />
						))}
					</div>
				</div>
				{
					products.map((product, index) => (
						<div className="h-1/2 row-start-2 col-start-1"
							key={index} // Add a React key prop here for list rendering
							x-show={`active == ${index}`}
							x-transition:enter="transition ease-out duration-300"
							x-transition:enter-start="opacity-0 transform scale-90"
							x-transition:enter-end="opacity-100 transform scale-100"
							x-transition:leave="transition ease-in duration-300"
							x-transition:leave-start="opacity-100 transform scale-100"
							x-transition:leave-end="opacity-0 transform scale-90"
						>
							<div className="carousel-item-container" x-data="carousel()">
								<div className="carousel col-start-1 row-start-1" x-ref="carousel">
									{
										product?.collection?.products?.map((product, productIndex) => (
											<div className="carousel-item" key={product.id}> {/* Correct placement of key prop */}
												<EcommProductCard product={product} swatches={product.swatches}/>
											</div>
										))
									}
								</div>
							</div>
						</div>
					))
				}
			</div>
		</div>
	)
}
