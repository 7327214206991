import React, { useEffect, useState } from "react";
import { EcommProductPrice } from "@mindarc/arctheme-components/frontend/src/stories/components/ecommerce/Price/Price";
import { Button } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Button/Button";
import Image from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Image/Image";
import { clsx } from "clsx";
import { getAlpineStore } from "../../../../entrypoints/theme";
import shopify from "../../../../js/shopify";

export const ExtendedProductTile = ({ product, swatches = [], siblings, show_add = false, show_price_inline = false }) => {
    const store = getAlpineStore('modelview');

    const [modelView, setModelView] = useState(store.isModelView);
    const [isHovered, setIsHovered] = useState(false);
    const [relatedProducts, setRelatedProducts] = useState([]);

    let imageIndex = 0;
    let hoverIndex = 1;

    useEffect(() => {
        // // console.log('transformed', transformedProducts)
        async function fetchProductSwatches(product) {
            const relatedProductsShopify = await shopify.getRelatedProducts([product]);
            // // console.log('related products', relatedProductsShopify);
            setRelatedProducts(relatedProductsShopify);
        }

        fetchProductSwatches(product);

        const handleModelViewToggle = (event) => {
            setModelView(event.detail.isModelView);
        };

        window.addEventListener('modelview-toggled', handleModelViewToggle);

        return () => {
            window.removeEventListener('modelview-toggled', handleModelViewToggle);
        };
    }, []);

    const onlyFirstImage = product?.images?.length === 2 && product.images.some(image => image.src?.includes('_s') || image.src?.includes('_s_'));
    const secondImageIsSwatch = product?.images?.[1]?.src?.includes('_s') || product?.images?.[1]?.src?.includes('_s_');

    if (modelView && !onlyFirstImage) {
        if ((product?.images?.length - 1) > imageIndex) {
            imageIndex = 1;
            hoverIndex = 0;
        } else {
            imageIndex = 0;
            hoverIndex = 0;
        }
    } else {
        hoverIndex = 1;
    }

    const productPrice = product?.price / 100;
    const comparePrice = product?.compare_at_price / 100;

    const createKeyValuePairs = (tags) => {
        const tagsArray = Array.isArray(tags) ? tags : [tags];

        return tagsArray.reduce((acc, tag) => {
            const [key, value] = tag.split(':');
            if (value) {
                if (acc[key]) {
                    if (Array.isArray(acc[key])) {
                        acc[key].push(value);
                    } else {
                        acc[key] = [acc[key], value];
                    }
                } else {
                    acc[key] = value;
                }
            }
            return acc;
        }, {});
    };

    const productTags = createKeyValuePairs(product.tags);
    const productSwatches: any = [];

    if (product.media) {
        product.images = product.media;
    }

    if (productTags?.colour) {
        const colors = Array.isArray(productTags.colour) ? productTags.colour : [productTags.colour];

        colors.forEach(colorHandle => {
            const siblingProduct = siblings?.find(sibling => sibling.handle === colorHandle);
            let isProduct = false;
            const matchingProduct = siblings?.find(sibling => sibling.handle === product.handle);

            if (matchingProduct === siblingProduct) {
                isProduct = true;
            }
            if (siblingProduct) {
                productSwatches.push({
                    url: '/products/' + siblingProduct.handle,
                    name: product.title,
                    image: siblingProduct.images?.find(image => image.src?.match(/_s_|_s(\.|$)/))?.src || siblingProduct.images[siblingProduct.images?.length - 1].src,
                    is_product: isProduct
                });
            }
        });
    }

    if (relatedProducts) {
        const colors = Array.isArray(productTags.colour) ? productTags.colour : [productTags.colour];

        colors.forEach(colorHandle => {
            const relatedProduct = relatedProducts?.find(related => related.handle === colorHandle);
            let isProduct = false;
            const matchingProduct = relatedProducts?.find(related => related.handle === product.handle);

            if (matchingProduct === relatedProduct) {
                isProduct = true;
            }
            if (relatedProduct) {
                productSwatches.push({
                    url: '/products/' + relatedProduct.handle,
                    name: product.title,
                    image: relatedProduct.images?.edges?.find(edge => edge.node.src?.match(/_s_|_s(\.|$)/))?.node.src || relatedProduct.images?.edges[relatedProduct.images.edges.length - 1]?.node.src,
                    is_product: isProduct
                });
            }
        });
    }

    const getImageUrl = (image) => {
        if (typeof image === 'object' && image !== null && image.src) {
            return image.src;
        }
        return image;
    }

    const groupFit = productTags?.Group_Fit || productTags?.group_fit;

    return (
        <div className={'theme__product-card'}>
            <div className={'productcard__content'}>
                <a
                    href={'/products/' + product.handle}
                    className="w-full"
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    {product?.images[isHovered && !secondImageIsSwatch ? hoverIndex : imageIndex] && <Image imageUrl={getImageUrl(product.images[isHovered && !secondImageIsSwatch ? hoverIndex : imageIndex])} />}
                </a>
                <div className={'flex justify-between mt-[16px] flex-col lg:flex-row'}>
                    <div>
                        <a href={'/products/' + product?.handle}>
                            <p className={'b2 text-[#54565A]'}>{product?.title}</p>
                        </a>
                        <p className={'b3 uppercase text-[13px] font-[500] text-[#54565A]'}>{groupFit}</p>
                    </div>
                    <div className={'flex flex-col lg:items-end'}>
                        <span className={'b1 font-regular'}>
                            {!show_price_inline &&
                                <EcommProductPrice price={'$' + productPrice.toFixed(2)} compare_at_price={comparePrice} />
                            }
                        </span>
                        <span className={'b3 line-through font-medium text-[#BBBBBD]'}>
                            {comparePrice > 0 && productPrice < comparePrice &&
                                <div className="product__price" data-prodify-price-container="">
                                    ${comparePrice.toFixed(2)}
                                </div>
                            }
                        </span>
                    </div>
                </div>

                <div className={'mt-[16px]'}>
                    <p className={'b3 text-[14px] font-[400] text-[#CDA176]'}>{productTags.automatic}</p>
                </div>
            </div>
        </div>
    );
};

export default ExtendedProductTile;
