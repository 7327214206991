import React from "react";

import ExtendedArticleFeaturedProduct from "./ExtendedArticleFeaturedProduct";
import ExtendedArticlePageHeader from "../components/ExtendedArticlePageHeader";

export default function ExtendedArticleOneColumn({ settings }) {
  const { content, featured_products } = settings;
  const contents = content.split('<p>[FEATURED_PRODUCTS]</p>');

  const articleSettings = {
    image: settings?.article?.image,
    title: settings?.article?.title,
    excerpt: settings?.article?.excerpt,
    author: settings?.article?.author,
    datePublished: settings?.article?.datePublished,
    read_time: settings?.read_time,
    type: settings?.type,
    type_color: settings?.type_color,
    type_bg: settings?.type_bg
  } 
  return (
    <div className="extended-article p-6 px-5 lg:px-16 lg:pb-28">
      <ExtendedArticlePageHeader settings={articleSettings}/>
      <div className="max-w-[1000px] mx-auto pt-4">
        {contents.map((content, index) => {
          return (
            <div key={`content_${index}`}>
     
              <div  className="extended-article__content-section" dangerouslySetInnerHTML={{ __html: content }} />

              {(contents.length - 1) !== index &&
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
                  {featured_products.map(product => {
                    return (
                      <ExtendedArticleFeaturedProduct
                        key={product.id}
                        product={product}
                      />
                    )
                  })}
                </div>
              }
            </div>
          )
        })}
      </div>
    </div>
  )
}