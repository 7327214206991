import shopifyHelper from "../../shopify";
import storefrontRequestConfig from '../../api'

export const orders = {
    name: 'orders',
    component() {

      return {
         deliveredDate: "",
          getOrders (orders) {
            const data = `{
              order(id: "gid://shopify/Order/5692673687721") {
                fulfillments(first: 10) {
                  deliveredAt
                  createdAt
                }
              }
            }`

            console.log('test orders');
            this.deliveredDate = "Oct 14"
            // eslint-disable-next-line no-undef
            return axios.post(`https://${Shopify.shop}/api/2022-10/graphql.json`, data, storefrontRequestConfig).then(({ data }) => {
         
              console.log(data);
              return data
            }).catch((error) => {
              // console.log('Shopify Products Query', error)
              throw error
            })
          }
      }
  }
}

export default orders;
