import React, { useState, useEffect } from "react";
import useCustomerLogin from "@src/hooks/useCustomerLogin";
import AccountForgotPassword from "@stories/ecommerce/Account/AccountForgotPassword";
import useCustomerAccessToken from "@src/hooks/useCustomerAccessToken";

const AccountLoginForm = () => {
    const { loginCustomer, loading, error, success } = useCustomerLogin();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const { getAccessToken, loading: accessLoading, errorMessage: accessError, successMessage: accessSuccess } = useCustomerAccessToken();
    const handleSubmit = async (e) => {
        e.preventDefault();
        const data: any = await getAccessToken(email, password)

        if (data.success) {
            await loginCustomer(email, password);
        }
    };
    const toggleForgotPassword = (e) => {
        e.preventDefault();
        setShowForgotPassword(!showForgotPassword);
    }
    return (
        <>
            {!showForgotPassword && <form data-shopify-captcha="true" id="loginForm" onSubmit={handleSubmit} className="account-page__form space-y-6">

            {(error || accessError) && <p style={{ color: 'red' }}>{error || accessError}</p>}
            {(success || accessSuccess) && <p style={{ color: 'green' }}>Login successful!</p>}

                <div className={`account-page__input-container default-input`}>
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        name={"email"}
                        placeholder={"Enter your email"}
                        className="account-page__input form-field"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>

                <div className={`account-page__input-container default-input`}>
                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        id="password"
                        placeholder={"Enter your password"}
                        name={"password"}
                        className="account-page__input form-field"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <div className={'flex flex-col'}>
                    <button type="submit" className="account-page__submit-btn button button-primary w-full">{loading ? "Logging in..." : "Login"}</button>
                </div>

                <p class="account-page__forgot-password text-center">
                    <a href="#forgotpassword" onClick={toggleForgotPassword}>
                        Forgot your password?
                    </a>
                </p>
            </form>}

            {showForgotPassword && <AccountForgotPassword onCancel={toggleForgotPassword}/>}
        </>
    );
};

const InputField = ({ label, id, type, required = false }) => (
    <div className="account-page__input-container default-input">
        <label htmlFor={id} className="account-page__label">{label}</label>
        <input type={type} id={id} name={id} className="account-page__input form-field" required={required} />
    </div>
);

export default AccountLoginForm;
