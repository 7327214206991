import React from 'react';
import { Headings } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Headings/Headings";
import { Paragraph } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Paragraph/Paragraph";

import ExtendedButton from '@src/stories/elements/ExtendedButton/ExtendedButton';
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { mobileBreakpoint } from 'frontend/entrypoints/theme';

export default function PrimaryHeroBanner ({ settings }) {
  const isMobile = useMobileBreakpoint(mobileBreakpoint);
  const { section, blocks } = settings;
  const {
    banner_type,
    is_auto_slide,
    auto_slide_duration,
    vertical_content_position_desktop,
    horizontal_content_position_desktop
  } = section;

  const autoPlay = is_auto_slide ? (auto_slide_duration * 1000) : 0;

  const headerEl = document.querySelector('header');
  const headerHeight = headerEl?.getBoundingClientRect().height || 0;
  
  const headerPromotionEl = document.querySelector('.header-promotion');
  const headerPromotionHeight = headerPromotionEl?.getBoundingClientRect().height || 0;

  return (
    <div
      className={`hero-banner ${banner_type}`}
      style={{'height': `calc(100vh - ${headerHeight}px - ${headerPromotionHeight}px)`}}
    >
      <div
        className="hero-banner__carousel"
        {
          ...{
            'x-data': "extended_carousel",
            'x-init': `$nextTick(() => { initialise(true, false, false, true, ${autoPlay}) })`,
            'x-ref': "carousel"
          }
        }
      >
        {blocks.map((block, index) => {
          const { color_schema, settings } = block;

          let imageUrl = settings.image?.src ?? settings.image;
          let videoSource = settings.video;

          if (isMobile) {
            imageUrl = settings.mobile_image
              ? settings.mobile_image
              : settings.image?.src ?? settings.image;

            videoSource = settings.video_mobile
              ? settings.video_mobile
              : settings.video;
          }

          const defaultUrl = videoSource?.sources?.find(source => source.url)?.url || '';
          const videoSourceUrl = videoSource?.sources[2] ?  videoSource?.sources[2].url :  defaultUrl;
          
          return (
            <div
              key={`hero-banner__block_${index}`}
              className="hero-banner__block"
            >
              <div className={`hero-banner__overlay ${horizontal_content_position_desktop}`}>
                <div className={`hero-banner__content ${vertical_content_position_desktop} color-${color_schema}`}>

                  <Headings text={settings.title} type={'h1'} />
                  <Paragraph text={settings.subtext} type={'b2'} />

                  <div className={`hero-banner__cta ${settings.cta_alignment}`}>
                    {settings.primary_button_text && 
                      <ExtendedButton
                        style={settings.primary_button_style}
                        label={settings.primary_button_text}
                        link={settings.primary_button_url || '#'}
                      />
                    }

                    {settings.secondary_button_text && 
                      <ExtendedButton
                        style={settings.secondary_button_style}
                        label={settings.secondary_button_text}
                        link={settings.secondary_button_url || '#'}
                      />
                    }
                  </div>
                </div>
              </div>
              
              {block.type === 'block_image' &&
                <div className="hero-banner__image-wrap">
                  <div
                    className="hero-banner__image"
                    style={{
                      backgroundImage: `url(${imageUrl})`,
                      backgroundSize: "cover",
                      backgroundPositionY: "center",
                      backgroundPositionX: "center"
                    }}
                  >
                  </div>
                </div>
              }

              {block.type === 'block_video' &&
                <video
                  autoPlay
                  muted
                  loop
                  playsInline
                  className="hero-banner__video"
                >
                  <source src={videoSourceUrl} type="video/mp4" />
                </video>
              }
            </div>
          )
        })}
      </div>
    </div>
  );
}