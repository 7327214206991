import React, { useEffect, useState } from 'react';
import { Headings } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Headings/Headings";
import { Paragraph } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Paragraph/Paragraph";
import ExtendedImage from "@src/stories/components/ExtendedImage/ExtendedImage";
import ExtendedButton from "@src/stories/elements/ExtendedButton/ExtendedButton";
import './ExtendedCollectionBanner.scss';
import ActionBar from "./ActionBar";


const PLACEHOLDER_EMPTY_PROMPT = "Empty";

export default function ExtendedCollectionBanner ({ settings }) {
  const { translations, search = false, action_text, show_actions = false , x_url, instagram_url, facebook_url, linkedin_url , enable_banner = true, blocks } = settings;
  const searchBanner = JSON.parse(search);
  const [query, setQuery] = useState( new URLSearchParams(document.location.search).get("q"));
  const altLinks = { x_url, instagram_url, facebook_url, linkedin_url };
  const searchResultLabel = query === PLACEHOLDER_EMPTY_PROMPT ? `${translations.without_results}` : `${translations.with_results} ${query}`;

  return (
    <div className={`collection-banner ${searchBanner ? 'search': ''}`}>
      { JSON.parse(enable_banner) || searchBanner ? 
        <div className='collection-banner__block-cols'>
          <div className='collection-banner__overlay'>
              <div
                className={`collection-banner__content color-${settings.color_schema} w-full`}
                style={{
                  backgroundColor: settings.text_content_background_color,
                  backgroundImage: `url(${settings.text_content_background_image})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className='collection-banner__breadcrumbs'>
                  <a
                    href="/collections/all"
                    title="Shop All"
                  >
                    {translations.shop_all}
                  </a>
    
                  <span className='icon-chevron-right text-2xl'></span>
                
                  { searchBanner ? 
                    <a
                    href={window.location.href}
                    title={`${translations.search_for} ${query}`}
                    className='collection-banner__breadcrumbs--active capitalize'
                    >
                      {`${translations.search_for} ${query}`}
                    </a> :
                    <a
                    href={settings.url}
                    title={settings.title}
                    className='collection-banner__breadcrumbs--active'
                    >
                      {settings.title}
                    </a> 
                  }
              
                </div>
                <h1 className="h3"> { `${searchBanner ? searchResultLabel : settings.title}`}</h1>
                <Paragraph text={settings.description} type={'b2'} />
                { JSON.parse(show_actions) && <ActionBar label={action_text} altLinks={altLinks} /> }
                { blocks && <div className={`collection-banner__button-container flex items-center`}>
                        { blocks.map((block, index) => (
                            <div key={block.key || index} className="w-1/2">
                                {block.settings.button_label && <ExtendedButton
                                    label={block.settings.button_label}
                                    style={block.settings.button_style}
                                    link={block.settings.button_url}
                                    onClick={undefined}/>}
                            </div>
                        ))}                    
                </div> }
              </div>
          </div>
          <div className="collection-banner__image-wrap">
            { search ?  
            <>
                <ExtendedImage
                imageUrl={settings.image}
                className={"hidden lg:block h-full"}
                />
  
                <ExtendedImage
                imageUrl={settings.mobile_image}
                className={"lg:hidden h-full w-full"}
                />
            </>
            : 
            <div
              className="collection-banner__image"
              style={{
                backgroundImage: `url(${settings.image})`,
                backgroundSize: "cover",
                backgroundPosition: "center"
              }}
            ></div>} 
            
          </div>     
        </div>
      : 
      <div className='collection-banner__breadcrumbs-no-banner p-6'>
        <a href="/collections/all" title="Shop All" > {translations.shop_all} </a>
        <span className='icon-chevron-right text-2xl'></span>
      
        { search ? 
          <a
          href={window.location.href}
          title={`${translations.search_for} ${query}`}
          className='collection-banner__breadcrumbs--active capitalize'
          >
            {`${translations.search_for} ${query}`}
          </a> :
          <a
          href={settings.url}
          title={settings.title}
          className='collection-banner__breadcrumbs--active'
          >
            {settings.title}
          </a> 
        }
    
    </div>
      
    }
    
  
    </div>
  );
}