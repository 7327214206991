import React from "react";
import ExtendedSlider from "@src/stories/components/ExtendedSlider/ExtendedSlider";

import ExtendedFilterCollapsible from '@src/stories/components/ExtendedFilterCollapsible/ExtendedFilterCollapsible'
import ExtendedCheckbox from "@src/stories/elements/ExtendedCheckbox/ExtendedCheckbox";
import ExtendedButton from "@src/stories/elements/ExtendedButton/ExtendedButton";

export const ExtendedCollectionMobileFilterTakeover = ({ modal_id, isOpen, filters, onClose, color_schema }) => {
    if (!isOpen) return null;

    return (
        <div id={`${modal_id}`} className={`main-collection__mobile-sidebar-takeover ${color_schema ? `color-${color_schema}` : ''}`} style={{backdropFilter: "blur(6px)"}}>
            <div className="main-collection__mobile-sidebar-takeover-header">
                <div className="main-collection__mobile-sidebar-takeover-header-sticky"> <span> Filters </span> <span className="icon-chevron-up text-[24px]" onClick={() => onClose()}></span></div>
       
            </div>
            <div className='main-collection__mobile-sidebar-takeover-container'>
                <div className="flex flex-col justify-between gap-2">
                    {filters.map((filter,index) => {
                        switch(filter.filter) {
                            case 'price':
                                return (
                                <ExtendedFilterCollapsible
                                    active
                                    key={`${filter.filter}-${index}`}
                                    title={filter.label}
                                    filter={filter.filter}
                                >
                                    {filter.options.map((option, index) => {
                                    return (
                                        <ExtendedCheckbox
                                        key={filter+option+index}
                                        label={option}
                                        />
                                    )
                                    })}
                                    <ExtendedSlider
                                    min={0}
                                    max={1000}
                                    attachCurrency 
                                    ></ExtendedSlider>
                                </ExtendedFilterCollapsible>
                                )
                            default:
                                return (
                                <ExtendedFilterCollapsible
                                    active
                                    title={filter.label}
                                    filter={filter.filter}
                                >
                                    {filter.options.map((option, index) => {
                                    return (
                                        <ExtendedCheckbox
                                        key={filter+option+index}
                                        label={option}
                                        />
                                    )
                            })}
                    </ExtendedFilterCollapsible>
                    )
                }
                    })}
                </div>
            </div>
            <div className="main-collection__mobile-sidebar-takeover-button-container">
                <ExtendedButton
                label={'Search'}
                style="secondary"
                onClick={() => onClose()}
                />
            </div>
        </div>
    )
}

ExtendedCollectionMobileFilterTakeover.propTypes = {};
