import * as React from "react";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { mobileBreakpoint } from "@src/../entrypoints/theme";
import { useState } from "react";
import AccountTabs from "../AccountTabs";
import AccountForm from "../AccountForm";
import AccountInfo from "../AccountInfo";
import useHashChecker from "@src/hooks/useHashCheck";

export const FoodClub = ({ settings }) => {
    const hash = useHashChecker(); // Get the current URL hash
    const [isLogin, setIsLogin] = useState(hash === '#login');
    const [showBusinessFields, setShowBusinessFields] = useState(false);
    const { image, bg_image } = settings?.section;
    const toggleForm = (isLoginState) => {
        setIsLogin(isLoginState);
    };

    const handleBusinessCheckbox = (isChecked) => {
        setShowBusinessFields(isChecked);
    };

    return (
        <div className="account-page min-h-screen flex flex-col items-center justify-center">
            <div className="account-page__container w-full space-y-8">
                <div className="account-page__wrapper flex flex-col md:flex-row w-full gap-8">
                    {/* Left Side (Form Section) */}
                    <div className="account-page__form-container w-full lg:basis-[480px] p-8">
                        <AccountTabs isLogin={isLogin} toggleForm={toggleForm} />
                        <AccountForm
                            settings={settings}
                            isLogin={isLogin}
                            showBusinessFields={showBusinessFields}
                            handleBusinessCheckbox={handleBusinessCheckbox}
                        />
                    </div>

                    {/* Right Side (Info Section) */}
                    <AccountInfo image={image} bg_image={bg_image}/>
                </div>
            </div>
        </div>
    );
};

export default FoodClub;
