import React, { useState } from "react";
import ExtendedImage from "@src/stories/components/ExtendedImage/ExtendedImage";

import { ExtendedProductPaymentModal } from "./ExtendedProductPaymentModal";

const ProductPayments = ({ settings }) => {
    const { payment_image, payment_label } = settings;
    const [openModal, setOpenModal ] = useState(false);

    const handleClose = () => {
        document.querySelector('.pdp-details')?.classList.remove('z-40');
        setOpenModal(false);
    }

    const handleOpen = () => {
        document.querySelector('.pdp-details')?.classList.add('z-40');
        setOpenModal(true)
    }
    return (
        <div>
            <div className="product-payments b3">
                    <div className="product-payments__label" onClick={()=> {handleOpen()}}> { payment_label } </div>
                    <div> 
                        <ExtendedImage
                            imageUrl={payment_image}
                            altText={"product-payments"}
                            className="product-payments__image"
                        />
                    </div>

            </div>
            <ExtendedProductPaymentModal  modal_id={'product_payment_modal'} settings={settings} isOpen={openModal} onClose={()=> {handleClose()}} />
        </div>
    );
};

export default ProductPayments;
