import React from "react";
import ExtendedImage from "@src/stories/components/ExtendedImage/ExtendedImage";
import { Headings } from "@mindarc/arctheme-components/frontend/src/stories/components/elements/Headings/Headings";
import "./FeaturedRecipe.scss";

const ExtendedFeaturedRecipe = ({ settings, recipe }) => {
  const { header, translations } = settings;
  const { url, title, featured_image, tags, sub_title  } = recipe;
  const badges = tags && tags.map(tag => tag.split('|'));
  return (
      <div className="featured-recipe">
          <div className="featured-recipe__header"> 
            <div className="featured-recipe__header-title"> {header} </div>
          </div>
          <div className="featured-recipe__main"> 
            <div className="featured-recipe__image"> 
                <a href={`${url}`}>
                  <ExtendedImage
                      imageUrl={featured_image}
                      altText={title}
                      />
                </a>
            </div>
            <div className="featured-recipe__content"> 
               {badges &&   
                (
                  <div className={`recipe-tile__badges`}>
                      {badges.map((badge, index) => (
                        <div key={`badge_${index}`}>
                          <div
                                className="product-tile__badge"
                                style={{ backgroundColor: `${badge[2] || '#FFF'}`, color: `${badge[3] || '#000'}` }}
                          >
                                <span>{badge[0]}</span>
                                <span className={`icon-${badge[1] || 'no-icon'}`}></span>
                              </div>
                          </div>
                          ))}
                  </div>
                  )
                }
                <div className="featured-recipe__title">   <a href={`${url}`}> < Headings text={`${title}`} type={"h4"} /> </a> </div>

                <div className="featured-recipe__subtitle"> { sub_title } </div>
                <div className="featured-recipe__read"> <a href={`${url}`}> {translations.read_more} </a> </div>
            </div>
          </div>
      </div>
  );
};

export default ExtendedFeaturedRecipe;
