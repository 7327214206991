export default {
  name: "shippingitem",
  component() {
    return {
      init() {
        // Listen to the 'add' event of the Liquid Ajax Cart
        document.addEventListener("liquid-ajax-cart:request-start", (event) => {
          const { requestState } = event.detail;

          if (requestState.requestType === "add") {
            // Call to add the shipping product after adding the actual product
            this.checkAndUpdateShippingProduct();
          }
        });
      },
      checkAndUpdateShippingProduct(groupedItems, shipping_id) {
        return fetch("/cart.js")
          .then((response) => response.json())
          .then((cart) => {
            // Create an array of promises for addShippingProduct calls
            const addShippingPromises = groupedItems.map((groupItem, index) => {
              const shippingItem = cart.items.find(
                (item) =>
                  item.variant_id === shipping_id &&
                  item.properties._bundleId === groupItem.consignmentKey &&
                  item.properties._price === groupItem.shipping_price
              );
      
              console.log(index + 1);
              console.log(groupItem);
              console.log(shippingItem);
      
              // If shippingItem is not found, add it and return the promise from addShippingProduct
              if (!shippingItem) {
                return this.addShippingProduct(
                  { id: groupItem.consignmentKey, price: groupItem.shipping_price },
                  shipping_id
                );
              }
      
              // If the item already exists, return a resolved promise
              return Promise.resolve();
            });
      
            // Wait for all addShippingProduct calls to complete
            return Promise.all(addShippingPromises);
          })
          .catch((error) => console.error("Error fetching cart:", error));
      },
      
      // Function to add the shipping product
      addShippingProduct(property, shipping_id) {
        const body = {
          items: [
            {
              id: shipping_id,
              quantity: 1,
              properties: {
                "_bundleId": property.id,
                "_price": property.price,
                "_is_shipping_line_item": 1
              }
            },
          ],
        };
      
        return new Promise((resolve, reject) => {
          const options = {
            firstCallback: (requestState) => {
              console.log("Shipping product is being added...");
            },
            lastCallback: (requestState) => {
              console.log("Shipping product added to the cart.");
              resolve();  // Resolve the promise when the lastCallback is called
            },
            errorCallback: (error) => {
              console.error("Error adding shipping product:", error);
              reject(error);  // Reject the promise on error
            }
          };
      
          liquidAjaxCart.add(body, options);
        });
      },

      // Function to update the shipping product quantity
      updateShippingProduct(lineItemKey, newQuantity) {
        const body = {
          updates: {
            [lineItemKey]: newQuantity,
          },
        };

        const options = {
          firstCallback: (requestState) => {
            console.log("Shipping product quantity is being updated...");
          },
          lastCallback: (requestState) => {
            console.log("Shipping product quantity updated.");
          },
        };

        liquidAjaxCart.update(body, options);
      },

      updateShippingProducts(lineItems, startLoading, stopLoading) {
        startLoading();
        const body = {
          updates: lineItems
        };

        const options = {
          firstCallback: (requestState) => {
            console.log("Shipping product quantity is being updated...");
          },
          lastCallback: (requestState) => {
            console.log("Shipping product quantity updated.");
            stopLoading();
          },
        };

        liquidAjaxCart.update(body, options);
      },
    };
  },
};
