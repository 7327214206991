import React, { ReactNode, useState } from "react";
import { clsx } from 'clsx';

import './ExtendedFilterCollapsible.scss'
import ExtendedButton from "@src/stories/elements/ExtendedButton/ExtendedButton";

type CollapsibleProps = {
  title: string,
  filter: string,
  openIcon?: string,
  closeIcon?: string,
  children: ReactNode,
  active?: boolean,
  disabled?: boolean,
  desktopDisabled?: boolean
  onClearClick?: Function,
  clearLabel?: string
};

export default function ExtendedFilterCollapsible(props: CollapsibleProps) {
  const {
    title,
    filter,
    active = false,
    openIcon = 'icon-chevron-up',
    closeIcon = 'icon-chevron-down',
    children,
    disabled = false,
    desktopDisabled = false,
    onClearClick,
    clearLabel = 'Clear'
  } = props;

  const [isActive, setIsActive] = useState(active);

  function toggle() {
    if (disabled) return;

    setIsActive(!isActive);
  }

  function handleClearClick () {
    if (onClearClick) onClearClick(filter)
  }

  return (
    <div
      className={clsx('filter-collapsible', {
        'collapsible': true,
        'collapsible--active': isActive,
        'collapsible--disabled': disabled,
        'collapsible--desktop-disabled': desktopDisabled
      })}
    >
      <div className="collapsible__title">
        {!disabled &&
          <>
            <span
              className={'collapsible__expand-icon ' + openIcon}
              onClick={() => toggle()}
            ></span>

            <span
              className={'collapsible__collapse-icon ' + closeIcon}
              onClick={() => toggle()}
            ></span>
          </>
        }

        <span
          className="collapsible__title-title"
          onClick={() => toggle()}
        >
          {title}
        </span>

        <ExtendedButton 
          style="text"
          label={`${clearLabel}`}
          className='collapsible__clear-button'
          onClick={() => handleClearClick()}
        />
      </div>

      <div className="collapsible__content">
        {children}
      </div>
    </div>
  )
};
