import * as React from "react";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";
import { mobileBreakpoint } from "../../../../entrypoints/theme";
import ExtendedProductTile from "@stories/components/ProductTile/ExtendedSearchProductTile";
import ExtendedArticleTile from "@src/stories/ecommerce/HeaderSearch/ExtendedSearchBlogArticleTile";
import ExtendedRecipeTile from "@src/stories/ecommerce/HeaderSearch/ExtendedSearchRecipeTile";
import {useEffect, useState} from "react";

export const HeaderSearchResultsProducts = ({ settings, type, results }) => {
    const isMobile = useMobileBreakpoint(mobileBreakpoint);

    return (
        <>
            {type == 'products' && results && results.map((product, index) => (
                <ExtendedProductTile product={product} key={`${product.id}_${index}`} siblings={undefined}/>
            ))}
            {type == 'articles' && results.map(article => (
                <ExtendedArticleTile article={article} key={article.id ? article.id : article.objectID} />
            ))}
              {type == 'recipes' && results.map(article => (
                <ExtendedRecipeTile article={article} key={article.id ? article.id : article.objectID} />
            ))}
        </>
    );
}

export default HeaderSearchResultsProducts;