import React, { ReactNode, useState, useCallback, useEffect } from 'react';
import { debounce } from "alpinejs/src/utils/debounce";

import Paragraph from '@src/stories/elements/Paragraph';
import ExtendedTextField from '@src/stories/elements/ExtendedTextField/ExtendedTextField';

import { formatPrice, capitalizeWords, formatDateTime } from "frontend/js/helpers";
import { getShippingRates, getSuburbs } from './macshipApi';
import { useCart } from 'frontend/js/useShopifyCart';

import './ExtendedShippingCalculator.scss';
import ExtendedSelectField from '@src/stories/elements/ExtendedSelectField/ExtendedSelectField';

type ExtendedShippingCalculatorProps = {
  settings?: any,
  chilled?: boolean,
  children?: ReactNode,
  shipping_rates?: any,
  shipping_cost_id?: string,
  free_price_bypass?: string,
  logged_out_threshold?: string,
  logged_in_threshold?: string,
  customer?: string
};

export default function ExtendedShippingCalculator ({ settings:sectionSettings , shipping_rates, shipping_cost_id: shipping_cost_id_props, logged_in_threshold: logged_in_threshold_props , logged_out_threshold: logged_out_threshold_props,  free_price_bypass: free_price_bypass_props, customer: customer_props, children }: ExtendedShippingCalculatorProps) {
  const [postcode, setPostcode] = useState('');
  const [suburbs, setSuburbs] = useState([]);
  const [selectedSuburb, setSelectedSuburb] = useState(null);
  const [suburbPlaceholder, setSuburbPlaceholder] = useState('Enter postcode first');
  const [isLoading, setIsLoading] = useState(false);
  const [shippingRates, setShippingRates] = useState([]);
  const { cart } = useCart();

  const { settings , chilled = false, shipping_rates: shippingRatesMap, shipping_cost_id, logged_in_threshold, logged_out_threshold,  free_price_bypass, customer } = sectionSettings;

  const shippingMap = shipping_rates || shippingRatesMap;
  const shipping_id = shipping_cost_id || shipping_cost_id_props;
  const customer_threshold = logged_in_threshold || logged_in_threshold_props;
  const guest_threshold = logged_out_threshold || logged_out_threshold_props;

  const shippingItems = cart.items.filter(item => item.variant_id == shipping_id);
  const totalShippingPrice = shippingItems && shippingItems.reduce((acc, item) => acc + item.price, 0);
  const totalCartPrice = ((cart.total_price - totalShippingPrice) / 100).toFixed(2);

  let availFree = false;
  if(customer || customer_props){
    availFree = Number(totalCartPrice) > Number(customer_threshold);
  } else {
    availFree = Number(totalCartPrice) > Number(guest_threshold);
  }
  const showFreeShippingDelivery = !chilled  && availFree;  

  const handleOnChange = useCallback(
    debounce((value) => {
      setShippingRates([]);
      setSelectedSuburb(null);
      setSuburbs([]);
      setSuburbPlaceholder(value ? '' : 'Enter postcode first')
      setPostcode(value);
    }, 500), []
  );

  async function handleSuburbSelect (option) {
    setSelectedSuburb(option);
    setIsLoading(true);

    try {
      const response = await getShippingRates(option.postcode, option.value, option.value , 1 , chilled );
      const results = response?.data?.object.routes.map((item, index) => {
        const byPass = item.priceDisplay == 0 && !showFreeShippingDelivery; // Bypass REG rate price when treshold is met
        const byPassPrice = free_price_bypass || free_price_bypass_props;
        return {
          id: `${item.requestId}_${index}`,
          label: item.carrierService.displayName,
          price: byPass ? (Number(byPassPrice)/100).toFixed(2) : item.priceDisplay,
          eta: item.despatchOptions[0]?.etaLocal,
          rate_id: item.carrierService.id,
        }
      });

    

      setShippingRates(results);
      setIsLoading(false);

    } catch (error) {
      console.error('Failed to get shipping rates: ', error);
      setShippingRates([]);
      setIsLoading(false);
    }
  }


  useEffect(() => {
    const _getSuburbs = async () => {
      setIsLoading(true);

      try {
        const response = await getSuburbs(postcode);

        if (!Array.isArray(response?.data)) throw response.data;

        const results = response?.data.map(item => {
          const suburb = capitalizeWords(item.suburb.toLowerCase());

          return {
            ...item,
            value: suburb,
            label: `${suburb}, ${item.postcode}`
          }
        });

        setSuburbPlaceholder('Select Suburb/City');
        setSuburbs(results);
        setIsLoading(false);

      } catch (error) {
        console.error('Failed to get suburb: ', error);
        setSuburbPlaceholder('No suburb found');
        setIsLoading(false);
      }
    }

    if (postcode.length >= 4) {
      console.log('getting suburbs');
      _getSuburbs();
    }

  }, [postcode])

  return (
    <div className='shipping-calculator'>
      <div className="flex flex-row gap-2">
        {children
          ? children
          : <Paragraph
              text={settings?.header || 'Delivery'}
              type='b3 shipping-calculator__heading'
            />
        }
      </div>

      <div className='shipping-calculator__body'>
        <div className='shipping-calculator__form'>
          <ExtendedTextField
            icon='search'
            inputType='number'
            iconRight
            placeholder={settings?.input_placeholder || 'Enter postcode'}
            className='shipping-calculator__form__postcode-field'
            disabled={isLoading}
            onChange={(event) => { 
          
                handleOnChange(event.target.value);
            
              }}
          />

          <ExtendedSelectField
            disabled={!suburbs.length || isLoading}
            options={suburbs}
            placeholder={suburbPlaceholder}
            className='shipping-calculator__form__suburb-field'
            value={selectedSuburb && selectedSuburb.value}
            onChange={(option) => handleSuburbSelect(option)}
          />
        </div>

        {isLoading &&
          <div className='shipping-calculator__loader'>
            <span className='icon-loader-alt'></span>
          </div>
        }

        {selectedSuburb &&
          <>
            {shippingRates.length && !isLoading
              ? <ul className='shipping-calculator__results'>
                {
                  shippingRates.filter(item => shippingMap[item.rate_id]?.type != 'Chilled Transit Time').map(result => {
                    return (
                      <div
                        key={result.id}
                        className='shipping-calculator__results__item'
                      >
                        <div className='shipping-calculator__results__label-price'>
                          <div>
                            { shippingMap[result.rate_id]?.display || result.label}
                          </div>
                          <div>
                            {result.price > 0 ? formatPrice(result.price, '', false) : 'Free'}
                          </div>
                        </div>

                        <div className='shipping-calculator__results__eta'>
                          Estimated arrival on {formatDateTime(result.eta).split('at') && formatDateTime(result.eta).split('at')[0]}
                        </div>
                      </div>
                    )
                  })
                }
              </ul>

              : postcode &&  shippingRates.length == 0 &&
                <div className='shipping-calculator__no-results'>
                  {settings?.no_result_text || 'No Results'}
                </div>
            }
          </>
        }
      </div>
    </div>
  )
}