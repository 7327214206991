import React, { useState, useRef } from "react";
import ExtendedSelectField from "@src/stories/elements/ExtendedSelectField/ExtendedSelectField";
import { ExtendedRecipeListingMobileFilterTakeover } from "./ExtendedRecipeListingMobileFilterTakeover";

const options = [
    { value: 'products', label: 'Products' },
    { value: 'recipes', label: 'Recipes' },
    { value: 'articles', label: 'Blogs' }
];

export default function ExtendedRecipeListingMobileFilter ({filters, color_schema, selectedFilters, setSelectedFilters, translations }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const ref = useRef(null);
  const onFocus = () => {
    setIsModalOpen(true);
    console.log(ref);
  }

  const onClose = () => {
    setIsModalOpen(false);
  }

  return (
    <div className="recipe-listing__mobile-sidebar">
        <div className="recipe-listing__mobile-sidebar-select" onClick={onFocus}> 
            Filters
            <span className="icon icon-chevron-down text-[24px]"> </span>
        </div>
        < ExtendedRecipeListingMobileFilterTakeover modal_id={'mobile-filter-takeover'} translations={translations} filters={filters} selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} isOpen={isModalOpen}  onClose={onClose} color_schema={color_schema} />
     
    </div>
  );
};
