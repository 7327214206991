import React from "react";

import ExtendedProductTile from "@src/stories/components/ExtendedProductTile/ExtendedProductTile";
import ExtendedButton from "@src/stories/elements/ExtendedButton/ExtendedButton";
import PromotionTile from "@stories/ecommerce/PromotionalTile/PromotionTile";
import useMobileBreakpoint from "@src/hooks/useMobileBreakpoint";

export default function ExtendedCollectionProductList ({ settings, products = [], localization }) {
  const { add_to_cart_url, star_ratings_app, translations } = settings;
  const {currency_symbol} = localization?.country;
  const isMobile = useMobileBreakpoint('1024px');

  return (
    <div className="collection-product-list">
      <div className="collection-product-list__products">
        {products.map((product: any, index) => {
          if (product.type == "promo") {
            if (!isMobile) {
              return (
                <PromotionTile
                  key={`${product.id}_${index}`}
                  promotion={product}
                />
              )
            }
            return null
          }          

          return (
            <ExtendedProductTile
              key={`${product.id}_${index}`}
              product={product}
              currentVariant={product.selected_or_first_available_variant}
              addToCartUrl={add_to_cart_url}
              starRatingsApp={star_ratings_app}
              translations={translations}
              currencySymbol={currency_symbol}
            />
          )
        })}
      </div>

      <div className="collection-product-list__button"> 
        <ExtendedButton
          label={'Load More'}
          style="secondary"
        />
      </div>
    </div>
  );
};
